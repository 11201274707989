import React from 'react';
import { withStyles } from 'tss-react/mui';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import AdminTable from '../../../common/components/table/AdminTable';
import ApiService from '../../../common/services/ApiService';
import MetadataService from '../../../common/services/MetadataService';
import PointsHistoryFilters from './PointsHistoryFilters';
import DetailCell from '../../components/users/user-point-request/DetailCell';

const styles = theme => ({
  exportHeader: {
    padding: `${theme.typography.pxToRem(22)} ${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(25)}`,
  },
  downloadPdf: {
    marginRight: theme.typography.pxToRem(20),
  }
});

class PointsHistory extends React.Component {

  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.meta = new MetadataService();
    this.state = {
      filters: { user_active_eq: "1" },
    };
  }

  handleApplyFilters = (filters) => {
    this.setState({ filters: filters });
  };

  handleApplySorting = (order, orderBy) => {
    this.setState({ order: order, orderBy: orderBy });
  };

  handleExport = () => {
    var sortingParams = { direction: this.state.order, field: this.state.orderBy };
    var searchRequest = this.api.searchUrl(this.state.filters, null, null, sortingParams, null);

    var apiUrl = '/api/v1/reports/points_history.csv' + (searchRequest !== '' ? '?' : '');
    var csvUrl = apiUrl + searchRequest;
    return csvUrl;
  };

  handleDownloadPdf = () => {
    var sortingParams = { direction: this.state.order, field: this.state.orderBy };
    var searchRequest = this.api.searchUrl(this.state.filters, null, null, sortingParams, null);

    var apiUrl = '/api/v1/reports/points_history.pdf' + (searchRequest !== '' ? '?' : '');
    var pdfUrl = apiUrl + searchRequest;
    return pdfUrl;
  };

  detailMetadata = () => {
    return {
      label: 'Detail',
      type: 'component',
      component: DetailCell,
    };
  };

  evidenceAttachedMetadata = () => ({
    label: 'Evidence Attached',
    type: 'boolean'
  });

  render() {
    const { classes, passportPeriod, institute } = this.props;
    const { filters } = this.state;

    const fields = [
      'date',
      'user_given_name',
      'user_family_name',
      institute.custom_user_field_1_enabled ? 'custom_1' : null,
      institute.custom_user_field_2_enabled ? 'custom_2' : null,
      'detail',
      'evidence_attached',
      'points',
      'approval_reason',
      'type'
    ].filter(Boolean);
    const metadata = {
      ...this.meta.getWithInstituteContext('points_histories', institute),
      detail: this.detailMetadata(),
      evidence_attached: this.evidenceAttachedMetadata(),
    }

    if (this.state.loading == true) {
      return (<Paper>Loading...</Paper>);
    }

    return (
      <Grid container className={classes.pointsHistoryReport} spacing={2}>
        <Grid item xs={12}>
          <Paper>
            <PointsHistoryFilters
              handleApplyFilters={this.handleApplyFilters}
              passportPeriod={passportPeriod}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Grid
              container
              className={classes.exportHeader}
              justifyContent="space-between"
            >
              <Typography variant="h6">Points History</Typography>
              <Grid item>
                <a href={`${this.handleDownloadPdf()}`} className={classes.downloadPdf} target="_blank">
                  <Button color="secondary" size="small" variant="contained">
                    <Typography variant="button">Download PDF</Typography>
                  </Button>
                </a>
                <a href={`${this.handleExport()}`} target="_blank">
                  <Button color="secondary" size="small" variant="contained">
                    <Typography variant="button">Export</Typography>
                  </Button>
                </a>
              </Grid>
            </Grid>

            <AdminTable
              showTablePagination
              fields={fields}
              filters={filters}
              handleApplySorting={this.handleApplySorting}
              metadata={metadata}
              onCellClicked={{}}
              onCellEdit={{}}
              resource="reports/points_history"
            />

          </Paper>
        </Grid>
      </Grid>
    );

  }

}

export default withStyles(PointsHistory, styles, { withTheme: true });
