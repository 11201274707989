import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';
import Divider from '@mui/material/Divider';

const styles = theme => ({
  dividerStyle: {
    backgroundColor: '#8d8d8d',
    border: '0.5px solid #8d8d8d',
    opacity: 0.5,
  }
});

class StyledDivider extends React.Component {

  render() {
    const { classes } = this.props;

    return(
      <Fragment><Divider className={`${classes.dividerStyle} ${this.props.extraStyles}`} /></Fragment>
    );
  }

}

export default withStyles(StyledDivider, styles, { withTheme: true });
