import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';

import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';

import ApiService from '../../../common/services/ApiService';
import WaveSelectField from '../../../common/components/form/WaveSelectField';
import WaveTextField from '../../../common/components/form/WaveTextField';

const styles = theme => ({
  closeModal: {
    color: theme.typography.color.primary,
    cursor: 'pointer',
    opacity: 0.3,
  },
  copyDataFrom: {
    paddingLeft: theme.typography.pxToRem(13),
  },
  createInstituteFields: {
    marginBottom: theme.typography.pxToRem(10),
  },
  createInstituteModal: {
    backgroundColor: theme.palette.background.paper,
    left: '50%',
    margin: '0',
    padding: `${theme.typography.pxToRem(24)} ${theme.typography.pxToRem(29)}`,
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
  },
  createInstituteModalHeader: {
    marginBottom: theme.typography.pxToRem(22),
  },
  createInstitute: {
    marginBottom: theme.typography.pxToRem(6),
  },
  newInstituteName: {
    paddingRight: theme.typography.pxToRem(13),
  },
  newInstituteMenuItem: {
    color: theme.typography.color.primary,
    opacity: 0.54
  }
});

class InstitutePicker extends React.Component {

  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.state = {
      anchorEl: null,
      copyInstituteId: null,
      copyInstituteOptions: [],
      name: null,
      open: false,
      validationErrors: [],
    }
  }

  componentDidMount() {
    this.api.query('/api/v1/institutes/options')
    .then((res) => {
      this.setState({ copyInstituteOptions: res });
    });
  }

  errorHelpers = (field) => {
    if (this.state.validationErrors.hasOwnProperty(field)) {
      return this.state.validationErrors[field][0];
    }
    return null;
  };

  errorStatus = (field) => {
    return this.state.validationErrors.hasOwnProperty(field)
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleCreateInstitute = () => {
    const url = '/api/v1/institutes';
    const instituteData = {
      institute: {
        name: this.state.name
      },
      copy_from_institute_id: this.state.copyInstituteId
    };

    this.api.post(url, instituteData)
    .then((res) => {
      if (res.status === 201) {
        const newInstitute = res.json.data;
        this.handleSelect(newInstitute.id);
      } else if (res.status === 422) {
        this.setValidationErrors(res.json);
      } else {
        console.error('Unknown response', res);
      }
    }).catch((ex) => console.error(ex));
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({ [name]: value });
  };

  handleSelect = (instituteId) => {
    this.setState({ anchorEl: null }, () => this.props.onInstituteChange(instituteId));
  };

  makeFieldProps = (fieldName) => {
    return {
      name: `${fieldName}`,
      onChange: this.handleInputChange,
      value: this.state[fieldName],
    };
  };

  setValidationErrors(json) {
    var errorMessage = json.title;

    if (json.messages.hasOwnProperty('base')) {
      errorMessage += " - " + json.messages.base.join(', ');
    }

    this.setState({ validationErrors: json.messages });
  }

  render() {
    const { anchorEl, copyInstituteId, copyInstituteOptions, name, open } = this.state;
    const { classes, institutes } = this.props;

    const menuItems = institutes.map((institute) => {
      return (<MenuItem component='div' key={institute.id} onClick={() => this.handleSelect(institute.id)}>{institute.name}</MenuItem>)
    });

    return (
      <Fragment>
        <ButtonBase
          style={{marginTop: 20, float: 'left'}}
          onClick={this.handleClick}
        >
          <img style={{height: 35}} src={this.props.institute.logo_url} />
          <ArrowDropDownIcon style={{marginTop: 12, marginLeft: 10}} color="primary" />
        </ButtonBase>
        <Menu
          anchorEl={anchorEl}
          id="simple-menu"
          onClose={this.handleClose}
          open={Boolean(anchorEl)}
        >
          {menuItems}
          <MenuItem
            component="div"
            className={classes.newInstituteMenuItem}
            onClick={() => this.setState({ open: true })}
          >
            Add new...
          </MenuItem>
        </Menu>
        <Modal
          open={Boolean(open)}
          onClose={() => this.setState({ open: false })}
        >
          <div className={classes.createInstituteModal}>
            <Grid
              container
              className={classes.createInstituteModalHeader}
              justifyContent="space-between"
            >
              <Typography variant="h5">
                Create New Institute
              </Typography>
              <a onClick={() => this.setState({ open: false })}>
                <CloseIcon className={classes.closeModal}/>
              </a>
            </Grid>
            <Grid
              container
              className={classes.createInstituteFields}
              justifyContent="space-between"
            >
              <Grid item xs={6} className={classes.newInstituteName}>
                <WaveTextField
                  error={this.errorStatus('name')}
                  helperText={this.errorHelpers('name')}
                  label="Institute Name"
                  {...this.makeFieldProps('name')}
                />
              </Grid>
              {copyInstituteOptions &&
                <Grid item xs={6} className={classes.copyDataFrom}>
                  <WaveSelectField
                    noValidation
                    label="Copy Data From"
                    menuItems={copyInstituteOptions}
                    {...this.makeFieldProps('copyInstituteId')}
                  />
                </Grid>
              }
            </Grid>
            <Button
              className={classes.createInstitute}
              color="secondary"
              onClick={this.handleCreateInstitute}
              size="small"
              variant="contained"
            >
              <Typography variant="button">Create</Typography>
            </Button>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

export default withStyles(InstitutePicker, styles, { withTheme: true });
