import React from 'react';
import { withStyles } from 'tss-react/mui';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import ApiService from '../../../common/services/ApiService';
import DepartmentsSidebar from './DepartmentsSidebar';
import ManageBusinessIdeals from './ManageBusinessIdeals';

const styles = theme => ({
  businessIdealsFormPadding: {
    padding: theme.typography.pxToRem(25),
  },
  containerLeftSpacing: {
    paddingRight: theme.typography.pxToRem(26),
  },
  containerRightSpacing: {
    paddingLeft: theme.typography.pxToRem(26),
  },
});

class BusinessIdeals extends React.Component {

  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.state = {
      employmentType: null,
      employmentTypeOptions: [],
      enabled: true,
      selectedDepartment: null,
      selectedDepartmentId: null,
    };
  }

  componentDidMount() {
    this.getEmploymentTypeOptions();
  };

  departmentCheck = (selectedDepartment) => {
    if (selectedDepartment === null) {
      return (<div>Select a department</div>)
    } else if (selectedDepartment === 'none') {
      return (<div>No available departments</div>)
    } else {
      return (
        <ManageBusinessIdeals
          employmentType={this.state.employmentType}
          onDataLoaded={this.dataLoaded}
          onReloadData={this.reloadData}
          responseLabels={this.props.responseLabels}
          selectedDepartment={this.state.selectedDepartment}
        />
      );
    }
  };

  dataLoaded = () => {
    this.setState({ enabled: true });
  }

  reloadData = () => {
    this.setState({ enabled: false });
  };

  getDepartment = (department) => {
    if (department.id !== this.state.selectedDepartmentId) {
      this.setState({
        enabled: false,
        selectedDepartment: department,
        selectedDepartmentId: department.id,
      });
    }
  };

  getEmploymentType = (type) => {
    this.setState({ employmentType: type });
  };

  getEmploymentTypeOptions = () => {
    this.api.query('/api/v1/employment_types/options').then((employmentTypes) => {
      var employmentTypesList = Object.keys(employmentTypes).map((key) => {
        return employmentTypes[key];
      });
      this.setState({ employmentTypeOptions: employmentTypesList });
      this.getFirstEmploymentType(employmentTypesList);
    }).catch(this.processError);
  };

  getFirstEmploymentType = (employmentTypes) => {
    employmentTypes.length !== 0 ? this.getEmploymentType(employmentTypes[0]) : this.getEmploymentType('Not Selected');
  };

  render() {
    const { classes } = this.props;
    const { employmentType, employmentTypeOptions, enabled, selectedDepartmentId, selectedDepartment } = this.state;

    if ((employmentTypeOptions === null || employmentTypeOptions.length === 0) && employmentType === null) {
      return(<div>Loading...</div>)
    }

    return (
      <Grid container>
        <Grid item className={classes.containerLeftSpacing} xs={4}>
          <Paper>
            {employmentType &&
              <DepartmentsSidebar
                employmentType={employmentType}
                employmentTypeOptions={employmentTypeOptions}
                enabled={enabled}
                handleEmploymentTypeChange={this.getEmploymentType}
                onDepartmentSelect={this.getDepartment}
                selectedItem={selectedDepartmentId}
              />
            }
          </Paper>
        </Grid>
        <Grid item className={classes.containerRightSpacing} xs={8}>
          <Paper className={classes.businessIdealsFormPadding}>
            {this.departmentCheck(selectedDepartment)}
          </Paper>
        </Grid>
      </Grid>
    );
  }

}

export default withStyles(BusinessIdeals, styles, { withTheme: true });
