import React from 'react';
import { withStyles } from 'tss-react/mui';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TrashIcon from '../../../common/icons/TrashIcon';

const styles = theme => ({
  deletePdfIcon: {
    color: theme.typography.color.primary,
    marginRight: theme.typography.pxToRem(20),
    verticalAlign: 'middle',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  pdfLink: {
    color: theme.typography.color.primary,
    '&:hover': {
      color: theme.typography.color.primary,
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  },
  hideInput: {
    display: 'none',
  },
  pdfButton: {
    marginBottom: theme.typography.pxToRem(25),
  },
});

class PdfUpload extends React.Component {

  render() {
    const { classes } = this.props;

    return (
      <div>
        { this.props.editEnabled &&
          <Button
            className={classes.pdfButton}
            color="secondary"
            component="label"
            size="small"
            variant="contained"
          >
            Upload
            <input
              accept="application/pdf"
              className={classes.hideInput}
              onChange={(event) => {
                this.props.onProgramPdfChange(event.target.files[0]);
                event.target.value = null;
              }}
              type="file"
            />
          </Button>
        }

        {this.props.program.pdf_filename === null ? '' :
        <Typography>
          <a onClick={() => { this.props.onProgramPdfChange('_destroy') }}>
            <TrashIcon className={classes.deletePdfIcon} />
          </a>
          { this.props.program.pdf_url ?
            <a className={classes.pdfLink} target="_blank" href={this.props.program.pdf_url}>{this.props.program.pdf_filename}</a>
              :
            this.props.program.pdf_filename
          }

        </Typography>
        }
        <Typography color="error">
          {this.props.fieldErrorMessage}
        </Typography>
      </div>
    );
  }

}

export default withStyles(PdfUpload, styles, { withTheme: true });
