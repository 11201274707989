import React, { Fragment } from 'react';
import Paper from '@mui/material/Paper';
import ApiService from '../../../common/services/ApiService';
import EducatorProfileList from './EducatorProfileList';
import ProfileListItem from './ProfileListItem';
import StyledDivider from '../../../common/components/misc/StyledDivider';
import SupervisorFilter from './SupervisorFilter';
import { withStyles } from 'tss-react/mui';

const styles = theme => ({
  emptyPadding: {
    padding: theme.typography.pxToRem(25),
  },
});

class EducatorSidebar extends React.Component {

  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.state = {
      namedFilter: 'open',
      profiles: [],
    };
  }

  componentDidMount() {
    this.getProfiles();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.namedFilter !== this.state.namedFilter) {
      this.getProfiles();
    }
    if (prevProps.supervisor.id !== this.props.supervisor.id) {
      this.getProfiles();
    }
    if (prevProps.refreshTick != this.props.refreshTick) {
      this.getProfiles();
    }
    if ((prevProps.selectedItem !== this.props.selectedItem) && this.state.namedFilter === 'open') {
      this.reloadProfiles();
    }
  };

  getFirstProfile = (profiles) => {
    profiles === undefined || profiles.length === 0 ?
    this.props.onProfileSelected('none', null) :
    this.props.onProfileSelected(profiles[0]);
  };

  getProfileList = (profiles) => {
    return Object.keys(profiles).map(obj => {
      var singleProfile = profiles[obj];

      return ( <ProfileListItem
        key={obj}
        handleProfileItemClick={(event) => this.handleProfileItemClick(event, singleProfile)}
        profileItem={singleProfile}
        selectedItem={this.props.selectedItem} />
      );
    });
  };

  getProfiles = () => {
    var namedFilter = this.state.namedFilter;
    var supervisorId = this.props.supervisor.id;

    this.api.query(this.profilesApiUrl(namedFilter, supervisorId)).then((profilesList) => {
      this.setState({ profiles: profilesList.data });
      this.getFirstProfile(profilesList.data);
    }).catch(this.processError);
  };

  handleProfileChange = (value) => {
    this.setState({ namedFilter: value });
  };

  handleProfileItemClick = (event, profile) => {
    this.props.onProfileSelected(profile);
  };

  profilesApiUrl(named_filter, supervisorId) {
    return `/api/v1/profiles?named_filter=${named_filter}&q[user_supervisor_id_eq]=${supervisorId}&per_page=100&q[s]=completed_at+desc`;
  };

  reloadProfiles = () => {
    var namedFilter = this.state.namedFilter;
    var supervisorId = this.props.supervisor.id;

    this.api.query(this.profilesApiUrl(namedFilter, supervisorId)).then((profilesList) => {
      this.setState({ profiles: profilesList.data });
    }).catch(this.processError);
  };

  render() {
    const { handleSupervisorChange, supervisor, user, classes } = this.props;
    const { namedFilter, profiles } = this.state;

    if(!supervisor.id) {
      return (<Paper className={classes.emptyPadding}><div>This institute has no supervisors.  Educators must be assigned to a user with the supervisor role</div></Paper>);
    }

    return (
      <Fragment>
        <SupervisorFilter
          currentProfile={namedFilter}
          currentSupervisor={supervisor.name}
          handleProfileChange={this.handleProfileChange}
          handleSupervisorChange={handleSupervisorChange}
          user={user}
        />
        <StyledDivider />
        <EducatorProfileList
          getProfileList={this.getProfileList}
          profiles={profiles}
        />
      </Fragment>
    );
  }

}

export default withStyles(EducatorSidebar, styles, { withTheme: true });
