import React from 'react';
import { withStyles } from 'tss-react/mui';

import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import SortableTableCell from './SortableTableCell';

const styles = theme => ({
  unsortableColumn: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.typography.color.primary,
    opacity: 0.55
  }
});

class AdminTableHead extends React.Component {
  constructor(props) {
    super(props);
  }

  renderHeaderCell(column_name, sortable) {
    if (sortable) {
      return (
        <SortableTableCell
          columnName={column_name}
          defaultOrder={this.props.defaultOrder}
          key={column_name}
          onSortRequest={this.props.onSortRequest}
          resourceMeta={this.props.resourceMeta}
        />
      );
    } else {
      const tableCellProps =
        this.props.resourceMeta[column_name].tableCellProps || {};
      return (
        <TableCell
          className={this.props.classes.unsortableColumn}
          key={column_name}
          {...tableCellProps}
        >
          {this.props.resourceMeta[column_name].label || column_name}
        </TableCell>
      );
    }
  }

  sortable = column_name => {
    if (
      this.props.resourceMeta &&
      this.props.resourceMeta.hasOwnProperty(column_name)
    ) {
      return this.props.resourceMeta[column_name].sortable;
    } else {
      return true;
    }
  };

  render() {
    return (
      <TableHead>
        <TableRow>
          {this.props.fields.map(column_name =>
            this.renderHeaderCell(column_name, this.sortable(column_name))
          )}
          {this.props.actionsSection && <TableCell />}
        </TableRow>
      </TableHead>
    );
  }
}

export default withStyles(AdminTableHead, styles);
