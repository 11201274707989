const monthPadding = 'default';

const ReportConfig = {
  // Rows
  sectionRow: {
    background: '#f2f2f2',
    textTransform: 'uppercase',
    fontWeight: 600
  },
  totalRow: {
    fontWeight: 600,
  },

  // Columns
  profiling: {
    group_description: {
      cellOptions: {
        padding: 'normal',
      },
      textOptions: {
        fontWeight: 'inherit',
        textAlign: 'inherit'
      }
    },
    educator_response_count: {
      cellOptions: {
        padding: 'none',
      },
      textOptions: {
        fontWeight: 'normal',
        textAlign: 'center'
      }
    },
    educator_average: {
      cellOptions: {
        padding: 'none',
      },
      textOptions: {
        fontWeight: 'normal',
        textAlign: 'center'
      }
    },
    supervisor_average: {
      cellOptions: {
        padding: 'none',
      },
      textOptions: {
        fontWeight: 'normal',
        textAlign: 'center'
      }
    },
    business_ideal_average: {
      cellOptions: {
        padding: 'none',
      },
      textOptions: {
        fontWeight: 'normal',
        textAlign: 'center'
      }
    }
  },
  programsMonthly: {
    group_description: {
    cellOptions: {
      padding: 'normal',
    },
    textOptions: {
      fontWeight: 'inherit',
      textAlign: 'inherit',
    }
  },
  month_1: {
    cellOptions: {
      padding: monthPadding,
    },
    textOptions: {
      fontWeight: 'normal',
      textAlign: 'center',
    }
  },
  month_2: {
    cellOptions: {
      padding: monthPadding,
    },
    textOptions: {
      fontWeight: 'normal',
      textAlign: 'center',
    }
  },
  month_3: {
    cellOptions: {
      padding: monthPadding,
    },
    textOptions: {
      fontWeight: 'normal',
      textAlign: 'center',
    }
  },
  month_4: {
    cellOptions: {
      padding: monthPadding,
    },
    textOptions: {
      fontWeight: 'normal',
      textAlign: 'center',
    }
  },
  month_5: {
    cellOptions: {
      padding: monthPadding,
    },
    textOptions: {
      fontWeight: 'normal',
      textAlign: 'center',
    }
  },
  month_6: {
    cellOptions: {
      padding: monthPadding,
    },
    textOptions: {
      fontWeight: 'normal',
      textAlign: 'center',
    }
  },
  month_7: {
    cellOptions: {
      padding: monthPadding,
    },
    textOptions: {
      fontWeight: 'normal',
      textAlign: 'center',
    }
  },
  month_8: {
    cellOptions: {
      padding: monthPadding,
    },
    textOptions: {
      fontWeight: 'normal',
      textAlign: 'center',
    }
  },
  month_9: {
    cellOptions: {
      padding: monthPadding,
    },
    textOptions: {
      fontWeight: 'normal',
      textAlign: 'center',
    }
  },
  month_10: {
    cellOptions: {
      padding: monthPadding,
    },
    textOptions: {
      fontWeight: 'normal',
      textAlign: 'center',
    }
  },
  month_11: {
    cellOptions: {
      padding: monthPadding,
    },
    textOptions: {
      fontWeight: 'normal',
      textAlign: 'center',
    }
  },
  month_12: {
    cellOptions: {
      padding: monthPadding,
    },
    textOptions: {
      fontWeight: 'normal',
      textAlign: 'center',
    }
  },
  total: {
    textOptions: {
      fontWeight: 'inherit',
      textAlign: 'center',
    }
  },
  },
  programsEmployment: {
    group_description: {
      cellOptions: {
        padding: 'normal',
      },
      textOptions: {
        fontWeight: 'inherit',
        textAlign: 'inherit'
      }
    },
    employment_type_total: {
      cellOptions: {
        padding: 'none',
      },
      textOptions: {
        fontWeight: 'inherit',
        textAlign: 'center'
      }
    },
  },
  departmentEngagement: {
    group_description: {
      cellOptions: {
        padding: 'normal',
      },
      textOptions: {
        fontWeight: 'inherit',
        textAlign: 'inherit'
      }
    },
    employment_type_total: {
      cellOptions: {
        padding: 'none',
      },
      textOptions: {
        fontWeight: 'inherit',
        textAlign: 'center'
      }
    },
  },
};

export default ReportConfig;
