import React from 'react';

import { withStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';

const styles = theme => ({
  root: {
    padding: '10px 20px',
    minWidth: 300
  },
  userName: {
    fontSize: 18
  },
  instituteName: {
    fontSize: 14
  },
  links: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'space-between'
  },
  link: {
    fontSize: 13,
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#1f1f1f',
    '&:visited': {
      color: '#1f1f1f'
    }
  }
});

class Account extends React.Component {

  render() {
    const { classes, onChangePassword } = this.props;
    return (
       <div className={classes.root}>
         <Typography className={classes.userName}>{this.props.user.given_name} {this.props.user.family_name}</Typography>
         <Typography className={classes.instituteName}>{this.props.user.email}</Typography>
         <Typography className={classes.instituteName}>{this.props.user.institute_name}</Typography>
         <div className={classes.links}>
           <a className={classes.link} href="/users/sign_out" data-method="delete">Logout</a>
           <a className={classes.link} onClick={onChangePassword}>Change Password</a>
         </div>
       </div>
    );
  }
}

export default withStyles(Account, styles);
