import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';

export const formHelperHoc = Component => {
  class FormHelperWrappedComponent extends React.Component {
    errorHelpers = field =>
      this.props.validationErrors &&
      this.props.validationErrors.hasOwnProperty(field)
        ? this.props.validationErrors[field][0]
        : null;

    errorStatus = field =>
      this.props.validationErrors &&
      this.props.validationErrors.hasOwnProperty(field);

    makeFieldProps = (field, fieldValue, errorField) => ({
      error: this.errorStatus(errorField || field),
      helperText: this.errorHelpers(errorField || field),
      name: field,
      value: fieldValue
    });

    get injectedProps() {
      return {
        errorHelpers: this.errorHelpers,
        errorStatus: this.errorHelpers,
        makeFieldProps: this.makeFieldProps
      };
    }

    render() {
      return <Component {...this.props} {...this.injectedProps} />;
    }
  }

  hoistNonReactStatics(FormHelperWrappedComponent, Component);

  return FormHelperWrappedComponent;
};
