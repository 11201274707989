import randomstring from 'randomstring';

export const transformEvidences = (evidences) => {
  const evidencesHash = {}

  evidences.forEach(file => {
    const uid = randomstring.generate();
    if (file.fileUrl) {
      evidencesHash[uid] = { id: file.id };

      if (file.removeFile) {
        evidencesHash[uid]._destroy = true;
      }
    } else {
      evidencesHash[uid] = {
        file: JSON.stringify(file)
      }
    }
  });

  return evidencesHash;
}
