import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const styles = theme => ({
  deactivateButtonText: {
    color: theme.typography.color.primary,
    fontWeight: 400,
    opacity: 0.75,
  },
  errorMessages: {
    paddingTop: theme.typography.pxToRem(20),
  },
  flatUserFormButtons: {
    marginRight: theme.typography.pxToRem(18),
  },
});

class UserFormActions extends React.Component {

  render() {
    const { classes, errorMessage, user } = this.props;

    return (
      <Fragment>
        <Grid container justifyContent="flex-end">
          <Button
            className={classes.flatUserFormButtons}
            onClick={() => this.props.handleActiveStatus(user.active)}
          >
            <Typography
              className={classes.deactivateButtonText}
              variant="button"
            >
              {user.active ? 'Deactivate' : 'Activate'}
            </Typography>
          </Button>
          <Button
            color="secondary"
            disabled={!this.props.dirty}
            onClick={this.props.onSave}
            size="small"
            variant="contained"
          >
            <Typography variant="button">Save</Typography>
          </Button>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Typography
            className={classes.errorMessages}
            color="error"
          >
            {errorMessage}
          </Typography>
        </Grid>
      </Fragment>
    );
  }

}

export default withStyles(UserFormActions, styles, { withTheme: true });
