import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';

import Alert from '@common/components/Alert';
import WaveTextField from '@common/components/form/WaveTextField';
import WaveSelectField from '@common/components/form/WaveSelectField';
import WorkshopReminder from '@common/config/Tooltip/WorkshopReminder';
import InfoIcon from '@common/icons/InfoIcon';
import ApiService from '@common/services/ApiService';
import MetadataService from '@common/services/MetadataService';
import { formHelperHoc } from '@common/components/form-helper-hoc';
import { withStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import { notificationStyles } from './styles';
import { DefaultCheckbox } from '@common/components/default-checkbox';


class Notification extends React.Component {

  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.meta = new MetadataService();
    this.state = {
      alertOpen: false,
      serverity: 'success',
      alertMessage: 'Success!',
    };
  }

  handleInputChange = event => {
    const { onChange, instituteDetails } = this.props;

    onChange({ ...instituteDetails, [event.target.name]: event.target.value });
  };

  handleAlertClose = () => {
    this.setState({ alertOpen: false });
  };

  handleProfilingReminder = () => {
    const { id } = this.props.instituteDetails;
    this.api
      .post(`/api/v1/institutes/${id}/profiling_reminder`)
      .then(data => {
        const { message } = data.json;
        this.setState({ alertOpen: true, alertMessage: message })
      });
  };

  handleProgramRegistrationNotification = () => {
    const { id } = this.props.instituteDetails;
    this.api
      .post(`/api/v1/institutes/${id}/supervisor_program_registration_notification`)
      .then(data => {
        const { message } = data.json;
        this.setState({ alertOpen: true, alertMessage: message })
      });
  };

  handleProfilesWaitiingNotification = () => {
    const { id } = this.props.instituteDetails;
    this.api
      .post(`/api/v1/institutes/${id}/supervisor_profiles_waiting_notification`)
      .then(data => {
        const { message } = data.json;
        this.setState({ alertOpen: true, alertMessage: message })
      });
  };

  render() {
    const {
      classes,
      makeFieldProps,
      instituteDetails: {
        workshop_reminder_days,
        zero_points_reminder_days,
        partial_points_reminder_days,
        profiling_reminder_days,
        zero_points_reminder_enabled,
        partial_points_reminder_enabled,
        workshop_reminder_enabled,
        profiling_reminder_enabled,
        supervisor_profile_waiting_frequency,
        supervisor_profile_waiting_enabled,
        supervisor_program_registration_period,
        supervisor_program_registration_enabled,
        unactioned_points_reminder_enabled,
        unactioned_points_reminder_days,
        pending_registrations_reminder_enabled,
        pending_registrations_reminder_days,
        registrations_require_approval_enabled,
      }
    } = this.props;

    const { alertOpen, serverity, alertMessage} = this.state;

    const profilingReminderOptions = {
      30: 30,
      60: 60,
      90: 90,
    }

    const instituteMeta = this.meta.get('institutes')
    const supervisorProfileWaitingFrequencies = instituteMeta.supervisor_profile_waiting_frequency.options;
    const supervisorProgramRegistrationPeriods = instituteMeta.supervisor_program_registration_period.options;

    return (
      <Grid container spacing={2}>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={alertOpen}
          autoHideDuration={3000}
          onClose={this.handleAlertClose}>
          <Alert variant={serverity} message={alertMessage} />
        </Snackbar>
        <Grid item xs={12}>
          <Paper className={classes.panelContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Points Achievement Reminder
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <WaveTextField
                  label="Zero Points Reminder (Days)"
                  tooltipProps={{
                    placement: 'top',
                    titleComponent: <WorkshopReminder
                      label="The frequency for a reminder notification to be sent when zero points have been achieved"
                    />,
                    tooltipContainer: classes.tooltipContainer,
                    tooltipPopper: classes.popper
                  }}
                  {...makeFieldProps(
                    'zero_points_reminder_days',
                    zero_points_reminder_days
                  )}
                  onChange={this.handleInputChange}
                />
              </Grid>
              <Grid item xs={6}>
                <WaveTextField
                  label="Partial Points Reminder (Days)"
                  tooltipProps={{
                    placement: 'top',
                    titleComponent: <WorkshopReminder
                      label="The frequency for a reminder notification to be sent when partial points have been achieved"
                    />,
                    tooltipContainer: classes.tooltipContainer,
                    tooltipPopper: classes.popper
                  }}
                  {...makeFieldProps(
                    'partial_points_reminder_days',
                    partial_points_reminder_days
                  )}
                  onChange={this.handleInputChange}
                />
              </Grid>
              <Grid item xs={6}>
                <DefaultCheckbox
                  name="zero_points_reminder_enabled"
                  label="Enable zero points reminder notification?"
                  onChange={this.handleInputChange}
                  checkboxValue={true}
                  unCheckValue={false}
                  value={zero_points_reminder_enabled}
                />
              </Grid>
              <Grid item xs={6}>
                <DefaultCheckbox
                  name="partial_points_reminder_enabled"
                  label="Enable partial points reminder notification?"
                  onChange={this.handleInputChange}
                  checkboxValue={true}
                  unCheckValue={false}
                  value={partial_points_reminder_enabled}
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper className={classes.panelContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Workshop Reminder</Typography>
              </Grid>
              <Grid item xs={12}>
                <WaveTextField
                  label="Workshop Reminder (Days)"
                  tooltipProps={{
                    placement: 'top',
                    titleComponent: <WorkshopReminder />,
                    tooltipContainer: classes.tooltipContainer,
                    tooltipPopper: classes.popper
                  }}
                  {...makeFieldProps(
                    'workshop_reminder_days',
                    workshop_reminder_days
                  )}
                  onChange={this.handleInputChange}
                />
              </Grid>

              <Grid item xs={6}>
                <DefaultCheckbox
                  name="workshop_reminder_enabled"
                  label="Enable workshop reminder notification?"
                  onChange={this.handleInputChange}
                  checkboxValue={true}
                  unCheckValue={false}
                  value={workshop_reminder_enabled}
                />
              </Grid>

            </Grid>
          </Paper>
          <Paper className={classes.panelContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                  <Typography variant="h6">Profiling tool Reminder</Typography>
                  <Tooltip
                    classes={{
                      popper: classes.popper,
                      tooltip: classes.tooltipContainer
                    }}
                    placement='top'
                    title={<WorkshopReminder label="Send now will send this email notification immediately to all applicable educators, regardless of the configuration of the automated reminder." />}
                  >
                    <Button
                      color="secondary"
                      size="small"
                      variant="contained"
                      onClick={this.handleProfilingReminder}
                    >
                      <Typography variant="button">Send Now</Typography>
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <WaveSelectField
                  label="Profiling tool Reminder (Days)"
                  menuItems={profilingReminderOptions}
                  {...makeFieldProps('profiling_reminder_days', profiling_reminder_days)}
                  onChange={this.handleInputChange}
                />
              </Grid>

              <Grid item xs={6}>
                <DefaultCheckbox
                  name="profiling_reminder_enabled"
                  label="Enable profiling reminder notification?"
                  onChange={this.handleInputChange}
                  checkboxValue={true}
                  unCheckValue={false}
                  value={profiling_reminder_enabled}
                />
              </Grid>
              <Grid item xs={6}>
              </Grid>

            </Grid>
          </Paper>

          <Paper className={classes.panelContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                  <Typography variant="h6">Supervisor Profiles Waiting</Typography>
                  <Tooltip
                    classes={{
                      popper: classes.popper,
                      tooltip: classes.tooltipContainer
                    }}
                    placement='top'
                    title={<WorkshopReminder label="Send now will send this email notification immediately to all applicable Supervisors, regardless of the configuration of the automated reminder." />}
                  >
                    <Button
                      color="secondary"
                      size="small"
                      variant="contained"
                      onClick={this.handleProfilesWaitiingNotification}
                    >
                      <Typography variant="button">Send Now</Typography>
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <WaveSelectField
                  label="Email Frequency"
                  menuItems={supervisorProfileWaitingFrequencies}
                  {...makeFieldProps('supervisor_profile_waiting_frequency', supervisor_profile_waiting_frequency)}
                  onChange={this.handleInputChange}
                />
              </Grid>

              <Grid item xs={6}>
                <DefaultCheckbox
                  name="supervisor_profile_waiting_enabled"
                  label="Enable supervisor profile waiting notification?"
                  onChange={this.handleInputChange}
                  checkboxValue={true}
                  unCheckValue={false}
                  value={supervisor_profile_waiting_enabled}
                />
              </Grid>
              <Grid item xs={6}>
              </Grid>

            </Grid>
          </Paper>

          <Paper className={classes.panelContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                  <Typography variant="h6">
                    Supervisor Program Registrations
                    <Tooltip
                      classes={{
                        popper: classes.tooltipPopper,
                        tooltip: classes.tooltipContainer
                      }}
                      placement='top'
                      title={<WorkshopReminder label="This notification will report on Educators who have registered for programs in the past period." />}
                    >
                      <span> ⓘ</span>
                    </Tooltip>
                  </Typography>
                  <Tooltip
                    classes={{
                      popper: classes.popper,
                      tooltip: classes.tooltipContainer
                    }}
                    placement='top'
                    title={<WorkshopReminder label="Send now will send this email notification immediately to all applicable Supervisors, regardless of the configuration of the automated reminder." />}
                  >
                    <Button
                      color="secondary"
                      size="small"
                      variant="contained"
                      onClick={this.handleProgramRegistrationNotification}
                    >
                      <Typography variant="button">Send Now</Typography>
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <WaveSelectField
                  label="Period"
                  menuItems={supervisorProgramRegistrationPeriods}
                  {...makeFieldProps('supervisor_program_registration_period', supervisor_program_registration_period)}
                  onChange={this.handleInputChange}
                />
              </Grid>

              <Grid item xs={6}>
                <DefaultCheckbox
                  name="supervisor_program_registration_enabled"
                  label="Enable Supervisor program registration notification?"
                  onChange={this.handleInputChange}
                  checkboxValue={true}
                  unCheckValue={false}
                  value={supervisor_program_registration_enabled}
                />
              </Grid>

            </Grid>
          </Paper>

          <Paper className={classes.panelContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                  <Typography variant="h6">Unactioned Points Reminder</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <WaveTextField
                  label="Unactioned Points Reminder (Days)"
                  tooltipProps={{
                    placement: 'top',
                    titleComponent: <WorkshopReminder
                      label="The frequency for a reminder notification to be sent when there are unactioned points requests"
                    />,
                    tooltipContainer: classes.tooltipContainer,
                    tooltipPopper: classes.popper
                  }}
                  {...makeFieldProps(
                    'unactioned_points_reminder_days',
                    unactioned_points_reminder_days
                  )}
                  onChange={this.handleInputChange}
                />
              </Grid>

              <Grid item xs={6}>
                <DefaultCheckbox
                  name="unactioned_points_reminder_enabled"
                  label="Enable unactioned points reminder notification?"
                  onChange={this.handleInputChange}
                  checkboxValue={true}
                  unCheckValue={false}
                  value={unactioned_points_reminder_enabled}
                />
              </Grid>

            </Grid>
          </Paper>

          { registrations_require_approval_enabled && (
            <Paper className={classes.panelContainer}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container justifyContent="space-between">
                    <Typography variant="h6">Unapproved Registrations Reminder</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <WaveTextField
                    label="Unapproved Registrations Reminder (Days)"
                    tooltipProps={{
                      placement: 'top',
                      titleComponent: <WorkshopReminder
                        label="The days before the program start date for a reminder notification to be sent when there are unapproved registrations"
                      />,
                      tooltipContainer: classes.tooltipContainer,
                      tooltipPopper: classes.popper
                    }}
                    {...makeFieldProps(
                      'pending_registrations_reminder_days',
                      pending_registrations_reminder_days
                    )}
                    onChange={this.handleInputChange}
                  />
                </Grid>

                <Grid item xs={6}>
                  <DefaultCheckbox
                    name="pending_registrations_reminder_enabled"
                    label="Enable unapproved registrations notification?"
                    onChange={this.handleInputChange}
                    checkboxValue={true}
                    unCheckValue={false}
                    value={pending_registrations_reminder_enabled}
                  />
                </Grid>

              </Grid>
            </Paper>
          )}
        </Grid>
      </Grid>
    );
  }
}

const EnhancedNotification = formHelperHoc(
    withStyles(Notification,notificationStyles, { withTheme: true })
  )


export { EnhancedNotification as Notification };
