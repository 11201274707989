import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';

import Typography from '@mui/material/Typography';

import LoginLayout from './LoginLayout';

import educatorPassportLogo from '../../images/educator-passport.png';

const styles = theme => ({
  logo: {
    marginBottom: theme.typography.pxToRem(30),
  },
});

const Content = (props) =>  {
  const { classes } = props;

  return (
    <Fragment>
      <div className={classes.logo}>
        <img src={educatorPassportLogo} />
      </div>
      <Typography>{props.message}</Typography>
    </Fragment>);
};

const StyledContent = withStyles(Content, styles, { withTheme: true });
const InfoApp = (props) => {
  return (<LoginLayout><StyledContent {...props}/></LoginLayout>);
};

export default InfoApp;
