import React, {useCallback, useState} from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';
import ApiService from '@common/services/ApiService';
import { DefaultModal } from '@common/components/default-modal';
import { DefaultButton } from '@common/components/default-button';
import { DefaultCheckbox } from '@common/components/default-checkbox';

const styles = theme => ({
  button: {
    marginTop: theme.typography.pxToRem(15)
  },
});

const ApproveModal = ({
  classes,
  isOpened,
  onClose,
  onRefreshRequest,
  registration,
}) => {
  const api = new ApiService();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const initialData = {
    notify_user: true,
  }
  const [data, setData] = useState(initialData);

  const handleInputChange = useCallback(event => {
    const { value, name } = event.target;

    setData({ ...data, [name]: value})
  }, [data, setData]);

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);

    try {
      const url = `/api/v2/registrations/${registration.id}/approve`;
      const res = await api.put(url, data);

      if (res.status === 204) {
        handleClose();
        onRefreshRequest();
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [data, registration]);

  const handleClose = useCallback(() => {
    setErrorMessage(null);
    setData(initialData);
    onClose(undefined)
  }, [setErrorMessage, onClose])

  return (
    <DefaultModal
      isOpened={isOpened}
      isLoading={isLoading}
      onClose={handleClose}
      title='Approve registration'
      action={
        <DefaultButton
          className={classes.button}
          color="secondary"
          onClick={handleSubmit}
          size="small"
          variant="contained"
        >
          Approve
        </DefaultButton>
      }
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography color="error">{errorMessage}</Typography>
        </Grid>
        <Grid item xs={12}>
          <DefaultCheckbox
            name="notify_user"
            label="Notify the user by email that the registration has been approved"
            onChange={handleInputChange}
            checkboxValue={true}
            unCheckValue={false}
            value={data.notify_user}
          />
        </Grid>
      </Grid>
    </DefaultModal>
  )
};

export default withStyles(ApproveModal, styles, { withTheme: true});
