import React from 'react';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import EntityManage from '@common/components/EntityManage';
import WaveSelectField from '@common/components/form/WaveSelectField';
import WaveTextArea from '@common/components/form/WaveTextArea';
import WaveTextField from '@common/components/form/WaveTextField';
import { reaManageStyles } from './styles';
import { formHelperHoc } from '@common/components/form-helper-hoc';
import { DefaultModal } from '@common/components/default-modal';
import { DefaultButton } from '@common/components/default-button';
import { DefaultCheckbox } from '@common/components/default-checkbox';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import WaveMultipleFileUploader from '@common/components/form/WaveMultipleFileUploader';
import Chisholm from '@common/config/Chisholm';
import { transformEvidences } from '@common/services/PointService';
import { MAX_FILE_SIZE } from '@common/constants';
import { WaveDatePicker } from '@common/components/WaveDatePicker';

const iniitalState = {
  domain_id: '',
  points: null,
  activity_name: '',
  activity_date: new Date(),
  activity_duration: undefined,
  activity_interval: undefined,
  learning_journey_equivalent: false,
  inform_teaching_practice: '',
  related_to_units: '',
  student_benefit: '',
  evidences: [],
  notify_user: true
};

class ReaManageModal extends EntityManage {
  state = iniitalState;

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({ [name]: value });
  };

  handleDateChange = (name) => (date) => {
    this.setState((d) => ({
      ...d,
      [name]: date
    }));
  };

  handlePush = ({ name, value }) => {
    this.setState((d) => ({
      ...d,
      [name]: d[name].concat(value)
    }));
  };

  handleRemove = ({ name, index }) => {
    this.setState((d) => {
      const array = d[name].slice();
      array.splice(index, 1);

      return {
        ...d,
        [name]: array
      };
    });
  };

  handleReplace = ({ index, name, value }) => {
    const array = data[name].slice();
    array.splice(index, 1, value);

    this.setState((d) => {
      const array = d[name].slice();
      array.splice(index, 1, value);

      return {
        ...d,
        [name]: array
      };
    });
  };

  resetModal = () => {
    this.setState(iniitalState);
  };

  handleSubmit = async () => {
    const {
      domain_id,
      points,
      notify_user,
      activity_name,
      activity_date,
      activity_duration,
      activity_interval,
      learning_journey_equivalent,
      inform_teaching_practice,
      related_to_units,
      student_benefit,
      evidences
    } = this.state;

    const { onSubmit } = this.props;

    const reaData = {
      points,
      domain_id,
      notify_user,
      activity_name,
      activity_date,
      activity_duration,
      activity_interval,
      learning_journey_equivalent,
      inform_teaching_practice,
      related_to_units,
      student_benefit,
      evidences_attributes: transformEvidences(evidences)
    };

    let reset = await onSubmit(reaData);

    if (reset) {
      this.resetModal();
    }
  };

  handleCloseModal = () => {
    const { onClose } = this.props;

    this.resetModal();

    onClose();
  };

  render() {
    const {
      classes,
      domainOptions,
      activityIntervalOptions,
      pointsFileAttachmentEnabled,
      errorMessage,
      isOpened,
      makeFieldProps,
      isLoading
    } = this.props;

    const {
      domain_id,
      points,
      notify_user,
      activity_name,
      activity_date,
      activity_duration,
      activity_interval,
      learning_journey_equivalent,
      inform_teaching_practice,
      related_to_units,
      student_benefit,
      evidences
    } = this.state;

    return (
      <DefaultModal
        wide
        isOpened={isOpened}
        isLoading={isLoading}
        onClose={this.handleCloseModal}
        title="Add External Professional Currency"
        action={
          <Grid container justifyContent="flex-end">
            <DefaultButton
              className={classes.addPointsButton}
              color="secondary"
              onClick={this.handleSubmit}
              size="small"
              variant="contained"
            >
              Add
            </DefaultButton>
          </Grid>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color="error">{errorMessage}</Typography>
          </Grid>
          <Grid item xs={4}>
            <WaveTextField
              label="Points"
              onChange={this.handleInputChange}
              {...makeFieldProps('points', points)}
            />
          </Grid>
          <Grid item xs={4}>
            <WaveSelectField
              emptyMenuItem
              label="Domain"
              menuItems={domainOptions}
              onChange={this.handleInputChange}
              {...makeFieldProps('domain_id', domain_id, 'domain')}
            />
          </Grid>
          <Grid item xs={4}>
            <WaveTextField
              label="Name of Activity"
              onChange={this.handleInputChange}
              {...makeFieldProps('activity_name', activity_name)}
            />
          </Grid>
          <Grid item xs={4}>
            <WaveDatePicker
              className={classes.ieFix}
              format={Chisholm.rangeDate}
              label="Date of Activity"
              onChange={this.handleDateChange('activity_date')}
              {...makeFieldProps('activity_date', activity_date)}
            />
          </Grid>
          <Grid item xs={4}>
            <WaveTextField
              label="Duration of Activity (number)"
              onChange={this.handleInputChange}
              {...makeFieldProps('activity_duration', activity_duration)}
            />
          </Grid>
          <Grid item xs={4}>
            <WaveSelectField
              label="Interval of Activity"
              menuItems={activityIntervalOptions}
              onChange={this.handleInputChange}
              {...makeFieldProps('activity_interval', activity_interval)}
            />
          </Grid>
          <Grid item xs={4}>
            <WaveTextArea
              label="Inform Teaching and Assessment Practice"
              maxLength={1000}
              rows={4}
              onChange={this.handleInputChange}
              placeholder="Describe what you did, discussed or learnt in terms of new skills and knowledge, and how this will inform your teaching and assessment practices?"
              {...makeFieldProps(
                'inform_teaching_practice',
                inform_teaching_practice
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <WaveTextArea
              label="Related to Course/s"
              maxLength={1000}
              rows={4}
              onChange={this.handleInputChange}
              placeholder="Please list course title/s and course code/s.  In addition, list unit title/s and unit code/s of particular relevance to this activity."
              {...makeFieldProps('related_to_units', related_to_units)}
            />
          </Grid>
          <Grid item xs={4}>
            <WaveTextArea
              label="Student Benefit"
              maxLength={1000}
              rows={4}
              onChange={this.handleInputChange}
              placeholder="How will this activity impact/benefit your students?"
              {...makeFieldProps('student_benefit', student_benefit)}
            />
          </Grid>
          <Grid item xs={12} container direction="column" justifyContent="center">
            <DefaultCheckbox
              label="Learning Journey Equivalent"
              checkboxValue={true}
              unCheckValue={false}
              onChange={this.handleInputChange}
              {...makeFieldProps(
                'learning_journey_equivalent',
                learning_journey_equivalent
              )}
            />
          </Grid>
          {pointsFileAttachmentEnabled && (
            <Grid item xs={12}>
              <WaveMultipleFileUploader
                label="Attach Evidence"
                accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                maxFileSize={MAX_FILE_SIZE}
                onPush={this.handlePush}
                onReplace={this.handleReplace}
                onRemove={this.handleRemove}
                {...makeFieldProps('evidences', evidences)}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControl variant="standard">
              <FormGroup row>
                <DefaultCheckbox
                  name="notify_user"
                  label="Notify the user that points have been added to their points history"
                  onChange={this.handleInputChange}
                  checkboxValue={true}
                  value={notify_user}
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </DefaultModal>
    );
  }
}

const StyledReaManageModal = formHelperHoc(
  withStyles(ReaManageModal, reaManageStyles, { withTheme: true })
);

export { StyledReaManageModal as ReaManageModal };
