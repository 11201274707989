import React, { Fragment } from 'react';
import ApiService from '../../../common/services/ApiService';
import DepartmentListItem from './DepartmentListItem';
import DepartmentsList from './DepartmentsList';
import EmploymentTypeFilter from './EmploymentTypeFilter';
import StyledDivider from '../../../common/components/misc/StyledDivider';

class DepartmentsSidebar extends React.Component {

  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.state = {
      departments: [],
    };
  }

  componentDidMount() {
    this.getDepartments();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.employmentType !== this.props.employmentType) {
      this.getDepartments();
    };
  };

  getDepartmentList = (departments) => {
    return Object.keys(departments).map(obj => {
      var singleDepartment = departments[obj];

      return ( <DepartmentListItem
        key={obj}
        departmentItem={singleDepartment}
        enabled={this.props.enabled}
        handleDepartmentSelect={(event) => this.handleDepartmentSelect(event, singleDepartment)}
        selectedItem={this.props.selectedItem}/>
      );
    });
  };

  getDepartments = () => {
    this.api.query('/api/v1/departments/options').then((departments) => {
      var departmentsList = Object.keys(departments).map((key) => {
        return departments[key];
      });
      this.setState({ departments: departmentsList });
    }).catch(this.processError);
  };

  handleDepartmentSelect = (event, department) => {
    this.props.onDepartmentSelect(department);
  };

  render() {
    const { classes, employmentType, employmentTypeOptions, handleEmploymentTypeChange } = this.props;
    const { departments } = this.state;

    return (
      <Fragment>
        <EmploymentTypeFilter
          employmentType={employmentType}
          employmentTypeOptions={employmentTypeOptions}
          handleEmploymentTypeChange={handleEmploymentTypeChange}
        />
        <StyledDivider />
        <DepartmentsList
          departments={departments}
          getDepartmentList={this.getDepartmentList}
        />
      </Fragment>
    );
  }

}

export default DepartmentsSidebar;
