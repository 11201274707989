import React, { Fragment } from 'react';
import format from 'date-fns/format';
import { withStyles } from 'tss-react/mui';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import EventIcon from '@mui/icons-material/Event';
import { DatePicker } from '@mui/x-date-pickers';
import ApiService from '../../../common/services/ApiService';
import Chisholm from '../../../common/config/Chisholm';
import WaveSelectField from '../../../common/components/form/WaveSelectField';
import Utils from "../../../common/services/Utils";
import { WaveDatePicker } from '../../../common/components/WaveDatePicker';

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. Unsupported arrow function syntax.
//Unexpected value type of CallExpression.
const styles = theme => ({
  adjustedSpacing: {
    padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(20)} 0 ${theme.typography.pxToRem(20)}`,
  },
  adjustedSpacingLeft: {
    padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(20)} 0 ${theme.typography.pxToRem(25)}`,
  },
  adjustedSpacingRight: {
    padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(25)} 0 ${theme.typography.pxToRem(20)}`,
  },
  adjustedSpacingGroupBy: {
    padding: `${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(40)} ${theme.typography.pxToRem(25)}`,
  },
  clearFilters: {
    color: theme.typography.color.primary,
    '&:hover': {
      color: theme.typography.color.primary,
      cursor: 'pointer',
    },
    '&:visited': {
      color: theme.typography.color.primary,
    },
    fontSize: theme.typography.pxToRem(13),
    marginRight: theme.typography.pxToRem(20),
    opacity: 0.7,
  },
  filterHeader: {
    padding: `${theme.typography.pxToRem(19)} ${theme.typography.pxToRem(25)} 0 ${theme.typography.pxToRem(25)}`
  },
  ieFix: Utils.ieDatePickerFix()
});

const groupByOptions = {
  'capabilityStatement': {
    id: 'capabilityStatement',
    label: 'Capability Statement',
  },
  'department': {
    id: 'department',
    label: 'Department',
  }
};

class ProfilingFilters extends React.Component {

  constructor(props) {
    super(props);

    const { passportPeriod } = props;

    this.api = new ApiService();
    this.state = {
      division_id: 'all',
      divisionOptions: [],
      department_id: 'all',
      departmentOptions: [],
      employment_type_id: 'all',
      employmentTypeOptions: [],
      errorMessage: null,
      filters: {},
      from_date: passportPeriod.from,
      to_date: passportPeriod.to,
      groupBy: 'capabilityStatement'
    };
  }

  componentDidMount() {
    this.getFiltersOptions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.division_id !== prevState.division_id) {
      this.setState({ department_id: 'all' });
    };
  }

  arrayToObject = (arr, keyField) =>
    Object.assign({}, ...arr.map(item => ({[item[keyField]]: item}))
  );

  clearFilters = () => {
    const { passportPeriod } = this.props;

    this.setState({
      division_id: 'all',
      department_id: 'all',
      errorMessage: null,
      filters: {},
      from_date: passportPeriod.from,
      to_date: passportPeriod.to,
      groupBy: 'capabilityStatement'
    });

    this.props.handleApplyFilters(this.state.filters);
  };

  getDepartments = (departments, divisionId) => {
    var departmentsArray = [];
    Object.keys(departments).map(obj =>
      departments[obj].division_id === divisionId ? departmentsArray.push(departments[obj]) : ''
    );
    return departmentsArray;
  };

  getFiltersOptions = () => {
    const divisionOptionsPromise = this.api.query('/api/v1/divisions/options');
    const departmentOptionsPromise = this.api.query('/api/v1/departments/options');
    const employmentTypeOptionsPromise = this.api.query( '/api/v1/employment_types/options');

    Promise.all([
      divisionOptionsPromise,
      departmentOptionsPromise,
      employmentTypeOptionsPromise
    ]).then(([divisionOptions, departmentOptions, employmentTypeOptions]) => {
      this.setState({
        divisionOptions,
        departmentOptions,
        employmentTypeOptions,
      });
    }).catch(this.processError);
  };

  handleDateChange = (name, date) => {
    const convertedDate = date == null ? null : format(new Date(date).setFullYear(new Date(date).getFullYear()), 'yyyy-MM-dd');
    this.setState({ [name]: date });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({ [name]: value });
  };

  makeFieldProps = (fieldName, fieldValue) => {
    return {
      name: fieldName,
      onChange: this.handleInputChange,
      value: fieldValue,
    };
  };

  onFilter = () => {
    var filters = this.state.filters;
    filters = {};

    if (this.state.division_id != null && this.state.division_id != 'all') {
      filters['division_id_eq'] = this.state.division_id;
    }
    if (this.state.department_id != null && this.state.department_id != 'all') {
      filters['department_id_eq'] = this.state.department_id;
    }
    if (this.state.employment_type_id != null && this.state.employment_type_id != 'all') {
      filters['user_employment_type_id_eq'] = this.state.employment_type_id;
    }
    if (this.state.from_date != null && this.state.to_date != null) {
      filters['date_from_eq'] = this.state.from_date;
      filters['date_to_eq'] = this.state.to_date;
    }
    if (this.state.groupBy != null) {
      filters['group_by'] = this.state.groupBy;
    }

    this.props.handleApplyFilters(filters);
  }

  // unexpected errors
  processError = (ex) => {
    console.error(ex);
    this.setState({ ...s, errorMessage: ex.message });
  };

  render() {
    const { classes } = this.props;
    const {
      division_id,
      divisionOptions,
      department_id,
      departmentOptions,
      employment_type_id,
      employmentTypeOptions,
      from_date,
      to_date,
      groupBy,
    } = this.state;
    const divisionIdInt = parseInt(division_id);
    const departmentsArray = this.getDepartments(departmentOptions, divisionIdInt);
    const filteredDepartments = this.arrayToObject(departmentsArray, 'id');

    return (
      <Fragment>

        <Grid
          container
          className={classes.filterHeader}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h6">Filter</Typography>
          <Grid item>
            <a
              className={classes.clearFilters}
              onClick={() => this.clearFilters()}
            >
              Clear
            </a>
            <Button
              color="secondary"
              onClick={() => this.onFilter()}
              size="small"
              variant="contained"
            >
              <Typography variant="button">Filter</Typography>
            </Button>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item className={classes.adjustedSpacingLeft} xs={3}>
            <WaveSelectField
              filterByAllItem
              filterByAllItemLabel="All"
              noValidation
              label="Division"
              menuItems={divisionOptions}
              {...this.makeFieldProps('division_id', division_id)}
            />
          </Grid>
          <Grid item className={classes.adjustedSpacing} xs={3}>
            <WaveSelectField
              filterByAllItem
              filterByAllItemLabel="All"
              noValidation
              label="Department"
              menuItems={divisionIdInt ? filteredDepartments : departmentOptions}
              {...this.makeFieldProps('department_id', department_id)}
            />
          </Grid>
          <Grid item className={classes.adjustedSpacing} xs={3}>
            <WaveDatePicker
              className={classes.ieFix}
              label="From"
              name="from_date"
              onChange={(value) => this.handleDateChange('from_date', value)}
              value={new Date(from_date)}
              format={Chisholm.rangeDate}
            />
          </Grid>
          <Grid item className={classes.adjustedSpacingRight} xs={3}>
          <WaveDatePicker
              className={classes.ieFix}
              label="To"
              name="to_date"
              onChange={(value) => this.handleDateChange('to_date', value)}
              value={to_date}
              format={Chisholm.rangeDate}
            />
          </Grid>

          <Grid item className={classes.adjustedSpacingGroupBy} xs={3}>
            <WaveSelectField
              noValidation
              label="Group By"
              menuItems={groupByOptions}
              {...this.makeFieldProps('groupBy', groupBy)}
              />
          </Grid>

          <Grid item className={classes.adjustedSpacingGroupBy} xs={3}>
            <WaveSelectField
              filterByAllItem
              filterByAllItemLabel="All"
              noValidation
              label="Employment Type"
              menuItems={employmentTypeOptions}
              {...this.makeFieldProps('employment_type_id', employment_type_id)}
            />
          </Grid>

        </Grid>

      </Fragment>
    );
  }

}

export default withStyles(ProfilingFilters, styles, { withTheme: true });
