import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function AddUsersIcon(props) {
  return (
    <SvgIcon {...props} style={{opacity: 0.6}}>
      <path d="M14 8.2C14 5.3 11.7 3 8.8 3 5.9 3 3.6 5.3 3.6 8.2 3.6 9.8 4.4 11.4 5.7 12.4 3.2 13.5 1.3 15.8 1 18.6L2.3 18.6C2.8 15.7 5.8 13 8.8 13 11.8 13 14.8 15.7 15.2 18.6L16.6 18.6C16.3 15.8 14.4 13.5 11.9 12.4 13.2 11.4 14 9.8 14 8.2ZM8.8 12C6.7 12 5 10.3 5 8.2 5 6.1 6.7 4.4 8.8 4.4 10.9 4.4 12.6 6.1 12.6 8.2 12.6 10.3 10.9 12 8.8 12Z"/><path d="M20.2 8.9C20.2 5.8 17.3 3.3 14.2 3.6 14.5 3.9 14.6 4.3 14.8 4.6 15 4.9 14.9 4.7 15 4.9 17.1 5.3 18.7 7.1 18.7 8.8 18.7 9.9 18.4 10.7 17.6 11.3 17.3 11.5 17 11.9 17 12.1 17 12.6 17.1 13 17.6 13.1 19.2 13.5 20.6 15.1 20.6 16.8L22 16.8C22 14.9 20.8 13 19 12.1 19.8 11.2 20.2 10.1 20.2 8.9Z"/> 
    </SvgIcon>
  );
}
