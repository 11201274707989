import React, { useCallback, useMemo, Fragment, useState } from 'react';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import ApiService from '@common/services/ApiService';
import AdminTable from '@common/components/table/AdminTable';
import ApproveModal from './ApproveModal';
import RejectModal from './RejectModal';
import ButtonActions from './ButtonActions';

const fields = [
  'created_at',
  'program_name',
  'program_start_date',
  'user_name',
  'supervisor_name',
];

const styles = theme => ({
  exportHeader: {
    padding: `${theme.typography.pxToRem(22)} ${theme.typography.pxToRem(
      25
    )} ${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(25)}`
  },
  headline: {
    marginTop: theme.typography.pxToRem(8),
    marginBottom: theme.typography.pxToRem(35)
  },
});

const RegistrationList = ({ user, classes, ...props}) => {
  const [registration, setRegistration] = useState(undefined)
  const [isApproveModalOpened, setIsApproveModalOpened] = useState(false);
  const [isRejectModalOpened, setIsRejectModalOpened] = useState(false);
  const [refreshTick, setRefreshTick] = useState(0);
  const [filters, setFilter] = useState({ status_eq: 'pending'})
  const [api, setApi] = useState(new ApiService());

  const handleRefresh = useCallback(() => {
    setRefreshTick(Date.now());
  }, [setRefreshTick]);

  const toggleApproveModal = useCallback(registration => {
    setRegistration(registration);
    setIsApproveModalOpened(registration !== undefined)
  }, [setRegistration, setIsApproveModalOpened]);

  const toggleRejectModal = useCallback(registration => {
    setRegistration(registration);
    setIsRejectModalOpened(registration !== undefined)
  }, [setRegistration, setIsRejectModalOpened]);

  const handleExport = useMemo(() => {
    const query = api.searchUrl(filters, null, null, {}, false);
    return `/api/v2/registrations.csv?${query}`;
  });

  const renderButtonActions = useCallback((registration) => {
    return <ButtonActions registration={registration} toggleApproveModal={toggleApproveModal} toggleRejectModal={toggleRejectModal} />
  }, [toggleApproveModal, toggleRejectModal]);

  const renderAdminTable = useMemo(() => {
    return (
      <AdminTable
        showTablePagination
        fields={fields}
        filters={filters}
        actionsSection={['buttons']}
        renderButtonActions={renderButtonActions}
        resource="registrations"
        url="/api/v2/registrations"
        refreshTick={refreshTick}
      />
    )
  }, [fields, refreshTick]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography className={classes.headline} variant="h5">
          Registrations submitted for approval
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Grid container className={classes.exportHeader} justifyContent="space-between" >
            <Typography variant="h6">Registrations</Typography>
            <a href={handleExport} target="_blank">
              <Button color="secondary" size="small" variant="contained">
                <Typography variant="button">Export</Typography>
              </Button>
            </a>
          </Grid>
          {renderAdminTable}
        </Paper>
        <ApproveModal
          isOpened={isApproveModalOpened}
          onClose={toggleApproveModal}
          registration={registration}
          onRefreshRequest={handleRefresh}
        />
        <RejectModal
          isOpened={isRejectModalOpened}
          onClose={toggleRejectModal}
          registration={registration}
          onRefreshRequest={handleRefresh}
        />
      </Grid>
    </Grid>
  );
}

export default withStyles(RegistrationList, styles, { withTheme: true });
