import React, { useCallback, useState } from 'react';
import { withStyles } from 'tss-react/mui';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import ApiService from '@common/services/ApiService';
import ImportPointsForm from './ImportPointsForm';
import ImportPointsSummary from './ImportPointsSummary';

const styles = (theme) => ({
  fileUploadContainer: {
    padding: `${theme.typography.pxToRem(25)} 0 ${theme.typography.pxToRem(
      45
    )} ${theme.typography.pxToRem(25)}`
  },
  importInstructions: {
    padding: `${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(
      334
    )} ${theme.typography.pxToRem(5)} ${theme.typography.pxToRem(25)}`
  },
  importNotesList: {
    listStyle: 'none',
    marginTop: 0,
    marginBottom: theme.typography.pxToRem(40),
    paddingLeft: theme.typography.pxToRem(25)
  },
  listItem: {
    lineHeight: 1.5,
    marginBottom: theme.typography.pxToRem(6),
    paddingLeft: theme.typography.pxToRem(8),
    textIndent: theme.typography.pxToRem(-26)
  },
  notesHeaders: {
    paddingBottom: theme.typography.pxToRem(15)
  },
  notesListSpacing: {
    paddingBottom: theme.typography.pxToRem(10)
  },
  notesSpacing: {
    paddingBottom: theme.typography.pxToRem(40)
  },
  importContainer: {
    marginTop: theme.typography.pxToRem(25),
    marginBottom: theme.typography.pxToRem(53)
  },
  buttonProgressWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '88%',
    marginTop: -12,
    marginLeft: -12
  },
  notesBody: {
    paddingBottom: theme.typography.pxToRem(24)
  }
});

function ImportPoints({ classes, theme }) {
  const [file, setFile] = useState(null);
  const [readyToImport, setReadyToImport] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [importResult, setImportResult] = useState(null);
  const [api, _] = useState(new ApiService());
  const [isUploading, setIsUploading] = useState(false);

  const handleImport = useCallback(() => {
    setIsUploading(true);

    api
      .upload(`/api/v1/point_requests/import`, file, 'file')
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        setImportResult(json);
        setReadyToImport(true);
        setIsUploading(false);
      })
      .catch((e) => {
        setReadyToImport(false);
        setIsUploading(false);
        setErrorMessage(e.message);
      });
  }, [
    api,
    file,
    setImportResult,
    setReadyToImport,
    setErrorMessage,
    setIsUploading
  ]);

  const handleFileChange = useCallback(
    (file) => {
      setFile(file);
      setReadyToImport(true);
      setErrorMessage(null);
      setImportResult(null);
    },
    [setFile, setReadyToImport, setErrorMessage]
  );

  return (
    <Grid container className={classes.importContainer} spacing={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Typography variant="h5">Import Activities</Typography>
          <Grid item>
            <div className={classes.buttonProgressWrapper}>
              <Button
                color="secondary"
                disabled={!readyToImport || isUploading}
                onClick={handleImport}
                size="small"
                variant="contained"
              >
                <Typography variant="button">Import</Typography>
              </Button>
              {isUploading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
            {isUploading && (
              <Typography color="textSecondary">
                Processing large imports could take several minutes
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="flex-end">
          <Typography color="error">{errorMessage}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ImportPointsForm file={file} onFileChange={handleFileChange} />
      </Grid>

      {importResult && <ImportPointsSummary importResult={importResult} />}

      <Grid item xs={12}>
        <Paper className={classes.importInstructions}>
          <Typography className={classes.notesHeaders} variant="h6">
            Instructions
          </Typography>

          <Typography className={classes.notesBody}>
            This CSV import can be used to import Vocational Currency and
            External Professional Currency. The items will be imported as Point
            Requests that will need to be approved by supervisors or admins.
          </Typography>

          <Typography className={classes.notesHeaders} variant="h6">
            Validation Rules
          </Typography>

          <Typography className={classes.notesListSpacing} variant="h6">
            The CSV import must follow these requirements to successfully
            import:
          </Typography>

          <ul className={classes.importNotesList}>
            <li className={classes.listItem}>
              The first row must contain column headers
            </li>
            <li className={classes.listItem}>
              The column headers are: Employee Number, Points, Points Type,
              Vocational Activity Type, Domain, Name of Activity, Date of
              Activity, Duration of Activity, Interval of Activity, Inform
              Teaching Practice, Related to Units, Student Benefit, Learning
              Journey Equivalent
            </li>
            <li className={classes.listItem}>
              Optional columns may be omitted
            </li>
            <li className={classes.listItem}>Max number of records 2000</li>
            <li className={classes.listItem}>
              The Employee Number must match exactly a User with the Educator
              role that is already in the system. This is a required field.
              Multiple rows with the same Employee Number can be entered if
              there are multiple points being imported for one user.
            </li>
            <li className={classes.listItem}>
              Points - Number, optional can be left blank and can be entered by
              the supervisor at the approval step.
            </li>
            <li className={classes.listItem}>
              Points Type - Must be 'Vocational Currency' or 'External
              Professional Currency'
            </li>
            <li className={classes.listItem}>
              Vocational Activity Type - Must match an item configured in
              Institute &gt; Vocational Activities, required for Vocational
              Currency
            </li>
            <li className={classes.listItem}>
              Domain - Must match an item configured in Institute &gt; Domains,
              required for External Professional Currency
            </li>
            <li className={classes.listItem}>
              Name of Activity - Free text, required
            </li>
            <li className={classes.listItem}>
              Date of Activity - YYYY-MM-DD or dd/mm/YYYY format, required
            </li>
            <li className={classes.listItem}>
              Duration of Activity - Number, required
            </li>
            <li className={classes.listItem}>
              Interval of Activity - Must be one of hours, days, weeks, months,
              years required
            </li>
            <li className={classes.listItem}>
              Inform Teaching Practice - Long free text, required
            </li>
            <li className={classes.listItem}>
              Related to Units - Long free text, required
            </li>
            <li className={classes.listItem}>
              Student Benefit - Long free text, required
            </li>
            <li className={classes.listItem}>
              Learning Journey Equivalent - 'Y' or 'N', optional, for External
              Professional Currency only, defaults to 'N'
            </li>
          </ul>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default withStyles(ImportPoints, styles, { withTheme: true });
