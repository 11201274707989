import React from 'react';
import Typography from '@mui/material/Typography';

function ValueAssociation(props) {
  var stringValue = '';

  if (props.metadata.options === undefined) {
    stringValue = props.value;
  }
  else {
    stringValue = props.metadata.options[props.value].label;
  }

  return (
    <Typography className={props.tableCellStyle}><span style={{whiteSpace: 'nowrap'}}>{stringValue}</span></Typography>
  )
}

export default ValueAssociation;
