import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import WaveTextField from '../form/WaveTextField';

const styles = theme => ({
  formFields: {
    paddingBottom: theme.typography.pxToRem(10),
  },
  vocationalActivityFields: {
    marginBottom: theme.typography.pxToRem(10)
  }
});

class VocationalActivityForm extends React.Component {

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Grid
          container
          className={classes.vocationalActivityFields}
        >
          <Grid item xs={12} className={classes.formFields}>
            <WaveTextField
              label="Name"
              {...this.props.makeFieldProps('name')}
            />
          </Grid>
          <Grid item xs={12} className={classes.formFields}>
            <WaveTextField
              label="Vocational Points"
              {...this.props.makeFieldProps('points')}
            />
          </Grid>
        </Grid>
      </Fragment>
    )
  }

}

export default withStyles(VocationalActivityForm, styles, { withTheme: true });
