import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

import ApiService from '../../../common/services/ApiService';
import MetadataService from '../../../common/services/MetadataService';

const styles = (theme) => ({
  actionButton: {
    marginBottom: theme.typography.pxToRem(6)
  },
  closeModal: {
    color: theme.typography.color.primary,
    cursor: 'pointer',
    opacity: 0.3
  },
  modalContainer: {
    backgroundColor: theme.palette.background.paper,
    left: '50%',
    margin: '0',
    padding: `${theme.typography.pxToRem(24)} ${theme.typography.pxToRem(29)}`,
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%'
  },
  modalHeader: {
    marginBottom: theme.typography.pxToRem(22)
  },
  buttonProgress: {
    marginLeft: theme.typography.pxToRem(8)
  }
});

class AdminModal extends React.Component {
  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.meta = new MetadataService();
    this.state = {
      entity: {},
      validationErrors: {},
      files: {},
      isUploading: false
    };
    this.newEntityId = null;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id && this.props.id !== null) {
      this.getEntity();
    }

    if (prevProps.open !== this.props.open) {
      this.resetModal();
    }
  }

  errorHelpers = (field) => {
    if (this.state.validationErrors.hasOwnProperty(field)) {
      return this.state.validationErrors[field][0];
    }
    return null;
  };

  errorStatus = (field) => {
    return this.state.validationErrors.hasOwnProperty(field);
  };

  getEntity = () => {
    this.api.get(this.props.resource, this.props.id).then((res) => {
      if (res.status === 200) {
        this.setState({ entity: res.json.data });
      }
    });
  };

  handleAction = async () => {
    const { callback } = this.props;
    await this.api
      .saveResource(this.props.resource, this.state.entity)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          this.newEntityId = res.json.data.id;
          console.log('Callback....');
          if (callback) {
            if (res.json && res.json.data && res.json.data.id) {
              try {
                callback(this.newEntityId);
              } catch (er) {
                console.log(er);
              }
            }
          }
          this.uploadFiles();
        } else if (res.status === 400 || res.status === 422) {
          this.setValidationErrors(res.json);
        } else {
          console.error('Unknown response', res);
        }
      })
      .catch((ex) => console.error(ex));
  };

  uploadFiles = () => {
    const allFiles = this.allFiles();

    if (allFiles.length > 0) {
      this.setState({ isUploading: true });
    }

    Promise.all(allFiles).then((values) => {
      console.log('Uploaded ' + values.length + ' files');
      this.setState({ isUploading: false, files: {} });
      if (!values.some((x) => x === false)) {
        this.props.onCloseModal(true);
        this.props.onForceRefresh();
      }
    });
  };

  allFiles = () => {
    const files = this.state.files;
    return Object.keys(this.state.files).map((key) => {
      const file = files[key];
      return this.uploadFile(key, file);
    });
  };

  uploadFile = async (field, file) => {
    const id = this.state.entity.id || this.newEntityId;
    const url = `/api/v1/${this.props.resource}/` + id;
    const fullField = this.props.resource.slice(0, -1) + '[' + field + ']'; // assumes regular plural
    console.log(url, fullField);
    return this.api
      .upload(url, file, fullField)
      .then(async (response) => {
        const json = await response.json();
        if (response.status === 400 || response.status === 422) {
          this.setValidationErrors(json);
          return false;
        } else {
          return true;
        }
      })
      .catch((ex) => console.error(ex));
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let entity = { ...this.state.entity };
    entity[name] = value;

    this.setState({ entity: entity });
  };

  makeFieldProps = (fieldName) => {
    return {
      error: this.errorStatus(fieldName),
      helperText: this.errorHelpers(fieldName),
      name: `${fieldName}`,
      onChange: this.handleInputChange,
      value: this.state.entity[fieldName]
    };
  };

  resetModal = () => {
    var fieldsInitialState = { id: null };
    this.props.fields.map((field) => {
      Object.assign(fieldsInitialState, { [field]: null });
    });

    this.setState({ entity: fieldsInitialState, validationErrors: {} });
  };

  setValidationErrors(json) {
    var errorMessage = json.title;

    if (json.messages.hasOwnProperty('base')) {
      errorMessage += ' - ' + json.messages.base.join(', ');
    }

    this.setState({ validationErrors: json.messages });
  }

  onFileChanged = (field, file, opts = {}) => {
    let files = { ...this.state.files };
    files[field] = file;
    this.setState({ files: files });

    const shouldRemoveAttachment = !!opts.removeAttachment;
    if (!file && shouldRemoveAttachment) {
      this.setState({
        entity: {
          ...this.state.entity,
          [`remove_${field}`]: shouldRemoveAttachment ? true : undefined
        }
      });
    }
  };

  render() {
    const { classes, mode, onCloseModal, open, title } = this.props;
    const { entity, isUploading } = this.state;

    const modalHeader =
      mode === 'add' ? `Create New ${title}` : `Edit ${title}`;
    const buttonText = mode === 'add' ? 'Create' : 'Save';

    const FormComponent = this.props.component;

    return (
      <Modal open={Boolean(open)} onClose={onCloseModal}>
        <div className={classes.modalContainer}>
          <Grid
            container
            className={classes.modalHeader}
            justifyContent="space-between"
          >
            <Typography variant="h5">{modalHeader}</Typography>
            <a onClick={() => onCloseModal(false)}>
              <CloseIcon className={classes.closeModal} />
            </a>
          </Grid>
          <FormComponent
            {...this.props}
            entity={entity}
            classes={null}
            onFileChanged={this.onFileChanged}
            makeFieldProps={this.makeFieldProps}
            errorHelpers={this.errorHelpers}
          />
          <div>
            <Button
              className={classes.actionButton}
              color="secondary"
              onClick={this.handleAction}
              size="small"
              variant="contained"
            >
              <Typography variant="button">{buttonText}</Typography>
            </Button>
            {isUploading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

export default withStyles(AdminModal, styles, { withTheme: true });
