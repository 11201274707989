import React from 'react';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ApiService from '@common/services/ApiService';
import { VcManageModal } from './VcManageModal';
import { ReaManageModal } from './ReaManageModal';
import { DefaultButton } from '@common/components/default-button';
import { addPointsActionsStyles } from './styles';

class AddPointsActions extends React.Component {
  api = new ApiService();

  state = {
    dirty: false,
    errorMessage: null,
    validationErrors: {},
    reaModal: false,
    isVcModalOpened: false,
    isReaModalOpened: false,
    isSubmittingVc: false,
    isSubmittingRea: false
  };

  handleAddRea = async reaData => {
    this.setState({ isSubmittingRea: true });

    try {
      const url = `/api/v1/users/${this.props.user.id}/points_reas`;
      const res = await this.api.post(url, reaData);

      if (res.status === 201) {
        this.handleCloseReaModal();
        this.props.onRefreshRequest();
        return true;
      } else if (res.status === 422 || res.status === 400) {
        this.setValidationErrors(res.json);
      } else {
        console.error('Unknown response', res);
      }
    } catch (error) {
      this.processError(error);
    } finally {
      this.setState({ isSubmittingRea: false });
    }
  };

  handleOpenReaModal = () => {
    this.setState({ isReaModalOpened: true });
  };

  handleCloseReaModal = () => {
    this.setState({
      isReaModalOpened: false,
      errorMessage: null,
      validationErrors: []
    });
  };

  handleAddVc = async vcData => {
    this.setState({
      isSubmittingVc: true
    });

    if (vcData.vocational_activity_id === 'other') {
      vcData.vocational_activity_id = null;
    }

    const url = `/api/v1/users/${this.props.user.id}/points_vocationals`;

    try {
      const res = await this.api.post(url, vcData);

      if (res.status === 201) {
        this.handleCloseVcModal();
        this.props.onRefreshRequest();
        return true;
      } else if (res.status === 422 || res.status === 400) {
        this.setValidationErrors(res.json);
      } else {
        console.error('Unknown response', res);
      }
    } catch (error) {
      this.processError(error);
    } finally {
      this.setState({
        isSubmittingVc: false
      });
    }
  };

  setValidationErrors(json) {
    if (json.code == 1) {
      this.setState({
        dirty: true,
        errorMessage: json.message,
        validationErrors: []
      });
    } else if (json.code == 2) {
      this.setState({
        dirty: true,
        errorMessage: null,
        validationErrors: json.messages
      });
    }
  }

  handleOpenVcModal = () => {
    this.setState({ isVcModalOpened: true });
  };

  handleCloseVcModal = () => {
    this.setState({
      isVcModalOpened: false,
      errorMessage: null,
      validationErrors: []
    });
  };

  handleExport = () => {
    var sortingParams = {
      direction: this.props.orderBy,
      field: this.props.order
    };
    var searchRequest = this.api.searchUrl(
      this.state.filters,
      null,
      null,
      sortingParams,
      null
    );

    var apiUrl =
      '/api/v1/users/' +
      this.props.user.id +
      '/points_histories.csv' +
      (searchRequest !== '' ? '?' : '');
    var csvUrl = apiUrl + searchRequest;
    return csvUrl;
  };

  handleDownloadPdf = () => {
    var sortingParams = {
      direction: this.props.orderBy,
      field: this.props.order
    };
    var searchRequest = this.api.searchUrl(
      this.state.filters,
      null,
      null,
      sortingParams,
      null
    );

    var apiUrl =
    '/api/v1/users/' +
    this.props.user.id +
    '/points_histories.pdf' +
    (searchRequest !== '' ? '?' : '');
    var pdfUrl = apiUrl + searchRequest;
    return pdfUrl;
  };

  render() {
    const {
      classes,
      currentUser,
      user,
      vocationalActivitiesOptions,
      activityIntervalOptions,
      pointsFileAttachmentEnabled,
      domainOptions
    } = this.props;

    const {
      errorMessage,
      isVcModalOpened,
      isReaModalOpened,
      isSubmittingVc,
      isSubmittingRea,
      validationErrors
    } = this.state;


    // Allow these users the ability to Add VC, except where the supervisor is looking at their own points requests
    const canAddVocationalCurrency = 
      currentUser.is_institute_admin
      || currentUser.is_super_admin
      || (currentUser.is_supervisor && (currentUser.id !== user.id));

    const canAddExternalProfessionalCurrency = currentUser.is_institute_admin || currentUser.is_super_admin;

    return (
      <Grid container className={classes.pointsHistory}>
        <Grid item>
          <Typography variant="h6">Points History</Typography>
        </Grid>
        <Grid item className={classes.pointsHistoryActionsContainer}>
          <Grid container justifyContent="flex-end">
            <DefaultButton
              containerClass={classes.pointsActionButtons}
              color="secondary"
              size="small"
              variant="contained"
              component="a"
              href={`${this.handleDownloadPdf()}`}
              target="_blank"
            >
              Download PDF
            </DefaultButton>
            <DefaultButton
              containerClass={classes.pointsActionButtons}
              color="secondary"
              size="small"
              variant="contained"
              component="a"
              href={`${this.handleExport()}`}
              target="_blank"
            >
              Export
            </DefaultButton>

            {(canAddVocationalCurrency) && (
              <DefaultButton
                containerClass={classes.pointsActionButtons}
                color="secondary"
                onClick={this.handleOpenVcModal}
                variant="contained"
              >
                Add External Vocational Currency
              </DefaultButton>
            )}

            {(canAddExternalProfessionalCurrency) && (
              <DefaultButton
                containerClass={classes.pointsActionButtons}
                color="secondary"
                variant="contained"
                onClick={this.handleOpenReaModal}
              >
                Add External Professional Currency
              </DefaultButton>
            )}
          </Grid>
        </Grid>
        <VcManageModal
          activityIntervalOptions={activityIntervalOptions}
          vocationalActivitiesOptions={vocationalActivitiesOptions}
          pointsFileAttachmentEnabled={pointsFileAttachmentEnabled}
          isOpened={isVcModalOpened}
          onSubmit={this.handleAddVc}
          onClose={this.handleCloseVcModal}
          validationErrors={validationErrors}
          errorMessage={errorMessage}
          isLoading={isSubmittingVc}
        />
        <ReaManageModal
          activityIntervalOptions={activityIntervalOptions}
          pointsFileAttachmentEnabled={pointsFileAttachmentEnabled}
          domainOptions={domainOptions}
          isOpened={isReaModalOpened}
          onSubmit={this.handleAddRea}
          onClose={this.handleCloseReaModal}
          validationErrors={validationErrors}
          errorMessage={errorMessage}
          isLoading={isSubmittingRea}
        />
      </Grid>
    );
  }
}

export default withStyles(AddPointsActions, addPointsActionsStyles, { withTheme: true });
