import React from 'react';
import Chisholm from '../config/Chisholm';
import { DatePicker } from '@mui/x-date-pickers';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import EventIcon from '@mui/icons-material/Event';
import TextField from '@mui/material/TextField';

const SlotTextField = (params) => <TextField {...params} variant="standard" />

export const WaveDatePicker = ({ value, ...props }) => {
  return (
    <DatePicker
      autoOk={true}
      fullWidth
      showTodayButton
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton size="large">
              <EventIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      slots={{
        textField: SlotTextField
      }}
      slotProps={{
        textField: {
          helperText: props.helperText,
          error: props.error,
        }
      }}
      sx={{
        width: '100%'
      }}
      {...props}
      value={new Date(value)}
    />
  )
}