import Utils from '@common/services/Utils';

const numericPx = (stringPx) => +stringPx.replace('px', '');

export const programFormStyles = theme => ({
  addWorkshopBtn: {
    marginTop: `${theme.typography.pxToRem(numericPx(theme.spacing(3)))}`,
    marginBottom: `${theme.typography.pxToRem(numericPx(theme.spacing(7.5)))}`
  },
  errorSpacing: {
    marginBottom: theme.typography.pxToRem(20)
  },
  deleteWorkshopIcon: {
    color: theme.typography.color.primary,
    marginLeft: theme.typography.pxToRem(15),
    verticalAlign: 'top',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  formContainer: {
    marginBottom: `${theme.spacing(7.5)}`
  },
  formLeft: {
    paddingRight: `${theme.typography.pxToRem(numericPx(theme.spacing(3)))}`
  },
  formRight: {
    paddingLeft: `${theme.typography.pxToRem(numericPx(theme.spacing(3)))}`
  },
  popper: {
    opacity: 1
  },
  programFormContainer: {
    padding: `${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(
      25
    )} ${theme.typography.pxToRem(35)} ${theme.typography.pxToRem(25)}`
  },
  sectionHeaders: {
    color: theme.typography.color.primary,
    fontSize: theme.typography.pxToRem(13),
    fontWeight: 600,
    lineHeight: 2.15,
    marginBottom: theme.typography.pxToRem(12),
    textTransform: 'uppercase'
  },
  textAreaContainer: {
    height: '100%'
  },
  tooltipContainer: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '2px',
    boxShadow: '0 2px 10px 0 #00000028',
    padding: theme.typography.pxToRem(20),
    opacity: 1
  },
  tooltipText: {
    color: '#0c0606de',
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.5
  },
  workshopContainer: {
    marginTop: `${theme.typography.pxToRem(numericPx(theme.spacing(3)))}`
  },
  ieFix: Utils.ieDatePickerFix()
});

export const programFormActionsStyles = theme => ({
  errorMessages: {
    paddingTop: theme.typography.pxToRem(20)
  },
  flatProgramFormButtons: {
    marginRight: theme.typography.pxToRem(18)
  },
  formActionsContainer: {
    paddingTop: theme.typography.pxToRem(20)
  },
  programButtonText: {
    color: theme.typography.color.primary,
    fontWeight: 400,
    opacity: 0.75
  },
  raisedProgramFormButtons: {
    marginLeft: theme.typography.pxToRem(30)
  }
});

export const notifyModalStyles = theme => ({
  notifyModalHelper: {
    color: '#1f1f1fde',
    fontSize: theme.typography.pxToRem(15),
    lineHeight: 1.6,
    marginBottom: theme.typography.pxToRem(30)
  },
  sendNotification: {
    marginBottom: theme.typography.pxToRem(6)
  }
});
