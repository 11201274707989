import React from 'react';
import { withStyles } from 'tss-react/mui';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import ApiService from '../../../../common/services/ApiService';
import SummaryReport from './SummaryReport';
import RegistrationsImportForm from './RegistrationsImportForm';

const styles = (theme) => ({
  fileUploadContainer: {
    padding: `${theme.typography.pxToRem(25)} 0 ${theme.typography.pxToRem(
      45
    )} ${theme.typography.pxToRem(25)}`
  },
  importInstructions: {
    padding: `${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(
      334
    )} ${theme.typography.pxToRem(5)} ${theme.typography.pxToRem(25)}`
  },
  importNotesList: {
    listStyle: 'none',
    marginTop: 0,
    marginBottom: theme.typography.pxToRem(40),
    paddingLeft: theme.typography.pxToRem(25)
  },
  listItem: {
    lineHeight: 1.5,
    marginBottom: theme.typography.pxToRem(6),
    paddingLeft: theme.typography.pxToRem(8),
    textIndent: theme.typography.pxToRem(-26)
  },
  topStatus: {
  },
  notesHeaders: {
    paddingBottom: theme.typography.pxToRem(15)
  },
  notesListSpacing: {
    paddingBottom: theme.typography.pxToRem(10)
  },
  notesSpacing: {
    paddingBottom: theme.typography.pxToRem(40)
  },
  notesBody: {
    paddingBottom: theme.typography.pxToRem(24)
  },
  registrationImportContainer: {
    marginTop: theme.typography.pxToRem(25),
    marginBottom: theme.typography.pxToRem(53)
  },
  buttonProgressWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '88%',
    marginTop: -12,
    marginLeft: -12
  }
});

class RegistrationsImport extends React.Component {
  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.state = {
      program: null,
      file: null,
      importBtn: false,
      importResults: null,
      ranImport: false,
      errorMessage: null,
      isUploading: false
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.api.get('programs', this.props.match.params.program_id).then(res => {
        if (res.status === 200) {
          const program = res.json.data;
          this.setState({
            program: program
          });
        } else {
          this.setState({
            errorMessage: 'Something went wrong. got status=' + res.status
          });
        }
      }).catch(e => {
        this.setState({
          errorMessage: 'Something went wrong. ' + e.toString()
        });
      });
  };

  handleRunImport = () => {
    this.setState({ importBtn: false, isUploading: true });
    this.api
      .upload(`/api/v1/programs/${this.props.match.params.program_id}/registrations/import`, this.state.file, 'file')
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        this.setState({
          importResults: json,
          ranImport: true,
          importBtn: true,
          isUploading: false
        });
      })
      .catch((e) => {
        this.setState({
          errorMessage: e.message,
          ranImport: false,
          importBtn: true,
          isUploading: false
        });
      });
  };

  onCsvFileChange = (file) => {
    this.setState({ file: file, importBtn: true });
  };

  render() {
    const { classes, theme, history } = this.props;
    const {
      program,
      file,
      importBtn,
      importResults,
      ranImport,
      errorMessage,
      isUploading
    } = this.state;

    if (ranImport === true) {
      return <SummaryReport programId={program.id} history={history} importResults={importResults} />;
    }

    return (
      <Grid container className={classes.registrationImportContainer} spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Typography variant="h5">Import Registrations</Typography>
            <Grid item>
              <div className={classes.buttonProgressWrapper}>
                <Button
                  color="secondary"
                  disabled={!importBtn || isUploading}
                  onClick={this.handleRunImport}
                  size="small"
                  variant="contained"
                >
                  <Typography variant="button">Import</Typography>
                </Button>
                {isUploading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
              {program && (
                <div className={classes.topStatus}>              
                  <Typography>
                    Registrations will be imported into program: <b>{program.name}</b>
                  </Typography>
                </div>
              )}
              {isUploading && (
                <div className={classes.topStatus}>
                  <Typography color="textSecondary">
                    Processing large imports could take several minutes
                  </Typography>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <Typography color="error">{errorMessage}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <RegistrationsImportForm file={file} onCsvFileChange={this.onCsvFileChange} />
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.importInstructions}>
            <Typography className={classes.notesHeaders} variant="h6">
              Instructions
            </Typography>

            <Typography className={classes.notesBody}>
              This CSV import can be used to import Registrations to the selected Program
            </Typography>
            <Typography className={classes.notesListSpacing}>
              Importing a registration will trigger one of two operations;
            </Typography>

            <ul className={classes.importNotesList}>
              <li className={classes.listItem}>
                Create a new registration
              </li>
              <li className={classes.listItem}>
                Update an existing registration
              </li>
            </ul>

            <Typography className={classes.notesHeaders} variant="h6">
              Create a new Registration
            </Typography>
            <Typography className={classes.notesSpacing}>
              A new registrations will be created for the selected program for the user identified by Employee Number. The Workshops, Hurdle and Completion will be set as specified.
            </Typography>

            <Typography className={classes.notesHeaders} variant="h6">
              Updating an existing Registration
            </Typography>
            <Typography className={classes.notesSpacing}>
              If an existing registration is found with the same Employee Number, then
              that registration will be updated.
            </Typography>

            <Typography className={classes.notesHeaders} variant="h6">
              Removing Registrations
            </Typography>
            <Typography className={classes.notesSpacing}>
              The CSV import process will not remove registrations, this needs to be done manually from the Attendance tab.
            </Typography>

            <Typography className={classes.notesHeaders} variant="h6">
              Validation Requirements
            </Typography>
            <Typography className={classes.notesListSpacing}>
              The CSV import must have the following requirements to
              successfully import:
            </Typography>

            <ul className={classes.importNotesList}>
              <li className={classes.listItem}>
                The first row must have column headers. E.g.: Employee Number,Workshop 1,Workshop 2,Hurdle,Completed,Notify,Learning Journey
              </li>
              <li className={classes.listItem}>Max number of records 2000</li>
              <li className={classes.listItem}>You can't exceed the the attendance limit for the program</li>
              <li className={classes.listItem}>Employee Number is always required</li>
              <li className={classes.listItem}>
                Workshop 1, Workshop 2, Workshop N (up to as many workshops as the program has) columns are optional.
                If included they should have a value of Y or N which will set the corresponding tick. If the value is
                blank or the column is missing then the corresponding tick will not be changed.
              </li>
              <li className={classes.listItem}>
                Hurdle - optional.
                If included it should have a value of Y or N which will set the corresponding tick. If the value is
                blank or the column is missing then the corresponding tick will not be changed.
              </li>
              <li className={classes.listItem}>
                Learning Journey - Y or N optional. Only applicable to learning journey type programs. If included it should have a value of Y or N which will set the corresponding tick. If the value is
                blank or the column is missing then the corresponding tick will not be changed.
              </li>
              <li className={classes.listItem}>
                Completed - optional.
                If included it should have a value of Y or N which will set the corresponding tick. If the value is
                blank or the column is missing then the corresponding tick will not be changed.
              </li>
              <li className={classes.listItem}>
                Notify - Y or N optional. if Completed is also Y then notify the educator by email. (NB: only notifies if Completed goes from N to Y)
              </li>
              <li className={classes.listItem}>
                The CSV may contain other columns which will be ignored
              </li>
            </ul>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(RegistrationsImport, styles, { withTheme: true });
