import React from 'react';
import { withStyles } from 'tss-react/mui';

import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from '@mui/material/Tooltip';

const styles = theme => ({
  unsortedColumn: {
    color: theme.typography.color.primary,
    fontSize: theme.typography.pxToRem(12),
    opacity: 0.55,
    '&:focus': {
      opacity: 1,
    },
    '&:hover': {
      opacity: 1,
    },
  },
  sortedColumn: {
    opacity: 1,
  },
});

class SortableTableCell extends React.Component {

  constructor(props) {
    super(props);
  }

  createSortHandler = property => event => {
    this.props.onSortRequest(event, property);
  };

  label = (column_name) => {
    if(this.props.resourceMeta && this.props.resourceMeta.hasOwnProperty(column_name)) {
      return this.props.resourceMeta[column_name].label || column_name;
    } else {
      return column_name;
    }
  };

  render() {
    const { classes, columnName, defaultOrder } = this.props;
    const tableCellProps = this.props.resourceMeta.tableCellProps || {};
    return(
      <TableCell
        sortDirection={defaultOrder.orderBy === columnName ? defaultOrder.order : false}
        {...tableCellProps}
      >
        <Tooltip title="Sort">
          <TableSortLabel
            active={defaultOrder.orderBy === columnName}
            classes={{
              root: classes.unsortedColumn,
              active: classes.sortedColumn,
            }}
            direction={defaultOrder.order}
            onClick={this.createSortHandler(columnName)}
          >
            {this.label(columnName)}
          </TableSortLabel>
        </Tooltip>
      </TableCell>
    )
  }

}

export default withStyles(SortableTableCell, styles);
