import React, { Fragment } from 'react';
import { format } from 'date-fns';
import Chisholm from '../../../common/config/Chisholm';

function ValueDate(props) {
  var getDateFormat = Chisholm[props.dateFormat];
  var convertedDate = props.value == null ? '' : format(new Date(props.value), getDateFormat);

  return (
    <Fragment><span style={{whiteSpace: 'nowrap'}}>{convertedDate}</span></Fragment>
  )
}

export default ValueDate;
