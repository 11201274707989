import React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export const InstituteActions = ({
  styles,
  instituteDetails,
  instituteDetailsTab,
  dirty,
  onSave
}) => (
  <Grid container justifyContent="space-between">
    <Typography className={styles} variant="h5">
      {instituteDetails.name}
    </Typography>
    {instituteDetailsTab ? (
      <Button
        color="secondary"
        disabled={!dirty}
        onClick={onSave}
        size="small"
        variant="contained"
      >
        <Typography variant="button">Save</Typography>
      </Button>
    ) : null}
  </Grid>
);
