import React from 'react';
import { withStyles } from 'tss-react/mui';

import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

const styles = theme => ({
  departmentsHeader: {
    paddingBottom: theme.typography.pxToRem(10),
  },
  employmentTypeOption: {
    fontFamily: 'IBM Plex Sans, medium',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500,
    opacity: 0.7,
  },
  selectEmploymentType: {
    padding: `${theme.typography.pxToRem(24)} ${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(25)}`,
  },
});

class EmploymentTypeFilter extends React.Component {

  onEmploymentTypeSelect = (event) => {
    const target = event.target;
    const value = target.value;
    var selectedEmploymentType = '';
    var employmentTypeOptions = this.props.employmentTypeOptions;
    Object.keys(employmentTypeOptions).map(obj => {
      if (value === employmentTypeOptions[obj].id) {
        selectedEmploymentType = employmentTypeOptions[obj]
      }
    });

    this.props.handleEmploymentTypeChange(selectedEmploymentType);
  };

  render() {
    const { classes, employmentType, employmentTypeOptions } = this.props;

    return (
      <Grid container className={classes.selectEmploymentType}>
        <Typography className={classes.departmentsHeader} variant="h6">
          Departments
        </Typography>
        <FormControl variant="standard" fullWidth>
          <Select
            variant="standard"
            disableUnderline
            onChange={this.onEmploymentTypeSelect}
            value={employmentType.id}>
            {Object.keys(employmentTypeOptions).map(obj =>
              <MenuItem
                component='div'
                key={obj}
                value={employmentTypeOptions[obj].id}
              >
                <Typography className={classes.employmentTypeOption}>
                  {employmentTypeOptions[obj].label}
                </Typography>
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </Grid>
    );
  }

}

export default withStyles(EmploymentTypeFilter, styles, { withTheme: true });
