import React,  { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';

import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';

import ApiService from '../../../common/services/ApiService';

const styles = theme => ({
  businessIdealStatement: {
    fontFamily: 'IBM Plex Sans, medium',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500,
    lineHeight: 1.13,
    marginBottom: theme.typography.pxToRem(6),
  },
  currentDomain: {
    fontFamily: 'IBM Plex Sans, semi-bold',
    fontSize: theme.typography.pxToRem(13),
    fontWeight: 600,
    lineHeight: 2.15,
    marginBottom: theme.typography.pxToRem(16),
    textTransform: 'uppercase',
  },
  radioField: {
    marginRight: theme.typography.pxToRem(27),
  },
  responses: {
    fontSize: theme.typography.pxToRem(13),
    opacity: 0.7,
  },
  singleCapability: {
    marginBottom: theme.typography.pxToRem(23),
  },
});

class BusinessIdealsForm extends React.Component {

  constructor(props) {
    super(props);
    this.api = new ApiService();
  }

  convertBusinessIdeal = (response) => {
    return response.toString();
  };

  handleResponse = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    const postUrl = '/api/v1/business_ideal_responses';
    var responseData = {
      business_ideal_response: {
        department_id: this.props.selectedDepartment.id,
        employment_type_id: this.props.employmentType.id,
        capability_id: parseInt(name),
        value: parseInt(value),
      }
    }

    this.api.post(postUrl, responseData).then((res) => {
      if (res.status === 201) {
        this.props.updateResponses(res.json.data, this.props.responsesList);
        this.props.updateProgressBar(res.json.meta.business_ideal_completed_percent);
      } else {
        console.error('Unknown response', res);
      }
    }).catch(this.processError);
  };

  renderBusinessIdealQuestions = (domainData, responsesList) => {
    const { classes, responseLabels } = this.props;
    var domainCapabilities = domainData.capabilities;

    return Object.keys(domainCapabilities).map((obj, index) => {
      var capability = domainCapabilities[index];
      var capabilityId = capability.id;
      var responseData = responsesList[capabilityId];
      var getBusinessIdeal = responseData ? responseData.BusinessIdealResponse : '';
      var businessIdeal = '';

      responsesList[capabilityId] === undefined ? businessIdeal = null : businessIdeal = this.convertBusinessIdeal(getBusinessIdeal);

      const radios = responseLabels.map((responseLabel) => {
        return (
          <FormControlLabel
            key={responseLabel.value + ':' + responseLabel.text}
            classes={{root: classes.radioField}}
            control={<Radio />}
            label={responseLabel.text}
            value={responseLabel.value + ''}
          />);
      });

      return (
        <Grid
          container
          className={classes.singleCapability}
          key={capabilityId}
        >
          <Typography className={classes.businessIdealStatement}>
            {`${index + 1}. ${capability.statement}`}
          </Typography>
          <FormControl variant="standard" fullWidth>
            <RadioGroup
              row
              name={`${capabilityId}`}
              onChange={this.handleResponse}
              value={businessIdeal}
            >
              {radios}
            </RadioGroup>
          </FormControl>
        </Grid>
      );
    });
  };

  render() {
    const { classes, currentDomain, responsesList } = this.props;

    return (
      <Fragment>
        <Typography className={classes.currentDomain}>
          {currentDomain.name}
        </Typography>
        {this.renderBusinessIdealQuestions(currentDomain, responsesList)}
      </Fragment>
    );
  }

}

export default withStyles(BusinessIdealsForm, styles, { withTheme: true });
