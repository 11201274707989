import React from 'react';
import { createRoot } from 'react-dom/client';
import AdminApp from './components/AdminApp';

const view = document.getElementById('admin_app');
if (view) {
  var instituteId = view.getAttribute('data-institute-id');
  var institute = window.institutes.find((institute) => institute.id == instituteId);
  const root = createRoot(view);
  root.render(<AdminApp user={window.current_user} institutes={window.institutes} institute={institute} />);
}
