import React, {
  Fragment,
  useCallback,
  useState,
  useEffect,
  useMemo
} from 'react';

import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';

import EventIcon from '@mui/icons-material/Event';
import { DatePicker } from '@mui/x-date-pickers';
import format from 'date-fns/format';

import ApiService from '@common/services/ApiService';
import Utils from '@common/services/Utils';
import Chisholm from '@common/config/Chisholm';
import WaveSelectField from '@common/components/form/WaveSelectField';
import Autocomplete from '@common/components/autocomplete/Autocomplete';
import { WaveDatePicker } from '../../../../common/components/WaveDatePicker';

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. Unsupported arrow function syntax.
//Unexpected value type of CallExpression.
const styles = (theme) => ({
  adjustedSpacing: {
    padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(
      20
    )} 0 ${theme.typography.pxToRem(20)}`
  },
  adjustedSpacingLeft: {
    padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(
      20
    )} 0 ${theme.typography.pxToRem(25)}`
  },
  adjustedSpacingRight: {
    padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(
      25
    )} 0 ${theme.typography.pxToRem(20)}`
  },
  adjustedSpacingUser: {
    padding: `${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(
      20
    )} ${theme.typography.pxToRem(40)} ${theme.typography.pxToRem(25)}`
  },
  adjustedSpacingSupervisor: {
    padding: `${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(
      20
    )} ${theme.typography.pxToRem(40)} ${theme.typography.pxToRem(20)}`
  },
  adjustedSpacingDate: {
    padding: `${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(
      20
    )} ${theme.typography.pxToRem(40)} ${theme.typography.pxToRem(25)}`
  },
  clearFilters: {
    color: theme.typography.color.primary,
    '&:hover': {
      color: theme.typography.color.primary,
      cursor: 'pointer'
    },
    '&:visited': {
      color: theme.typography.color.primary
    },
    fontSize: theme.typography.pxToRem(13),
    marginRight: theme.typography.pxToRem(20),
    opacity: 0.7
  },
  filterHeader: {
    padding: `${theme.typography.pxToRem(19)} ${theme.typography.pxToRem(
      25
    )} 0 ${theme.typography.pxToRem(25)}`
  },
  ieFix: Utils.ieDatePickerFix()
});

const UserPointsFilters = ({ classes, handleApplyFilters, passportPeriod }) => {
  const userStatusOptions = {
    1: {
      id: '1',
      label: 'Active'
    },
    0: {
      id: '0',
      label: 'Deactivated'
    }
  };

  const initialFilters = {
    department_division_id_eq: 'all',
    department_id_eq: 'all',
    employment_type_id_eq: 'all',
    id_eq: null,
    educator_label: '',
    supervisor_id_eq: null,
    supervisor_label: '',
    date_from_eq: passportPeriod.from,
    date_to_eq: passportPeriod.to,
    active_eq: '1'
  };

  const [filters, setFilters] = useState(initialFilters);
  const [divisionOptions, setDivsionOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [employmentTypeOptions, setEmploymentTypeOptions] = useState([]);

  const api = new ApiService();

  const {
    department_division_id_eq,
    department_id_eq,
    employment_type_id_eq,
    date_from_eq,
    date_to_eq,
    active_eq
  } = filters;

  const departments = useMemo(() => {
    let result = {};
    const divisionId = parseInt(department_division_id_eq);
    Object.keys(departmentOptions).forEach((key) => {
      if (departmentOptions[key].division_id === divisionId) {
        result[key] = departmentOptions[key];
      }
    });
    return result;
  }, [department_division_id_eq, departmentOptions]);

  const handleDateChange = useCallback(
    (filterName, date) => {
      const convertedDate =
        date == null ? null : format(new Date(date), 'yyyy-MM-dd');
      setFilters((prevFilters) => ({
        ...prevFilters,
        [filterName]: convertedDate
      }));
    },
    [setFilters]
  );

  const handleInputChange = useCallback(
    (filterName, event) => {
      const target = event.target;
      const value = target.value;

      if (value !== null && value !== 'all') {
        setFilters((prevFilters) => ({ ...prevFilters, [filterName]: value }));
      } else {
        setFilters((prevFilters) => {
          const { [filterName]: value, ...rest } = prevFilters;
          return rest;
        });
      }
    },
    [setFilters]
  );

  const handleAutocompleteChangeUser = (id, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      id_eq: id,
      user_label: value
    }));
  };

  const handleAutocompleteChangeSupervisor = (id, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      supervisor_id_eq: id,
      supervisor_label: value
    }));
  };

  const makeFieldProps = (filterName, fieldValue) => ({
    name: filterName,
    onChange: (e) => handleInputChange(filterName, e),
    value: fieldValue
  });

  const applyFilters = useCallback(() => {
    const newFilters = {};
    Object.keys(filters).forEach((key) => {
      if (filters[key] !== null && filters[key] !== 'all') {
        newFilters[key] = filters[key];
      }
    });

    handleApplyFilters(newFilters);
  }, [filters]);

  const clearFilters = useCallback(() => {
    setFilters(initialFilters);
    handleApplyFilters({});
  }, [handleApplyFilters]);

  const getFiltersOptions = useCallback(() => {
    const divisionOptionsPromise = api.query('/api/v1/divisions/options');
    const departmentOptionsPromise = api.query('/api/v1/departments/options');
    const employmentTypeOptionsPromise = api.query(
      '/api/v1/employment_types/options'
    );

    Promise.all([
      divisionOptionsPromise,
      departmentOptionsPromise,
      employmentTypeOptionsPromise
    ]).then(
      ([
        newDivisionOptions,
        newDepartmentOptions,
        newEmploymentTypeOptions
      ]) => {
        setDivsionOptions(newDivisionOptions);
        setDepartmentOptions(newDepartmentOptions);
        setEmploymentTypeOptions(newEmploymentTypeOptions);
        setFilters((prevFilters) => {
          return {
            ...prevFilters
          };
        });
      }
    );
  }, [
    setDivsionOptions,
    setDepartmentOptions,
    setEmploymentTypeOptions,
    setFilters
  ]);

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      department_id_eq: 'all'
    }));
  }, [department_division_id_eq, setFilters]);

  useEffect(() => {
    getFiltersOptions();
  }, []);

  return (
    <Fragment>
      <Grid
        container
        className={classes.filterHeader}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h6">Filter</Typography>
        <Grid item>
          <a className={classes.clearFilters} onClick={clearFilters}>
            Clear
          </a>
          <Button
            color="secondary"
            onClick={applyFilters}
            size="small"
            variant="contained"
          >
            <Typography variant="button">Filter</Typography>
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item className={classes.adjustedSpacingLeft} xs={3}>
          <WaveSelectField
            filterByAllItem
            filterByAllItemLabel="All"
            noValidation
            label="Division"
            menuItems={divisionOptions}
            {...makeFieldProps(
              'department_division_id_eq',
              department_division_id_eq
            )}
          />
        </Grid>
        <Grid item className={classes.adjustedSpacing} xs={3}>
          <WaveSelectField
            filterByAllItem
            filterByAllItemLabel="All"
            noValidation
            label="Department"
            menuItems={
              parseInt(department_division_id_eq)
                ? departments
                : departmentOptions
            }
            {...makeFieldProps('department_id_eq', department_id_eq)}
          />
        </Grid>
        <Grid item className={classes.adjustedSpacing} xs={3}>
          <WaveSelectField
            filterByAllItem
            filterByAllItemLabel="All"
            noValidation
            label="Employment Type"
            menuItems={employmentTypeOptions}
            {...makeFieldProps('employment_type_id_eq', employment_type_id_eq)}
          />
        </Grid>
        <Grid item className={classes.adjustedSpacingRight} xs={3}>
          <WaveSelectField
            filterByAllItem
            filterByAllItemLabel="All"
            noValidation
            label="User Status"
            menuItems={userStatusOptions}
            {...makeFieldProps('active_eq', active_eq)}
          />
        </Grid>
        <Grid item className={classes.adjustedSpacingUser} xs={3}>
          <Autocomplete
            label="User"
            name="user_id"
            onChange={handleAutocompleteChangeUser}
            role={["educator", "guest"]}
            value={filters.user_label || ''}
          />
        </Grid>
        <Grid item className={classes.adjustedSpacingSupervisor} xs={3}>
          <Autocomplete
            label="Supervisor"
            name="supervisor_id"
            onChange={handleAutocompleteChangeSupervisor}
            role="supervisor"
            value={filters.supervisor_label || ''}
          />
        </Grid>
        <Grid item className={classes.adjustedSpacingDate} xs={3}>
          <WaveDatePicker
            className={classes.ieFix}
            format={Chisholm.rangeDate}
            label="From"
            name="from_date"
            onChange={(value) => handleDateChange('date_from_eq', value)}
            value={date_from_eq}
          />
        </Grid>
        <Grid item className={classes.adjustedSpacingDate} xs={3}>
          <WaveDatePicker
            className={classes.ieFix}
            format={Chisholm.rangeDate}
            label="To"
            name="to_date"
            onChange={(value) => handleDateChange('date_to_eq', value)}
            value={date_to_eq}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default withStyles(UserPointsFilters, styles, { withTheme: true });
