import React from 'react';
import { withStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';

import AdminTable from '../../../common/components/table/AdminTable';
import ApiService from '../../../common/services/ApiService';
import StyledDivider from '../../../common/components/misc/StyledDivider';

const fields = ['start_date', 'workshop_start_time', 'name', 'program_type', 'domain_name', 'points', 'location_names', 'registration_info'];

const styles = theme => ({
  defaultTabText: {
    color: theme.typography.color.primary,
    opacity: 0.55,
  },
  fab: {
    position: 'fixed',
    bottom: theme.typography.pxToRem(30),
    right: theme.typography.pxToRem(30),
  },
  programsListHeadline: {
    marginTop: theme.typography.pxToRem(8),
    marginBottom: theme.typography.pxToRem(8),
  },
  selectedTabText: {
    opacity: 1,
  },
  typographyTabText: {
    color: 'inherit',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500,
    textTransform: 'capitalize',
  },
  exportHeader: {
    padding: `${theme.typography.pxToRem(22)} ${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(25)}`,
  }
});

const orderMapping = {
  'live': { order: 'asc', orderBy: 'workshop_start_time' },
  'draft': { order: 'desc', orderBy: 'workshop_start_time' },
  'past': { order: 'desc', orderBy: 'workshop_start_time' }
}

class ProgramsList extends React.Component {

  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.state = {
      loading: true,
      programsLive: 0,
      programsDraft: 0,
      programsPast: 0,
      value: 'live',
      order: 'asc',
      orderBy: 'workshop_start_time',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.instituteId !== prevProps.instituteId) {
      this.getProgramsListTabsCount();
    }
  }

  componentDidMount() {
    this.getProgramsListTabsCount();
  };

  handleApplySorting = (order, orderBy) => {
    this.setState({ order, orderBy });
  };

  getProgramsListTabsCount = () => {
    var api = new ApiService();
    var url = '/api/v1/program_summary';
    api.query(url).then((data) => {
      this.setState({
        programsLive: data.data.live,
        programsDraft: data.data.draft,
        programsPast: data.data.past,
      });
    }).catch((ex) => {
      this.setState({ loading: false });
    });
  };

  handleChange = (event, value) => {
    const { order, orderBy } = orderMapping[value];
    this.setState({ value, order, orderBy });
  };

  handleOnNameCellClicked = (row) => {
    this.props.history.push('programs/' + row.id);
  };

  handleExport = () => {
    const { order, orderBy, value } = this.state
    const sortingParams = { direction: order, field: orderBy };
    const searchRequest = this.api.searchUrl(null, null, value, sortingParams, null);

    return '/api/v1/programs.csv?' + searchRequest
  };

  render() {
    const { classes } = this.props;
    const { value, orderBy, order } = this.state;
    const addEnabled = this.props.user.is_institute_admin || this.props.user.is_super_admin;

    return (
      <Grid
        container
        className={classes.programsList}
        spacing={2}
      >
        <Grid item xs={12}>
          <Typography
            className={classes.programsListHeadline}
            variant="h5"
          >
            Manage Programs
          </Typography>
          <Tabs
            onChange={this.handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            value={value}
          >
            <Tab
              classes={{
                root: classes.defaultTabText,
                selected: classes.selectedTabText,
              }}
              label={
                <Typography className={classes.typographyTabText}>
                  Live ({this.state.programsLive})
                </Typography>
              }
              value='live'
            />
            <Tab
              classes={{
                root: classes.defaultTabText,
                selected: classes.selectedTabText,
              }}
              label={
                <Typography className={classes.typographyTabText}>
                  Draft ({this.state.programsDraft})
                </Typography>
              }
              value='draft'
            />
            <Tab
              classes={{
                root: classes.defaultTabText,
                selected: classes.selectedTabText,
              }}
              label={
                <Typography className={classes.typographyTabText}>
                  Past ({this.state.programsPast})
                </Typography>
              }
              value='past'
            />
          </Tabs>
          <StyledDivider />
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Grid
              container
              className={classes.exportHeader}
              justifyContent="space-between"
            >
              <Typography variant="h6">Programs</Typography>
              <a href={`${this.handleExport()}`} target="_blank">
                <Button color="secondary" size="small" variant="contained">
                  <Typography variant="button">Export</Typography>
                </Button>
              </a>
            </Grid>
            <AdminTable
              showTablePagination
              orderBy={orderBy}
              order={order}
              fields={fields}
              namedFilter={value}
              handleApplySorting={this.handleApplySorting}
              onCellClicked={{ name: this.handleOnNameCellClicked }}
              resource="programs"
            />
          </Paper>
        </Grid>
        { addEnabled &&
          <Link to="/programs/new">
            <Fab
              aria-label="Add"
              className={classes.fab}
              color="secondary"
            >
              <AddIcon />
            </Fab>
          </Link>
        }
      </Grid>
    );
  }

}

export default withStyles(ProgramsList, styles, { withTheme: true });
