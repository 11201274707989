import React from 'react';
import { withStyles } from 'tss-react/mui';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { format, parseISO, isValid } from 'date-fns';
import Typography from '@mui/material/Typography';

import Chisholm from '../../../common/config/Chisholm';
import WaveSelectField from '../../../common/components/form/WaveSelectField';
import WaveTextArea from '../../../common/components/form/WaveTextArea';
import WaveTextField from '../../../common/components/form/WaveTextField';
import { WaveDatePicker } from '../../../common/components/WaveDatePicker';
import { DefaultCheckbox } from '@common/components/default-checkbox';

const styles = (theme) => ({
  errorSpacing: {
    marginBottom: theme.typography.pxToRem(20)
  },
  textAreaContainer: {
    height: '100%'
  },
  workshopFields: {
    marginBottom: theme.spacing(7.5)
  },
  workshopLeft: {
    paddingRight: theme.spacing(3)
  },
  workshopRight: {
    paddingLeft: theme.spacing(3)
  },
  sectionHeaders: {
    color: theme.typography.color.primary,
    fontSize: theme.typography.pxToRem(13),
    fontWeight: 600,
    lineHeight: 2.15,
    textTransform: 'uppercase'
  }
});

class WorkshopFields extends React.Component {
  constructor(props) {
    super(props);
  }

  errorHelpers = (field) => {
    if (this.props.validationErrors.hasOwnProperty(field)) {
      return this.props.validationErrors[field][0];
    }
    return null;
  };

  errorStatus = (field) => {
    return this.props.validationErrors.hasOwnProperty(field);
  };

  handleDateChange = (fieldName, newDate) => {
    if (isValid(newDate) == false) {
      // if the text input is incomplete we get invalid Date Object
      return;
    }

    let workshop = { ...this.props.workshop };

    const oldDate = workshop[fieldName]
      ? parseISO(workshop[fieldName])
      : new Date();
    const timePartLocal = format(oldDate, 'HH:mm');
    const datePartLocal = format(newDate, 'yyyy-MM-dd');
    const tzOffset = format(newDate, 'xxx');
    const newDateTime = parseISO(
      `${datePartLocal}T${timePartLocal}${tzOffset}`
    );

    workshop[fieldName] = newDateTime.toISOString();
    this.props.onWorkshopChange(workshop);
  };

  handleTimeChange = (fieldName, localTimeString) => {
    if (!localTimeString) {
      return;
    }

    let workshop = { ...this.props.workshop };

    const oldDateTimeObject = parseISO(workshop[fieldName]);
    const localDate = format(oldDateTimeObject, 'yyyy-MM-dd');
    const tzOffset = format(oldDateTimeObject, 'xxx');
    const localDateTime = `${localDate}T${localTimeString}${tzOffset}`;
    const newDateTimeString = new Date(localDateTime).toISOString();

    workshop[fieldName] = newDateTimeString;
    this.props.onWorkshopChange(workshop);
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let workshop = { ...this.props.workshop };
    if (name === 'location_id' && value === 'null') {
      workshop[name] = null;
    } else {
      workshop[name] = value;
    }

    if (
      name === 'pre_workshop_communication_enabled' &&
      !workshop['pre_workshop_communication_send_days_before']
    ) {
      workshop['pre_workshop_communication_send_days_before'] = 7;
    }

    if (
      name === 'post_workshop_communication_enabled' &&
      !workshop['post_workshop_communication_send_days_after']
    ) {
      workshop['post_workshop_communication_send_days_after'] = 1;
    }

    this.props.onWorkshopChange(workshop);
  };

  makeFieldProps = (field) => {
    if (field === 'location') {
      return {
        error: this.errorStatus(field),
        helperText: this.errorHelpers(field),
        name: field + '_id',
        onChange: this.handleInputChange,
        value: this.props.workshop[field + '_id']
      };
    } else {
      return {
        error: this.errorStatus(field),
        helperText: this.errorHelpers(field),
        name: field,
        onChange: this.handleInputChange,
        value: this.props.workshop[field]
      };
    }
  };

  local24HrTime(dateTimeString) {
    const dateObject = dateTimeString ? parseISO(dateTimeString) : new Date();
    return format(dateObject, 'HH:mm');
  }

  render() {
    const { classes, workshop } = this.props;
    const locationFields = this.props.locationOptions;
    workshop.start_time = workshop.start_time ?? new Date().toISOString();
    workshop.end_time = workshop.end_time ?? new Date().toISOString();
    const startTimePart = this.local24HrTime(workshop.start_time);
    const endTimePart = this.local24HrTime(workshop.end_time);

    return (
      <Grid container className={classes.workshopFields}>
        <Grid item className={classes.workshopLeft} xs={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <WaveTextField
                label="Workshop Name (optional)"
                maxLength={150}
                {...this.makeFieldProps('name')}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <WaveDatePicker
                className={[
                  this.errorStatus('start_time') ? '' : classes.errorSpacing,
                  classes.ieFix
                ].join(' ')}
                error={this.errorStatus('start_time')}
                format={Chisholm.datePicker}
                helperText={this.errorHelpers('start_time')}
                label="Start Date"
                name="start_time"
                onChange={(value) => this.handleDateChange('start_time', value)}
                value={workshop.start_time}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                variant="standard"
                label="Start Time"
                type="time"
                value={startTimePart}
                onChange={(event) =>
                  this.handleTimeChange('start_time', event.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <WaveDatePicker
                className={[
                  this.errorStatus('end_time') ? '' : classes.errorSpacing,
                  classes.ieFix
                ].join(' ')}
                error={this.errorStatus('end_time')}
                format={Chisholm.datePicker}
                helperText={this.errorHelpers('end_time')}
                label="End Date"
                name="end_time"
                onChange={(value) => this.handleDateChange('end_time', value)}
                value={workshop.end_time}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                variant="standard"
                label="End Time"
                type="time"
                value={endTimePart}
                onChange={(event) =>
                  this.handleTimeChange('end_time', event.target.value)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <WaveSelectField
                label="Location"
                menuItems={locationFields}
                {...this.makeFieldProps('location')}
              />
            </Grid>
            <Grid item xs={6}>
              <WaveTextField label="Room" {...this.makeFieldProps('room')} />
            </Grid>
            <Grid item xs={6}>
              <WaveTextField
                label="Presenter"
                {...this.makeFieldProps('presenter')}
              />
            </Grid>
            <Grid item xs={6}>
              <WaveTextField
                label="Passport Points"
                {...this.makeFieldProps('points')}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.workshopRight} xs={6}>
          <Grid container className={classes.textAreaContainer} spacing={3}>
            <Grid item xs={12}>
              <WaveTextArea
                label="Workshop Description"
                maxLength={2000}
                rows={6}
                {...this.makeFieldProps('description')}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} container mt={4}>
          <Grid
            item
            containter
            display="flex"
            flexDirection="column"
            gap={2}
            xs={12}
            border="2px solid #cecece"
            px={4}
            py={2}
            borderRadius="4px"
            mb={4}
          >
            <Grid item xs={12} display="flex" gap={2} alignItems="center">
              <Typography className={classes.sectionHeaders}>
                Pre workshop communication
              </Typography>
              <DefaultCheckbox
                checkboxValue={true}
                unCheckValue={false}
                {...this.makeFieldProps('pre_workshop_communication_enabled')}
              />
            </Grid>
            {this.props.workshop.pre_workshop_communication_enabled ? (
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <WaveTextField
                    label="Send days before"
                    type="number"
                    min="1"
                    {...this.makeFieldProps(
                      'pre_workshop_communication_send_days_before'
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <WaveTextArea
                    label="Subject"
                    placeholder="Enter email subject"
                    maxLength={200}
                    rows={2}
                    {...this.makeFieldProps(
                      'pre_workshop_communication_subject'
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <WaveTextArea
                    label="Message"
                    placeholder="Enter email message"
                    maxLength={1000}
                    rows={4}
                    {...this.makeFieldProps(
                      'pre_workshop_communication_message'
                    )}
                  />
                </Grid>
              </Grid>
            ) : null}
          </Grid>
          <Grid
            item
            containter
            display="flex"
            flexDirection="column"
            gap={2}
            xs={12}
            border="2px solid #cecece"
            px={4}
            py={2}
            borderRadius="4px"
          >
            <Grid item xs={12} display="flex" gap={2} alignItems="center">
              <Typography className={classes.sectionHeaders}>
                Post workshop communication
              </Typography>
              <DefaultCheckbox
                checkboxValue={true}
                unCheckValue={false}
                {...this.makeFieldProps('post_workshop_communication_enabled')}
              />
            </Grid>
            {this.props.workshop.post_workshop_communication_enabled ? (
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <WaveTextField
                    label="Send days after"
                    type="number"
                    min="1"
                    {...this.makeFieldProps(
                      'post_workshop_communication_send_days_after'
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <WaveTextArea
                    label="Subject"
                    placeholder="Enter email subject"
                    maxLength={200}
                    rows={2}
                    {...this.makeFieldProps(
                      'post_workshop_communication_subject'
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <WaveTextArea
                    label="Message"
                    placeholder="Enter email message"
                    maxLength={1000}
                    rows={4}
                    {...this.makeFieldProps(
                      'post_workshop_communication_message'
                    )}
                  />
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(WorkshopFields, styles);
