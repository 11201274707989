import React from 'react';
import { withStyles } from 'tss-react/mui';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import EducatorSidebar from './EducatorSidebar';
import ManageResponses from './ManageResponses';

const styles = theme => ({
  containerLeftSpacing: {
    paddingRight: theme.typography.pxToRem(26),
  },
  containerRightSpacing: {
    paddingLeft: theme.typography.pxToRem(26),
  },
  responseFormPadding: {
    padding: theme.typography.pxToRem(25),
  },
});

class EducatorProfiles extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      refreshTick: 0,
      selectedProfile: null,
      selectedProfileId: null
    };
  }

  forceRefresh = () => {
    this.setState({ refreshTick: Date.now() });
  };

  setProfile = (profile) => {
    this.setState({
      selectedProfile: profile,
      selectedProfileId: profile.id
    });
  };

  profileCheck = (selectedProfile) => {
    if (selectedProfile === null) {
      return (<div>Loading...</div>)
    }
    else if (selectedProfile === 'none') {
      return (<div>No profiles available</div>)
    }
    else {
      return ( <ManageResponses
        profile={this.state.selectedProfile}
        onForceRefresh={this.forceRefresh}
        responseLabels={this.props.responseLabels}
        supervisor={this.props.supervisor}
        user={this.props.user} />
      );
    }
  };

  render() {
    const { classes, handleSupervisorChange, supervisor, user } = this.props;
    const { refreshTick, selectedProfileId, selectedProfile } = this.state;

    return (
      <Grid container>
        <Grid item className={classes.containerLeftSpacing} xs={4}>
          <Paper>
            <EducatorSidebar
              handleSupervisorChange={handleSupervisorChange}
              onProfileSelected={this.setProfile}
              refreshTick={refreshTick}
              selectedItem={selectedProfileId}
              supervisor={supervisor}
              user={user}
            />
          </Paper>
        </Grid>
        <Grid item className={classes.containerRightSpacing} xs={8}>
          <Paper className={classes.responseFormPadding}>
            {this.profileCheck(selectedProfile)}
          </Paper>
        </Grid>
      </Grid>
    );
  }

}

export default withStyles(EducatorProfiles, styles, { withTheme: true });
