import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';

const styles = theme => ({
  span: {
    fontFamily: 'IBM Plex Sans, medium',
    fontWeight: 500,
  },
  tooltipText: {
    color: '#0c0606de',
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.5
  }
});

class DefaultEmail extends React.Component {

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Typography className={classes.tooltipText}>Please contact <span className={classes.span}>pec@chisholm.edu.au</span> to include your institute email address or leave blank to use the system default</Typography>
      </Fragment>
    )
  }

}

export default withStyles(DefaultEmail, styles, { withTheme: true });
