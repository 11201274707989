import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';

import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import StyledDivider from '../../../common/components/misc/StyledDivider';

const styles = theme => ({
  departmentName: {
    color: theme.typography.color.primary,
    fontSize: theme.typography.pxToRem(16),
  },
  menuItem: {
    height: 'auto',
    padding: `${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(25)}`,
  },
});

class DepartmentListItem extends React.Component {

  render() {
    const { classes, departmentItem, enabled, selectedItem } = this.props;

    return (
      <Fragment>
        <MenuItem
          classes={{ root: classes.menuItem }}
          component='div'
          onClick={(event) => enabled && this.props.handleDepartmentSelect(event, departmentItem)}
          selected={selectedItem === departmentItem.id}
        >
          <Typography className={classes.departmentName}>
            {departmentItem.label}
          </Typography>
        </MenuItem>
        <StyledDivider />
      </Fragment>
    );
  }

}

export default withStyles(DepartmentListItem, styles, { withTheme: true });
