import React, { Fragment } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ValueDate from '@common/components/table/ValueDate';

export const ProgramFormSummary = ({
  classes,
  domainStyle,
  program,
  programTypeLabel
}) => (
  <Fragment>
    <div className={classes.programHeader}>
      <Typography className={classes.programStatus}>
        {program.published ? 'LIVE' : 'DRAFT'}
      </Typography>
      <Typography variant="h5">
        {program.name || 'Unnamed Program'}
      </Typography>
    </div>

    <div className={classes.programLocation}>
      <Typography className={classes.programLocationDetails}>
        <ValueDate dateFormat="standardDate" value={program.start_date} /> - <ValueDate dateFormat="standardDate" value={program.end_date} />
      </Typography>
      <Typography className={classes.programLocationDetails}>
        {program.location_names}
      </Typography>
    </div>

    <Grid container>
      {program.domain && program.domain.icon_url && (
        <img
          className={classes.programIcon}
          src={program.domain && program.domain.icon_url}
        />
      )}
      <Typography className={classes.programType} style={domainStyle}>
        {[program.domain_name, programTypeLabel()].filter(e => e).join(' - ')}
      </Typography>
    </Grid>
  </Fragment>
);
