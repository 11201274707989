import React from 'react';
import { withStyles } from 'tss-react/mui';

import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';

import ApiService from '../../../common/services/ApiService';
import BusinessIdeals from './BusinessIdeals';
import EducatorProfiles from './EducatorProfiles';
import StyledDivider from '../../../common/components/misc/StyledDivider';

const styles = theme => ({
  defaultTabText: {
    color: theme.typography.color.primary,
    opacity: 0.55,
  },
  manageProgramsHeadline: {
    marginTop: theme.typography.pxToRem(8),
    marginBottom: theme.typography.pxToRem(8),
  },
  selectedTabText: {
    opacity: 1,
  },
  typographyTabText: {
    color: 'inherit',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500,
    textTransform: 'capitalize',
  },
});

class ManageProfiles extends React.Component {

  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.state = {
      supervisor: {},
      tabValue: 0,
      responseLabels: null,
    };
  }

  componentDidMount() {
    this.getResponseLabels();
    if(this.props.user.is_supervisor && !(this.props.user.is_super_admin || this.props.user.is_institute_admin)) {
      this.setState({
        supervisor: {
          id: this.props.user.id,
          name: this.props.user.given_name + ' ' + this.props.user.family_name
        }
      });
    } else {
      this.preGetSupervisor();
    }
  };

  getResponseLabels = () => {
    this.api.query('/api/v1/response_labels').then((responseLabels) => {
      this.setState({
        responseLabels: responseLabels.data
      });
    }).catch(this.processError);
  };

  preGetSupervisor = () => {

    var preferredSupervisorId = null;
    var educatorId = this.props.match && this.props.match.params.educator_id;

    if (educatorId) {
      this.api.query('/api/v1/users/' + educatorId).then((json) => {
        preferredSupervisorId = json.data.supervisor_id;
        this.getSupervisor(preferredSupervisorId);
      });
    } else {
      this.getSupervisor(null);
    }
  };

  getSupervisor = (preferredSupervisorId) => {

    this.api.query('/api/v1/users?page=1&per_page=1000&q[is_supervisor_true]=1')
    .then((supervisors) => {

      if(supervisors.data.length > 0) {

        if(!preferredSupervisorId) {
          preferredSupervisorId = parseInt(localStorage.getItem('profiling_selected_supervisor_id'), 10);
        }
        var index = supervisors.data.map(e => e.id).indexOf(preferredSupervisorId);
        if(index == -1) {
          index = 0;
        }

        this.setState({
          supervisor: {
            id: supervisors.data[index].id,
            name: supervisors.data[index].full_name
          }
        });
        localStorage.setItem('profiling_selected_supervisor_id', supervisors.data[index].id);
        localStorage.setItem('profiling_selected_supervisor_name', supervisors.data[index].full_name);

      }
    }).catch(this.processError);
  };

  handleSupervisorChange = (id, name) => {
    this.setState({
      supervisor: {
        id: id,
        name: name
      }
    });
    localStorage.setItem('profiling_selected_supervisor_id', id);
    localStorage.setItem('profiling_selected_supervisor_name', name);
  };

  handleTabChange = (event, tabValue) => {
    this.setState({ tabValue: tabValue });
  };

  render() {
    const { classes } = this.props;
    const { supervisor, tabValue, responseLabels } = this.state;
    const enableBusinessIdealTab = this.props.user.is_super_admin || this.props.user.is_institute_admin

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            className={classes.manageProgramsHeadline}
            variant="h5"
          >
            Manage Profiles
          </Typography>
          <Tabs
            onChange={this.handleTabChange}
            textColor="secondary"
            indicatorColor="secondary"
            value={tabValue}
          >
            <Tab
              classes={{
                root: classes.defaultTabText,
                selected: classes.selectedTabText,
              }}
              label={
                <Typography className={classes.typographyTabText}>
                  Educator Profiles {this.props.openProfilesCount > 0 ? '(' + this.props.openProfilesCount + ')' : ''}
                </Typography>
              }
            />
            { enableBusinessIdealTab  &&
              <Tab
                classes={{
                  root: classes.defaultTabText,
                  selected: classes.selectedTabText,
                }}
                label={
                  <Typography className={classes.typographyTabText}>
                    Business Ideal
                  </Typography>
                }
              />
            }
          </Tabs>
          <StyledDivider />
        </Grid>
        <Grid item xs={12}>
          {tabValue === 0 &&
            <EducatorProfiles
              handleSupervisorChange={this.handleSupervisorChange}
              supervisor={supervisor}
              user={this.props.user}
              responseLabels={responseLabels}
            />
          }
          {tabValue === 1 &&
            <BusinessIdeals
              responseLabels={responseLabels}
            />
          }
        </Grid>
      </Grid>
    );
  }

}

export default withStyles(ManageProfiles, styles, { withTheme: true });
