import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ActionRegister from  './ActionRegister';

import ApiService from '../../../common/services/ApiService';

const styles = theme => ({
  attendanceFormButtons: {
    marginLeft: theme.typography.pxToRem(30),
  },
  closeModal: {
    color: theme.typography.color.primary,
    cursor: 'pointer',
    opacity: 0.3,
  },
  formActionsContainer: {
    paddingTop: theme.typography.pxToRem(20),
  },
  registerButton: {
    marginTop: theme.typography.pxToRem(15),
  },
  registerModal: {
    backgroundColor: theme.palette.background.paper,
    left: '50%',
    margin: '0',
    padding: `${theme.typography.pxToRem(24)} ${theme.typography.pxToRem(29)}`,
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
  },
  registerModalHeader: {
    marginBottom: theme.typography.pxToRem(22),
  },
  registerModalHelper: {
    color: '#1f1f1fde',
    fontSize: theme.typography.pxToRem(15),
    lineHeight: 1.6,
    marginBottom: theme.typography.pxToRem(30),
  },
});

class AttendanceActions extends React.Component {

  constructor(props) {
    super(props);
    this.api = new ApiService();
  }

  handleExport = (path) => {
    const { programId } = this.props;
    const sortingParams = { direction: this.props.orderBy, field: this.props.order };
    const searchRequest = this.api.searchUrl(null, null, null, sortingParams, null);

    const apiUrl = `/api/v1/programs/${programId}${path}${(searchRequest !== '' ? '?' : '')}`;
    return apiUrl + searchRequest;
  }

  importRegistrations = () => {
    const { programId } = this.props;
    this.props.history.push(`/import_registrations/${programId}`);
  }

  render() {
    const { classes, programId } = this.props;

    return (
      <Fragment>
        <Grid
          container
          className={classes.formActionsContainer}
          justifyContent="flex-end"
        >
          <ActionRegister programId={programId} onAddRegistration={this.props.onAddRegistration} />
          <Button
              onClick={this.importRegistrations}
              className={classes.attendanceFormButtons}
              color="secondary"
              variant="contained"
            >
              <Typography variant="button">IMPORT</Typography>
          </Button>
          <a href={this.handleExport('/attendance.pdf')} target="_blank">
            <Button
              className={classes.attendanceFormButtons}
              color="secondary"
              variant="contained"
            >
              <Typography variant="button">Print Attendance</Typography>
            </Button>
          </a>
            <a href={this.handleExport('/registrations.csv')} target="_blank">
            <Button
              className={classes.attendanceFormButtons}
              color="secondary"
              variant="contained"
            >
              <Typography variant="button">Export</Typography>
            </Button>
          </a>
        </Grid>
      </Fragment>
    );
  }

}

export default withStyles(AttendanceActions, styles, { withTheme: true });
