import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';

const styles = theme => ({
  span: {
    fontFamily: 'IBM Plex Sans, medium',
    fontWeight: 500,
  },
  tooltipText: {
    color: '#0c0606de',
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.5
  }
});

class WorkshopReminder extends React.Component {

  render() {
    const { classes} = this.props;
    let label = this.props.label != null ?
      <Typography className={classes.tooltipText}>{this.props.label}</Typography> :
      <Typography className={classes.tooltipText}>The number of <span className={classes.span}>business days</span> before the workshop that the reminder is sent</Typography>;

    return (
      <Fragment>
        {label}
      </Fragment>
    )
  }

}

export default withStyles(WorkshopReminder, styles, { withTheme: true });
