import React from 'react';
import { withStyles } from 'tss-react/mui';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import AdminTable from '../../../common/components/table/AdminTable';
import ApiService from "../../../common/services/ApiService";
import DepartmentForm from "../../../common/components/modal/DepartmentForm";
import AdminModal from '../../../common/components/modal/AdminModal';

const styles = theme => ({
  root: {
    marginBottom: 15
  },
  header: {
    padding: `${theme.typography.pxToRem(22)} ${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(25)}`,
  },
  headerButton: {
    marginLeft: 10
  },
  deleteButtonText: {
    color: theme.typography.color.primary,
    fontWeight: 400,
    opacity: 0.75,
  },
});

class DivisionPanel extends React.Component {

  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.state = {
      mode: 'add',
      open: false,
      id: null,
      refreshTick: 0
    };
  }

  render() {
    const { classes, division } = this.props;
    const { id, mode, open } = this.state;
    const fields = ['code', 'name'];
    const actions = ['edit', 'delete'];

    return (
      <Paper className={classes.root}>
        <Grid
          container
          className={classes.header}
          justifyContent="space-between"
        >
          <Typography variant="h6">{division.name}</Typography>
          {this.headerButtons()}
        </Grid>
        <AdminTable
          tableBottomPadding
          actionsSection={actions}
          fields={fields}
          onOpenEditModal={this.handleOpenEditModal}
          refreshTick={this.state.refreshTick}
          resource={`divisions/${division.id}/departments`}
        />
        <AdminModal
          component={DepartmentForm}
          fields={fields}
          id={id}
          mode={mode}
          onCloseModal={this.handleCloseModal}
          onForceRefresh={() => {}}
          open={open}
          resource={`divisions/${division.id}/departments`}
          title="Departments"
        />
      </Paper>
    );
  }

  headerButtons = () => {
    const { classes, division } = this.props;
    return (
      <div>
        <Button
          onClick={this.deleteDivision}
          size="small"
          className={classes.headerButton}
        >
          <Typography className={classes.deleteButtonText} variant="button">Delete</Typography>
        </Button>
        <Button
          color="secondary"
          onClick={() => this.props.onEditDivision(division)}
          size="small"
          variant="contained"
          className={classes.headerButton}
        >
          <Typography variant="button">Edit Division</Typography>
        </Button>
        <Button
          color="secondary"
          onClick={this.handleOpenAddModal}
          size="small"
          variant="contained"
          className={classes.headerButton}
        >
          <Typography variant="button">Add Department</Typography>
        </Button>
      </div>
    );
  };

  deleteDivision = () => {
    this.api.delete('divisions', this.props.division.id).then((response) => {
      if(response.status === 400) {
        this.api.notifyModalError(response.json.message);
      } else {
        this.props.onRefresh();
      }
    });
  };

  handleCloseModal = () => {
    this.setState({ mode: null, open: false, id: null, refreshTick: Date.now() });
  };

  handleOpenAddModal = () => {
    this.setState({ mode: 'add', open: true, id: null });
  };

  handleOpenEditModal = (department) => {
    this.setState({ mode: 'edit', open: true, id: department.id });
  };

}

export default withStyles(DivisionPanel, styles, { withTheme: true });
