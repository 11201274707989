import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function EditIcon(props) {
  return (
    <SvgIcon {...props} style={{opacity: 0.6}}>
      <path d="M18.791 8.92a.72.72 0 0 0 0-1.019l-1.69-1.69a.72.72 0 0 0-1.018 0l-1.33 1.322 2.71 2.708L18.79 8.92zM6 16.294v2.709h2.708l7.989-7.996L13.988 8.3 6 16.294z"/>
    </SvgIcon>
  );
}
