import React, { Fragment } from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';

class UserRoles extends React.Component {

  render() {
    const { classes, user } = this.props;

    return (
      <FormControl variant="standard" error={this.props.error}>
        <FormLabel className={classes.checkboxLabel}>Role</FormLabel>
        <FormGroup row name="user_roles">
          { user.is_super_admin ?
            this.superRoles()
            :
            this.normalRoles()
          }
        </FormGroup>
        <FormHelperText>{this.props.helperText}</FormHelperText>
      </FormControl>
    );
  }

  superRoles = () => {
    const { classes, user } = this.props;
    return (
      <FormControlLabel
        classes={{root: classes.checkboxField}}
        control={
          <Checkbox
            checked={user.is_super_admin}
            name="is_super_admin"
            disabled
          />
        }
        label="Super Admin"
      />
    );
  };

  normalRoles = () => {
    const { classes, user } = this.props;
    return (
      <Fragment>
        <FormControlLabel
          classes={{root: classes.checkboxField}}
          control={
            <Checkbox
              checked={user.is_guest}
              name="is_guest"
              onChange={this.props.handleInputChange}
            />
          }
          label="Guest"
        />
        <FormControlLabel
          classes={{root: classes.checkboxField}}
          control={
            <Checkbox
              checked={user.is_educator}
              name="is_educator"
              onChange={this.props.handleInputChange}
            />
          }
          label="Educator"
        />
        <FormControlLabel
          classes={{root: classes.checkboxField}}
          control={
            <Checkbox
              checked={user.is_supervisor}
              name="is_supervisor"
              onChange={this.props.handleInputChange}
            />
          }
          label="Supervisor"
        />
        <FormControlLabel
          classes={{root: classes.checkboxField}}
          control={
            <Checkbox
              checked={user.is_facilitator}
              name="is_facilitator"
              onChange={this.props.handleInputChange}
            />
          }
          label="Facilitator"
        />
        <FormControlLabel
          classes={{root: classes.checkboxField}}
          control={
            <Checkbox
              checked={user.is_institute_admin}
              name="is_institute_admin"
              onChange={this.props.handleInputChange}
            />
          }
          label="Institute Admin"
        />
      </Fragment>
    );
  };

}

export default UserRoles;
