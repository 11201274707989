import React, { useState, useCallback, useMemo } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';

import AdminTable from '@common/components/table/AdminTable';
import ApiService from '@common/services/ApiService';
import MetadataService from '@common/services/MetadataService';
import PointRequestFilters from './PointRequestFilters';

const styles = (theme) => ({
  exportHeader: {
    padding: `${theme.typography.pxToRem(22)} ${theme.typography.pxToRem(
      25
    )} ${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(25)}`
  }
});

const PointRequest = ({ classes, institute, passportPeriod }) => {
  const api = new ApiService();
  const meta = new MetadataService();
  const fields = [
    'updated_at',
    'user_given_name',
    'user_family_name',
    institute.custom_user_field_1_enabled ? 'custom_1' : null,
    institute.custom_user_field_2_enabled ? 'custom_2' : null,
    'points',
    'type',
    'evidence_attached',
    'status',
    'user_supervisor_name',
    'reason'
  ].filter(Boolean);

  const evidenceAttachedMetadata = useMemo(
    () => ({
      label: 'Evidence Attached',
      type: 'boolean'
    }),
    []
  );

  const metadata = {
    ...meta.getWithInstituteContext('point_requests', institute),
    evidence_attached: evidenceAttachedMetadata
  };

  const [filters, setFilters] = useState({ user_active_eq: '1' });
  const [order, setOrder] = useState('');
  const [orderBy, setOrderBy] = useState('');

  const handleApplyFilters = useCallback(
    (newFilters) => {
      setFilters(newFilters);
    },
    [setFilters]
  );

  const handleApplySorting = useCallback(
    (newOrder, newOrderBy) => {
      setOrder(newOrder);
      setOrderBy(newOrderBy);
    },
    [setOrder, setOrderBy]
  );

  const exportURL = useCallback(() => {
    var sortingParams = { direction: order, field: orderBy };
    var searchRequest = api.searchUrl(filters, null, null, sortingParams, null);

    var apiUrl =
      '/api/v1/reports/point_request.csv' + (searchRequest !== '' ? '?' : '');
    var csvUrl = apiUrl + searchRequest;
    return csvUrl;
  }, [order, orderBy, filters]);

  return (
    <Grid container className={classes.pointRequestReport} spacing={2}>
      <Grid item xs={12}>
        <Paper>
          <PointRequestFilters
            handleApplyFilters={handleApplyFilters}
            institute={institute}
            passportPeriod={passportPeriod}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Grid
            container
            className={classes.exportHeader}
            justifyContent="space-between"
          >
            <Typography variant="h6">Point Request</Typography>
            <a href={exportURL()} target="_blank">
              <Button color="secondary" size="small" variant="contained">
                <Typography variant="button">Export</Typography>
              </Button>
            </a>
          </Grid>
          <AdminTable
            showTablePagination
            fields={fields}
            filters={filters}
            handleApplySorting={handleApplySorting}
            metadata={metadata}
            onCellEdit={{}}
            order="desc"
            orderBy="updated_at"
            resource="reports/point_request"
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default withStyles(PointRequest, styles, { withTheme: true });
