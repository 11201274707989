import React from 'react';
import Checkbox from '@mui/material/Checkbox';

class ValueBoolean extends React.Component {

  handleChange = (event) => {
    this.props.onCellEdit(event.target.checked);
  };

  render() {
    return (
      <Checkbox
        checked={this.props.value}
        disabled={this.props.metadata?.readonly}
        onChange={this.handleChange}
        {...this.props.metadata?.componentProps}
        sx={{
          '&.Mui-disabled ': {
            color: 'rgba(0, 0, 0, 0.6)',
            '&.Mui-checked': {
              color: '#2B65EC',
            }
          }
        }}
      />
    );
  }

}

export default ValueBoolean;
