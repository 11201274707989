import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';

import ApiService from '../../../common/services/ApiService';
import Autocomplete from '../../../common/components/autocomplete/Autocomplete';

const styles = theme => ({
  closeModal: {
    color: theme.typography.color.primary,
    cursor: 'pointer',
    opacity: 0.3,
  },
  registerButton: {
    marginTop: theme.typography.pxToRem(15),
  },
  registerModal: {
    backgroundColor: theme.palette.background.paper,
    left: '50%',
    margin: '0',
    padding: `${theme.typography.pxToRem(24)} ${theme.typography.pxToRem(29)}`,
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
  },
  registerModalHeader: {
    marginBottom: theme.typography.pxToRem(22),
  },
  registerModalHelper: {
    color: '#1f1f1fde',
    fontSize: theme.typography.pxToRem(15),
    lineHeight: 1.6,
    marginBottom: theme.typography.pxToRem(30),
  },
});

class ActionRegister extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      autocompleteValue: '',
      open: false,
      userId: null,
      responseMessage: null
    };
    this.api = new ApiService();
  }

  handleOpenRegisterModal = () => {
    this.setState({ open: true });
  };

  handleCloseRegisterModal = () => {
    this.setState({ open: false });
  };

  handleChange = (id, value) => {
    this.setState({ autocompleteValue: value, userId: id });
  };

  handleRegister = (userId) => {
    this.setState({ autocompleteValue: '', userId: null });
    this.handleCloseRegisterModal();

    const url = '/api/v1/programs/' + this.props.programId + '/registrations';
    this.api.post(url, { registration: { user_id: userId, status: 'approved' } })
      .then((res) => {
        if(res.status == 201) {
          this.setState({ registrationDialogOpen: false }, () => {
            this.props.onAddRegistration();
          });
        } else {
          this.setState({ registrationDialogOpen: false }, () => {
            this.setState({ responseMessage: Object.values(res.json.messages).map(m => m.join(', ')) });
          });
        }
      });
  };

  handleSnackbarClose = () => {
    this.setState({ responseMessage: null });
  };

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Button
          color="secondary"
          onClick={this.handleOpenRegisterModal}
          variant="contained"
        >
          <Typography variant="button">Add Registration</Typography>
        </Button>
        <Modal
          open={this.state.open}
          onClose={this.handleCloseRegisterModal}
        >
          <div className={classes.registerModal}>
            <Grid
              container
              className={classes.registerModalHeader}
              justifyContent="space-between"
            >
              <Typography variant="h5">
                Register a User
              </Typography>
              <a onClick={this.handleCloseRegisterModal}>
                <CloseIcon className={classes.closeModal}/>
              </a>
            </Grid>
            <Typography className={classes.registerModalHelper}>
              Choose an Educator or Guest to register for this program!
            </Typography>
            <Autocomplete
              modalMode
              label="Search by name or employee number"
              onChange={this.handleChange}
              role={['guest', 'educator']}
              value={this.state.autocompleteValue}
              userStatus='active'
            />
            <Button
              className={classes.registerButton}
              color="secondary"
              disabled={!this.state.userId}
              onClick={() => this.handleRegister(this.state.userId)}
              variant="contained"
            >
              <Typography variant="button">Register</Typography>
            </Button>
          </div>
        </Modal>
        <Snackbar
          anchorOrigin={{
            horizontal: 'left',
            vertical: 'bottom',
          }}
          autoHideDuration={4000}
          message={<span>{this.state.responseMessage}</span>}
          onClose={this.handleSnackbarClose}
          open={this.state.responseMessage !== null}
        />
      </Fragment>
    );
  }

}

export default withStyles(ActionRegister, styles, { withTheme: true });
