import React, { Fragment } from 'react';
import format from 'date-fns/format';
import { withStyles } from 'tss-react/mui';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import EventIcon from '@mui/icons-material/Event';
import { DatePicker } from '@mui/x-date-pickers';
import ApiService from '../../../common/services/ApiService';
import Autocomplete from '../../../common/components/autocomplete/Autocomplete';
import Chisholm from '../../../common/config/Chisholm';
import WaveSelectField from '../../../common/components/form/WaveSelectField';
import Utils from '../../../common/services/Utils';
import { WaveDatePicker } from '../../../common/components/WaveDatePicker';

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. Unsupported arrow function syntax.
//Unexpected value type of CallExpression.
const styles = theme => ({
  adjustedSpacing: {
    padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(
      20
    )} 0 ${theme.typography.pxToRem(20)}`
  },
  adjustedSpacingLeft: {
    padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(
      20
    )} 0 ${theme.typography.pxToRem(25)}`
  },
  adjustedSpacingRight: {
    padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(
      25
    )} 0 ${theme.typography.pxToRem(20)}`
  },
  adjustedSpacingUser: {
    padding: `${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(
      20
    )} ${theme.typography.pxToRem(40)} ${theme.typography.pxToRem(25)}`
  },
  adjustedSpacingSupervisor: {
    padding: `${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(
      20
    )} ${theme.typography.pxToRem(40)} ${theme.typography.pxToRem(20)}`
  },
  adjustedSpacingDate: {
    padding: `${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(
      20
    )} ${theme.typography.pxToRem(40)} ${theme.typography.pxToRem(20)}`
  },
  clearFilters: {
    color: theme.typography.color.primary,
    '&:hover': {
      color: theme.typography.color.primary,
      cursor: 'pointer'
    },
    '&:visited': {
      color: theme.typography.color.primary
    },
    fontSize: theme.typography.pxToRem(13),
    marginRight: theme.typography.pxToRem(20),
    opacity: 0.7
  },
  filterHeader: {
    padding: `${theme.typography.pxToRem(19)} ${theme.typography.pxToRem(
      25
    )} 0 ${theme.typography.pxToRem(25)}`
  },
  ieFix: Utils.ieDatePickerFix()
});

const userStatusOptions = {
  active: {
    id: 'active',
    label: 'Active'
  },
  inactive: {
    id: 'inactive',
    label: 'Deactivated'
  }
};

const evidenceAttachmentOptions = {
  attached: {
    label: 'Attached',
  },
  notAttached: {
    label: 'Not Attached',
  },
}

class PointsHistoryFilters extends React.Component {
  constructor(props) {
    super(props);
    this.api = new ApiService();

    const { passportPeriod } = props;

    this.state = {
      division_id: 'all',
      department_id: 'all',
      employment_type_id: 'all',
      errorMessage: null,
      active: 'active',
      user_id: null,
      userLabel: '',
      supervisor_id: null,
      supervisorLabel: '',
      from_date: passportPeriod.from,
      to_date: passportPeriod.to,
      evidenceAttached: 'all',
      filters: {},
      divisionOptions: [],
      departmentOptions: [],
      employmentTypeOptions: [],
    };
  }

  componentDidMount() {
    this.getFiltersOptions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.division_id !== prevState.division_id) {
      this.setState({ department_id: 'all' });
    }
  }

  arrayToObject = (arr, keyField) =>
    Object.assign({}, ...arr.map(item => ({ [item[keyField]]: item })));

  clearFilters = () => {
    const { passportPeriod } = this.props;

    this.setState({
      division_id: 'all',
      department_id: 'all',
      employment_type_id: 'all',
      errorMessage: null,
      active: 'active',
      user_id: null,
      userLabel: '',
      supervisor_id: null,
      supervisorLabel: '',
      from_date: passportPeriod.from,
      to_date: passportPeriod.to,
      evidenceAttached: 'all',
      filters: {}
    });

    this.props.handleApplyFilters(this.state.filters);
  };

  getDepartments = (departments, divisionId) => {
    var departmentsArray = [];
    Object.keys(departments).map(obj =>
      departments[obj].division_id === divisionId
        ? departmentsArray.push(departments[obj])
        : ''
    );
    return departmentsArray;
  };

  getFiltersOptions = () => {
    const divisionOptionsPromise = this.api.query('/api/v1/divisions/options');
    const departmentOptionsPromise = this.api.query(
      '/api/v1/departments/options'
    );
    const employmentTypeOptionsPromise = this.api.query(
      '/api/v1/employment_types/options'
    );

    Promise.all([
      divisionOptionsPromise,
      departmentOptionsPromise,
      employmentTypeOptionsPromise
    ])
      .then(([divisionOptions, departmentOptions, employmentTypeOptions]) => {
        this.setState({
          divisionOptions: divisionOptions,
          departmentOptions: departmentOptions,
          employmentTypeOptions: employmentTypeOptions
        });
      })
      .catch(this.processError);
  };

  handleAutocompleteChangeUser = (id, value) => {
    this.setState({ user_id: id, userLabel: value });
  };

  handleAutocompleteChangeSupervisor = (id, value) => {
    this.setState({ supervisor_id: id, supervisorLabel: value });
  };

  handleDateChange = (name, date) => {
    const convertedDate =
      date == null ? null : format(new Date(date), 'yyyy-MM-dd');
    this.setState({ [name]: convertedDate });
  };

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({ [name]: value });
  };

  makeFieldProps = (fieldName, fieldValue) => {
    return {
      name: fieldName,
      onChange: this.handleInputChange,
      value: fieldValue
    };
  };

  onFilter = () => {
    var filters = {};

    if (![null, 'all'].includes(this.state.division_id)) {
      filters['user_department_division_id_eq'] = this.state.division_id;
    }
    if (![null, 'all'].includes(this.state.department_id)) {
      filters['user_department_id_eq'] = this.state.department_id;
    }
    if (
      this.state.employment_type_id != null &&
      this.state.employment_type_id != 'all'
    ) {
      filters['user_employment_type_id_eq'] = this.state.employment_type_id;
    }
    if (this.state.active != null && this.state.active != 'all') {
      filters['user_active_eq'] = this.state.active === 'active' ? '1' : '0';
    }
    if (this.state.evidenceAttached != null && this.state.evidenceAttached != 'all')
      filters['evidence_attached_true'] = this.state.evidenceAttached === 'attached' ? '1' : '0';
    if (this.state.user_id != null && this.state.user_id != 'all') {
      filters['user_id_eq'] = this.state.user_id;
    }
    if (this.state.supervisor_id != null && this.state.supervisor_id != 'all') {
      filters['user_supervisor_id_eq'] = this.state.supervisor_id;
    }
    if (this.state.from_date != null && this.state.to_date != null) {
      filters['date_from_eq'] = this.state.from_date;
      filters['date_to_eq'] = this.state.to_date;
    }

    this.props.handleApplyFilters(filters);
  };

  // unexpected errors
  processError = ex => {
    console.error(ex);
    this.setState({ ...s, errorMessage: ex.message });
  };

  render() {
    const { classes } = this.props;
    var {
      division_id,
      divisionOptions,
      department_id,
      departmentOptions,
      employment_type_id,
      employmentTypeOptions,
      from_date,
      to_date,
      active,
      supervisorLabel,
      userLabel,
      evidenceAttached,
    } = this.state;
    var divisionIdInt = parseInt(division_id);
    var departmentsArray = this.getDepartments(
      departmentOptions,
      divisionIdInt
    );
    var filteredDepartments = this.arrayToObject(departmentsArray, 'id');

    return (
      <Fragment>
        <Grid
          container
          className={classes.filterHeader}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h6">Filter</Typography>
          <Grid item>
            <a
              className={classes.clearFilters}
              onClick={() => this.clearFilters()}
            >
              Clear
            </a>
            <Button
              color="secondary"
              onClick={() => this.onFilter()}
              size="small"
              variant="contained"
            >
              <Typography variant="button">Filter</Typography>
            </Button>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item className={classes.adjustedSpacingLeft} xs={3}>
            <WaveSelectField
              filterByAllItem
              filterByAllItemLabel="All"
              noValidation
              label="Division"
              menuItems={divisionOptions}
              {...this.makeFieldProps('division_id', division_id)}
            />
          </Grid>
          <Grid item className={classes.adjustedSpacing} xs={3}>
            <WaveSelectField
              filterByAllItem
              filterByAllItemLabel="All"
              noValidation
              label="Department"
              menuItems={
                divisionIdInt ? filteredDepartments : departmentOptions
              }
              {...this.makeFieldProps('department_id', department_id)}
            />
          </Grid>
          <Grid item className={classes.adjustedSpacing} xs={3}>
            <WaveSelectField
              filterByAllItem
              filterByAllItemLabel="All"
              noValidation
              label="Employment Type"
              menuItems={employmentTypeOptions}
              {...this.makeFieldProps('employment_type_id', employment_type_id)}
            />
          </Grid>
          <Grid item className={classes.adjustedSpacingRight} xs={3}>
            <WaveSelectField
              filterByAllItem
              filterByAllItemLabel="All"
              noValidation
              label="Status"
              menuItems={userStatusOptions}
              {...this.makeFieldProps('active', active)}
            />
          </Grid>

          <Grid item className={classes.adjustedSpacingUser} xs={3}>
            <Autocomplete
              label="User"
              name="user_id"
              onChange={this.handleAutocompleteChangeUser}
              role={["educator", "guest"]}
              value={userLabel || ''}
            />
          </Grid>
          <Grid item className={classes.adjustedSpacingSupervisor} xs={3}>
            <Autocomplete
              label="Supervisor"
              name="supervisor_id"
              onChange={this.handleAutocompleteChangeSupervisor}
              role="supervisor"
              value={supervisorLabel || ''}
            />
          </Grid>
          <Grid item className={classes.adjustedSpacingDate} xs={2}>
            <WaveDatePicker
              className={classes.ieFix}
              format={Chisholm.rangeDate}
              label="From"
              name="from_date"
              onChange={value => this.handleDateChange('from_date', value)}
              value={from_date}
            />
          </Grid>
          <Grid item className={classes.adjustedSpacingDate} xs={2}>
            <WaveDatePicker
              className={classes.ieFix}
              format={Chisholm.rangeDate}
              label="To"
              name="to_date"
              onChange={value => this.handleDateChange('to_date', value)}
              value={to_date}
            />
          </Grid>
          <Grid item className={classes.adjustedSpacingDate} xs={2}>
            <WaveSelectField
              filterByAllItem
              filterByAllItemLabel="All"
              noValidation
              label="Evidence Attached"
              menuItems={evidenceAttachmentOptions}
              {...this.makeFieldProps('evidenceAttached', evidenceAttached)}
            />
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(PointsHistoryFilters, styles, { withTheme: true });
