import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '../../../common/icons/EditIcon';
import TrashIcon from '../../../common/icons/TrashIcon';
import UpIcon from '../../../common/icons/UpIcon';
import DownIcon from '../../../common/icons/DownIcon';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import AdminTableHead from './AdminTableHead';
import ApiService from '../../services/ApiService';
import MetadataService from '../../services/MetadataService';
import ValueAssociation from './ValueAssociation';
import ValueBoolean from './ValueBoolean';
import ValueDate from './ValueDate';
import ValueStatus from './ValueStatus';

const styles = (theme) => ({
  actionsSection: {
    textAlign: 'right'
  },
  clickableCell: {
    width: 'fit-content',
    '&:hover': {
      color: theme.typography.color.primary,
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  },
  selectRowsIcon: {
    right: -5
  },
  selectRowsList: {
    '&:before': {
      display: 'none'
    }
  },
  singleButtonMargin: {
    marginRight: theme.typography.pxToRem(10)
  },
  tableBottomPadding: {
    paddingBottom: theme.typography.pxToRem(16.5)
  },
  tableCell: {
    fontSize: theme.typography.pxToRem(13)
  },
  tableContainer: {
    overflowX: 'auto'
  }
});

class AdminTable extends React.Component {
  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.meta = new MetadataService();
    this.state = {
      count: 0,
      loading: true,
      order: this.props.order || 'asc',
      orderBy: this.props.orderBy || '',
      page: 0,
      perPage: 100,
      resourceMeta: {
        ...this.meta.get(this.basicResource()),
        ...this.props.metadata
      }, // NB: might be null
      viewAll: [],
      refreshTick: 0,
      selectedIds: []
    };
  }

  basicResource() {
    if (this.props.resource && this.props.resource.includes('/')) {
      const parts = this.props.resource.split('/');
      return parts[parts.length - 1];
    } else {
      return this.props.resource;
    }
  }

  componentDidMount() {
    this.getAllData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.refreshTick != this.props.refreshTick ||
      JSON.stringify(prevProps.filters) != JSON.stringify(this.props.filters) ||
      JSON.stringify(prevProps.namedFilter) !=
        JSON.stringify(this.props.namedFilter) ||
      prevProps.order != this.props.order ||
      prevProps.orderBy != this.props.orderBy
    ) {
      const { order, orderBy } = this.props;
      this.setState({ loading: true, order, orderBy }, () => this.getAllData());
    }
  }

  reload = () => {
    this.getAllData();
  };

  cellClickDone = (reload) => {
    if (reload) {
      this.reload();
    }
  };

  extractValue(data, key) {
    const parts = key.split('.');
    if (parts.length === 1) {
      return data[key];
    } else {
      const nestedObject = data[parts[0]];
      if (nestedObject) {
        return nestedObject[parts[1]];
      } else {
        return null;
      }
    }
  }

  cellContent = (column_name, row, classes) => {
    const { cantClick, onCellClicked } = this.props;
    var cellContent = this.renderValue(
      column_name,
      this.extractValue(row, column_name),
      row.id,
      row
    );

    if (cantClick && cantClick(row)) {
      return cellContent;
    } else if (onCellClicked && onCellClicked.hasOwnProperty(column_name)) {
      cellContent = (
        <div
          className={this.props.classes.clickableCell}
          onClick={() => onCellClicked[column_name](row, this.cellClickDone)}
        >
          {cellContent}
        </div>
      );
    }

    return cellContent;
  };

  columnMetadata(column_name) {
    var metadata = {};
    if (
      this.state.resourceMeta &&
      this.state.resourceMeta.hasOwnProperty(column_name)
    ) {
      metadata = this.state.resourceMeta[column_name];
    }
    return metadata;
  }

  getAllData = () => {
    var filters = {};
    var namedFilter = '';
    var noPagination = this.props.noPaginationQuery;
    var pagination = null;

    if (this.props.filters != null) {
      filters = this.props.filters;
    }

    this.props.namedFilter != null
      ? (namedFilter = this.props.namedFilter)
      : (namedFilter = '');

    noPagination
      ? (pagination = null)
      : (pagination = {
          page: this.state.page,
          per_page: this.state.perPage
        });

    var options = {
      pagination,
      filters,
      sorting: {
        direction: this.state.order,
        field: this.state.orderBy
      }
    };

    this.api
      .search(namedFilter, options, this.props.resource, this.props.url)
      .then((data) => {
        this.setState({
          count:
            data.meta && data.meta.hasOwnProperty('total_count')
              ? data.meta.total_count
              : 0,
          loading: false,
          viewAll: data.data
        });
        if (this.props.onDataLoaded) {
          this.props.onDataLoaded(data);
        }
      })
      .catch((ex) => {
        console.error(ex);
        this.setState({ loading: false });
      });
  };

  handleDelete = (item) => {
    this.api.delete(this.props.resource, item.id).then((response) => {
      if (response.status === 400) {
        this.api.notifyModalError(response.json.message);
      } else {
        this.reload();
      }
    });
  };

  handleToggleActive = (item) => {
    this.setState({ selectedIds: [item.id] });
    this.api
      .saveResource(this.props.resource, { id: item.id, active: !item.active })
      .then((response) => {
        if (response.status === 400) {
          this.api.notifyModalError(response.json.message);
        } else {
          this.reload();
        }
      });
  };

  move = (item, directionUp) => {
    let list = [...this.state.viewAll];
    const index = list.findIndex((i) => i.id === item.id);
    const lower = directionUp ? 1 : 0;
    const upper = directionUp ? list.length - 1 : list.length - 2;
    if (index >= lower && index <= upper) {
      const otherIndex = index + (directionUp ? -1 : 1);
      const other = list[otherIndex];
      list[index] = other;
      list[otherIndex] = item;
      this.setState(
        { viewAll: list, selectedIds: [item.id] },
        this.props.onReOrder(list)
      );
    }
  };

  handleChangePage = (event, page) => {
    this.setState({ page: page }, () => this.getAllData());
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ perPage: event.target.value }, () => this.getAllData());
  };

  handleSortRequest = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const meta = this.columnMetadata(orderBy);

    if (meta.frontendSort) {
      this.setState(
        {
          order: order,
          orderBy: orderBy
        },
        () => this.frontendSort(meta.frontendSortCompare, order, orderBy)
      );
    } else {
      this.setState(
        {
          order: order,
          orderBy: orderBy
        },
        () => this.getAllData()
      ); // do API and backend will sort.
    }

    if (this.props.handleApplySorting) {
      this.props.handleApplySorting(order, orderBy);
    }
  };

  frontendSort = (compareFn, order, orderBy) => {
    const standardCompare = (a, b) => {
      const valueA = this.extractValue(a, orderBy) || '';
      const valueB = this.extractValue(b, orderBy) || '';
      return valueA.localeCompare(valueB);
    };

    const cmp = compareFn || standardCompare;
    const data = this.state.viewAll.slice(0);
    data.sort(cmp);
    if (order === 'desc') {
      data.reverse();
    }
    this.setState({ viewAll: data });
  };

  renderTablePagination() {
    if (this.props.showTablePagination) {
      return (
        <TablePagination
          slotProps={{
            actions: {
              previousButton: {
                'aria-label': 'Previous Page'
              },
              nextButton: {
                'aria-label': 'Next Page'
              }
            }
          }}
          classes={{
            menuItem: this.props.classes.selectRowsList,
            selectIcon: this.props.classes.selectRowsIcon
          }}
          component="div"
          count={this.state.count}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
          page={this.state.page}
          rowsPerPage={this.state.perPage}
          rowsPerPageOptions={[25, 50, 100]}
        />
      );
    }
  }

  renderValue(column_name, value, id, row) {
    var metadata = this.columnMetadata(column_name);

    const type =
      metadata && metadata.hasOwnProperty('type') ? metadata.type : 'default';
    const { classes } = this.props;

    switch (type) {
      case 'association':
      case 'enum':
        return (
          <ValueAssociation
            metadata={metadata}
            tableCellStyle={classes.tableCell}
            value={value}
          />
        );
        break;
      case 'boolean':
        return (
          <ValueBoolean
            metadata={metadata}
            onCellEdit={(value) =>
              this.props.onCellEdit?.[column_name] &&
              this.props.onCellEdit[column_name](id, value, row, this.reload)
            }
            value={value}
          />
        );
      case 'component':
        const props = {
          ...metadata.componentProps,
          row: row,
          onCellEdit: (value) =>
            this.props.onCellEdit[column_name] &&
            this.props.onCellEdit[column_name](id, value, row, this.reload)
        };
        return React.createElement(metadata.component, props);
      case 'date':
        return (
          <Typography className={classes.tableCell}>
            <ValueDate dateFormat="standardDate" value={value} />
          </Typography>
        );
        break;
      case 'datetime':
        return (
          <Typography className={classes.tableCell}>
            <ValueDate dateFormat="dateTimePicker" value={value} />
          </Typography>
        );
      case 'status':
        return <ValueStatus value={value} />;
      case 'link':
        const label = row[metadata.label_field]
        return <a target='_blank' href={value}>{label}</a>;
      default:
        return this.defaultCellValue(value);
    }
  }

  defaultCellValue = (value) => {
    const { classes } = this.props;
    return (
      <Typography className={classes.tableCell}>
        {(typeof value === 'string' || value instanceof String) &&
        value.length > 30 ? (
          value
        ) : (
          <span style={{ whiteSpace: 'nowrap' }}>{value}</span>
        )}
      </Typography>
    );
  };

  toggleActiveButton = (row) => {
    return (
      <Fragment key="toggle_active">
        <IconButton
          className={this.props.classes.singleButtonMargin}
          onClick={() => this.handleToggleActive(row)}
          size="large">
          {row.active ? (
            <CheckBoxIcon color="secondary" />
          ) : (
            <CheckBoxOutlineBlankIcon />
          )}
        </IconButton>
      </Fragment>
    );
  };

  deleteActionCheck = (row) => {
    return (
      <Fragment key="delete">
        {this.props.canDelete ? (
          this.props.canDelete(row) && (
            <IconButton
              className={this.props.classes.singleButtonMargin}
              onClick={() => this.handleDelete(row)}
              size="large">
              <TrashIcon />
            </IconButton>
          )
        ) : (
          <IconButton
            className={this.props.classes.singleButtonMargin}
            onClick={() => this.handleDelete(row)}
            size="large">
            <TrashIcon />
          </IconButton>
        )}
      </Fragment>
    );
  };

  renderActions = (row, actions, index) => {
    const buttonClasses =
      actions.length === 1 ? this.props.classes.singleButtonMargin : '';

    return actions.map((action) => {
      switch (action) {
        case 'move_up':
          return (
            <Fragment key={action}>
              <IconButton
                disabled={index === 0}
                className={buttonClasses}
                onClick={() => this.move(row, true)}
                size="large">
                <UpIcon />
              </IconButton>
            </Fragment>
          );
        case 'move_down':
          return (
            <Fragment key={action}>
              <IconButton
                disabled={index === this.state.viewAll.length - 1}
                className={buttonClasses}
                onClick={() => this.move(row, false)}
                size="large">
                <DownIcon />
              </IconButton>
            </Fragment>
          );
        case 'toggle_active':
          return this.toggleActiveButton(row, index);
        case 'delete':
          return this.deleteActionCheck(row);
        case 'edit':
          return (
            <Fragment key={action}>
              <IconButton
                className={buttonClasses}
                onClick={() => this.props.onOpenEditModal(row)}
                size="large">
                <EditIcon />
              </IconButton>
            </Fragment>
          );
        case 'buttons':
          return (
            <Fragment key={action}>
              {this.props.renderButtonActions(row)}
            </Fragment>
          );
        default:
          return <Fragment></Fragment>;
      }
    });
  };

  render() {
    if (this.state.loading == true) {
      return <div>Loading...</div>;
    }

    const { classes } = this.props;
    const propsFields = this.props.fields;
    const usePadding = this.props.tableBottomPadding;
    const defaultOrderState = {
      order: this.state.order,
      orderBy: this.state.orderBy
    };

    const body = this.state.viewAll.map((row, index) => (
      <TableRow
        key={row.id || index}
        selected={this.state.selectedIds.includes(row.id)}
      >
        {propsFields.map((column_name) => {
          const metadata = this.columnMetadata(column_name);
          const tableCellProps = metadata.tableCellProps || {};
          return (
            <TableCell key={column_name} {...tableCellProps}>
              {this.cellContent(column_name, row)}
            </TableCell>
          );
        })}
        {this.props.actionsSection && (
          <TableCell
            className={classes.actionsSection}
            key="action"
            {...this.props.actionCellProps}
          >
            {this.renderActions(row, this.props.actionsSection, index)}
          </TableCell>
        )}
      </TableRow>
    ));

    return (
      <Fragment>
        <div
          className={`${classes.tableContainer} ${
            usePadding ? classes.tableBottomPadding : ''
          } `}
        >
          <Table>
            <AdminTableHead
              fields={propsFields}
              defaultOrder={defaultOrderState}
              onSortRequest={this.handleSortRequest}
              resourceMeta={this.state.resourceMeta}
              actionsSection={this.props.actionsSection}
            />
            <TableBody>{body}</TableBody>
          </Table>
        </div>
        {this.renderTablePagination()}
      </Fragment>
    );
  }
}

export default withStyles(AdminTable, styles, { withTheme: true });
