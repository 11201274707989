import React, { useCallback, useMemo, Fragment } from 'react';
import { withStyles } from 'tss-react/mui';
import Input from '@mui/material/Input';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Uppy from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import { StatusBar } from '@uppy/react';

import { DefaultButton } from '@common/components/default-button';
import useUppy from '@common/hooks/useUppy';

const styles = ({ spacing }) => ({
  input: {
    display: 'none'
  },
  file: {
    textDecoration: 'underline'
  },
  removeFile: {
    cursor: 'pointer'
  },
  uploadWrapper: {
    marginTop: spacing.unit,
    marginBottom: spacing.unit
  }
});

const WaveMultipleFileUploader = ({
  name,
  accept,
  maxFileSize,
  label,
  onPush,
  onReplace,
  onRemove,
  value,
  classes,
  style = { title : {}},
  error,
  helperText
}) => {
  const getFileData = useCallback(
    (file, id) => ({
      id,
      storage: 'cache',
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type,
        file_id: file.id
      }
    }),
    []
  );

  const uploadedFileData = useCallback(
    (file) => {
      const id = file.meta.key.match(/^cache\/(.+)/)[1];

      return getFileData(file, id);
    },
    [getFileData]
  );

  const handleUploadSuccess = useCallback(
    (file, _response) => {
      const fileData = uploadedFileData(file);
      onPush({
        name,
        value: fileData
      });
    },
    [onPush, uploadedFileData, name]
  );

  const uppy = useUppy(() => {
    return Uppy({
      autoProceed: true,
      allowMultipleUploads: true,
      restrictions: {
        maxFileSize,
        allowedFileTypes: accept.split(',')
      }
    })
      .use(AwsS3, { companionUrl: '/' }) // will call Shrine's presign endpoint mounted on `/s3/params`
      .on('upload-success', handleUploadSuccess);
  });

  const handleChange = useCallback(
    (event) => {
      const files = Array.from(event.target.files);

      files.forEach((file) => {
        try {
          uppy.addFile({
            source: 'file input',
            name: file.name,
            type: file.type,
            data: file
          });
        } catch (error) {}
      });
    },
    [uppy]
  );

  const removeFile = useCallback(
    (index) => () => {
      const evidence = value[index];

      if (evidence.fileUrl) {
        onReplace({
          name,
          index,
          value: {
            ...evidence,
            removeFile: true
          }
        });
      } else {
        uppy.removeFile(evidence.metadata.file_id);
        onRemove({
          name,
          index
        });
      }
    },
    [onReplace, name, value, uppy]
  );

  const renderFiles = useMemo(
    () =>
      value.map((file, index) => {
        if (!file.removeFile) {
          return (
            <Grid item key={index}>
              <Grid container spacing={8}>
                <Grid item>
                  <a
                    href={file.fileUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Typography
                      key={index}
                      color="textSecondary"
                      className={classes.file}
                    >
                      {file.metadata.filename}
                    </Typography>
                  </a>
                </Grid>
                <Grid item>
                  <Typography
                    onClick={removeFile(index)}
                    color="textSecondary"
                    className={classes.removeFile}
                  >
                    X
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          );
        }

        return null;
      }),
    [classes.file, classes.removeFile, value, name, removeFile]
  );

  return (
    <Fragment>
      <Input
        disableUnderline
        className={classes.input}
        inputProps={{
          accept,
          multiple: true
        }}
        onChange={handleChange}
        type="file"
        id="contained-button-file"
      />

      <Typography variant="h6" color="secondary" style={style.title}>
        {label}
      </Typography>

      <Grid
        justifyContent="flex-start"
        container
        spacing={2}
        alignItems="center"
        className={classes.uploadWrapper}
      >
        <Grid item>
          <label htmlFor="contained-button-file">
            <DefaultButton
              size="small"
              color="secondary"
              variant="contained"
              component="span"
            >
              UPLOAD
            </DefaultButton>
          </label>
        </Grid>
        {renderFiles}
      </Grid>
      <StatusBar uppy={uppy} hideAfterFinish showProgressDetails />
      <Grid item>
        <FormHelperText error={error}>{helperText}</FormHelperText>
      </Grid>
    </Fragment>
  );
};

export default withStyles(WaveMultipleFileUploader, styles, { withTheme: true });
