import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import UpIcon from '../../../common/icons/UpIcon';
import DownIcon from '../../../common/icons/DownIcon';

import AdminTable from '../../../common/components/table/AdminTable';
import ApiService from "../../../common/services/ApiService";
import CapabilityForm from "../../../common/components/modal/CapabilityForm";
import AdminModal from '../../../common/components/modal/AdminModal';

const styles = theme => ({
  root: {
    marginBottom: 15
  },
  header: {
    padding: `${theme.typography.pxToRem(22)} ${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(25)}`,
  },
  headerButton: {
    marginLeft: 10
  },
  deleteButtonText: {
    color: theme.typography.color.primary,
    fontWeight: 400,
    opacity: 0.75,
  },
  toggleActiveButtonText: {
    color: theme.typography.color.primary,
    fontWeight: 400,
    opacity: 0.75,
  },
  summary: {
    opacity: 0.5,
    fontSize: 13,
    color: '#1f1f1f',
  },
  domainIcon: {
    height: theme.typography.pxToRem(22),
    width: theme.typography.pxToRem(22),
    marginRight: 14,
    padding: 4
  },
  svgDomainIcon: {
    height: theme.typography.pxToRem(22),
    width: theme.typography.pxToRem(22),
    marginRight: 14,
    borderRadius: 4,
    padding: 4
  },
});

class CapabilityPanel extends React.Component {

  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.state = {
      mode: 'add',
      open: false,
      id: null
    };
  }

  // Convert filter fomr props to one that wortks with AdminTable
  tableFilters = () => {
    const { filter } = this.props;
    const tableFilter = {};
    if(filter.hideDeactivated) {
      tableFilter['active_eq'] = 1;
    }
    return tableFilter;
  };

  render() {
    const { classes, domain } = this.props;
    const { id, mode, open } = this.state;

    const fields = ['statement'];
    const actions = ['toggle_active', 'move_up', 'move_down', 'edit', 'delete'];

    return (
      <Paper className={classes.root}>
        {this.header()}
        <AdminTable
          tableBottomPadding
          actionsSection={actions}
          fields={fields}
          onOpenEditModal={this.handleOpenEditModal}
          refreshTick={this.state.refreshTick}
          resource={`domains/${domain.id}/capabilities`}
          onReOrder={this.updateDisplayOrder}
          filters={this.tableFilters()}
        />
        <AdminModal
          component={CapabilityForm}
          fields={fields}
          id={id}
          mode={mode}
          onCloseModal={this.handleCloseModal}
          onForceRefresh={() => {}}
          open={open}
          resource={`domains/${domain.id}/capabilities`}
          title="Capability"
        />
      </Paper>
    );
  }

  header = () => {
    const { classes, domain } = this.props;

    return (
      <Grid
        container
        className={classes.header}
        justifyContent="space-between"
      >
        <Grid item xs={6}>
          <Grid container>
            <img className={classes.svgDomainIcon} style={{background: `#${domain.color}`}} src={domain.svg_icon_url} />
            <Typography style={{color: `#${domain.color}`}} variant="h6">{domain.name}{domain.active ? '' : ' (Deactivated)'}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container justifyContent="flex-end">
            <div>{this.headerButtons()}</div>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.summary}>{domain.summary}</Typography>
        </Grid>
      </Grid>
    );
  };

  headerButtons = () => {
    const { classes, domain, domainsCount, index } = this.props;

    return (
      <Fragment>
        <IconButton
          disabled={index === 0}
          onClick={() => {this.props.onMoveUp(domain)}}
          size="large">
          <UpIcon />
        </IconButton>
        <IconButton
          disabled={index === (domainsCount - 1)}
          onClick={() => {this.props.onMoveDown(domain)}}
          size="large">
          <DownIcon />
        </IconButton>
        <Button
          onClick={this.toggleActive}
          size="small"
          className={classes.headerButton}
        >
          <Typography className={classes.toggleActiveButtonText} variant="button">{this.props.domain.active ? 'Deactivate' : 'Activate'}</Typography>
        </Button>
        <Button
          onClick={this.deleteDomain}
          size="small"
          className={classes.headerButton}
        >
          <Typography className={classes.deleteButtonText} variant="button">Delete</Typography>
        </Button>
        <Button
          color="secondary"
          onClick={() => this.props.onEditDomain(domain)}
          size="small"
          variant="contained"
          className={classes.headerButton}
        >
          <Typography variant="button">Edit Domain</Typography>
        </Button>
        <Button
          color="secondary"
          onClick={this.handleOpenAddModal}
          size="small"
          variant="contained"
          className={classes.headerButton}
        >
          <Typography variant="button">Add Capability</Typography>
        </Button>
      </Fragment>
    );
  };

  handleOpenAddModal = () => {
    this.setState({ mode: 'add', open: true, id: null });
  };

  handleOpenEditModal = (capability) => {
    this.setState({ mode: 'edit', open: true, id: capability.id });
  };

  handleCloseModal = () => {
    this.setState({ mode: null, open: false, id: null, refreshTick: Date.now() });
  };

  toggleActive = () => {
    this.api.put('/api/v1/domains/' + this.props.domain.id, { domain: { active: !this.props.domain.active } }).then((res) => {
      if(res.status === 200) {
        this.props.onRefresh();
      } else if(res.status === 422) {
        const message = Object.values(res.json.messages).map(m => m.join(', '));
        this.api.notifyModalError(message);
      } else {
        console.error(res);
        this.api.notifyModalError('Failed to update');
      }
    });
  };

  deleteDomain = () => {
    this.api.delete('domains', this.props.domain.id).then((response) => {
      if(response.status === 400) {
        this.api.notifyModalError(response.json.message);
      } else {
        this.props.onRefresh();
      }
    });
  };

  updateDisplayOrder = (rows) => {
    var n = 1;
    const data = rows.map(capability => {
      return {id: capability.id, display_order: n++};
    });
    this.api.put(`/api/v1/domains/${this.props.domain.id}/capabilities.json`, { capabilities: data });
  }

}

export default withStyles(CapabilityPanel, styles, { withTheme: true });
