import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import WaveTextField from '../form/WaveTextField';
import WaveTextArea from '../form/WaveTextArea';
import { DefaultCheckbox } from '@common/components/default-checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';

const styles = (theme) => ({
  formFields: {
    paddingBottom: theme.typography.pxToRem(16)
  },
  videoResourceFields: {
    marginBottom: theme.typography.pxToRem(10)
  },
  tooltipContainer: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '2px',
    boxShadow: '0 2px 10px 0 #00000028',
    padding: theme.typography.pxToRem(20),
    opacity: 1
  },
  tooltipText: {
    color: '#0c0606de',
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.5
  },
  popper: {
    opacity: 1
  },
  hideInput: {
    display: 'none'
  },
  fileUpload: {
    marginBottom: theme.typography.pxToRem(20)
  }
});

class VideoResourcesForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      thumbnailImageFilename: props.entity.thumbnail_image_filename ?? null
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.entity.thumbnail_image_filename !==
      this.props.entity.thumbnail_image_filename
    ) {
      this.setState({
        thumbnailImageFilename:
          this.props.entity.thumbnail_image_filename ?? null
      });
    }
  }

  render() {
    const { classes } = this.props;
    const thumbnailImage = this.props.makeFieldProps('thumbnail_image');

    // t.string "category", limit: 50
    // t.string "title", limit: 50
    // t.string "duration", limit: 20
    // t.string "link_label", limit: 20
    // t.string "link_url", limit: 2000

    return (
      <Fragment>
        <Grid container className={classes.videoResourceFields}>
          <Grid item xs={12} className={classes.formFields}>
            <WaveTextField
              label="Category"
              maxLength={20}
              {...this.props.makeFieldProps('category')}
              helperText="eg Product Demo, Tutorial"
            />
          </Grid>
          <Grid item xs={12} className={classes.formFields}>
            <WaveTextField
              label="Title"
              maxLength={40}
              {...this.props.makeFieldProps('title')}
            />
          </Grid>
          <Grid item xs={12} className={classes.formFields}>
            <WaveTextField
              label="Duration"
              maxLength={20}
              {...this.props.makeFieldProps('duration')}
              helperText="eg 4 mins"
            />
          </Grid>
          <Grid item xs={12} className={classes.formFields}>
            <WaveTextField
              label="Link Label"
              maxLength={20}
              {...this.props.makeFieldProps('link_label')}
              helperText="eg Watch now"
            />
          </Grid>
          <Grid item xs={12} className={classes.formFields}>
            <WaveTextField
              label="Link URL"
              maxLength={2000}
              {...this.props.makeFieldProps('link_url')}
              helperText="Ensure URL includes https://"
            />
          </Grid>
          <Grid item xs={12} className={classes.formFields}>
            <WaveTextField
              name={thumbnailImage.name}
              error={thumbnailImage.error}
              helperText={
                thumbnailImage.helperText ||
                'jpg or png max 1MB, preferred dimensions 184x100 px'
              }
              fileUpload={
                <InputAdornment position="end">
                  <Box display="flex" alignItems="flex-start" gap="8px">
                    {this.state.thumbnailImageFilename ? (
                      <IconButton
                        size="large"
                        onClick={() => {
                          this.props.onFileChanged('thumbnail_image', null, { removeAttachment: true });
                          this.setState({
                            thumbnailImageFilename: null
                          });
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    ) : null}
                    <Button
                      className={classes.fileUpload}
                      color="secondary"
                      component="label"
                      size="small"
                      variant="contained"
                    >
                      <Typography variant="button">Browse</Typography>
                      <input
                        accept=".jpg,.png"
                        className={classes.hideInput}
                        onChange={(event) => {
                          this.props.onFileChanged(
                            'thumbnail_image',
                            event.target.files[0],
                            { removeAttachment: true }
                          );
                          this.setState({
                            thumbnailImageFilename: event.target.files[0].name
                          });
                          event.target.value = null;
                        }}
                        type="file"
                      />
                    </Button>
                  </Box>
                </InputAdornment>
              }
              label="Thumbnail Image"
              value={this.state.thumbnailImageFilename}
            />
          </Grid>
          <Grid item xs={12} className={classes.formFields}>
            <DefaultCheckbox
              label="Active - Show on dashboard?"
              checkboxValue={true}
              unCheckValue={false}
              {...this.props.makeFieldProps('active')}
            />
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(VideoResourcesForm, styles, { withTheme: true });
