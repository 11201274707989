import React from 'react';
import Typography from '@mui/material/Typography';

export default function FlashMessages(props) {

  return props.flashMessages.map(
    (flashMessage) => {
      return (<Typography key={flashMessage.id} color="error">{flashMessage.text}</Typography>);
    }
  );

}
