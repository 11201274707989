
// Also add to ApplicationController.all_metadata
const fields = {
  employment_types: window.modelMetadata.employment_types,
  locations: window.modelMetadata.locations,
  passports: window.modelMetadata.passports,
  programs: window.modelMetadata.programs,
  points_histories: window.modelMetadata.points_histories,
  point_requests: window.modelMetadata.point_requests,
  registrations: window.modelMetadata.registrations,
  response_labels: window.modelMetadata.response_labels,
  banners: window.modelMetadata.banners,
  video_resources: window.modelMetadata.video_resources,
  users: window.modelMetadata.users,
  vocational_activities: window.modelMetadata.vocational_activities,
  capabilities: window.modelMetadata.capabilities,
  departments: window.modelMetadata.departments,
  notification_logs: window.modelMetadata.notification_logs,
  institutes: window.modelMetadata.institutes,
  categories: window.modelMetadata.categories,
};

export default class MetadataService {
  fields(resource) {
    return new Promise(function(resolve, reject) {
      resolve(fields[resource]);
    });
  }

  // synchronous
  get(resource) {
    return fields[resource];
  }

  getWithInstituteContext(resource, institute) {
    const rawMeta = { ...this.get(resource) };
    
    if (institute.custom_user_field_1_enabled) {
      rawMeta.custom_1 = { type: 'string', label: institute.custom_user_field_1 }
    }

    if (institute.custom_user_field_2_enabled) {
      rawMeta.custom_2 = { type: 'string', label: institute.custom_user_field_2 }
    }

    return rawMeta;
  }
}
