import React from 'react';

class EntityManage extends React.Component {

  // unexpected errors
  processError(ex) {
    console.error(ex);
    this.setState({ ...s, errorMessage: ex.message });
  }

  // Default
  processResponse(res) {
    const s = { validationErrors: [], dirty: false, errorMessage: null };

    if (res.status == 200) {
      this.setEntity(res.json);
    } else if (res.status == 201) {
      this.setEntity(res.json);
    } else if (res.status == 400) {
      this.setState({...s,
        dirty: true,
        errorMessage: res.json.title + ': ' + res.json.message,
      });
    } else if (res.status == 422) {
      this.setValidationErrors(res.json);
    } else if (res.status == 500) {
      this.setState({ ...s, errorMessage: '500 Internal Server Error' });
    } else {
      console.warn('Got status ' + req.status + '?');
      this.setState({...s});
    }
  }

  setValidationErrors(json) {
    var errorMessage = json.title;

    if (json.messages.hasOwnProperty('base')) {
      errorMessage += " - " + json.messages.base.join(', ');
    }

    this.setState({
      dirty: true,
      errorMessage: errorMessage,
      validationErrors: json.messages
    });
  }

}

export default EntityManage;
