import React, { useCallback, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';
import ApiService from '@common/services/ApiService';
import { DefaultModal } from '@common/components/default-modal';
import { DefaultButton } from '@common/components/default-button';
import { DefaultCheckbox } from '@common/components/default-checkbox';
import { modalStyles } from './styles';

const ConvertModal = ({
  classes,
  isOpened,
  onClose,
  onRefreshRequest,
  pointRequest,
  user
}) => {
  const { type } = pointRequest || {};

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const initialData = { notify_user: true };
  const [data, setData] = useState(initialData);

  const api = new ApiService();

  const getModalTitle = (type) => {
    switch (type) {
      case 'ReaPointRequest':
        return 'Convert to Vocational Currency';
      case 'VocationalPointRequest':
        return 'Convert to External Professional Currency';
    }
  };

  const getNewPointRequestType = (type) => {
    switch (type) {
      case 'ReaPointRequest':
        return 'VocationalPointRequest';
      case 'VocationalPointRequest':
        return 'ReaPointRequest';
    }
  };

  const handleInputChange = useCallback(
    (event) => {
      const { value, name } = event.target;

      setData({ ...data, [name]: value });
    },
    [data, setData]
  );

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);

    try {
      const url = `/api/v1/users/${user.id}/point_requests/${pointRequest.id}/convert`;
      const newData = {
        ...data,
        type: getNewPointRequestType(pointRequest.type)
      };
      const res = await api.put(url, newData);

      if (res.status === 204) {
        handleClose();
        onRefreshRequest();
      } else if (res.status === 422) {
        setErrorMessage(res.json.message);
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [data, pointRequest]);

  const handleClose = useCallback(() => {
    setErrorMessage(null);
    setData(initialData);
    onClose();
  }, [setErrorMessage, onClose]);

  return (
    <DefaultModal
      isOpened={isOpened}
      isLoading={isLoading}
      onClose={handleClose}
      title={getModalTitle(type)}
      action={
        <DefaultButton
          className={classes.editButton}
          color="secondary"
          onClick={handleSubmit}
          size="small"
          variant="contained"
        >
          Convert
        </DefaultButton>
      }
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography color="error">{errorMessage}</Typography>
        </Grid>
        <Grid item xs={12}>
          <DefaultCheckbox
            name="notify_user"
            label="Notify the Educator and the Approver by email"
            onChange={handleInputChange}
            checkboxValue={true}
            unCheckValue={false}
            value={data.notify_user}
          />
        </Grid>
      </Grid>
    </DefaultModal>
  );
};

export default withStyles(ConvertModal, modalStyles, { withTheme: true });
