import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';

const styles = theme => ({
  tooltipText: {
    color: '#0c0606de',
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.5
  }
});

class DomainIcon extends React.Component {

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Typography className={classes.tooltipText}>Please upload a transparent .png with a minimum dimensions 55px by 55px.</Typography>
        <br/>
        <Typography className={classes.tooltipText}>For best results upload an icon that uses one colour and is an outline, not filled with colour.</Typography>
      </Fragment>
    )
  }

}

export default withStyles(DomainIcon, styles, { withTheme: true });
