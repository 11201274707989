import React, { Fragment } from 'react';
import MenuList from '@mui/material/MenuList';

class DepartmentsList extends React.Component {

  render() {
    const { departments } = this.props;

    return(
      <Fragment>
        <MenuList disablePadding>
          {this.props.getDepartmentList(departments)}
        </MenuList>
      </Fragment>
    );
  }

}

export default DepartmentsList;
