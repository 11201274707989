import React from 'react';
import { createTheme, ThemeProvider, StyledEngineProvider, adaptV4Theme } from '@mui/material/styles';

import { withStyles } from 'tss-react/mui';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

function pxToRem(value) {
  const fontSize = 14; //mui default
  const htmlFontSize = 16; //browser default
  const coef = fontSize / 14; //coefficient
  return `${value / htmlFontSize * coef}rem`;
}

const styles = theme => ({
  paper: {
    paddingTop: pxToRem(25),
    paddingBottom: pxToRem(50),
    paddingLeft: pxToRem(35),
    paddingRight: pxToRem(35),
  },
  root: {
    marginTop: pxToRem(120),
  },
});

const LoginLayout = (props) => {
  const { classes } = props;
  var baseTheme = window.themes['theme_default'];

  baseTheme.overrides = {
    MuiButton: {
      root: {
        padding: `${pxToRem(9)} ${pxToRem(12)}`,
      },
    },
    MuiFormLabel: {
      root: {
        color: '#1f1f1fde',
        opacity: 0.7,
        '&.Mui-focused': {
          color: '#1f1f1fde',
          opacity: 0.7,
        },
        '&.Mui-error': {
          opacity: 1,
        },
      },
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottom: `2px solid ${baseTheme.palette.primary.main}`,
        },
        '&:before': {
          borderBottom: '2px solid #cecece',
        },
         '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: `2px solid ${baseTheme.palette.primary.main}`,
        },
      },
    },
  };

  baseTheme.typography = {
    fontFamily: 'IBM Plex Sans, sans-serif',
    color: {
      primary: '#1f1f1f',
    },
    button: {
      color: baseTheme.palette.secondary.contrastText,
      fontSize: pxToRem(13),
      fontWeight: 500,
    },
    h5: {
      color: baseTheme.palette.primary.main,
      fontSize: pxToRem(23),
      fontWeight: 500,
    },
    title: {
      color: '#1f1f1f',
      fontSize: pxToRem(20),
      fontWeight: 500,
    },
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createTheme(adaptV4Theme(baseTheme))}>
        <Grid
          container
          className={classes.root}
          justifyContent="center"
        >
          <Grid item md={6} xs={12}>
            <Paper className={classes.paper}>
              {props.children}
            </Paper>
          </Grid>
        </Grid>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default withStyles(LoginLayout, styles, { withTheme: true });
