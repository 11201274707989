import React, {
  Fragment,
  useCallback,
  useState,
  useEffect,
  useMemo
} from 'react';
import format from 'date-fns/format';
import { withStyles } from 'tss-react/mui';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import EventIcon from '@mui/icons-material/Event';
import { DatePicker } from '@mui/x-date-pickers';
import ApiService from '@common/services/ApiService';
import Autocomplete from '@common/components/autocomplete/Autocomplete';
import Chisholm from '@common/config/Chisholm';
import WaveSelectField from '@common/components/form/WaveSelectField';
import Utils from '@common/services/Utils';
import { WaveDatePicker } from '../../../common/components/WaveDatePicker';

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. Unsupported arrow function syntax.
//Unexpected value type of CallExpression.
const styles = (theme) => ({
  adjustedSpacing: {
    padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(
      20
    )} 0 ${theme.typography.pxToRem(20)}`
  },
  adjustedSpacingLeft: {
    padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(
      20
    )} 0 ${theme.typography.pxToRem(25)}`
  },
  adjustedSpacingRight: {
    padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(
      25
    )} 0 ${theme.typography.pxToRem(20)}`
  },
  adjustedSpacingUser: {
    padding: `${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(
      20
    )} ${theme.typography.pxToRem(40)} ${theme.typography.pxToRem(25)}`
  },
  adjustedSpacingSupervisor: {
    padding: `${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(
      20
    )} ${theme.typography.pxToRem(40)} ${theme.typography.pxToRem(20)}`
  },
  adjustedSpacingDate: {
    padding: `${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(
      20
    )} ${theme.typography.pxToRem(40)} ${theme.typography.pxToRem(20)}`
  },
  clearFilters: {
    color: theme.typography.color.primary,
    '&:hover': {
      color: theme.typography.color.primary,
      cursor: 'pointer'
    },
    '&:visited': {
      color: theme.typography.color.primary
    },
    fontSize: theme.typography.pxToRem(13),
    marginRight: theme.typography.pxToRem(20),
    opacity: 0.7
  },
  filterHeader: {
    padding: `${theme.typography.pxToRem(19)} ${theme.typography.pxToRem(
      25
    )} 0 ${theme.typography.pxToRem(25)}`
  },
  ieFix: Utils.ieDatePickerFix()
});

const PointRequestFilters = ({ classes, handleApplyFilters, institute, passportPeriod }) => {
  const api = new ApiService();

  const evidenceAttachmentOptions = useMemo(
    () => ({
      1: {
        label: 'Attached'
      },
      0: {
        label: 'Not Attached'
      }
    }),
    []
  );

  const initialFilters = {
    user_department_division_id_eq: 'all',
    user_department_id_eq: 'all',
    user_employment_type_id_eq: 'all',
    user_active_eq: '1',
    evidence_attached_true: 'all',
    status_eq: 'all',
    user_id_eq: 'all',
    user_supervisor_id_eq: 'all',
    updated_at_from_eq: passportPeriod.from,
    updated_at_to_eq: passportPeriod.to
  };

  const [filters, setFilters] = useState(initialFilters);
  const [userLabel, setUserLabel] = useState('');
  const [supervisorLabel, setSupervisorLabel] = useState('');
  const [divisionOptions, setDivsionOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [employmentTypeOptions, setEmploymentTypeOptions] = useState([]);

  const userStatusOptions = useMemo(() => ({
    1: {
      id: '1',
      label: 'Active'
    },
    0: {
      id: '0',
      label: 'Deactivated'
    }
  }));

  const pointRequestsStatusOptions = useMemo(() => ({
    pending: {
      id: 0,
      label: 'Pending'
    },
    approved: {
      id: 1,
      label: 'Approved'
    },
    incomplete: {
      id: 2,
      label: 'Incomplete'
    }
  }));

  const {
    user_department_division_id_eq,
    user_department_id_eq,
    user_employment_type_id_eq,
    user_active_eq,
    evidence_attached_true,
    status_eq,
    updated_at_from_eq,
    updated_at_to_eq
  } = filters;

  const getFiltersOptions = useCallback(() => {
    const divisionOptionsPromise = api.query('/api/v1/divisions/options');
    const departmentOptionsPromise = api.query('/api/v1/departments/options');
    const employmentTypeOptionsPromise = api.query(
      '/api/v1/employment_types/options'
    );

    Promise.all([
      divisionOptionsPromise,
      departmentOptionsPromise,
      employmentTypeOptionsPromise
    ]).then(
      ([
        newDivisionOptions,
        newDepartmentOptions,
        newEmploymentTypeOptions
      ]) => {
        setDivsionOptions(newDivisionOptions);
        setDepartmentOptions(newDepartmentOptions);
        setEmploymentTypeOptions(newEmploymentTypeOptions);
      }
    );
  }, [
    setDivsionOptions,
    setDepartmentOptions,
    setEmploymentTypeOptions,
    setFilters
  ]);

  useEffect(() => {
    getFiltersOptions();
  }, []);

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      user_department_id_eq: 'all'
    }));
  }, [user_department_division_id_eq, setFilters]);

  const clearFilters = useCallback(() => {
    setFilters(initialFilters);
    handleApplyFilters({});
  }, [initialFilters, handleApplyFilters]);

  const departments = useMemo(() => {
    let result = {};
    const divisionId = parseInt(user_department_division_id_eq);
    Object.keys(departmentOptions).forEach((key) => {
      if (departmentOptions[key].division_id === divisionId) {
        result[key] = departmentOptions[key];
      }
    });
    return result;
  }, [user_department_division_id_eq, departmentOptions]);

  const handleChangeUser = useCallback(
    (id, value) => {
      setUserLabel(value);

      if (id !== null && id !== 'all') {
        setFilters((prevFilters) => ({ ...prevFilters, user_id_eq: id }));
      } else {
        setFilters((prevFilters) => {
          const { user_id_eq, ...rest } = prevFilters;
          return rest;
        });
      }
    },
    [setFilters, setUserLabel]
  );

  const handleChangeSupervisor = useCallback(
    (id, value) => {
      setSupervisorLabel(value);
      if (id !== null && id !== 'all') {
        setFilters((prevFilters) => ({
          ...prevFilters,
          user_supervisor_id_eq: id
        }));
      } else {
        setFilters((prevFilters) => {
          const { user_supervisor_id_eq, ...rest } = prevFilters;
          return rest;
        });
      }
    },
    [setSupervisorLabel, setFilters]
  );

  const handleDateChange = useCallback(
    (filterName, date) => {
      const convertedDate =
        date == null ? null : format(new Date(date), 'yyyy-MM-dd');
      setFilters((prevFilters) => ({
        ...prevFilters,
        [filterName]: convertedDate
      }));
    },
    [setFilters]
  );

  const handleInputChange = useCallback(
    (filterName, event) => {
      const target = event.target;
      const value = target.value;

      if (value !== null && value !== 'all') {
        setFilters((prevFilters) => ({ ...prevFilters, [filterName]: value }));
      } else {
        setFilters((prevFilters) => {
          const { [filterName]: value, ...rest } = prevFilters;
          return rest;
        });
      }
    },
    [setFilters]
  );

  const makeFieldProps = (filterName, fieldValue) => ({
    name: filterName,
    onChange: (e) => handleInputChange(filterName, e),
    value: fieldValue
  });

  const applyFilters = useCallback(() => {
    const newFilters = {};
    Object.keys(filters).forEach((key) => {
      if (filters[key] !== null && filters[key] !== 'all') {
        newFilters[key] = filters[key];
      }
    });
    handleApplyFilters(newFilters);
  }, [filters]);

  return (
    <Fragment>
      <Grid
        container
        className={classes.filterHeader}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h6">Filter</Typography>
        <Grid item>
          <a className={classes.clearFilters} onClick={clearFilters}>
            Clear
          </a>
          <Button
            color="secondary"
            onClick={applyFilters}
            size="small"
            variant="contained"
          >
            <Typography variant="button">Filter</Typography>
          </Button>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item className={classes.adjustedSpacingLeft} xs={3}>
          <WaveSelectField
            filterByAllItem
            filterByAllItemLabel="All"
            noValidation
            label="Division"
            menuItems={divisionOptions}
            {...makeFieldProps(
              'user_department_division_id_eq',
              user_department_division_id_eq
            )}
          />
        </Grid>
        <Grid item className={classes.adjustedSpacing} xs={3}>
          <WaveSelectField
            filterByAllItem
            filterByAllItemLabel="All"
            noValidation
            label="Department"
            menuItems={
              parseInt(user_department_division_id_eq)
                ? departments
                : departmentOptions
            }
            {...makeFieldProps('user_department_id_eq', user_department_id_eq)}
          />
        </Grid>
        <Grid item className={classes.adjustedSpacing} xs={2}>
          <WaveSelectField
            filterByAllItem
            filterByAllItemLabel="All"
            noValidation
            label="Employment Type"
            menuItems={employmentTypeOptions}
            {...makeFieldProps(
              'user_employment_type_id_eq',
              user_employment_type_id_eq
            )}
          />
        </Grid>
        <Grid item className={classes.adjustedSpacing} xs={2}>
          <WaveSelectField
            filterByAllItem
            filterByAllItemLabel="All"
            noValidation
            label="User Status"
            menuItems={userStatusOptions}
            {...makeFieldProps('user_active_eq', user_active_eq)}
          />
        </Grid>

        <Grid item className={classes.adjustedSpacingRight} xs={2}>
          <WaveSelectField
            filterByAllItem
            filterByAllItemLabel="All"
            noValidation
            label="Evidence Attached"
            menuItems={evidenceAttachmentOptions}
            {...makeFieldProps(
              'evidence_attached_true',
              evidence_attached_true
            )}
          />
        </Grid>

        <Grid item className={classes.adjustedSpacingUser} xs={3}>
          <Autocomplete
            label="User"
            name="user_id"
            onChange={handleChangeUser}
            role={["educator", "guest"]}
            value={userLabel || ''}
          />
        </Grid>
        <Grid item className={classes.adjustedSpacingSupervisor} xs={3}>
          <Autocomplete
            label="Supervisor"
            name="supervisor_id"
            onChange={handleChangeSupervisor}
            role="supervisor"
            value={supervisorLabel || ''}
          />
        </Grid>
        <Grid item className={classes.adjustedSpacingDate} xs={2}>
          <WaveDatePicker
            className={classes.ieFix}
            format={Chisholm.rangeDate}
            label="From"
            name="from_date"
            onChange={(value) => handleDateChange('updated_at_from_eq', value)}
            value={updated_at_from_eq}
          />
        </Grid>
        <Grid item className={classes.adjustedSpacingDate} xs={2}>
          <WaveDatePicker
            className={classes.ieFix}
            format={Chisholm.rangeDate}
            label="To"
            name="to_date"
            onChange={(value) => handleDateChange('updated_at_to_eq', value)}
            value={updated_at_to_eq}
          />
        </Grid>
        <Grid item className={classes.adjustedSpacingDate} xs={2}>
          <WaveSelectField
            filterByAllItem
            filterByAllItemLabel="All"
            noValidation
            label="Point Request Status"
            menuItems={pointRequestsStatusOptions}
            {...makeFieldProps('status_eq', status_eq)}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default withStyles(PointRequestFilters, styles, { withTheme: true });
