import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import AdminTable from '../../../common/components/table/AdminTable';
import ApiService from '../../../common/services/ApiService';
import UsersFilters from './UsersFilters';
import AddUserIcon from '../../../common/icons/AddUserIcon';
import AddUsersIcon from '../../../common/icons/AddUsersIcon';
import ImportPointsIcon from '../../../common/icons/ImportPointsIcon';
import ImportPointsNoApprovalIcon from '@common/icons/ImportPointsNoApprovalIcon';


const getFields = ({ customField1Enabled, customField2Enabled }) => {
  const fields = [
    'full_name',
    'unique_identifier',
    'division.name',
    'department.name',
    'employment_type.name',
    'supervisor.full_name',
    'role_text'
  ];

  if (customField1Enabled) {
    fields.push('custom_1')
  }
  if (customField2Enabled) {
    fields.push('custom_2')
  }

  return fields
}

const styles = theme => ({
  exportHeader: {
    padding: `${theme.typography.pxToRem(22)} ${theme.typography.pxToRem(
      25
    )} ${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(25)}`
  },
  usersListHeadline: {
    marginTop: theme.typography.pxToRem(8),
    marginBottom: theme.typography.pxToRem(35)
  },
  speedDial: {
    position: 'fixed',
    bottom: theme.typography.pxToRem(30),
    right: theme.typography.pxToRem(30)
  },
  speedDialAction: {
    background: theme.palette.grey[50]
  }
});

class UsersList extends React.Component {
  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.state = {
      filters: { active_eq: '1' },
      order: null,
      orderBy: null,
      open: false
    };
  }

  handleApplyFilters = filters => {
    this.setState({ filters: filters });
  };

  handleApplySorting = (order, orderBy) => {
    this.setState({ order: order, orderBy: orderBy });
  };

  handleExport = () => {
    var sortingParams = {
      direction: this.state.order,
      field: this.state.orderBy
    };
    var searchRequest = this.api.searchUrl(
      this.state.filters,
      null,
      null,
      sortingParams,
      null
    );

    var apiUrl = '';
    searchRequest !== ''
      ? (apiUrl = '/api/v1/users.csv?')
      : (apiUrl = '/api/v1/users.csv');
    var csvUrl = apiUrl + searchRequest;
    return csvUrl;
  };

  handleOnUserCellClicked = row => {
    this.props.history.push('users/' + row.id);
  };

  handleOpen = () => {
    this.setState({
      open: true
    });
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  addUser = () => {
    this.props.history.push('users/new');
  };

  importUser = () => {
    this.props.history.push('users_import');
  };

  importPoints = () => {
    this.props.history.push('import_points')
  }

  ImportPointsNoApproval = () => {
    this.props.history.push('import_points_no_approval')
  }

  render() {
    const { classes, user, institute } = this.props;
    const { filters, open } = this.state;

    let isTouch;
    if (typeof document !== 'undefined') {
      isTouch = 'ontouchstart' in document.documentElement;
    }

    const addEnabled = user.is_super_admin || user.is_institute_admin;

    const fields = getFields({
      customField1Enabled: institute.custom_user_field_1_enabled,
      customField2Enabled: institute.custom_user_field_2_enabled 
    })

    return (
      <Grid container className={classes.manageUsers} spacing={2}>
        <Grid item xs={12}>
          <Typography className={classes.usersListHeadline} variant="h5">
            Manage Users
          </Typography>
          <Paper>
            <UsersFilters handleApplyFilters={this.handleApplyFilters} />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Grid
              container
              className={classes.exportHeader}
              justifyContent="space-between"
            >
              <Typography variant="h6">Users</Typography>
              <a href={`${this.handleExport()}`} target="_blank">
                <Button color="secondary" size="small" variant="contained">
                  <Typography variant="button">Export</Typography>
                </Button>
              </a>
            </Grid>
            <AdminTable
              showTablePagination
              fields={fields}
              filters={filters}
              handleApplySorting={this.handleApplySorting}
              onCellClicked={{ full_name: this.handleOnUserCellClicked }}
              metadata={{
                'custom_1': { type: 'string', label: institute.custom_user_field_1 },
                'custom_2': { type: 'string', label: institute.custom_user_field_2 }
              }}
              resource="users"
            />
          </Paper>
        </Grid>
        <Fragment>
          {addEnabled && (
            <SpeedDial
              ariaLabel="Add Users"
              ButtonProps={{ color: 'secondary' }}
              classes={{ root: classes.speedDial }}
              icon={<SpeedDialIcon />}
              onBlur={this.handleClose}
              onClose={this.handleClose}
              onFocus={isTouch ? undefined : this.handleOpen}
              onMouseEnter={isTouch ? undefined : this.handleOpen}
              onMouseLeave={this.handleClose}
              open={open}
            >
              <SpeedDialAction
                classes={{ button: classes.speedDialAction }}
                icon={<AddUsersIcon />}
                key="import users"
                onClick={this.importUser}
                tooltipTitle="Import users"
              />
              <SpeedDialAction
                classes={{ button: classes.speedDialAction }}
                icon={<AddUserIcon />}
                key="add user"
                onClick={this.addUser}
                tooltipTitle="Add single user"
              />
              <SpeedDialAction
                classes={{ button: classes.speedDialAction }}
                icon={<ImportPointsIcon />}
                key="import activities"
                onClick={this.importPoints}
                tooltipTitle="Import Activities"
              />
              <SpeedDialAction
                classes={{ button: classes.speedDialAction }}
                icon={<ImportPointsNoApprovalIcon />}
                key="import activities no approval"
                onClick={this.ImportPointsNoApproval}
                tooltipTitle="Import Activities (No Approval)"
              />
            </SpeedDial>
          )}
        </Fragment>
      </Grid>
    );
  }
}

export default withStyles(UsersList, styles, { withTheme: true });
