import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const styles = theme => ({
  errorsNotesList: {
    listStyle: 'none',
    marginTop: 0,
    marginBottom: theme.typography.pxToRem(40),
    paddingLeft: theme.typography.pxToRem(25),
  },
  importErrorTitle: {
    color: '#d0021b',
    paddingBottom: theme.typography.pxToRem(15),
  },
  listItem: {
    lineHeight: 1.5,
    marginBottom: theme.typography.pxToRem(6),
    paddingLeft: theme.typography.pxToRem(8),
    textIndent: theme.typography.pxToRem(-26),
  },
  summaryReport: {
    padding: `${theme.typography.pxToRem(25)} 0 ${theme.typography.pxToRem(45)} ${theme.typography.pxToRem(25)}`
  },
  summaryReportContainer: {
    marginTop: theme.typography.pxToRem(25)
  },
  issuesTitle: {
    paddingBottom: theme.typography.pxToRem(20)
  },
  importSuccess: {
    paddingBottom: theme.typography.pxToRem(25)
  }
});

function ImportPointsSummary({ classes, theme, importResult }) {
  const { errors } = importResult || {};

  return (
    <Grid item xs={12}>
      <Paper className={classes.summaryReport}>
        { errors.length > 0 ? (
          <Fragment>
            <Typography
              className={classes.importErrorTitle}
              variant="h6"
            >
              Import Failed
            </Typography>
            <Typography className={classes.issuesTitle} variant="h6">
              Issues:
            </Typography>
            <ul className={classes.errorsNotesList}>
              {errors.map((errorMessage, index) => (
                <li key={index} className={classes.listItem}>
                  {errorMessage}
                </li>
              ))}
            </ul>
            <Typography variant="h6">Please resolve these issues and re-upload the file</Typography>
          </Fragment>
        ): (
          <Typography className={classes.importSuccess} variant="h6">
            Import Successful!
          </Typography>
        )}
      </Paper>
    </Grid>
  );
}

export default withStyles(ImportPointsSummary, styles, { withTheme: true });
