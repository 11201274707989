import React from 'react';
import { withStyles } from 'tss-react/mui';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ApiService from '@common/services/ApiService';
import AdminTable from '../../../common/components/table/AdminTable';
import AdminModal from '../../../common/components/modal/AdminModal';
import BannerForm from '../../../common/components/modal/BannerForm';
import MetadataService from '../../../common/services/MetadataService';

const styles = theme => ({
  bannersHeader: {
    padding: `${theme.typography.pxToRem(22)} ${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(25)}`
  }
});

class Banners extends React.Component {

  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.meta = new MetadataService();
    this.metadata = {
      ...this.meta.get('banner'),
      button_label: {
        label: 'Button Label',
        type: 'string'
      },
      button_url: {
        label: 'Button URL',
        type: 'string'
      },
      desktop_image_url: {
        label: 'Desktop Image',
        type: 'link',
        label_field: 'desktop_image_filename'
      },
      mobile_image_url: {
        label: 'Mobile Image',
        type: 'link',
        label_field: 'mobile_image_filename'
      },
      active: {
        label: 'Active',
        type: 'boolean',
        //config to remove hover effect on a read only checkbox
        /*
        componentProps: {
          readOnly: true,
          style: { cursor: 'auto' },
          disableRipple: true
        }
        */
      }
    };
    this.state = {
      id: null,
      mode: null,
      open: false,
      refreshTick: 0
    };
  }

  forceRefresh = () => {
    this.setState({ refreshTick: Date.now() });
  };

  handleCloseModal = (save) => {
    if(save) {
      this.setState({ mode: null, open: false, id: null });
      this.forceRefresh();
    } else {
      this.setState({ mode: null, open: false, id: null });
    }
  };

  handleOpenAddModal = () => {
    this.setState({ mode: 'add', open: true, id: null });
  };

  handleOpenEditModal = (row) => {
    this.setState({ mode: 'edit', open: true, id: row.id });
  };

  updateDisplayOrder = (rows) => {
    var n = 1;
    const data = rows.map(row => {
      return {id: row.id, display_order: n++};
    });
    this.api.put(`/api/v1/banners.json`, { banners: data });
  }

  render() {
    const { classes } = this.props;
    const { id, mode, open } = this.state;
    const fields = [
      'title',
      'button_label',
      'button_url',
      'desktop_image_url',
      'mobile_image_url',
      'active'
    ];
    const actions = ['move_up', 'move_down', 'edit', 'delete'];

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper>
            <Grid
              container
              className={classes.bannersHeader}
              justifyContent="space-between"
            >
              <Typography variant="h6">
                Banners
              </Typography>
              <Button
                color="secondary"
                onClick={this.handleOpenAddModal}
                size="small"
                variant="contained"
              >
                <Typography variant="button">Add</Typography>
              </Button>
            </Grid>
            <AdminTable
              tableBottomPadding
              actionsSection={actions}
              fields={fields}
              metadata={this.metadata}
              onOpenEditModal={this.handleOpenEditModal}
              refreshTick={this.state.refreshTick}
              resource="banners"
              onReOrder={this.updateDisplayOrder}
            />
          </Paper>
        </Grid>
        <AdminModal
          component={BannerForm}
          fields={fields}
          id={id}
          mode={mode}
          onCloseModal={this.handleCloseModal}
          onForceRefresh={this.forceRefresh}
          open={open}
          resource="banners"
          title="Banner"
        />
      </Grid>
    );
  }
}

export default withStyles(Banners, styles, { withTheme: true });
