import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';

const styles = theme => ({
  tooltipText: {
    color: '#0c0606de',
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.5
  }
});

class DomainSvgIcon extends React.Component {

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Typography className={classes.tooltipText}>Please upload an .svg file with white line art and transparent background</Typography>
      </Fragment>
    )
  }

}

export default withStyles(DomainSvgIcon, styles, { withTheme: true });
