import React from 'react';
import { withStyles } from 'tss-react/mui';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import ApiService from '../../../common/services/ApiService';
import MetadataService from '../../../common/services/MetadataService';
import AdminTable from '../../../common/components/table/AdminTable';
import AdminModal from '../../../common/components/modal/AdminModal';
import LocationForm from '../../../common/components/modal/LocationForm';

const styles = theme => ({
  locationHeader: {
    padding: `${theme.typography.pxToRem(22)} ${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(25)}`,
  },
});

class Locations extends React.Component {

  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.meta = new MetadataService();
    this.state = {
      id: null,
      mode: null,
      open: false,
      refreshTick: 0
    };
  }

  forceRefresh = () => {
    this.setState({ refreshTick: Date.now() });
  };

  handleCloseModal = () => {
    this.setState({ mode: null, open: false, id: null });
  };

  handleOpenAddModal = () => {
    this.setState({ mode: 'add', open: true, id: null });
  };

  handleOpenEditModal = (row) => {
    this.setState({ mode: 'edit', open: true, id: row.id });
  };

  render() {
    const { classes } = this.props;
    const { id, mode, open } = this.state;
    const fields = ['name'];
    const actions = ['edit', 'delete'];

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper>
            <Grid
              container
              className={classes.locationHeader}
              justifyContent="space-between"
            >
              <Typography variant="h6">Locations</Typography>
              <Button
                color="secondary"
                onClick={this.handleOpenAddModal}
                size="small"
                variant="contained"
              >
                <Typography variant="button">Add</Typography>
              </Button>
            </Grid>
            <AdminTable
              tableBottomPadding
              actionsSection={actions}
              fields={fields}
              onOpenEditModal={this.handleOpenEditModal}
              refreshTick={this.state.refreshTick}
              resource="locations"
            />
          </Paper>
        </Grid>
        <AdminModal
          component={LocationForm}
          fields={fields}
          id={id}
          mode={mode}
          onCloseModal={this.handleCloseModal}
          onForceRefresh={this.forceRefresh}
          open={open}
          resource="locations"
          title="Location"
        />
      </Grid>
    );
  }

}

export default withStyles(Locations, styles, { withTheme: true });
