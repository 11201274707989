import React from 'react';
import { withStyles } from 'tss-react/mui';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import ApiService from '../../../../common/services/ApiService';
import SummaryReport from './SummaryReport';
import UsersImportForm from './UsersImportForm';

const styles = (theme) => ({
  fileUploadContainer: {
    padding: `${theme.typography.pxToRem(25)} 0 ${theme.typography.pxToRem(
      45
    )} ${theme.typography.pxToRem(25)}`
  },
  importInstructions: {
    padding: `${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(
      334
    )} ${theme.typography.pxToRem(5)} ${theme.typography.pxToRem(25)}`
  },
  importNotesList: {
    listStyle: 'none',
    marginTop: 0,
    marginBottom: theme.typography.pxToRem(40),
    paddingLeft: theme.typography.pxToRem(25)
  },
  listItem: {
    lineHeight: 1.5,
    marginBottom: theme.typography.pxToRem(6),
    paddingLeft: theme.typography.pxToRem(8),
    textIndent: theme.typography.pxToRem(-26)
  },
  notesHeaders: {
    paddingBottom: theme.typography.pxToRem(15)
  },
  notesListSpacing: {
    paddingBottom: theme.typography.pxToRem(10)
  },
  notesSpacing: {
    paddingBottom: theme.typography.pxToRem(40)
  },
  userImportContainer: {
    marginTop: theme.typography.pxToRem(25),
    marginBottom: theme.typography.pxToRem(53)
  },
  buttonProgressWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '88%',
    marginTop: -12,
    marginLeft: -12
  },
  notesBody: {
    paddingBottom: theme.typography.pxToRem(24)
  }
});

class UsersImport extends React.Component {
  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.state = {
      deactivateUsers: false,
      file: null,
      importBtn: false,
      importResults: null,
      ranImport: false,
      errorMessage: null,
      isUploading: false
    };
  }

  handleRunImport = () => {
    this.setState({ importBtn: false, isUploading: true });
    this.api
      .upload('/api/v1/users/import', this.state.file, 'file')
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        this.setState({
          importResults: json,
          ranImport: true,
          importBtn: true,
          isUploading: false
        });
      })
      .catch((e) => {
        this.setState({
          errorMessage: e.message,
          ranImport: false,
          importBtn: true,
          isUploading: false
        });
      });
  };

  onCsvFileChange = (file) => {
    this.setState({ file: file, importBtn: true });
  };

  render() {
    const { classes, theme, institute } = this.props;
    const {
      file,
      importBtn,
      importResults,
      ranImport,
      errorMessage,
      isUploading
    } = this.state;

    if (ranImport === true) {
      return <SummaryReport importResults={importResults} />;
    }

    const isCustomFieldsEnabled = institute.custom_user_field_1_enabled || institute.custom_user_field_2_enabled
    const customFields = [
      institute.custom_user_field_1_enabled && institute.custom_user_field_1,
      institute.custom_user_field_2_enabled && institute.custom_user_field_2,
    ].filter(Boolean);
  
    return (
      <Grid container className={classes.userImportContainer} spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Typography variant="h5">Import Users</Typography>
            <Grid item>
              <div className={classes.buttonProgressWrapper}>
                <Button
                  color="secondary"
                  disabled={!importBtn || isUploading}
                  onClick={this.handleRunImport}
                  size="small"
                  variant="contained"
                >
                  <Typography variant="button">Import</Typography>
                </Button>
                {isUploading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
              {isUploading && (
                <Typography color="textSecondary">
                  Processing large imports could take several minutes
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <Typography color="error">{errorMessage}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <UsersImportForm file={file} onCsvFileChange={this.onCsvFileChange} />
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.importInstructions}>
            <Typography className={classes.notesHeaders} variant="h6">
              Instructions
            </Typography>

            <Typography
              className={classes.notesBody}
              variant="h6"
            >
              This CSV import can be used to import Guests, Educators, Supervisors, &
              Facilitators.
            </Typography>
            <Typography
              className={classes.notesListSpacing}
              variant="h6"
            >
              Importing a user will trigger one of three operations;
            </Typography>

            <ul className={classes.importNotesList}>
              <li className={classes.listItem}>Insert a new user</li>
              <li className={classes.listItem}>
                Update an existing user by their Employee Number
              </li>
              <li className={classes.listItem}>
                Deactivate any user who has the value Y in the Deactivate column
                in the file
              </li>
            </ul>

            <Typography className={classes.notesHeaders} variant="h6">
              Inserting New User
            </Typography>
            <Typography className={classes.notesSpacing} variant="h6">
              A new user will be created in the system using all of the columns
              in the CSV, additionally a system generated password will be
              created for this user in the format of ‘passport-employee number’
              (passport-123456)
            </Typography>

            <Typography className={classes.notesHeaders} variant="h6">
              Updating New User
            </Typography>
            <Typography className={classes.notesSpacing} variant="h6">
              If an existing user is found with the same Employee Number, then
              that user will be updated. The following fields will be updated:
              Given Name, Family Name, Email, Department Code, Employment Type.
              The following fields will not be changed with an update:
              Supervisor or Roles. These fields may be left blank as they will
              be ignored in an update. If a user is marked as deactivated and
              then updated it will be re-activated and updated.
            </Typography>

            <Typography className={classes.notesHeaders} variant="h6">
              Deactivating Users
            </Typography>
            <Typography className={classes.notesSpacing} variant="h6">
              Any user with a role of Guest, Educator, Supervisor, or Facilitator who
              has the value Y in the Deactivate column in the CSV when updated
              will be marked as deactivated.
            </Typography>

            <Typography className={classes.notesHeaders} variant="h6">
              Validation Requirements
            </Typography>
            <Typography
              className={classes.notesListSpacing}
              variant="h6"
            >
              The CSV import must have the following requirements to
              successfully import:
            </Typography>

            <ul className={classes.importNotesList}>
              <li className={classes.listItem}>
                The first row must have column headers. E.g.: Employee Number, Given
                Name, Family Name, Email, Department Code, Supervisor Employee
                Number, Is Guest, Is Educator, Is Supervisor, Is Facilitator, Employment
                Type, Deactivate
              </li>
              <li className={classes.listItem}>Max number of records 2000</li>
              <li className={classes.listItem}>
                Employee Number, Given Name, Family Name, Email are always
                mandatory and must be in a valid format & length
              </li>
              <li className={classes.listItem}>
                For new additions Department Code, Supervisor Employee Number,
                Is Educator, Is Supervisor, Employment Type
                columns are mandatory and must be valid.
              </li>
              <li className={classes.listItem}>
                Is Facilitator & Is Guest are optional columns. These will default to N if the column is missing.
              </li>
              {isCustomFieldsEnabled 
                ? (
                  <li className={classes.listItem}>
                    Custom field{customFields.length > 1 ? 's' : ''},
                    {customFields.map((field) => ` "${field}" `).join('&')}
                    for your institute,
                    {customFields.length > 1 ? ' are also optional columns.' : ' is also an optional column.'}
                  </li>
                )
                : null
              }
              <li className={classes.listItem}>
                The Role columns should have a value of Y or N. If blank then a
                default of N will be used.
              </li>
              <li className={classes.listItem}>
                The Deactivate column should have a value of Y or N. If blank
                then a default of N will be used.
              </li>
              <li className={classes.listItem}>
                The Department Code must match an existing Department already in
                the system
              </li>
              <li className={classes.listItem}>
                The Supervisor Employee Number must match exactly a User with
                the Supervisor role that is already in the system. This is
                required for Educators & Guests.
              </li>
              <li className={classes.listItem}>
                As the users are imported row-by-row this means that the
                Supervisor records must appear in the CSV before the Educators or Guests
                to ensure they exist when an Educator or Guest is inserted
              </li>
            </ul>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(UsersImport, styles, { withTheme: true });
