import ApiService from './ApiService';

export class ProgramService {
  apiService = new ApiService();

  notifyEducators = (programId, notification) =>
    this.apiService.post(
      `/api/v1/programs/${programId}/educator_notifications`,
      { notification }
    );

  notifyAttendees = (programId, notification) =>
    this.apiService.post(`/api/v1/programs/${programId}/notifications`, {
      notification
    });
}
