import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ApiService from '../../../../common/services/ApiService';
import AdminTable from '../../../../common/components/table/AdminTable';
import MetadataService from '../../../../common/services/MetadataService';
import { withStyles } from 'tss-react/mui';
import { notificationReportStyles } from './styles';
import { NotificationReportFilter } from './NotificationReportFilter';
import format from 'date-fns/format';
import subYears from 'date-fns/subYears';

const fields = [
  'created_at',
  'message',
  'program_name',
  'notification_type',
  'number_of_recipients',
  'user'
];

class NotificationReport extends React.Component {
  state = {};
  apiService = new ApiService();
  metaService = new MetadataService();

  get handleExport() {
    const { filters } = this.state;
    if (!filters || !filters.date_from_eq || !filters.date_to_eq) {
      return '/api/v1/reports/notification_logs.csv';
    }

    return `/api/v1/reports/notification_logs.csv?q[date_from_eq]=${filters.date_from_eq}&q[date_to_eq]=${filters.date_to_eq}`;
  }

  handleFilterChange = filtersData => {
    this.setState({
      filters: filtersData && {
        date_from_eq:
          filtersData.date_from_eq &&
          format(new Date(filtersData.date_from_eq), 'yyyy-MM-dd'),
        date_to_eq:
          filtersData.date_to_eq && format(new Date(filtersData.date_to_eq), 'yyyy-MM-dd')
      }
    });
  };

  render() {
    const { reportHeader } = this.props.classes;
    const { passportPeriod } = this.props;
    const { filters } = this.state;

    return (
      <div>
        <NotificationReportFilter
          onFilterChange={this.handleFilterChange}
          passportPeriod={passportPeriod}
        />
        <Paper>
          <div className={reportHeader}>
            <Typography variant="h6">Notification Log</Typography>
            <Button
              href={this.handleExport}
              target="_blank"
              color="secondary"
              size="small"
              variant="contained"
              component="a"
            >
              <Typography variant="button">Export</Typography>
            </Button>
          </div>
          <AdminTable
            filters={filters}
            showTablePagination
            fields={fields}
            handleApplySorting={this.handleApplySorting}
            orderBy={'created_at'}
            order={'desc'}
            metadata={this.metaService.get('notification_logs')}
            resource="reports/notification_logs"
          />
        </Paper>
      </div>
    );
  }
}

export default withStyles(NotificationReport, notificationReportStyles, { withTheme: true });
