import React from 'react';
import { withStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import WaveTextArea from '@common/components/form/WaveTextArea';
import { notifyModalStyles } from './styles';
import { DefaultModal } from '@common/components/default-modal';
import { DefaultButton } from '@common/components/default-button';

class NotifyEducatorsModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      message: this.props.message
    };
  }

  handleMessageChange = event => {
    this.setState({ message: event.target.value });
  };

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { message } = this.state;

    onSubmit(message);
  };

  render() {
    const { classes, isOpened, onClose, isLoading } = this.props;

    return (
      <DefaultModal
        title="Notify Educators"
        onClose={onClose}
        isOpened={isOpened}
        isLoading={isLoading}
        action={
          <DefaultButton
            containerClass={classes.sendNotification}
            color="secondary"
            disabled={!this.state.message}
            onClick={this.handleSubmit}
            size="small"
            variant="contained"
          >
            Send
          </DefaultButton>
        }
      >
        <Typography className={classes.notifyModalHelper}>
          Send Educator Passport app notification to all active Educators!
        </Typography>
        <WaveTextArea
          label="Message"
          maxLength={1000}
          onChange={this.handleMessageChange}
          rows="4"
          value={this.state.message}
        />
      </DefaultModal>
    );
  }
}

const StyledNotifyEducatorsModal = withStyles(NotifyEducatorsModal, notifyModalStyles, {
  withTheme: true
});

export { StyledNotifyEducatorsModal as NotifyEducatorsModal };
