import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import DivisionPanel from '../../../admin/components/institute/DivisionPanel';
import MetadataService from "../../../common/services/MetadataService";
import ApiService from "../../../common/services/ApiService";
import DivisionForm from "../../../common/components/modal/DivisionForm";
import AdminModal from '../../../common/components/modal/AdminModal';

const styles = theme => ({

});

class Divisions extends React.Component {

  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.meta = new MetadataService();
    this.state = {
      divisions: []
    };
  }

  componentDidMount() {
    this.fetchDivisions();
  }

  fetchDivisions = () => {
    this.api.query('/api/v1/divisions').then((response) => {
      this.setState({ divisions: response.data });
    });
  };

  render() {
    const { id, mode, open } = this.state;
    const fields = ['name'];

    const divisions = this.divisionTables();

    return (
      <Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {divisions}
            <Button
              color="secondary"
              onClick={this.handleOpenAddModal}
              size="small"
              variant="contained"
            >
              <Typography variant="button">Create a Division</Typography>
            </Button>
          </Grid>
        </Grid>
        <AdminModal
          component={DivisionForm}
          fields={fields}
          id={id}
          mode={mode}
          onCloseModal={this.handleCloseModal}
          onForceRefresh={() => {}}
          open={open}
          resource="divisions"
          title="Division"
        />
      </Fragment>
    );
  }

  divisionTables = () => {
    return this.state.divisions.map((division) => {
      return (<DivisionPanel key={division.id} division={division} onRefresh={this.fetchDivisions} onEditDivision={this.handleOpenEditModal} />);
    });
  };

  handleCloseModal = () => {
    this.setState({ mode: null, open: false, id: null });
    this.fetchDivisions();
  };

  handleOpenAddModal = () => {
    this.setState({ mode: 'add', open: true, id: null });
  };

  handleOpenEditModal = (division) => {
    this.setState({ mode: 'edit', open: true, id: division.id });
  };

}

export default withStyles(Divisions, styles, { withTheme: true });
