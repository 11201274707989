import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function TrashIcon(props) {
  return (
    <SvgIcon {...props} style={{opacity: 0.6}}>
      <path d="M19.5 4.7L15.5 4.7 15.5 4.1C15.5 2.9 14.5 2 13.4 2L9.6 2C8.5 2 7.5 2.9 7.5 4.1L7.5 4.7 3.6 4.7C3.3 4.7 3 5 3 5.3 3 5.6 3.3 5.8 3.6 5.8L4.5 5.8 4.5 18.2C4.5 19.7 5.8 21 7.3 21L15.7 21C17.2 21 18.5 19.7 18.5 18.2L18.5 5.8 19.5 5.8C19.7 5.8 20 5.6 20 5.3 20 5 19.7 4.7 19.5 4.7ZM8.6 4.1C8.6 3.5 9.1 3.1 9.6 3.1L13.4 3.1C14 3.1 14.4 3.5 14.4 4.1L14.4 4.7 8.6 4.7 8.6 4.1ZM17.4 18.2C17.4 19.1 16.6 19.9 15.7 19.9L7.3 19.9C6.4 19.9 5.6 19.1 5.6 18.2L5.6 5.8 17.4 5.8 17.4 18.2 17.4 18.2Z"/>
      <path d="M11.5 18C11.8 18 12 17.8 12 17.5L12 8.2C12 7.9 11.8 7.7 11.5 7.7 11.2 7.7 11 7.9 11 8.2L11 17.5C11 17.8 11.2 18 11.5 18Z"/>
      <path d="M8.1 17.5C8.4 17.5 8.6 17.2 8.6 16.9L8.6 8.8C8.6 8.5 8.4 8.3 8.1 8.3 7.8 8.3 7.5 8.5 7.5 8.8L7.5 16.9C7.5 17.2 7.8 17.5 8.1 17.5Z"/>
      <path d="M15 17.5C15.3 17.5 15.5 17.2 15.5 16.9L15.5 8.8C15.5 8.5 15.3 8.3 15 8.3 14.7 8.3 14.4 8.5 14.4 8.8L14.4 16.9C14.4 17.2 14.7 17.5 15 17.5Z"/>
    </SvgIcon>
  );
}
