import React from 'react';
import { withStyles } from 'tss-react/mui';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const styles = (theme) => ({
  csvFile: {
    paddingLeft: theme.typography.pxToRem(15)
  },
  fileImportHeaders: {
    paddingBottom: theme.typography.pxToRem(15)
  },
  fileUploadContainer: {
    padding: `${theme.typography.pxToRem(25)} 0 ${theme.typography.pxToRem(
      35
    )} ${theme.typography.pxToRem(25)}`
  },
  hideInput: {
    display: 'none'
  },
  uploadCsv: {
    marginBottom: theme.typography.pxToRem(30)
  }
});

class RegistrationsImportForm extends React.Component {
  render() {
    const { classes, file, onCsvFileChange } = this.props;

    return (
      <Paper className={classes.fileUploadContainer}>
        <Typography className={classes.fileImportHeaders} variant="h6">
          File
        </Typography>
        <Grid container alignItems="center" className={classes.uploadCsv}>
          <Button
            color="secondary"
            component="label"
            size="small"
            variant="contained"
          >
            Upload
            <input
              className={classes.hideInput}
              accept="text/csv"
              onChange={(event) => {
                this.props.onCsvFileChange(event.target.files[0]);
                event.target.value = null;
              }}
              type="file"
            />
          </Button>
          {this.props.file === null ? (
            ''
          ) : (
            <Typography className={classes.csvFile} variant="h6">
              {this.props.file.name}
            </Typography>
          )}
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(RegistrationsImportForm, styles, { withTheme: true });
