import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import ApiService from '../../../common/services/ApiService';
import Autocomplete from '../../../common/components/autocomplete/Autocomplete';
import WaveSelectField from '../../../common/components/form/WaveSelectField';

const styles = theme => ({
  adjustedSpacing: {
    padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(20)} 0 ${theme.typography.pxToRem(20)}`,
  },
  adjustedSpacingLeft: {
    padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(20)} 0 ${theme.typography.pxToRem(25)}`,
  },
  adjustedSpacingRight: {
    padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(25)} 0 ${theme.typography.pxToRem(20)}`,
  },
  adjustedSpacingRole: {
    padding: `${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(40)} ${theme.typography.pxToRem(25)}`,
  },
  adjustedSpacingActive: {
    padding: `${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(40)} ${theme.typography.pxToRem(20)}`,
  },
  clearFilters: {
    color: theme.typography.color.primary,
    '&:hover': {
      color: theme.typography.color.primary,
      cursor: 'pointer',
    },
    '&:visited': {
      color: theme.typography.color.primary,
    },
    fontSize: theme.typography.pxToRem(13),
    marginRight: theme.typography.pxToRem(20),
    opacity: 0.7,
  },
  filterHeader: {
    padding: `${theme.typography.pxToRem(19)} ${theme.typography.pxToRem(25)} 0 ${theme.typography.pxToRem(25)}`
  },
});

const userRoleOptions = {
  'is_guest': {
    id: 'is_guest',
    label: 'Guest',
  },
  'is_educator': {
    id: 'is_educator',
    label: 'Educator',
  },
  'is_facilitator': {
    id: 'is_facilitator',
    label: 'Facilitator',
  },
  'is_institute_admin': {
    id: 'is_institute_admin',
    label: 'Institute Admin',
  },
  is_supervisor: {
    id: 'is_supervisor',
    label: 'Supervisor',
  },
};

const userStatusOptions = {
  'active': {
    id: 'active',
    label: 'Active',
  },
  'inactive': {
    id: 'inactive',
    label: 'Deactivated',
  }
};

class UsersFilters extends React.Component {

  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.state = {
      active: 'active',
      division_id: 'all',
      divisionOptions: {},
      department_id: 'all',
      departmentOptions: {},
      employment_type_id: 'all',
      employmentTypeOptions: {},
      errorMessage: null,
      filters: {},
      role: 'all',
      supervisor_id: null,
      supervisorLabel: '',
      nameFilter: '',
      employeeNumber: ''
    };
  }

  componentDidMount() {
    this.getFiltersOptions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.division_id !== prevState.division_id) {
      this.setState({ department_id: 'all' });
    };
  }

  arrayToObject = (arr, keyField) =>
    Object.assign({}, ...arr.map(item => ({[item[keyField]]: item}))
  );

  clearFilters = () => {
    this.setState({
      active: 'active',
      division_id: 'all',
      department_id: 'all',
      employment_type_id: 'all',
      filters: {},
      role: 'all',
      supervisor_id: null,
      supervisorLabel: '',
      nameFilter: '',
      employeeNumber: ''
    });

    this.props.handleApplyFilters(this.state.filters);
  };

  getDepartments = (departments, divisionId) => {
    var departmentsArray = [];
    Object.keys(departments).map(obj =>
      departments[obj].division_id === divisionId ? departmentsArray.push(departments[obj]) : ''
    );
    return departmentsArray;
  };

  getFiltersOptions = () => {
    const divisionOptionsPromise = this.api.query('/api/v1/divisions/options');
    const departmentOptionsPromise = this.api.query('/api/v1/departments/options');
    const employmentTypeOptionsPromise = this.api.query('/api/v1/employment_types/options');

    Promise.all([divisionOptionsPromise, departmentOptionsPromise, employmentTypeOptionsPromise]).then(([divisionOptions, departmentOptions, employmentTypeOptions]) => {
      this.setState({
        divisionOptions: divisionOptions,
        departmentOptions: departmentOptions,
        employmentTypeOptions: employmentTypeOptions
      });
    }).catch(this.processError);
  };

  handleAutocompleteChange = (id, value) => {
    this.setState({ supervisor_id: id, supervisorLabel: value });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({ [name]: value });
  };

  makeFieldProps = (fieldName, fieldValue) => {
    return {
      name: fieldName,
      onChange: this.handleInputChange,
      value: fieldValue,
    };
  };

  onFilter = () => {
    var filters = this.state.filters;
    filters = {};

    if (this.state.division_id != null && this.state.division_id != 'all') {
      filters['division_id_eq'] = this.state.division_id;
    }
    if (this.state.department_id != null && this.state.department_id != 'all') {
      filters['department_id_eq'] = this.state.department_id;
    }
    if (this.state.employment_type_id != null && this.state.employment_type_id != 'all') {
      filters['employment_type_id_eq'] = this.state.employment_type_id;
    }
    if (this.state.supervisor_id != null && this.state.supervisor_id != 'all') {
      filters['supervisor_id_eq'] = this.state.supervisor_id;
    }
    if (this.state.role != null && this.state.role != 'all') {
      filters[`${this.state.role}_eq`] = true;
    }
    if (this.state.active != null && this.state.active != 'all') {
      filters['active_eq'] = this.state.active === 'active' ? true : false;
    }
    if (this.state.nameFilter != null && this.state.nameFilter.length > 0) {
      filters['name_contains'] = this.state.nameFilter;
    }
    if (this.state.employeeNumber != null && this.state.employeeNumber.length > 0) {
      filters['unique_identifier_matches_any'] = '%'+this.state.employeeNumber+'%';
    }

    this.props.handleApplyFilters(filters);
  }

  // unexpected errors
  processError = (ex) => {
    console.error(ex);
    this.setState({ ...s, errorMessage: ex.message });
  };

  render() {
    const { classes } = this.props;
    var { active, division_id, divisionOptions, department_id, departmentOptions, employment_type_id, employmentTypeOptions, role, nameFilter, employeeNumber } = this.state;
    var divisionIdInt = parseInt(division_id);
    var departmentsArray = this.getDepartments(departmentOptions, divisionIdInt);
    var filteredDepartments = this.arrayToObject(departmentsArray, 'id');

    return (
      <Fragment>
        <Grid
          container
          className={classes.filterHeader}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h6">Filter</Typography>
          <Grid item>
            <a
              className={classes.clearFilters}
              onClick={() => this.clearFilters()}
            >
              Clear
            </a>
            <Button
              color="secondary"
              onClick={() => this.onFilter()}
              size="small"
              variant="contained"
            >
              <Typography variant="button">Filter</Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item className={classes.adjustedSpacingLeft} xs={3}>
            <WaveSelectField
              filterByAllItem
              filterByAllItemLabel="All"
              noValidation
              label="Division"
              menuItems={divisionOptions}
              {...this.makeFieldProps('division_id', division_id)}
            />
          </Grid>
          <Grid item className={classes.adjustedSpacing} xs={3}>
            <WaveSelectField
              filterByAllItem
              filterByAllItemLabel="All"
              noValidation
              label="Department"
              menuItems={divisionIdInt ? filteredDepartments : departmentOptions}
              {...this.makeFieldProps('department_id', department_id)}
            />
          </Grid>
          <Grid item className={classes.adjustedSpacing} xs={3}>
            <WaveSelectField
              filterByAllItem
              filterByAllItemLabel="All"
              noValidation
              label="Employment Type"
              menuItems={employmentTypeOptions}
              {...this.makeFieldProps('employment_type_id', employment_type_id)}
            />
          </Grid>
          <Grid item className={classes.adjustedSpacingRight} xs={3}>
            <Autocomplete
              label="Supervisor"
              name="supervisor_id"
              onChange={this.handleAutocompleteChange}
              role='supervisor'
              value={this.state.supervisorLabel || ''}
            />
          </Grid>
          <Grid item className={classes.adjustedSpacingRole} xs={3}>
            <WaveSelectField
              filterByAllItem
              filterByAllItemLabel="All"
              noValidation
              label="Role"
              menuItems={userRoleOptions}
              {...this.makeFieldProps('role', role)}
            />
          </Grid>
          <Grid item className={classes.adjustedSpacingActive} xs={3}>
            <WaveSelectField
              filterByAllItem
              filterByAllItemLabel="All"
              noValidation
              label="Active"
              menuItems={userStatusOptions}
              {...this.makeFieldProps('active', active)}
            />
          </Grid>
          <Grid item className={classes.adjustedSpacingActive} xs={3}>
            <TextField
              variant="standard"
              fullWidth
              label={
                <Fragment>
                  <span>Name Contains</span>
                </Fragment>
              }
              {...this.makeFieldProps('nameFilter', nameFilter)} />
          </Grid>
          <Grid item className={classes.adjustedSpacingActive} xs={3}>
            <TextField
              variant="standard"
              fullWidth
              label={
                <Fragment>
                  <span>Employee Number</span>
                </Fragment>
              }
              {...this.makeFieldProps('employeeNumber', employeeNumber)} />
          </Grid>
        </Grid>
      </Fragment>
    );
  }

}

export default withStyles(UsersFilters, styles, { withTheme: true });
