import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import WaveTextField from '../form/WaveTextField';

const styles = theme => ({
  createLocationFields: {
    marginBottom: theme.typography.pxToRem(10)
  }
});

class LocationForm extends React.Component {

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Grid
          container
          className={classes.createLocationFields}
          justifyContent="space-between"
        >
          <Grid item xs={12}>
            <WaveTextField
              label="Name"
              {...this.props.makeFieldProps('name')}
            />
          </Grid>
        </Grid>
      </Fragment>
    );
  }

}

export default withStyles(LocationForm, styles, { withTheme: true });
