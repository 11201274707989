import React from 'react';
import { withStyles } from 'tss-react/mui';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Tooltip from '@mui/material/Tooltip';
import { Input, TextareaAutosize } from '@mui/material';

const styles = theme => ({
  textAreaInput: {
    border: '1px solid #cecece',
    borderRadius: '2px',
    fontSize: theme.typography.pxToRem(15),
    fontFamily: "'IBM Plex Sans', Helvetica, Arial, sans-serif",
    height: '100%',
    lineHeight: 1.6,
    padding: theme.spacing(1.5),
    'label + &': {
      marginTop: theme.spacing(3),
    },
    outline: 'none',
    alignItems: 'start',
    // Important needed here because MUI tries to be clever and compute the height
    // but we want to have it flex to the height of the parent 
    // @see https://github.com/mui/material-ui/blob/16cb18aac4e9311f1862ddc16f77e3c61f77e7c4/packages/mui-base/src/TextareaAutosize/TextareaAutosize.tsx#L138
    height: '100% !important',
    overflow: 'scroll !important',
    resize: 'none',
  },
  textAreaFormControl: {
    height: '100%',
  }
});

class WaveTextArea extends React.Component {

  render() {
    const value = (this.props.value == null ? '' : this.props.value + '');
    const { classes, tooltipProps, label } = this.props;

    return (
      <FormControl
        variant="standard"
        className={classes.textAreaFormControl}
        fullWidth
        error={this.props.error}>
        <InputLabel shrink>
          {label}
          {tooltipProps &&
            <Tooltip
              classes={{
                popper: tooltipProps.tooltipPopper,
                tooltip: tooltipProps.tooltipContainer
              }}
              placement={tooltipProps.placement}
              title={tooltipProps.titleComponent}
            >
              <span> ⓘ</span>
            </Tooltip>
          }
        </InputLabel>
        <TextareaAutosize 
          minRows={this.props.rows}
          maxRows={this.props.rows}
          className={classes.textAreaInput}
          maxLength={this.props.maxLength}
          name={this.props.name}
          onChange={this.props.onChange}
          placeholder={this.props.placeholder || "Enter description here"}
          value={value}
        />
        <Grid
          container
          direction="row"
          justifyContent="space-between"
        >
          <FormHelperText>{this.props.helperText}</FormHelperText>
          <FormHelperText error={false}>
            {`${value.length} / ${this.props.maxLength}`}
          </FormHelperText>
        </Grid>
      </FormControl>
    );
  }

}

export default withStyles(WaveTextArea, styles, { withTheme: true });
