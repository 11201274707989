import React from 'react';
import { withStyles } from 'tss-react/mui';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ApiService from '@common/services/ApiService';
import AdminTable from '../../../common/components/table/AdminTable';
import AdminModal from '../../../common/components/modal/AdminModal';
import VideoResourcesForm from '../../../common/components/modal/VideoResourcesForm';
import MetadataService from '../../../common/services/MetadataService';

const styles = theme => ({
  videoResourcesHeader: {
    padding: `${theme.typography.pxToRem(22)} ${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(25)}`
  }
});

class VideoResources extends React.Component {

  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.meta = new MetadataService();
    this.metadata = {
      ...this.meta.get('video_resource'),
      thumbnail_image_url: {
        label: 'Thumbnail Image',
        type: 'link',
        label_field: 'thumbnail_image_filename'
      }
    };
    this.state = {
      id: null,
      mode: null,
      open: false,
      refreshTick: 0
    };
  }

  forceRefresh = () => {
    this.setState({ refreshTick: Date.now() });
  };

  handleCloseModal = (save) => {
    if(save) {
      this.setState({ mode: null, open: false, id: null });
      this.forceRefresh();
    } else {
      this.setState({ mode: null, open: false, id: null });
    }
  };

  handleOpenAddModal = () => {
    this.setState({ mode: 'add', open: true, id: null });
  };

  handleOpenEditModal = (row) => {
    this.setState({ mode: 'edit', open: true, id: row.id });
  };

  updateDisplayOrder = (rows) => {
    var n = 1;
    const data = rows.map(row => {
      return {id: row.id, display_order: n++};
    });
    this.api.put(`/api/v1/video_resources.json`, { video_resources: data });
  }

  render() {
    const { classes } = this.props;
    const { id, mode, open } = this.state;
    const fields = ['category', 'title', 'duration', 'thumbnail_image_url', 'active'];
    const actions = ['move_up', 'move_down', 'edit', 'delete'];

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper>
            <Grid
              container
              className={classes.videoResourcesHeader}
              justifyContent="space-between"
            >
              <Typography variant="h6">
                Video Resources
              </Typography>
              <Button
                color="secondary"
                onClick={this.handleOpenAddModal}
                size="small"
                variant="contained"
              >
                <Typography variant="button">Add</Typography>
              </Button>
            </Grid>
            <AdminTable
              tableBottomPadding
              actionsSection={actions}
              fields={fields}
              metadata={this.metadata}
              onOpenEditModal={this.handleOpenEditModal}
              refreshTick={this.state.refreshTick}
              resource="video_resources"
              onReOrder={this.updateDisplayOrder}
            />
          </Paper>
        </Grid>
        <AdminModal
          component={VideoResourcesForm}
          fields={fields}
          id={id}
          mode={mode}
          onCloseModal={this.handleCloseModal}
          onForceRefresh={this.forceRefresh}
          open={open}
          resource="video_resources"
          title="Video Resources"
        />
      </Grid>
    );
  }
}

export default withStyles(VideoResources, styles, { withTheme: true });
