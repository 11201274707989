import React, {useCallback} from 'react';
import { withStyles } from 'tss-react/mui';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const styles = theme => ({
  csvFile: {
    paddingLeft: theme.typography.pxToRem(15)
  },
  fileImportHeaders: {
    paddingBottom: theme.typography.pxToRem(15)
  },
  fileUploadContainer: {
    padding: `${theme.typography.pxToRem(25)} 0 ${theme.typography.pxToRem(35)} ${theme.typography.pxToRem(25)}`
  },
  hideInput: {
    display: 'none'
  },
});

function ImportPointsForm({ classes, file, onFileChange }) {

  const handleFileChange = useCallback((event) => {
    onFileChange(event.target.files[0]);
    event.target.value = null;
  })

  return (
    <Paper className={classes.fileUploadContainer}>
      <Typography className={classes.fileImportHeaders} variant="h6">
        CSV File
      </Typography>
      <Grid container alignItems="center">
        <Button
          color="secondary"
          component="label"
          size="small"
          variant="contained"
        >
          Upload
          <input
            className={classes.hideInput}
            accept="text/csv"
            onChange={handleFileChange}
            type="file"
          />
        </Button>
        {file !== null && (
          <Typography className={classes.csvFile} variant="h6">
            {file.name}
          </Typography>
        )}
      </Grid>
    </Paper>
  );
}

export default withStyles(ImportPointsForm, styles, { withTheme: true });
