import { attendanceStyles } from './style';
import React from 'react';
import { DefaultModal } from '@common/components/default-modal';
import { DefaultButton } from '@common/components/default-button';
import { withStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';

const AttendanceProgramIncompleteModal = ({ onSubmit, classes, ...props }) => (
  <DefaultModal
    {...props}
    action={
      <DefaultButton
        containerClass={classes.markEducatorIncomplete}
        color="secondary"
        onClick={onSubmit}
        size="small"
        variant="contained"
      >
        Remove
      </DefaultButton>
    }
    title="Mark user as Incomplete"
  >
    <Typography className={classes.incompletEducatorModalHelper}>
      This will remove any points this user has received from completing
      this program, and will remove it from their program history!
    </Typography>
  </DefaultModal>
);

const StyledAttendanceProgramIncompleteModal = withStyles(AttendanceProgramIncompleteModal, attendanceStyles, {
  withTheme: true
});

export {
  StyledAttendanceProgramIncompleteModal as AttendanceProgramIncompleteModal
};
