import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

import ApiService from '../../../common/services/ApiService';
import BusinessIdealsForm from './BusinessIdealsForm';
import StyledDivider from '../../../common/components/misc/StyledDivider';

const styles = theme => ({
  currentDomain: {
    fontFamily: 'IBM Plex Sans, semi-bold',
    fontSize: theme.typography.pxToRem(13),
    fontWeight: 600,
    lineHeight: 2.15,
    marginBottom: theme.typography.pxToRem(16),
    textTransform: 'uppercase',
  },
  domainName: {
    marginTop: theme.typography.pxToRem(15),
    marginBottom: theme.typography.pxToRem(25),
  },
  domainNavButtons: {
    padding: `${theme.typography.pxToRem(25)} 0`,
  },
  progressBar: {
    borderRadius: theme.typography.pxToRem(100),
  },
  progressText: {
    fontFamily: 'IBM Plex Sans, medium',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500,
    opacity: 0.7,
  },
});

class ManageBusinessIdeals extends React.Component {

  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.state = {
      allDomains: [],
      currentDomain: null,
      domainIndex: 0,
      progressBar: 0,
      responsesList: [],
    };
  }

  componentDidMount() {
    this.getDomainsList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedDepartment.id !== this.props.selectedDepartment.id) {
      this.refreshData(this.state.currentDomain.id);
    }
    if (prevProps.employmentType.id !== this.props.employmentType.id) {
      this.refreshData(this.state.currentDomain.id);
    }
  }

  getDomainsList = () => {
    this.api.query('/api/v1/domains').then((json) => {
      const domainsList = json.data.filter(domain => domain.active);
      const currentDomain = domainsList[0];
      this.setState({
        allDomains: domainsList,
        domainIndex: 0
      });
      this.getFormData(currentDomain.id);
    }).catch(this.processError);
  };

  getFormData = (currentDomainId) => {
    const getQuestionsPromise = this.api.query(`/api/v1/domains/${currentDomainId}`);
    const getResponsesPromise = this.api.query(`/api/v1/response_summary?mode=bi&department_id=${this.props.selectedDepartment.id}&employment_type_id=${this.props.employmentType.id}&domain_id=${currentDomainId}`);
    Promise.all([getQuestionsPromise, getResponsesPromise]).then(([domainData, responses]) => {
      this.setState({
        currentDomain: domainData.data,
        progressBar: responses.meta.business_ideal_completed_percent,
        responsesList: responses.data,
      });
      this.props.onDataLoaded();
    }).catch(this.processError);
  };

  getNextDomain = () => {
    var newDomainIndex = this.state.domainIndex + 1;
    this.setState({ domainIndex: newDomainIndex });
    this.getFormData(this.state.allDomains[newDomainIndex].id);
    this.scrollToTop();
  };

  getPreviousDomain = () => {
    var newDomainIndex = this.state.domainIndex - 1;
    this.setState({ domainIndex: newDomainIndex });
    this.getFormData(this.state.allDomains[newDomainIndex].id);
    this.scrollToTop();
  };

  refreshData = (domainId) => {
    this.props.onReloadData();
    this.getFormData(domainId);
  };

  scrollToTop = () => {
    window.scroll({
      top: 100,
      behavior: "smooth",
    });
  };

  updateProgressBar = (updatedPercentage) => {
    this.setState({ progressBar: updatedPercentage });
  };

  updateResponses = (response, currentResponses) => {
    var capabilityId = response.capability_id;
    var responseType = response.type;
    var value = response.value;

    currentResponses[capabilityId] === undefined ?
    currentResponses = Object.assign({[capabilityId]:
      {[responseType]: value}}, currentResponses) :
      currentResponses[capabilityId][responseType] = value;
    this.setState({ responsesList: currentResponses });
  };

  render() {
    const { classes, employmentType, selectedDepartment } = this.props;
    const { allDomains, currentDomain, domainIndex, progressBar, responsesList } = this.state;
    var showPreviousDomain, showNextDomain = 'visible';
    domainIndex === 0 ? showPreviousDomain = 'hidden' : showPreviousDomain = 'visible';
    domainIndex === (allDomains.length - 1) ? showNextDomain = 'hidden' : showNextDomain = 'visible';

    return (
      <Fragment>
        <Grid container alignItems="center">
          <Grid item xs={11}>
            <LinearProgress
              className={classes.progressBar}
              color="secondary"
              value={progressBar}
              valueBuffer={100}
              variant="determinate"
            />
          </Grid>
          <Grid item xs={1}>
            <Typography align="right" className={classes.progressText}>
              {`${progressBar}%`}
            </Typography>
          </Grid>
        </Grid>
        <Typography className={classes.domainName} variant="h6">
          {`${selectedDepartment.label} ${employmentType.label}`}
        </Typography>
        <StyledDivider />
        {currentDomain &&
          <BusinessIdealsForm
            currentDomain={currentDomain}
            employmentType={employmentType}
            responsesList={responsesList}
            selectedDepartment={selectedDepartment}
            updateProgressBar={this.updateProgressBar}
            updateResponses={this.updateResponses}
            responseLabels={this.props.responseLabels}
          />
        }
        <Grid
          container
          className={classes.domainNavButtons}
          justifyContent="space-between"
        >
          <Button
            color="secondary"
            disabled={domainIndex === 0}
            onClick={(event) => this.getPreviousDomain()}
            style={{visibility: showPreviousDomain}}
            variant="contained"
          >
            <Typography variant="button">Previous Domain</Typography>
          </Button>
          <Button
            color="secondary"
            disabled={domainIndex === (allDomains.length - 1)}
            onClick={(event) => this.getNextDomain()}
            style={{visibility: showNextDomain}}
            variant="contained"
          >
            <Typography variant="button">Next Domain</Typography>
          </Button>
        </Grid>
      </Fragment>
    );
  }

}

export default withStyles(ManageBusinessIdeals, styles, { withTheme: true });
