import React from 'react';
import { withStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import AdminTable from '../../../common/components/table/AdminTable';
import ApiService from '../../../common/services/ApiService';
import MetadataService from '../../../common/services/MetadataService';
import PassportFilters from './PassportFilters';

const styles = (theme) => ({
  exportHeader: {
    padding: `${theme.typography.pxToRem(22)} ${theme.typography.pxToRem(
      25
    )} ${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(25)}`
  }
});

class Passports extends React.Component {
  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.meta = new MetadataService();
    this.state = {
      filters: null,
      order: null,
      orderBy: null,
      isFilterOptionFetched: false
    };
  }

  handleFetchOptionFilters = () => {
    this.setState({ isFilterOptionFetched: true });
  };

  handleApplyFilters = (filters) => {
    this.setState({ filters: filters });
  };

  handleApplySorting = (order, orderBy) => {
    this.setState({ order: order, orderBy: orderBy });
  };

  handleEducatorCellClicked = (row) => {
    this.props.history.push('/users/' + row.user_id);
  };

  handleEducatorProfileCellClicked = (row) => {
    this.props.history.push('/profiling/' + row.user_id);
  };

  handleExport = () => {
    var sortingParams = {
      direction: this.state.order,
      field: this.state.orderBy
    };
    var searchRequest = this.api.searchUrl(
      this.state.filters,
      null,
      null,
      sortingParams,
      null
    );

    var apiUrl = '';
    searchRequest !== ''
      ? (apiUrl = '/api/v1/reports/passport.csv?')
      : (apiUrl = '/api/v1/reports/passport.csv');
    var csvUrl = apiUrl + searchRequest;
    return csvUrl;
  };

  render() {
    const { classes, institute } = this.props;
    const { filters, isFilterOptionFetched } = this.state;

    // No data for passport obtained date yet
    // Include supervisor for now for testing purposes
    const fields = [
      'passport_status',
      'user_full_name',
      'department_name',
      'employment_type_name',
      institute.custom_user_field_1_enabled ? 'custom_1' : null,
      institute.custom_user_field_2_enabled ? 'custom_2' : null,
      'professional_points_combined',
      'vocational_points_combined',
      'learning_journeys_combined',
      'vocational_learning_journeys_combined',
      'profile_status'
    ].filter(Boolean);

    return (
      <Grid container className={classes.passportReport} spacing={2}>
        <Grid item xs={12}>
          <Paper>
            <PassportFilters
              onOptionsFetched={this.handleFetchOptionFilters}
              handleApplyFilters={this.handleApplyFilters}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Grid
              container
              className={classes.exportHeader}
              justifyContent="space-between"
            >
              <Typography variant="h6">Passports</Typography>
              <a href={`${this.handleExport()}`} target="_blank">
                <Button color="secondary" size="small" variant="contained">
                  <Typography variant="button">Export</Typography>
                </Button>
              </a>
            </Grid>
            {/** Don't display the admin table until all the filter option is fetched */}
            {isFilterOptionFetched && (
              <AdminTable
                showTablePagination
                fields={fields}
                filters={filters}
                handleApplySorting={this.handleApplySorting}
                metadata={this.meta.getWithInstituteContext('passports', institute)}
                onCellClicked={{
                  user_full_name: this.handleEducatorCellClicked,
                  professional_points: this.handleEducatorCellClicked,
                  vocational_points: this.handleEducatorCellClicked,
                  profile_status: this.handleEducatorProfileCellClicked
                }}
                resource="reports/passport"
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(Passports, styles, { withTheme: true });
