import { useMemo, useEffect } from 'react';

export default function useUppy(factory) {
  const uppy = useMemo(factory, []);

  useEffect(() => {
    return () => uppy.close();
  }, [uppy]);

  return uppy;
}
