import React from 'react';
import format from 'date-fns/format';
import { withStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import EventIcon from '@mui/icons-material/Event';
import { DatePicker } from '@mui/x-date-pickers';
import TrashIcon from '@common/icons/TrashIcon';
import Autocomplete from '@common/components/autocomplete/Autocomplete';
import Chisholm from '@common/config/Chisholm';
import MetadataService from '@common/services/MetadataService';
import TooltipTitle from '@common/config/Tooltip/TooltipTitle';
import WaveSelectField from '@common/components/form/WaveSelectField';
import WaveTextArea from '@common/components/form/WaveTextArea';
import WaveTextField from '@common/components/form/WaveTextField';
import WorkshopFields from '@admin/components/programs/WorkshopFields';
import PdfUpload from '@admin/components/programs/PdfUpload';
import { programFormStyles } from './styles';
import { WaveDatePicker } from '../../../../common/components/WaveDatePicker';

class ProgramForm extends React.Component {
  constructor(props) {
    super(props);
    this.meta = new MetadataService();
    this.state = {
      userId: this.props.program.facilitator_id,
      userLabel: this.props.program.facilitator_full_name
    };
  }

  addWorkshop = () => {
    const program = { ...this.props.program };

    program.workshops.push({ name: '' });
    this.props.onProgramChange(program);
  };

  errorHelpers = (field) => {
    if (this.props.validationErrors.hasOwnProperty(field)) {
      return this.props.validationErrors[field][0];
    }
    return null;
  };

  errorStatus = (field) => {
    return this.props.validationErrors.hasOwnProperty(field);
  };

  handleAutocompleteChange = (id, value) => {
    this.setState({ userId: id, userLabel: value });

    let program = { ...this.props.program };
    program['facilitator_id'] = id;

    this.props.onProgramChange(program);
  };

  handleDateChange = (fieldName, date) => {
    const convertedDate =
      date == null ? null : format(new Date(date), 'yyyy-MM-dd');
    let program = { ...this.props.program };
    program[fieldName] = convertedDate;

    this.props.onProgramChange(program);
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let program = { ...this.props.program };
    program[name] = value;

    this.props.onProgramChange(program);
  };

  handleWorkshopChange = (newWorkshop, index) => {
    let program = { ...this.props.program };
    program.workshops[index] = newWorkshop;

    this.props.onProgramChange(program);
  };

  makeFieldProps = (field) => {
    return {
      error: this.errorStatus(field),
      helperText: this.errorHelpers(field),
      name: field,
      onChange: this.handleInputChange,
      value: this.props.program[field]
    };
  };

  removeWorkshop = (index) => {
    let program = { ...this.props.program };
    program.workshops[index]._destroy = true;
    this.props.onProgramChange(program);
  };

  renderWorkshops = () => {
    const { classes } = this.props;
    var workshopNumber = 0;
    return this.props.program.workshops.map((workshop, index) => {
      if (workshop.hasOwnProperty('_destroy') && workshop._destroy) {
        return null;
      }

      workshopNumber++;

      // validationErrors exists?
      // Trying to access workshop in array doesnt exist?
      // --> Always returns empty object
      const getValidationErrors = this.props.validationErrors;
      const workshopValidationErrors = getValidationErrors.hasOwnProperty(
        'workshops'
      )
        ? getValidationErrors.workshops[index] || {}
        : {};

      return (
        <div className={classes.workshopContainer} key={index + 1}>
          <Typography className={classes.sectionHeaders}>
            Workshop {workshopNumber}
            <a
              onClick={() => {
                this.removeWorkshop(index);
              }}
            >
              <TrashIcon className={classes.deleteWorkshopIcon} />
            </a>
          </Typography>
          <div>
            <WorkshopFields
              locationOptions={this.props.locationOptions}
              onWorkshopChange={(workshop) =>
                this.handleWorkshopChange(workshop, index)
              }
              validationErrors={workshopValidationErrors}
              workshop={workshop}
            />
          </div>
          <Divider />
        </div>
      );
    });
  };

  render() {
    const { classes, program } = this.props;
    const getFields = this.meta.get('programs');
    const programTypeFields = getFields.program_type.options;
    const domainFields = this.props.domainOptions;
    const categoryFields = this.props.categoryOptions;
    const registrationsOpenOptions = getFields.registrations_open.options;

    if (program == null) {
      return <div>No Program</div>;
    }

    const shouldHideDomain = [
      'vocational_learning_journey',
      'vocational_elective'
    ].includes(program.program_type);

    return (
      <Paper className={classes.programFormContainer}>
        <Typography className={classes.sectionHeaders}>
          Program Details
        </Typography>
        <form noValidate autoComplete="off">
          <Grid container className={classes.formContainer}>
            <Grid item xs={6} className={classes.formLeft}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <WaveTextField
                    label="Program Name"
                    maxLength={150}
                    {...this.makeFieldProps('name')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <WaveTextField
                    label="Reporting Code"
                    {...this.makeFieldProps('code')}
                  />
                </Grid>

                <Grid item xs={6}>
                  <WaveSelectField
                    label="Program Type"
                    menuItems={programTypeFields}
                    {...this.makeFieldProps('program_type')}
                  />
                </Grid>
                {shouldHideDomain ? null : (
                  <Grid item xs={6}>
                    <WaveSelectField
                      emptyMenuItem
                      label="Domain"
                      menuItems={domainFields}
                      {...this.makeFieldProps('domain_id')}
                      error={this.errorStatus('domain')}
                      helperText={this.errorHelpers('domain')}
                    />
                  </Grid>
                )}
                <Grid item xs={6}>
                    <WaveSelectField
                      emptyMenuItem
                      emptyMenuItemLabel="None"
                      label="Category"
                      menuItems={categoryFields}
                      {...this.makeFieldProps('category_id')}
                      error={this.errorStatus('category')}
                      helperText={this.errorHelpers('category')}
                    />
                  </Grid>
                <Grid item xs={6}>
                  <WaveDatePicker
                    className={[
                      this.errorStatus('start_date')
                        ? ''
                        : classes.errorSpacing,
                      classes.ieFix
                    ].join(' ')}
                    error={this.errorStatus('start_date')}
                    format={Chisholm.datePicker}
                    helperText={this.errorHelpers('start_date')}
                    label="Program Start Date"
                    name="start_date"
                    onChange={(value) =>
                      this.handleDateChange('start_date', value)
                    }
                    value={program.start_date}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    error={this.errorStatus('facilitator')}
                    errorSpacing={classes.errorSpacing}
                    helperText={this.errorHelpers('facilitator')}
                    label="Facilitator"
                    name="facilitator_id"
                    onChange={this.handleAutocompleteChange}
                    role={'facilitator'}
                    tooltipProps={{
                      placement: 'top',
                      titleComponent: (
                        <Typography className={classes.tooltipText}>
                          {TooltipTitle.facilitator}
                        </Typography>
                      ),
                      tooltipContainer: classes.tooltipContainer,
                      tooltipPopper: classes.popper
                    }}
                    value={this.state.userLabel || ''}
                  />
                </Grid>
                <Grid item xs={6}>
                  <WaveTextField
                    label="Maximum Attendance"
                    {...this.makeFieldProps('attendee_limit')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <WaveSelectField
                    label="Registrations Open"
                    menuItems={registrationsOpenOptions}
                    {...this.makeFieldProps('registrations_open')}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.formRight}>
              <Grid container className={classes.textAreaContainer} spacing={3}>
                <Grid item xs={12}>
                  <WaveTextArea
                    label="Program Description"
                    maxLength={2000}
                    rows={6}
                    {...this.makeFieldProps('description')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <WaveTextArea
                    label="Summary Description"
                    maxLength={1000}
                    rows={6}
                    tooltipProps={{
                      placement: 'top',
                      titleComponent: (
                        <Typography className={classes.tooltipText}>
                          {TooltipTitle.summaryDescription}
                        </Typography>
                      ),
                      tooltipContainer: classes.tooltipContainer,
                      tooltipPopper: classes.popper
                    }}
                    {...this.makeFieldProps('summary_description')}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider />

          {this.renderWorkshops()}

          {this.props.editEnabled && (
            <Button
              className={classes.addWorkshopBtn}
              color="secondary"
              onClick={this.addWorkshop}
              variant="contained"
            >
              <Typography variant="button">Add Workshop</Typography>
            </Button>
          )}

          <Typography className={classes.sectionHeaders}>
            Contact Information
          </Typography>

          <Grid container spacing={5} mb={2}>
            <Grid item xs={3}>
              <WaveTextField
                label="Contact Name"
                {...this.makeFieldProps('contact_name')}
              />
            </Grid>
            <Grid item xs={3}>
              <WaveTextField
                label="Job Title or Description"
                {...this.makeFieldProps('contact_title')}
              />
            </Grid>
            <Grid item xs={3}>
              <WaveTextField
                label="Email"
                type="email"
                {...this.makeFieldProps('contact_email')}
              />
            </Grid>
            <Grid item xs={3}>
              <WaveTextField
                label="Phone Number or Extension"
                {...this.makeFieldProps('contact_phone')}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <Typography className={classes.sectionHeaders}>
                Upload PDF (max 10MB)
              </Typography>

              <PdfUpload
                program={this.props.program}
                onProgramPdfChange={this.props.onProgramPdfChange}
                editEnabled={this.props.editEnabled}
                fieldErrorMessage={this.errorHelpers('pdf_filename')}
              />
            </Grid>
          </Grid>
        </form>
      </Paper>
    );
  }
}

const StyledProgramForm = withStyles(ProgramForm, programFormStyles, {
  withTheme: true
});

export { StyledProgramForm as ProgramForm };
