export const attendanceStyles = theme => ({
  attendanceCheckbox: {
    textAlign: 'center'
  },
  attendanceTitle: {
    paddingTop: theme.typography.pxToRem(25),
    paddingLeft: theme.typography.pxToRem(25)
  },
  closeModal: {
    color: theme.typography.color.primary,
    cursor: 'pointer',
    opacity: 0.3
  },
  completedCheckbox: {
    paddingLeft: theme.typography.pxToRem(100),
    textAlign: 'center'
  },
  incompletEducatorModal: {
    backgroundColor: theme.palette.background.paper,
    left: '50%',
    margin: '0',
    padding: `${theme.typography.pxToRem(24)} ${theme.typography.pxToRem(29)}`,
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%'
  },
  incompletEducatorModalHeader: {
    marginBottom: theme.typography.pxToRem(22)
  },
  incompletEducatorModalHelper: {
    color: '#1f1f1fde',
    fontSize: theme.typography.pxToRem(15),
    lineHeight: 1.6,
    marginBottom: theme.typography.pxToRem(15)
  },
  registrationsTableWrapper: {
    overflowX: 'auto',
    paddingBottom: theme.typography.pxToRem(16.5)
  },
  markEducatorIncomplete: {
    marginTop: theme.typography.pxToRem(15)
  }
});
