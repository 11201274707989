import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import { NotifyAttendeesModal } from './NotifyAttendeesModal';
import AlertDialog from '@common/components/AlertDialog';
import { programFormActionsStyles } from './styles';
import { DefaultButton } from '@common/components/default-button';
import { NotifyEducatorsModal } from './NotifyEducatorsModal';
import { ProgramService } from '@common/services/ProgramService';

class ProgramFormActions extends React.Component {
  programService = new ProgramService();

  state = {
    alertOpen: false,
    message: '',
    open: false,
    title: 'Confirm',
    isNotifyEducatorsModalOpened: false,
    isSubmittingEducatorNotifications: false,
    isNotifyAttendeesModalOpened: false,
    isSubmittingAttendeesNotifications: false,
    responseMessage: ''
  };

  get programEducatorNotificationMessage() {
    const { program } = this.props;

    if (!program) {
      return;
    }

    return `${
      program.name
    } Program has been added to the Educator Passport. Open the app to register!`;
  }

  shouldSaveProgramFirst = () => {
    if (this.props.dirty) {
      this.setState({
        alertOpen: true,
        message: 'Please save first',
        title: 'Unsaved program'
      });
    }

    return this.props.dirty;
  };

  handleDuplicate = () => {
    if (this.shouldSaveProgramFirst()) {
      return;
    }

    this.props.onDuplicate();
  };

  handleOk = () => {
    this.setState({ alertOpen: false });
  };

  handlePublish = () => {
    if (this.shouldSaveProgramFirst()) {
      return;
    }

    const { onPublish, published } = this.props;

    onPublish(!published);
  };

  handleOpenNotifyAttendeesModal = () => {
    this.setState({
      isNotifyAttendeesModalOpened: true
    });
  };

  handleCloseNotifyAttendeesModal = () => {
    this.setState({
      isNotifyAttendeesModalOpened: false
    });
  };

  handleSubmitAttendeesNotification = async message => {
    this.setState({
      isSubmittingAttendeesNotifications: true
    });

    const { program } = this.props;

    try {
      const res = await this.programService.notifyAttendees(program.id, {
        message
      });

      this.setState({
        responseMessage: res.json.message
      });
    } catch (error) {
      this.setState({
        responseMessage: 'Error: ' + error
      });
    } finally {
      this.setState({
        isSendingAttendeesNotification: false,
        isNotifyAttendeesModalOpened: false
      });
    }
  };

  handleOpenNotifyEducatorsModal = () => {
    this.setState({
      isNotifyEducatorsModalOpened: true
    });
  };

  handleCloseNotifyEducatorsModal = () => {
    this.setState({
      isNotifyEducatorsModalOpened: false
    });
  };

  handleSubmitEducatorNotification = async (message) => {
    this.setState({
      isSubmittingEducatorNotifications: true
    });

    const { program } = this.props;

    try {
      const res = await this.programService.notifyEducators(program.id, {
        message: message
      });

      this.setState({
        responseMessage: res.json.message
      });
    } catch (error) {
      this.setState({
        responseMessage: 'Error: ' + error
      });
    } finally {
      this.setState({
        isSubmittingEducatorNotifications: false,
        isNotifyEducatorsModalOpened: false
      });
    }
  };

  handleSnackbarClose = () => {
    this.setState({ responseMessage: undefined });
  };

  render() {
    const {
      classes,
      errorMessage,
      published,
      countRegistrations,
      onDelete,
      program,
      dirty,
      onSave
    } = this.props;

    const {
      isNotifyEducatorsModalOpened,
      isNotifyAttendeesModalOpened,
      isSubmittingAttendeesNotifications,
      isSubmittingEducatorNotifications,
      responseMessage
    } = this.state;

    return (
      <Fragment>
        <Grid
          container
          className={classes.formActionsContainer}
          justifyContent="flex-end"
        >
          {program && program.id && (
            <Fragment>
              {published && (
                <DefaultButton
                  containerClass={classes.flatProgramFormButtons}
                  titleClass={classes.programButtonText}
                  onClick={this.handleOpenNotifyEducatorsModal}
                >
                  Notify Educators
                </DefaultButton>
              )}
              {published && countRegistrations > 0 && (
                <DefaultButton
                  containerClass={classes.flatProgramFormButtons}
                  titleClass={classes.programButtonText}
                  onClick={this.handleOpenNotifyAttendeesModal}
                >
                  Notify Attendees
                </DefaultButton>
              )}
              <DefaultButton
                containerClass={classes.flatProgramFormButtons}
                titleClass={classes.programButtonText}
                onClick={this.handleDuplicate}
              >
                Duplicate
              </DefaultButton>
              {!published && (
                <DefaultButton
                  containerClass={classes.flatProgramFormButtons}
                  titleClass={classes.programButtonText}
                  onClick={onDelete}
                >
                  Delete
                </DefaultButton>
              )}
              <DefaultButton
                containerClass={published ? classes.flatProgramFormButtons : ''}
                titleClass={published ? classes.programButtonText : ''}
                color={published ? 'primary' : 'secondary'}
                onClick={this.handlePublish}
                variant={published ? 'text' : 'contained'}
              >
                {published ? 'Unpublish' : 'Publish'}
              </DefaultButton>
            </Fragment>
          )}
          <DefaultButton
            containerClass={!published ? classes.raisedProgramFormButtons : ''}
            onClick={onSave}
            disabled={!dirty}
            color="secondary"
            size="small"
            variant="contained"
          >
            {published ? 'Save' : 'Save Draft'}
          </DefaultButton>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Typography className={classes.errorMessages} color="error">
            {errorMessage}
          </Typography>
          <AlertDialog
            message={this.state.message}
            onOk={this.handleOk}
            open={this.state.alertOpen}
            title={this.state.title}
          />
        </Grid>
        {
          program &&
          <NotifyEducatorsModal
            isOpened={isNotifyEducatorsModalOpened}
            isLoading={isSubmittingEducatorNotifications}
            onSubmit={this.handleSubmitEducatorNotification}
            onClose={this.handleCloseNotifyEducatorsModal}
            message={this.programEducatorNotificationMessage}
            />
        }
        <NotifyAttendeesModal
          isOpened={isNotifyAttendeesModalOpened}
          isLoading={isSubmittingAttendeesNotifications}
          onSubmit={this.handleSubmitAttendeesNotification}
          onClose={this.handleCloseNotifyAttendeesModal}
        />
        <Snackbar
          anchorOrigin={{
            horizontal: 'left',
            vertical: 'bottom'
          }}
          autoHideDuration={4000}
          message={<span>{responseMessage}</span>}
          onClose={this.handleSnackbarClose}
          open={!!responseMessage}
        />
      </Fragment>
    );
  }
}

const StyledProgramFormActions = withStyles(ProgramFormActions, programFormActionsStyles, {
  withTheme: true
});

export { StyledProgramFormActions as ProgramFormActions };
