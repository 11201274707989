import React from 'react';
import { withStyles } from 'tss-react/mui';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import Autocomplete from '../../../common/components/autocomplete/Autocomplete';
import UserRoles from './UserRoles';
import WaveSelectField from '../../../common/components/form/WaveSelectField';
import WaveTextField from '../../../common/components/form/WaveTextField';

const styles = theme => ({
  adjustedSpacing: {
    padding: `${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(20)} 0 ${theme.typography.pxToRem(20)}`,
  },
  adjustedSpacingLeft: {
    padding: `${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(20)} 0 0`,
  },
  adjustedSpacingRight: {
    padding: `${theme.typography.pxToRem(20)} 0 0 ${theme.typography.pxToRem(20)}`,
  },
  checkboxField: {
    marginRight: theme.typography.pxToRem(38),
  },
  checkboxLabel: {
    fontSize: theme.typography.pxToRem(12),
  },
  errorSpacing: {
    marginBottom: theme.typography.pxToRem(20),
  },
  userFormContainer: {
    padding: theme.typography.pxToRem(25),
  },
});

class UserForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      supervisorId: this.props.user.supervisor_id,
      supervisorLabel: this.props.user.supervisor && this.props.user.supervisor.full_name,
    };
  }

  errorHelpers = (field) => {
    if (this.props.validationErrors.hasOwnProperty(field)) {
      return this.props.validationErrors[field][0];
    }
    var altField = field.replace(/_id$/, '');
    if (this.props.validationErrors.hasOwnProperty(altField)) {
      return this.props.validationErrors[altField][0];
    }
    return null;
  };

  errorStatus = (field) => {
    return this.props.validationErrors.hasOwnProperty(field) || this.props.validationErrors.hasOwnProperty(field.replace(/_id$/, ''));
  };

  handleAutocompleteChange = (id, value) => {
    this.setState({ supervisorId: id, supervisorLabel: value });

    let user = { ...this.props.user };
    user['supervisor_id'] = id;

    this.props.onUserChange(user);
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let user = { ...this.props.user };
    user[name] = value;

    this.props.onUserChange(user);
  };

  makeFieldProps = (field) => {
    return {
      error: this.errorStatus(field),
      helperText: this.errorHelpers(field),
      name: field,
      onChange: this.handleInputChange,
      value: this.props.user[field],
    };
  };

  render() {
    const { classes, user } = this.props;
    const departmentFields = this.props.departmentFields;
    const employmentTypeFields = this.props.employmentTypeFields;

    if (user == null) {
      return (<div>No User</div>);
    }

    return (
      <Paper className={classes.userFormContainer}>
        <Typography variant="h6">User Information</Typography>
        <form noValidate autoComplete="off">
          <Grid container spacing={2} mb={2}>
            <Grid item className={classes.adjustedSpacingLeft} xs={3}>
              <WaveTextField
                label="Employee No."
                {...this.makeFieldProps('unique_identifier')}
              />
            </Grid>
            <Grid item className={classes.adjustedSpacing} xs={3}>
              <WaveTextField
                label="Given Name"
                {...this.makeFieldProps('given_name')}
              />
            </Grid>
            <Grid item className={classes.adjustedSpacing} xs={3}>
              <WaveTextField
                label="Family Name"
                {...this.makeFieldProps('family_name')}
              />
            </Grid>
            <Grid item className={classes.adjustedSpacingRight} xs={3}>
              <WaveSelectField
                emptyMenuItem
                label="Employment Type"
                menuItems={employmentTypeFields}
                {...this.makeFieldProps('employment_type_id')}
              />
            </Grid>
            <Grid item className={[classes.adjustedSpacingLeft, classes.errorSpacing]} xs={3}>
              <WaveSelectField
                emptyMenuItem
                label="Department"
                menuItems={departmentFields}
                {...this.makeFieldProps('department_id')}
              />
            </Grid>
            {user.is_educator || user.is_guest ?
              <Grid item className={classes.adjustedSpacing} xs={3}>
                <Autocomplete
                  error={this.errorStatus('supervisor')}
                  errorSpacing={classes.errorSpacing}
                  helperText={this.errorHelpers('supervisor')}
                  label="Supervisor"
                  name="supervisor_id"
                  onChange={this.handleAutocompleteChange}
                  role={'supervisor'}
                  value={this.state.supervisorLabel || ''}
                />
              </Grid>
            : ''}
            <Grid item className={classes.adjustedSpacing} xs={3}>
              <WaveTextField
                label="Email"
                type="email"
                {...this.makeFieldProps('email')}
              />
            </Grid>
            {this.props.institute.custom_user_field_1_enabled ? 
            (
              <Grid item className={classes.adjustedSpacing} xs={3}>
              <WaveTextField
                label={this.props.institute.custom_user_field_1}
                {...this.makeFieldProps('custom_1')}
              />
              </Grid>
            )
            : null}

            {this.props.institute.custom_user_field_2_enabled ? 
              (
                <Grid item className={classes.adjustedSpacing} xs={3}>
                <WaveTextField
                label={this.props.institute.custom_user_field_2}
                  {...this.makeFieldProps('custom_2')}
                />
                </Grid>
              )
              : null}
          </Grid>
          <Grid>
            <Grid item xs={12}>
              <UserRoles
                classes={classes}
                error={this.errorStatus('user_roles')}
                handleInputChange={this.handleInputChange}
                helperText={this.errorHelpers('user_roles')}
                user={user}
              />
            </Grid>
          </Grid>
        </form>
      </Paper>
    )
  }

}

export default withStyles(UserForm, styles, { withTheme: true });
