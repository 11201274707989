import React from 'react';
import Typography from '@mui/material/Typography';

export class StrikethroughCell extends React.Component {

  render() {
    const { attribute, classes, row } = this.props;
    const value = row[attribute];

    const strikethrough = !row['user_active'];
    const strikethroughStyle = strikethrough ? {textDecoration: 'line-through'} : null;

    return (<Typography className={classes.tableCell}>
      <span style={{...strikethroughStyle, whiteSpace: 'nowrap'}}>{value}</span>
    </Typography>);
  }

}
