import React, { useCallback, useState } from 'react';
import { withStyles } from 'tss-react/mui';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import ApiService from '@common/services/ApiService';
import ImportPointsForm from './ImportPointsForm';
import ImportPointsSummary from './ImportPointsSummary';
import WaveMultipleFileUploader from '@common/components/form/WaveMultipleFileUploader';
import { MAX_FILE_SIZE } from '@common/constants';
import { formHelperHoc } from '../../../../common/components/form-helper-hoc/FormHelperHoc';
import { transformEvidences } from '@common/services/PointService';

const styles = (theme) => ({
  fileUploadContainer: {
    padding: `${theme.typography.pxToRem(25)} 0 ${theme.typography.pxToRem(
      45
    )} ${theme.typography.pxToRem(25)}`
  },
  importInstructions: {
    padding: `${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(
      334
    )} ${theme.typography.pxToRem(5)} ${theme.typography.pxToRem(25)}`
  },
  importNotesList: {
    listStyle: 'none',
    marginTop: 0,
    marginBottom: theme.typography.pxToRem(40),
    paddingLeft: theme.typography.pxToRem(25)
  },
  listItem: {
    lineHeight: 1.5,
    marginBottom: theme.typography.pxToRem(6),
    paddingLeft: theme.typography.pxToRem(8),
    textIndent: theme.typography.pxToRem(-26)
  },
  notesHeaders: {
    paddingBottom: theme.typography.pxToRem(15)
  },
  notesListSpacing: {
    paddingBottom: theme.typography.pxToRem(10)
  },
  notesSpacing: {
    paddingBottom: theme.typography.pxToRem(40)
  },
  importContainer: {
    marginTop: theme.typography.pxToRem(25),
    marginBottom: theme.typography.pxToRem(53)
  },
  buttonProgressWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '88%',
    marginTop: -12,
    marginLeft: -12
  },
  notesBody: {
    paddingBottom: theme.typography.pxToRem(24)
  },
  uploadImportInstructions: {
    paddingBottom: theme.typography.pxToRem(30),
  }
});

function ImportPointsNoApproval({ classes, theme, makeFieldProps, institute }) {
  const [file, setFile] = useState(null);
  const [evidences, setEvidences] = useState([]);
  const [readyToImport, setReadyToImport] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [importResult, setImportResult] = useState(null);
  const [api, _] = useState(new ApiService());
  const [isUploading, setIsUploading] = useState(false);

  const handleImport = useCallback(() => {
    setIsUploading(true);

    api
      .upload(`/api/v1/points_histories/import_no_approval`, file, 'file', {
        evidences_attributes: JSON.stringify(transformEvidences(evidences))
      })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        setImportResult(json);
        setReadyToImport(true);
        setIsUploading(false);
        if (json.errors && json.errors.length < 1) {
          setFile(null);
          setEvidences([]);
        }
      })
      .catch((e) => {
        setReadyToImport(false);
        setIsUploading(false);
        setErrorMessage(e.message);
      });
  }, [
    api,
    file,
    setImportResult,
    setReadyToImport,
    setErrorMessage,
    evidences,
    setIsUploading
  ]);

  const handleFileChange = useCallback(
    (file) => {
      setFile(file);
      setReadyToImport(true);
      setErrorMessage(null);
      setImportResult(null);
    },
    [setFile, setReadyToImport, setErrorMessage]
  );

  const handlePush = useCallback(({ name, value }) => {
    setEvidences([...evidences, value]);
  }, []);

  const handleRemove = useCallback(
    ({ name, index }) => {
      const array = evidences.slice();
      array.splice(index, 1);
      setEvidences(array);
    },
    [evidences]
  );

  const handleReplace = useCallback(
    ({ index, name, value }) => {
      const array = evidences.slice();
      array.splice(index, 1, value);
      setEvidences(array);
    },
    [evidences]
  );

  return (
    <Grid container className={classes.importContainer} spacing={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Typography variant="h5">
            Import Activities - No Approval
          </Typography>
          <Grid item>
            <div className={classes.buttonProgressWrapper}>
              <Button
                color="secondary"
                disabled={!readyToImport || isUploading}
                onClick={handleImport}
                size="small"
                variant="contained"
              >
                <Typography variant="button">Import</Typography>
              </Button>
              {isUploading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
            {isUploading && (
              <Typography color="textSecondary">
                Processing large imports could take several minutes
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent="flex-end">
          <Typography color="error">{errorMessage}</Typography>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <ImportPointsForm file={file} onFileChange={handleFileChange} />
      </Grid>

      <Grid item xs={12}>
        <Paper className={`${classes.importInstructions} ${classes.uploadImportInstructions}`}>
          <WaveMultipleFileUploader
            label="Evidence file"
            accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            maxFileSize={MAX_FILE_SIZE}
            onPush={handlePush}
            onReplace={handleReplace}
            onRemove={handleRemove}
            style={{ title: { color: '#1f1f1f', marginBottom: '14px' } }}
            {...makeFieldProps('evidences', evidences)}
          />
        </Paper>
      </Grid>

      {importResult && <ImportPointsSummary importResult={importResult} />}

      <Grid item xs={12}>
        <Paper className={classes.importInstructions}>
          <Typography className={classes.notesHeaders} variant="h6">
            Instructions
          </Typography>

          <Typography className={classes.notesBody}>
            This CSV import can be used to import Vocational Currency and
            External Professional Currency. The items will be imported directly
            as Points History. (Please use ‘Import Activities’ if you require an
            approval step). You can also upload one Evidence File (eg flyer)
            that will be attached to each Points History entry under the users.
          </Typography>

          <Typography
            className={classes.notesBody}
            variant="h6"
          >
            Add Evidence is used when one activity is being added for multiple
            users. Evidence File name should reflect the activity. File Types =
            pdf, jpg, png, word, excel
          </Typography>

          <Typography className={classes.notesHeaders} variant="h6">
            Validation Rules
          </Typography>

          <Typography className={classes.notesListSpacing} variant="h6">
            The CSV import must follow these requirements to successfully
            import:
          </Typography>

          <ul className={classes.importNotesList}>
            <li className={classes.listItem}>
              The first row must contain column headers
            </li>
            <li className={classes.listItem}>
              The column headers are: Employee Number, Email Address, Points,
              Points Type, Vocational Activity Type, Domain, Name of Activity,
              Date of Activity, Duration of Activity, Interval of Activity,
              Inform Teaching Practice, Related to Units, Student Benefit,
              Learning Journey Equivalent, Notes
              {institute.requirement_1_name &&
                institute.requirement_1_enabled &&
                `, ${institute.requirement_1_name}`}
              {institute.requirement_2_name &&
                institute.requirement_2_enabled &&
                `, ${institute.requirement_2_name}`}
            </li>
            <li className={classes.listItem}>
              Optional columns may be omitted. For instance if you are uploading
              only Vocational Currency then the Domain column could be omitted
              or you could leave out columns like Inform Teaching Practice as
              they are optional.
            </li>
            <li className={classes.listItem}>Max number of records 2000</li>
            <li className={classes.listItem}>
              Either Employee Number column or Email Address column must be
              present.
              <ul>
                <li className={classes.listItem}>
                  Employee Number must match exactly a User with the Educator
                  role that is already in the system. Multiple rows with the
                  same Employee Number can be entered if there are multiple
                  points/activities being imported for one user. This is a
                  required field unless Email Address is supplied instead.
                </li>
                <li className={classes.listItem}>
                  Email Address must match, case-insensitively, a User with the
                  Educator role that is already in the system. Multiple rows
                  with the same Email Address can be entered if there are
                  multiple points/activities being imported for one user. This
                  is a required field unless Employee Number is supplied
                  instead.
                </li>
              </ul>
            </li>
            <li className={classes.listItem}>
              Points - Number, required, 0 is valid but not blank.
            </li>
            <li className={classes.listItem}>
              Points Type - Must be ‘Vocational Currency' or 'External
              Professional Currency'
            </li>
            <li className={classes.listItem}>
              {`Vocational Activity Type - Must match an item configured in Institute > Vocational Activities, required for Vocational Currency`}
            </li>
            <li className={classes.listItem}>
              {`Domain - Must match an item configured in Institute > Domains, required for External Professional Currency`}
            </li>
            <li className={classes.listItem}>
              Name of Activity - Free text, required
            </li>
            <li className={classes.listItem}>
              Date of Activity - YYYY-MM-DD or dd/mm/YYYY format, optional
            </li>
            <li className={classes.listItem}>
              Duration of Activity - Number, optional
            </li>
            <li className={classes.listItem}>
              Interval of Activity - Must be one of hours, days, weeks, months,
              years; optional
            </li>
            <li className={classes.listItem}>
              Inform Teaching Practice - Free text, 1000 characters, optional
            </li>
            <li className={classes.listItem}>
              Related to Units - Free text, 1000 characters, optional
            </li>
            <li className={classes.listItem}>
              Student Benefit - Free text, 1000 characters, optional
            </li>
            <li className={classes.listItem}>
              Learning Journey Equivalent - 'Y' or 'N', optional, used for
              External Professional Currency only, defaults to 'N'
            </li>
            <li className={classes.listItem}>
              Notes - Free text, 1000 characters, optional
            </li>
            {institute.requirement_1_name && institute.requirement_1_enabled && (
              <li className={classes.listItem}>
                {institute.requirement_1_name}: Optional, Y or N, set to Y if{' '}
                {institute.requirement_1_name} has been completed. (Note: This
                is Requirement 1 setup in Institute {'>'} Additional Reporting
                Requirements)
              </li>
            )}
            {institute.requirement_2_name && institute.requirement_2_enabled && (
              <li className={classes.listItem}>
                {institute.requirement_2_name}: Optional, Y or N, set to Y if{' '}
                {institute.requirement_2_name} has been completed. (Note: This
                is Requirement 2 setup in Institute {'>'} Additional Reporting
                Requirements)
              </li>
            )}
          </ul>
        </Paper>
      </Grid>
    </Grid>
  );
}

const StyledImportPointsNoApproval = formHelperHoc(
  withStyles(ImportPointsNoApproval, styles, {
    withTheme: true
  })
);

export { StyledImportPointsNoApproval as ImportPointsNoApproval };
