import React, { Fragment } from 'react';
import MenuList from '@mui/material/MenuList';

class EducatorProfileList extends React.Component {

  render() {
    const { profiles } = this.props;

    return(
      <Fragment>
        <MenuList disablePadding>
          {this.props.getProfileList(profiles)}
        </MenuList>
      </Fragment>
    );
  }

}

export default EducatorProfileList;
