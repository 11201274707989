export const instituteDetailsStyles = theme => ({
  divider: {
    marginTop: theme.typography.pxToRem(48),
    marginBottom: theme.typography.pxToRem(21)
  },
  formLeft: {
    paddingRight: theme.typography.pxToRem(25),
    paddingBottom: theme.typography.pxToRem(12)
  },
  formRight: {
    paddingLeft: theme.typography.pxToRem(25),
    paddingBottom: theme.typography.pxToRem(12)
  },
  hideInput: {
    display: 'none'
  },
  instituteDetailsContainer: {
    padding: theme.typography.pxToRem(25)
  },
  logoUpload: {
    marginBottom: theme.typography.pxToRem(20)
  },
  popper: {
    opacity: 1
  },
  registrationCutoff: {
    paddingRight: theme.typography.pxToRem(25)
  },
  sectionHeaders: {
    color: theme.typography.color.primary,
    fontSize: theme.typography.pxToRem(13),
    fontWeight: 600,
    lineHeight: 2.15,
    marginBottom: theme.typography.pxToRem(12),
    textTransform: 'uppercase'
  },
  tooltipContainer: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '2px',
    boxShadow: '0 2px 10px 0 #00000028',
    padding: theme.typography.pxToRem(20),
    opacity: 1
  },
  tooltipText: {
    color: '#0c0606de',
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.5
  },
  checkboxField: {
    marginRight: theme.typography.pxToRem(38),
  },
  checkboxLabel: {
    fontSize: theme.typography.pxToRem(12),
  },
});

export const notificationStyles = theme => ({
  panelContainer: {
    marginBottom: theme.typography.pxToRem(20),
    padding: theme.typography.pxToRem(25)
  },
  tooltipContainer: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '2px',
    boxShadow: '0 2px 10px 0 #00000028',
    padding: theme.typography.pxToRem(20),
    opacity: 1
  },
  popper: {
    opacity: 1
  },
  workshopReminder: {
    paddingLeft: theme.typography.pxToRem(25)
  }
});

export const manageInstituteStyles = theme => ({
  defaultTabText: {
    color: theme.typography.color.primary,
    opacity: 0.55,
  },
  newInstituteContainer: {
    marginTop: theme.typography.pxToRem(8),
    marginBottom: theme.typography.pxToRem(34)
  },
  selectedTabText: {
    opacity: 1
  },
  typographyTabText: {
    color: 'inherit',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500,
    textTransform: 'capitalize'
  },
  tabContainer: {
    overflow: 'auto'
  }
});
