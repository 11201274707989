import Utils from '@common/services/Utils';

export const vcManageStyles = theme => ({
  addPointsButton: {
    marginTop: theme.typography.pxToRem(15)
  },
  closeModal: {
    color: theme.typography.color.primary,
    cursor: 'pointer',
    opacity: 0.3
  },
  pointsActionButtons: {
    marginRight: theme.typography.pxToRem(18)
  },
  pointsModal: {
    backgroundColor: theme.palette.background.paper,
    left: '50%',
    margin: '0',
    padding: `${theme.typography.pxToRem(24)} ${theme.typography.pxToRem(29)}`,
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%'
  },
  pointsModalHeader: {
    marginBottom: theme.typography.pxToRem(22)
  },
  ieFix: Utils.ieDatePickerFix(),
});

export const reaManageStyles = theme => ({
  addPointsButton: {
    marginTop: theme.typography.pxToRem(15)
  },
  closeModal: {
    color: theme.typography.color.primary,
    cursor: 'pointer',
    opacity: 0.3
  },
  pointsModal: {
    backgroundColor: theme.palette.background.paper,
    left: '50%',
    margin: '0',
    padding: `${theme.typography.pxToRem(24)} ${theme.typography.pxToRem(29)}`,
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%'
  },
  pointsModalHeader: {
    marginBottom: theme.typography.pxToRem(22)
  },
  popper: {
    opacity: 1
  },
  tooltipContainer: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '2px',
    boxShadow: '0 2px 10px 0 #00000028',
    padding: theme.typography.pxToRem(20),
    opacity: 1
  },
  ieFix: Utils.ieDatePickerFix(),
});

export const addPointsActionsStyles = theme => ({
  pointsHistory: {
    padding: theme.typography.pxToRem(25)
  },
  pointsActionButtons: {
    marginLeft: theme.typography.pxToRem(18)
  },
  pointsHistoryActionsContainer: {
    flex: '1',
    display: 'flex',
    justifyContent: 'flex-end'
  }
});
