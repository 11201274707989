import React from 'react';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import ApiService from '../../../common/services/ApiService';
import { Attendance } from '@admin/components/programs/attendance';
import AttendanceActions from './AttendanceActions';
import EntityManage from '../../../common/components/EntityManage';
import {
  ProgramForm,
  ProgramFormActions,
  ProgramFormSummary
} from './program-form';
import MetadataService from '../../../common/services/MetadataService';
import StyledDivider from '../../../common/components/misc/StyledDivider';

const styles = theme => ({
  defaultTabText: {
    color: theme.typography.color.primary,
    opacity: 0.55
  },
  programContainer: {
    marginTop: theme.typography.pxToRem(17)
  },
  programHeader: {
    marginBottom: theme.typography.pxToRem(16)
  },
  programIcon: {
    height: theme.typography.pxToRem(21),
    marginRight: theme.typography.pxToRem(14),
    width: theme.typography.pxToRem(21)
  },
  programLocation: {
    marginBottom: theme.typography.pxToRem(25)
  },
  programLocationDetails: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(15)
  },
  programStatus: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(15),
    lineHeight: 1.87
  },
  programType: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500,
    lineHeight: 1.33
  },
  selectedTabText: {
    opacity: 1
  },
  typographyTabText: {
    color: 'inherit',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500,
    textTransform: 'capitalize'
  }
});

class ProgramManage extends EntityManage {
  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.meta = new MetadataService();
    this.state = {
      countRegistrations: 0,
      dirty: false,
      domainOptions: [],
      errorMessage: null,
      locationOptions: [],
      categoryOptions: [],
      program: null,
      programId: props.match.params.id,
      programPdf: null, // file attachment
      refreshTick: 0,
      selectedTab: 0,
      validationErrors: {},
      defaultOrderBy: 'user_family_name',
      orderBy: 'asc',
      order: 'user_family_name'
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const programDataPromise = this.api.get('programs', this.state.programId);
    const domainOptionsPromise = this.api.query('/api/v1/domains/options');
    const locationOptionsPromise = this.api.query('/api/v1/locations/options');
    const categoryOptionsPromise = this.api.query('/api/v1/categories/options');

    Promise.all([
      programDataPromise,
      domainOptionsPromise,
      locationOptionsPromise,
      categoryOptionsPromise
    ])
      .then(([res, domainOptions, locationOptions, categoryOptions]) => {
        if (res.status === 200) {
          const program = res.json.data;
          const allDomainOptions = { ...domainOptions };
          // include current value if not on the list (eg deactivated)
          if(program.domain_id && !allDomainOptions[program.domain_id]) {
            allDomainOptions[program.domain_id] = program.domain;
          }

          this.setState({
            domainOptions: allDomainOptions,
            locationOptions,
            categoryOptions,
            programPdf: null
          });
          this.setEntity(res.json);
        } else {
          this.setState({
            errorMessage: 'Something went wrong. got status=' + res.status
          });
        }
      })
      .catch(this.processError);
  };

  forceRefresh = () => {
    this.setState({ refreshTick: Date.now() });
  };

  handleApplySorting = (orderBy, order) => {
    this.setState({
      orderBy: orderBy,
      order: order
    });
  };

  handleChange = (event, value) => {
    this.setState({ selectedTab: value });
  };

  handleDelete = () => {
    this.api
      .delete('programs', this.state.program.id)
      .then(res => {
        if (res.status == 400) {
          this.setState({
            dirty: false,
            errorMessage: res.json.message,
            validationErrors: {}
          });
        } else if (res.status == 204) {
          this.setState(
            {
              dirty: false,
              errorMessage: null,
              program: null,
              programId: null,
              validationErrors: {}
            },
            () => {
              this.props.history.push('/programs');
            }
          );
        } else {
          console.error('Unknown response', res);
        }
      })
      .catch(ex => console.error(ex));
  };

  handleDuplicate = () => {
    const url = '/api/v1/programs/duplicate';

    this.api.post(url, { id: this.state.program.id }).then(res => {
      if (res.status == 201) {
        this.props.history.push('/programs/' + res.json.data.id);
      }
      this.setEntity(res.json); // set either way if it's error or new
    });
  };

  handleProgramChange = newProgram => {
    this.setState({ dirty: true, program: newProgram });
  };

  handleProgramPdfChange = newProgramPdf => {
    const mb = newProgramPdf.size / 1024 / 1024;
    if (mb > 10) {
      let program = { ...this.state.program };
      let validationErrors = { ...this.state.validationErrors };
      validationErrors['pdf_filename'] = ['The selected PDF is too large'];
      program.pdf_filename = null;
      program.pdf_url = null;
      this.setState({
        dirty: true,
        program: program,
        programPdf: null,
        validationErrors: validationErrors
      });
    } else {
      let program = { ...this.state.program };
      program.pdf_filename =
        newProgramPdf === '_destroy' ? null : newProgramPdf.name;
      program.pdf_url = null;
      this.setState({
        dirty: true,
        program: program,
        programPdf: newProgramPdf,
        validationErrors: {}
      });
    }
  };

  handlePublish = publish => {
    this.saveData({ published: publish });
  };

  isNew = () => {
    return this.state.programId === 'new';
  };

  makeAttendanceTab = () => {
    const { classes } = this.props;
    const { dirty, program, selectedTab } = this.state;

    var registrationInfo = '';
    const attendeeLimit =
      program && program.attendee_limit ? program.attendee_limit : null;
    if (attendeeLimit) {
      registrationInfo =
        program && this.state.countRegistrations + '/' + attendeeLimit;
    }

    return (
      <Tab
        key="attendanceTab"
        classes={{
          root: classes.defaultTabText,
          selected: classes.selectedTabText
        }}
        label={
          <Typography className={classes.typographyTabText}>
            Attendance ({registrationInfo})
          </Typography>
        }
        disabled={dirty}
      />
    );
  };

  makeProgramTab = () => {
    const { classes } = this.props;
    return (
      <Tab
        key="programTab"
        classes={{
          root: classes.defaultTabText,
          selected: classes.selectedTabText
        }}
        label={
          <Typography className={classes.typographyTabText}>
            Program Details
          </Typography>
        }
      />
    );
  };

  processResponse(res) {
    super.processResponse(res);
    if (res.status == 200) {
      if (this.state.programPdf != null) {
        this.uploadProgramPdf();
      }
    } else if (res.status == 201) {
      this.props.history.push('/programs/' + res.json.data.id);
    }
  }

  programTypeLabel = () => {
    return (
      this.state.program &&
      this.meta.get('programs').program_type.options[
        this.state.program.program_type
      ].label
    );
  };

  saveData = async (proposedAttributes = {}) => {
    try {

      const shouldHideDomain = [
        'vocational_learning_journey',
        'vocational_elective'
      ].includes(this.state.program.program_type);

      const res = await this.api.saveResource('program', {
        ...this.state.program,
        ...proposedAttributes,
        domain: shouldHideDomain ? null : this.state.program.domain,
      });
      this.processResponse(res);
    } catch (error) {
      this.processError(error);
    }
  };

  setEntity = json => {
    const s = { validationErrors: [], dirty: false, errorMessage: null };
    this.setState({
      ...s,
      program: json.data,
      programId: json.data.id,
      countRegistrations: json.data.num_registrations
    });
  };

  uploadProgramPdf = () => {
    const url = '/api/v1/programs/' + this.state.programId + '/pdf';
    if (this.state.programPdf === '_destroy') {
      this.api
        .put(url, { program: { pdf: null } })
        .then(response => {
          this.getData();
        })
        .catch(ex => console.error(ex));
    } else {
      this.api
        .upload(url, this.state.programPdf, 'program[pdf]')
        .then(response => {
          this.getData();
        })
        .catch(ex => console.error(ex));
    }
  };

  render() {
    const { classes, user } = this.props;
    const {
      dirty,
      errorMessage,
      program,
      selectedTab,
      validationErrors
    } = this.state;
    const domainStyle =
      program && program.domain && program.domain.color
        ? { color: `#${program.domain.color}` }
        : {};
    const editEnabled =
      user.is_institute_admin ||
      user.is_super_admin ||
      (user.is_facilitator && program && program.facilitator_id === user.id);
    const attendanceEnabled = editEnabled;
    var tabs = [this.makeProgramTab()];

    if (
      program &&
      (program.published || program.num_registrations > 0) &&
      attendanceEnabled
    ) {
      tabs.push(this.makeAttendanceTab());
    }

    return (
      <Grid container className={classes.programContainer} spacing={2}>
        <Grid item xs={6}>
          {program && (
            <ProgramFormSummary
              classes={classes}
              domainStyle={domainStyle}
              program={program}
              programTypeLabel={this.programTypeLabel}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          {selectedTab === 0 && editEnabled && (
            <ProgramFormActions
              countRegistrations={this.state.countRegistrations}
              dirty={dirty}
              errorMessage={errorMessage}
              onDelete={this.handleDelete}
              onDuplicate={this.handleDuplicate}
              onPublish={this.handlePublish}
              onSave={() => this.saveData()}
              program={program}
              published={program && program.published}
            />
          )}
          {selectedTab === 1 && program && attendanceEnabled && (
            <AttendanceActions
              history={this.props.history}
              programId={program.id}
              onAddRegistration={this.forceRefresh}
              order={this.state.order}
              orderBy={this.state.orderBy}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Tabs
            onChange={this.handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            value={selectedTab}
          >
            {tabs}
          </Tabs>
          <StyledDivider />
        </Grid>
        <Grid item className={classes.programForm} xs={12}>
          {program && selectedTab === 0 && (
            <ProgramForm
              domainOptions={this.state.domainOptions}
              locationOptions={this.state.locationOptions}
              categoryOptions={this.state.categoryOptions}
              onProgramChange={this.handleProgramChange}
              onProgramPdfChange={this.handleProgramPdfChange}
              program={program}
              validationErrors={validationErrors}
              editEnabled={editEnabled}
            />
          )}
          {program && selectedTab === 1 && attendanceEnabled && (
            <Attendance
              defaultOrderBy={this.state.defaultOrderBy}
              handleApplySorting={this.handleApplySorting}
              onRefreshRequest={this.forceRefresh}
              onDataLoaded={data =>
                this.setState({ countRegistrations: data.meta.total_count })
              }
              program={program}
              refreshTick={this.state.refreshTick}
              workshops={program.workshops}
            />
          )}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(ProgramManage, styles, { withTheme: true });
