import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';

const styles = theme => ({
  tooltipText: {
    color: '#0c0606de',
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.5
  }
});

class InstituteLogoText extends React.Component {

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Typography className={classes.tooltipText}>Please upload a transparent, non-interlaced .png with a height between 70px - 200px.</Typography>
        <br/>
        <Typography className={classes.tooltipText}>This logo is used in multiple locations, follow these guidelines for best results.</Typography>
      </Fragment>
    )
  }

}

export default withStyles(InstituteLogoText, styles, { withTheme: true });
