import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';

import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import ExportIcon from '@mui/icons-material/OpenInNew';
import EmailIcon from '@mui/icons-material/MailOutline';
import TrashIcon from '../../../common/icons/TrashIcon';

import ApiService from '../../../common/services/ApiService';
import ResponsesForm from './ResponsesForm';
import StyledDivider from '../../../common/components/misc/StyledDivider';
import ConfirmationDialog from '@common/components/ConfirmationDialog';

const styles = theme => ({
  domainNavButtons: {
    padding: `${theme.typography.pxToRem(25)} 0`,
  },
  profileActionButton: {
    color: theme.typography.color.primary,
    cursor: 'pointer',
    '&:hover': {
      color: theme.typography.color.primary,
    },
    marginLeft: theme.typography.pxToRem(20)
  },
  profileName: {
    marginTop: theme.typography.pxToRem(15),
    marginBottom: theme.typography.pxToRem(25),
  },
  progressBar: {
    borderRadius: theme.typography.pxToRem(100),
  },
  progressText: {
    fontFamily: 'IBM Plex Sans, medium',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500,
    opacity: 0.7,
  },
});

class ManageResponses extends React.Component {

  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.state = {
      profileDomains: [],
      currentDomain: null,
      disableNextDomain: true,
      disableRadioBtn: false,
      disableSubmitProfile: false,
      domainIndex: 0,
      progressBar: this.props.profile.supervisor_completed_percent || 0,
      open: false,
      responsesList: [],
      responseMessage: null,
      supervisorEmail: null,
      deleteAlertOpen: false,
    };
  }

  componentDidMount() {
    this.getDomainsList();
    this.resolvedProfileCheck();
    this.getSupervisorEmail();
  };

  componentDidUpdate(prevProps, prevState) {
    const profile = this.props.profile;
    if (prevProps.profile.id !== profile.id) {
      this.getDomainsList();
      this.setState({
        progressBar: profile.completed ? 100 : profile.supervisor_completed_percent
      });
      this.setState({ disableRadioBtn: profile.educator_completed === false || profile.supervisor_completed === true });
    }
    if (prevProps.profile.supervisor_completed !== true && this.props.profile.supervisor_completed === true) {
      this.setState({ disableRadioBtn: true });
    }
    if (prevProps.profile.supervisor_completed !== false && this.props.profile.supervisor_completed === false) {
      this.setState({ disableRadioBtn: false });
    }
  };

  checkTotalResponses = (capabilityCount, allResponses) => {
    var responsesCount = 0;
    var supervisorResponses = Object.keys(allResponses).forEach(key => {
      if (allResponses[key].SupervisorResponse !== null) {
        responsesCount ++;
      }
    });
    const disabled = responsesCount !== capabilityCount;
    this.setState({ disableNextDomain: disabled });
  };

  getSupervisorEmail = () => {
    this.api.query('/api/v1/users/' + this.props.supervisor.id)
    .then((res) => {
      this.setState({ supervisorEmail: res.data.email });
    })
  }

  exportProfile = () => {
    var exportPdfUrl = `/api/v1/profiles/${this.props.profile.id}/export_summary_supervisor`;
    this.api.query(exportPdfUrl).then((response) => {
      this.setState({
        open: true,
        responseMessage: response.message,
      });
    });
  };

  formCompletionCheck = (progressPercentage, domainIndex) => {
    var formCompletionButtons = 'visible';
    const completedWithoutSupervisor = this.props.profile.completed && !this.props.supervisor_completed
    const disableNext = completedWithoutSupervisor ? false : this.state.disableNextDomain
    const disableSubmit = completedWithoutSupervisor ? false : this.state.disableSubmitProfile
    
    if (progressPercentage === 100 && domainIndex === (this.state.profileDomains.length - 1)) {
      if (this.props.profile.completed === false) {
        formCompletionButtons = 'visible';
      }
      else if (this.props.profile.completed === true) {
        formCompletionButtons = 'hidden';
      }
      return (
        <Button
          color="secondary"
          disabled={disableSubmit}
          onClick={(event) => this.submitCompletedProfile()}
          style={{visibility: formCompletionButtons}}
          variant="contained"
        >
          <Typography variant="button">Submit Profile</Typography>
        </Button>
      )
    }
    else {
      return (
        <Button
          color="secondary"
          disabled={disableNext}
          onClick={(event) => this.getNextDomain()}
          variant="contained"
        >
          <Typography variant="button">Next Domain</Typography>
        </Button>
      )
    }
  };

  getDomainsList = () => {

    // Get the list of domains that the educator responed
    // They may or may not currenlty be active.
    this.api.query(`/api/v1/profiles/${this.props.profile.id}/domains.json`).then((domainsList) => {
      var currentDomain = domainsList.data[0];
      this.setState({
        profileDomains: domainsList.data,
        domainIndex: 0
      });
      this.getFormData(currentDomain.id);
    }).catch(this.processError);
  };

  getFormData = (currentDomainId) => {
    const getQuestionsPromise = this.api.query(`/api/v1/profiles/${this.props.profile.id}/domains/${currentDomainId}`);
    const getResponsesPromise = this.api.query(`/api/v1/response_summary?profile_id=${this.props.profile.id}&domain_id=${currentDomainId}`);
    Promise.all([getQuestionsPromise, getResponsesPromise]).then(([domainData, responses]) => {
      const currentDomain = domainData.data;
      const responsesList = responses.data;

      this.setState({
        currentDomain,
        responsesList,
      });

      var capabilityCount = currentDomain.capabilities.length; 
      var allResponses = responsesList;
      this.checkTotalResponses(capabilityCount, allResponses);
    }).catch(this.processError);
  };

  getNextDomain = () => {
    var newDomainIndex = this.state.domainIndex + 1;
    this.setState({ domainIndex: newDomainIndex });
    this.getFormData(this.state.profileDomains[newDomainIndex].id);
    this.scrollToTop();
  };

  getPreviousDomain = () => {
    var newDomainIndex = this.state.domainIndex - 1;
    this.setState({ domainIndex: newDomainIndex });
    this.getFormData(this.state.profileDomains[newDomainIndex].id);
    this.scrollToTop();
  };

  handleSnackbarClose = () => {
    this.setState({ open: false, responseMessage: null });
  };

  resolvedProfileCheck = () => {
    if (this.props.profile.supervisor_completed === true || this.props.profile.educator_completed === false) {
      this.setState({ disableRadioBtn: true });
    }
  };

  scrollToTop = () => {
    window.scroll({
      top: 100,
      behavior: "smooth",
    });
  };

  submitCompletedProfile = () => {
    const url = '/api/v1/profiles/' + this.props.profile.id;
    this.api.put(url, {profile: {supervisor_completed: true}}).then((res) => {
      if (res.status === 200) {
        this.setState({
          disableRadioBtn: true,
          disableSubmitProfile: true,
        });
        this.props.onForceRefresh();
        this.scrollToTop();
      } else {
        console.error('Unknown response', res);
      }
    }).catch(this.processError);
  };

  updateProgressBar = (updatedPercentage) => {
    this.setState({ progressBar: this.props.profile.completed ? 100 : updatedPercentage });
  };

  updateResponses = (response, currentResponses) => {
    var capabilityId = response.capability_id;
    var responseType = response.type;
    var value = response.value;

    currentResponses[capabilityId] === undefined ?
    currentResponses = Object.assign({[capabilityId]:
      {['BusinessIdealResponse']: null,
      ['EducatorResponse']: null,
      [responseType]: value}}, currentResponses) :
      currentResponses[capabilityId][responseType] = value;
    this.setState({ responsesList: currentResponses });

    var capabilityCount = this.state.currentDomain.capabilities.length;
    var allResponses = this.state.responsesList;
    this.checkTotalResponses(capabilityCount, allResponses);
    this.state.progressBar === 100 ? this.setState({ disableSubmitProfile: false }) : '';
  };

  handleDeleteConfirm = () => {
    this.api.delete('profiles', this.props.profile.id).then((response) => {
      if(response.status === 400) {
        this.api.notifyModalError(response.json.message);
      } else {
        this.props.onForceRefresh();
        this.setState({ deleteAlertOpen: false });
      }
    });
  };

  handleDeleteCancel = () => {
    this.setState({ deleteAlertOpen: false });
  };

  render() {
    const { classes, profile, responseLabels, user } = this.props;
    const { currentDomain, disableRadioBtn, domainIndex, open, progressBar, responsesList, supervisorEmail } = this.state;
    var showPreviousDomain = domainIndex === 0 ? 'hidden' : 'visible';

    return (
      <Fragment>
        <Grid container alignItems="center">
          <Grid item xs={11}>
            <LinearProgress
              className={classes.progressBar}
              color="secondary"
              value={progressBar}
              valueBuffer={100}
              variant="determinate"
            />
          </Grid>
          <Grid item xs={1}>
            <Typography align="right" className={classes.progressText}>
              {`${progressBar}%`}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.profileName}
          justifyContent="space-between"
        >
          <Typography variant="h6">
            {profile.educator_full_name}
          </Typography>
          <Grid>
            { profile.completed === false && (user.is_super_admin || user.is_institute_admin) &&
              <Tooltip title={`Delete this profile, this can't be undone. Admins only.`}>
                <a
                  className={classes.profileActionButton}
                  onClick={(event) => this.setState({ deleteAlertOpen: true })}
                >
                  <TrashIcon />
                </a>
              </Tooltip>
            }
            <Tooltip title={`Email profile to ${supervisorEmail}`}>
              <a
                className={classes.profileActionButton}
                onClick={(event) => this.exportProfile()}
              >
                <EmailIcon style={{opacity: 0.6}} />
              </a>
            </Tooltip>
            <Tooltip title='Generate & open PDF profile in new tab.'>
              <a
                href={`/api/v1/profiles/${profile.id}.pdf`}
                target='_blank'
                className={classes.profileActionButton}

              >
                <ExportIcon style={{opacity: 0.6}} />
              </a>
            </Tooltip>
          </Grid>
        </Grid>
        <StyledDivider />
        {currentDomain && responseLabels &&
          <ResponsesForm
            currentDomain={currentDomain}
            disableRadioBtn={disableRadioBtn}
            profile={profile}
            responsesList={responsesList}
            updateProgressBar={this.updateProgressBar}
            updateResponses={this.updateResponses}
            responseLabels={responseLabels}
          />
        }
        <Grid
          container
          className={classes.domainNavButtons}
          justifyContent="space-between"
        >
          <Button
            color="secondary"
            disabled={domainIndex === 0}
            onClick={(event) => this.getPreviousDomain()}
            style={{visibility: showPreviousDomain}}
            variant="contained"
          >
            <Typography variant="button">Previous Domain</Typography>
          </Button>
          {this.formCompletionCheck(progressBar, domainIndex)}
        </Grid>
        <Snackbar
          anchorOrigin={{
            horizontal: 'left',
            vertical: 'bottom',
          }}
          autoHideDuration={4000}
          message={<span>{this.state.responseMessage}</span>}
          onClose={this.handleSnackbarClose}
          open={open}
        />
        <ConfirmationDialog
          message="This will fully delete the educator & supervisor responses for this profiling tool. This operation can't be undone!"
          okLabel={`Delete ${profile.educator_full_name}'s profile`}
          onOk={this.handleDeleteConfirm}
          onCancel={this.handleDeleteCancel}
          open={this.state.deleteAlertOpen}
          title={`Delete ${profile.educator_full_name}'s profile?`}
        />
      </Fragment>
    );
  }

}

export default withStyles(ManageResponses, styles, { withTheme: true });
