import React from 'react';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ApiService from '../../../common/services/ApiService';
import MetadataService from '../../../common/services/MetadataService';
import AdminTable from '../../../common/components/table/AdminTable';
import AdminModal from '../../../common/components/modal/AdminModal';
import ProfilingToolLabelForm from '../../../common/components/modal/ProfilingToolLabelForm';

const styles = theme => ({
  profilingToolLabelsHeader: {
    padding: `${theme.typography.pxToRem(22)} ${theme.typography.pxToRem(
      25
    )} ${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(25)}`
  }
});

class ProfilingToolLabels extends React.Component {
  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.meta = new MetadataService();
    this.state = {
      id: null,
      mode: null,
      open: false,
      refreshTick: 0
    };
  }

  forceRefresh = () => {
    this.setState({ refreshTick: Date.now() });
  };

  handleCloseModal = () => {
    this.setState({ mode: null, open: false, id: null });
  };

  handleOpenEditModal = row => {
    this.setState({ mode: 'edit', open: true, id: row.id });
  };

  render() {
    const { classes } = this.props;
    const { id, mode, open } = this.state;
    const fields = ['text', 'value'];
    const actions = ['edit'];

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper>
            <Grid container className={classes.profilingToolLabelsHeader}>
              <Typography variant="h6">
                Profiling Tool Response Labels
              </Typography>
            </Grid>
            <AdminTable
              tableBottomPadding
              actionsSection={actions}
              fields={fields}
              onOpenEditModal={this.handleOpenEditModal}
              refreshTick={this.state.refreshTick}
              resource="response_labels"
            />
          </Paper>
        </Grid>
        <AdminModal
          component={ProfilingToolLabelForm}
          fields={fields}
          id={id}
          mode={mode}
          onCloseModal={this.handleCloseModal}
          onForceRefresh={this.forceRefresh}
          open={open}
          resource="response_labels"
          title="Label"
        />
      </Grid>
    );
  }
}

export default withStyles(ProfilingToolLabels, styles, { withTheme: true });
