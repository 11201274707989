import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';

export const DefaultCheckbox = ({
  checkboxValue,
  unCheckValue = undefined,
  value,
  onChange,
  name,
  label,
  error,
  helperText,
  tooltipProps,
}) => {
  const handleChange = event => {
    onChange({
      ...event,
      target: {
        name: event.target.name,
        value: value === checkboxValue ? unCheckValue : checkboxValue
      }
    });
  };

  const toolTip = (tooltipProps && <Tooltip
    key='tooltip'
    classes={{
      popper: tooltipProps.tooltipPopper,
      tooltip: tooltipProps.tooltipContainer
    }}
    placement={tooltipProps.placement}
    title={tooltipProps.titleComponent}
  >
    <span> ⓘ</span>
  </Tooltip>)

  return (
    <FormControl variant="standard" error={error}>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={value === checkboxValue}
              onChange={handleChange}
              name={name}
            />
          }
          label={[label, toolTip]}
        />
      </FormGroup>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};
