import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';

const styles = theme => ({
  span: {
    fontFamily: 'IBM Plex Sans, medium',
    fontWeight: 500,
  },
  tooltipText: {
    color: '#0c0606de',
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.5
  }
});

class RegistrationCutoff extends React.Component {

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Typography className={classes.tooltipText}>The number of <span className={classes.span}>business days</span> before the program start date that registrations will close</Typography>
      </Fragment>
    )
  }

}

export default withStyles(RegistrationCutoff, styles, { withTheme: true });
