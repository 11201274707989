import React, { useState, useCallback, Fragment, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';
import EditIcon from '@common/icons/EditIcon';
import AdminTable from '@common/components/table/AdminTable';
import MetadataService from '@common/services/MetadataService';
import { pointRequestStyles } from './styles';
import EditModal from './EditModal';
import ApproveModal from './ApproveModal';
import RejectModal from './RejectModal';
import ConvertModal from './ConvertModal';
import DetailCell from './DetailCell';

const UserPointRequest = ({
  classes,
  user,
  currentUser,
  allowSupervisorToAction,
  domainOptions,
  vocationalActivitiesOptions,
  activityIntervalOptions,
  pointsFileAttachmentEnabled,
  refreshTick,
  onRefresh
}) => {
  const fields = ['updated_at', 'detail', 'points', 'type_label'];

  const filters = {
    status_eq: 'pending'
  };

  const allowSupervisorToActionOn = (pointRequest) => {
    // Stop supervisors from approving/rejecting their own EPC-PR and V-PR
    if (currentUser.is_supervisor && user.id === currentUser.id) {
      return false;
    }

    return (
      (pointRequest.type === 'ReaPointRequest' &&
        allowSupervisorToAction.includes(
          'external_professional_currency_point_requests'
        )) ||
      (pointRequest.type === 'VocationalPointRequest' &&
        allowSupervisorToAction.includes('vocational_point_requests'))
    );
  };

  const [pointRequest, setPointRequest] = useState(undefined);
  const [isEditModalOpened, setIsEditModalOpened] = useState(false);
  const [isApproveModalOpened, setIsApproveModalOpened] = useState(false);
  const [isRejectModalOpened, setIsRejectModalOpened] = useState(false);
  const [isConvertModalOpened, setIsConvertModalOpened] = useState(false);

  const openApproveModal = useCallback(
    (pointRequest) => () => {
      setPointRequest(pointRequest);
      setIsApproveModalOpened(true);
    },
    [setPointRequest, setIsApproveModalOpened]
  );

  const openRejectModal = useCallback(
    (pointRequest) => () => {
      setPointRequest(pointRequest);
      setIsRejectModalOpened(true);
    },
    [setPointRequest, setIsRejectModalOpened]
  );

  const openEditModal = useCallback(
    (pointRequest) => () => {
      setPointRequest(pointRequest);
      setIsEditModalOpened(true);
    },
    [setPointRequest, setIsEditModalOpened]
  );

  const openConvertModal = useCallback(
    (pointRequest) => () => {
      setPointRequest(pointRequest);
      setIsConvertModalOpened(true);
    },
    [setPointRequest, setIsConvertModalOpened]
  );

  const handleCloseEditModal = useCallback(() => {
    setPointRequest(undefined);
    setIsEditModalOpened(false);
  }, [setIsEditModalOpened]);

  const handleCloseApproveModal = useCallback(() => {
    setPointRequest(undefined);
    setIsApproveModalOpened(false);
  }, [setIsApproveModalOpened]);

  const handleCloseRejectModal = useCallback(() => {
    setPointRequest(undefined);
    setIsRejectModalOpened(false);
  }, [setIsRejectModalOpened]);

  const handleCloseConvertModal = useCallback(() => {
    setPointRequest(undefined);
    setIsConvertModalOpened(false);
  }, [setIsConvertModalOpened]);

  const EditIconButton = ({ pointRequest }) => {
    return (
      <IconButton onClick={openEditModal(pointRequest)} size="large">
        <EditIcon />
      </IconButton>
    );
  };

  const ConvertButton = ({ pointRequest }) => {
    return (
      <Button
        onClick={openConvertModal(pointRequest)}
        className={`${classes.actionButtons} ${classes.convertButton}`}
        color="secondary"
        size="small"
        variant="contained"
      >
        <Typography variant="button">Convert</Typography>
      </Button>
    );
  };

  const ApproveButton = ({ pointRequest }) => {
    return (
      <Button
        onClick={openApproveModal(pointRequest)}
        className={`${classes.actionButtons} ${classes.approveButton}`}
        color="secondary"
        size="small"
        variant="contained"
      >
        <Typography variant="button">Approve</Typography>
      </Button>
    );
  };

  const RejectButton = ({ pointRequest }) => {
    return (
      <Button
        onClick={openRejectModal(pointRequest)}
        className={`${classes.actionButtons} ${classes.rejectButton}`}
        color="secondary"
        size="small"
        variant="contained"
      >
        <Typography variant="button">Incomplete</Typography>
      </Button>
    );
  };

  const renderButtonActions = useCallback(
    (pointRequest) => {
      if (currentUser.is_institute_admin || currentUser.is_super_admin) {
        return (
          <Fragment>
            <EditIconButton pointRequest={pointRequest} />
            <ConvertButton pointRequest={pointRequest} />
            <ApproveButton pointRequest={pointRequest} />
            <RejectButton pointRequest={pointRequest} />
          </Fragment>
        );
      }

      if (currentUser.is_supervisor) {
        return (
          <Fragment>
            {allowSupervisorToActionOn(pointRequest) && (
              <EditIconButton pointRequest={pointRequest} />
            )}
            <ConvertButton pointRequest={pointRequest} />
            {allowSupervisorToActionOn(pointRequest) && (
              <Fragment>
                <ApproveButton pointRequest={pointRequest} />
                <RejectButton pointRequest={pointRequest} />
              </Fragment>
            )}
          </Fragment>
        );
      }

      return null;
    },
    [
      classes.actionButtons,
      classes.editIconButton,
      classes.approveButton,
      classes.rejectButton,
      classes.convertButton
    ]
  );

  const [metadata, setMetadata] = useState({
    detail: { label: 'Detail', type: 'component', component: DetailCell }
  });

  useEffect(() => {
    const meta = new MetadataService();
    const metadata = meta.get('point_requests');

    setMetadata((prevMetadata) => ({ ...prevMetadata, ...metadata }));
  }, []);

  return (
    <Fragment>
      <Paper>
        <Grid container className={classes.pointRequest}>
          <Grid item>
            <Typography variant="h6">
              Activities Submitted for Approval
            </Typography>
          </Grid>
        </Grid>
        <AdminTable
          tableBottomPadding
          actionsSection={['buttons']}
          fields={fields}
          filters={filters}
          metadata={metadata}
          resource={`users/${user.id}/point_requests`}
          order="desc"
          orderBy="updated_at"
          renderButtonActions={renderButtonActions}
          actionCellProps={{
            width: 250
          }}
          refreshTick={refreshTick}
        />
      </Paper>
      <ApproveModal
        isOpened={isApproveModalOpened}
        onClose={handleCloseApproveModal}
        pointRequest={pointRequest}
        onRefreshRequest={onRefresh}
        user={user}
      />
      <RejectModal
        isOpened={isRejectModalOpened}
        onClose={handleCloseRejectModal}
        pointRequest={pointRequest}
        onRefreshRequest={onRefresh}
        user={user}
      />
      <ConvertModal
        isOpened={isConvertModalOpened}
        onClose={handleCloseConvertModal}
        pointRequest={pointRequest}
        onRefreshRequest={onRefresh}
        user={user}
      />
      <EditModal
        isOpened={isEditModalOpened}
        onClose={handleCloseEditModal}
        pointRequest={pointRequest}
        onRefreshRequest={onRefresh}
        domainOptions={domainOptions}
        vocationalActivitiesOptions={vocationalActivitiesOptions}
        activityIntervalOptions={activityIntervalOptions}
        pointsFileAttachmentEnabled={pointsFileAttachmentEnabled}
        user={user}
      />
    </Fragment>
  );
};

export default withStyles(UserPointRequest, pointRequestStyles, { withTheme: true });
