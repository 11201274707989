var middleDot = '\u{00B7}'

const Chisholm = {
  datePicker: 'd/M/yy',
  dateTimePicker: 'd/M/yy h:mm a',
  educatorProfile: `d/M/yy ${middleDot} h:mm a`,
  standardDate: 'd/M/yy',
  rangeDate: 'd/M/yy'
};

export default Chisholm;
