import PubSub from 'pubsub-js';
import Pluralize from 'pluralize';

var qs = require('qs');

export default class ApiService {
  PATH_PREFIX = '/api/v1/';

  // Give the backend a hint that this is the webapp calling.
  // NB: Not to be used for security.
  DEFAULT_HEADERS = {
    'X-Client-App': 'webapp'
  };

  noContentExpected = status => status === 403;

  get(resource, id) {
    const url = this.PATH_PREFIX + resource + '/' + id;
    return fetch(url, {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        ...(this.DEFAULT_HEADERS),
        'Content-Type': 'application/json'
      }
    })
      .then(function(response) {
        return response.json().then(function(json) {
          return { status: response.status, json: json };
        });
      })
      .then(function(res) {
        if (res.status == 200) {
          return res;
        } else if (res.status == 401) {
          this.notifySessionError('You are no longer logged in');
          throw new Error('Something went wrong 401: ' + res.json.error);
        } else {
          throw new Error('Something went wrong');
        }
      })
      .catch(function(ex) {
        console.error(ex);
        throw ex;
      });
  }

  delete(resource, id) {
    const url = this.PATH_PREFIX + resource + '/' + id;
    return fetch(url, {
      ...(this.DEFAULT_HEADERS),
      method: 'DELETE',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(function(response) {
        if (response.status == 204) {
          return { status: response.status };
        } else {
          return response.json().then(function(json) {
            return { status: response.status, json: json };
          });
        }
      })
      .then(function(res) {
        if (res.status == 204) {
          return res;
        } else if (res.status == 400) {
          return res;
        } else {
          throw new Error('Something went wrong');
        }
      })
      .catch(function(ex) {
        console.error(ex);
        throw ex;
      });
  }

  post(url, data) {
    const options = {
      method: 'post',
      headers: {
        ...(this.DEFAULT_HEADERS),
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
      credentials: 'same-origin'
    };

    return fetch(url, options).then(response =>
      response.json().then(json => ({ status: response.status, json }))
    );
  }

  put(url, data) {
    const options = {
      method: 'put',
      headers: {
        ...(this.DEFAULT_HEADERS),
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
      credentials: 'same-origin'
    };

    return fetch(url, options).then(response => {
      if (response.status === 204) {
        return { status: response.status, json: null };
      } else {
        return response.json().then(function(json) {
          return { status: response.status, json: json };
        });
      }
    });
  }

  query(url) {
    return fetch(url, {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        ...(this.DEFAULT_HEADERS),
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (this.noContentExpected(response.status)) {
          return { status: response.status, json: null };
        } else {
          return response.json().then(json => {
            return { status: response.status, json: json };
          });
        }
      })
      .then(res => {
        if (res.status == 200) {
          return res.json;
        } else if (res.status == 401) {
          this.notifySessionError('You are no longer logged in');
          throw new Error('Something went wrong 401: ' + res.json.error);
        } else if (res.status == 403) {
          this.notifyError(url + ' is forbidden');
        } else {
          throw new Error('Something went wrong');
        }
      })
      .catch(ex => {
        console.error(ex);
        throw ex;
      });
  }

  searchUrl(filters, params, namedFilter, sorting, pagination) {
    let paramsArray = [];

    if (filters && Object.keys(filters).length > 0) {
      let filtersWrappedInQ = { q: filters };
      let filterParams = qs.stringify(filtersWrappedInQ, {
        arrayFormat: 'brackets'
      });
      paramsArray.push(filterParams);
    }

    if (params) {
      let miscParams = qs.stringify(params, { arrayFormat: 'brackets' });
      paramsArray.push(miscParams);
    }

    if (namedFilter) {
      let namedFilterParams = `named_filter=${namedFilter}`;
      paramsArray.push(namedFilterParams);
    }

    if (sorting.field && sorting.direction) {
      let sortingParams = `q\[s\]=${sorting.field}+${sorting.direction}`;
      paramsArray.push(sortingParams);
    }

    if (pagination) {
      let page = pagination.page + 1;
      let paginationParams = `page=${page}&per_page=${pagination.per_page}`;
      paramsArray.push(paginationParams);
    }

    let searchRequest = paramsArray.join('&');
    return searchRequest;
  }

  search(namedFilter, options, resource, url = null) {
    var url = (url == null) ? this.PATH_PREFIX + resource : url;

    var full_request =
      url +
      '?' +
      this.searchUrl(
        options.filters,
        options.params,
        namedFilter,
        options.sorting,
        options.pagination
      );

    return fetch(full_request, {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        ...(this.DEFAULT_HEADERS),
        'Content-Type': 'application/json'
      }
    })
      .then(function(response) {
        return response.json().then(function(json) {
          return { status: response.status, json: json };
        });
      })
      .then(function(res) {
        if (res.status == 200) {
          return res.json;
        } else if (res.status == 401) {
          this.notifySessionError('You are no longer logged in');
          throw new Error('Something went wrong 401: ' + res.json.error);
        } else {
          throw new Error('Something went wrong');
        }
      })
      .catch(function(ex) {
        console.error(ex);
        throw ex;
      });
  }

  // saveResource('department', {})
  // saveResource('divisions/1/departments', {})
  saveResource(resourceOrPath, data) {
    var resource, path;
    if (resourceOrPath.includes('/')) {
      const parts = resourceOrPath.split('/');
      resource = Pluralize.singular(parts[parts.length - 1]);
      path = resourceOrPath;
    } else {
      resource = Pluralize.singular(resourceOrPath);
      path = Pluralize.plural(resource);
    }

    const isNew = data.id === null;
    const method = isNew ? 'post' : 'put';
    const url = isNew
      ? this.PATH_PREFIX + path
      : this.PATH_PREFIX + path + '/' + data.id;

    let body = {};
    body[resource] = data;

    const options = {
      method: method,
      headers: {
        ...(this.DEFAULT_HEADERS),
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body),
      credentials: 'same-origin'
    };

    return fetch(url, options).then(response => {
      return response.json().then(json => {
        return { status: response.status, json: json };
      });
    });
  }

  upload(url, fileObject, fieldName, miscFields = {}) {
    const formData = new FormData();
    formData.append(fieldName, fileObject);
    Object.keys(miscFields).forEach(key => {
      formData.append(key, miscFields[key]);
    });

    const options = {
      body: formData,
      method: 'put',
      credentials: 'same-origin',
      headers: {
        ...(this.DEFAULT_HEADERS),
      }
    };
    return fetch(url, options);
  }

  notifyError(msg) {
    PubSub.publish('ERROR', msg);
  }

  notifyModalError(msg) {
    PubSub.publish('MODAL_ERROR', msg);
  }

  notifySessionError(msg) {
    PubSub.publish('SESSION_ERROR', msg);
  }
}
