import React, { Fragment } from 'react';

class ValueStatus extends React.Component {

  render() {
    const statusCircle = "\u25CF";
    var passportStatus = '';
    switch (this.props.value) {
      case 'COMPLETE':
      passportStatus = '#b8e986';
      break;
      case 'IN_PROGRESS':
      passportStatus = '#f5a623';
      break;
      case 'INCOMPLETE':
      passportStatus = '#fd4f64';
      break;
      default:
      passportStatus = '';
    }

    return (
      <div style={{
        color: `${passportStatus}`,
        fontSize: '20px',
        textAlign: 'center'
      }}>
        {statusCircle}
      </div>
    )
  }

}

export default ValueStatus;
