import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import WaveTextField from '../form/WaveTextField';
import WaveTextArea from '../form/WaveTextArea';
import InputAdornment from '@mui/material/InputAdornment';
import DomainIconText from '../../../common/config/Tooltip/DomainIconText';
import DomainSvgIconText from '../../../common/config/Tooltip/DomainSvgIconText';

const styles = theme => ({
  fields: {
    marginBottom: theme.typography.pxToRem(10)
  },
  formLeft: {
    paddingRight: theme.typography.pxToRem(13),
    paddingBottom: theme.typography.pxToRem(10),
  },
  formRight: {
    paddingLeft: theme.typography.pxToRem(13),
    paddingBottom: theme.typography.pxToRem(10),
  },
  tooltipContainer: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '2px',
    boxShadow: '0 2px 10px 0 #00000028',
    padding: theme.typography.pxToRem(20),
    opacity: 1
  },
  tooltipText: {
    color: '#0c0606de',
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.5
  },
  logoUpload: {
    marginBottom: theme.typography.pxToRem(20),
  },
  popper: {
    opacity: 1
  },
  hideInput: {
    display: 'none',
  },
});

class DomainForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      iconFilename: null,
      svgIconFilename: null
    };
  }

  render() {
    const { classes } = this.props;
    const domain = this.props.entity;

    return (
      <Fragment>
        <Grid
          container
          className={classes.fields}
          justifyContent="space-between"
        >
          <Grid item xs={6} className={classes.formLeft} >
            <WaveTextField
              label="Name"
              {...this.props.makeFieldProps('name')}
            />
          </Grid>
          <Grid item xs={6} className={classes.formRight} >
            <WaveTextField
              label="Colour"
              {...this.props.makeFieldProps('color')}
            />
          </Grid>
          <Grid item xs={12}>
            <WaveTextField
              fileUpload={
                <InputAdornment position="end">
                  <Button
                    className={classes.logoUpload}
                    color="secondary"
                    component="label"
                    size="small"
                    variant="contained"
                  >
                    <Typography variant="button">Browse</Typography>
                    <input
                      accept=".svg"
                      className={classes.hideInput}
                      onChange={(event) => {
                        this.setState({ svgIconFilename: event.target.files[0].name });
                        this.props.onSvgIconChange(event.target.files[0]);
                        event.target.value = null;
                      }}
                      type="file"
                    />
                  </Button>
                </InputAdornment>
              }
              label="SVG Icon"
              tooltipProps={{
                placement: "top",
                titleComponent: <DomainSvgIconText />,
                tooltipContainer: classes.tooltipContainer,
                tooltipPopper: classes.popper
              }}
              value={this.state.svgIconFilename || domain.svg_icon_filename}
            />
          </Grid>
          <Grid item xs={12}>
            <WaveTextArea
              label="Description"
              placeholder="Enter description here"
              maxLength={250}
              rows={4}
              {...this.props.makeFieldProps('summary')}
            />
          </Grid>
        </Grid>
      </Fragment>
    );
  }

}

export default withStyles(DomainForm, styles, { withTheme: true });
