import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function UpIcon(props) {
  return (
    <SvgIcon {...props} style={{opacity: 0.6}}>
      <path d="M12 10l-6 6 1.4 1.4 4.6-4.6 4.6 4.6L18 16z"/>
    </SvgIcon>
  );
}
