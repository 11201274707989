import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import educatorPassportLogo from '../../images/educator-passport.png';
import FlashMessages from '../../common/components/misc/FlashMessages';
import LoginLayout from './LoginLayout';

const styles = theme => ({
  backToLoginLink: {
    color: theme.typography.color.primary,
    '&:hover': {
      color: theme.typography.color.primary,
    },
    '&:visited': {
      color: theme.typography.color.primary,
    },
    fontSize: theme.typography.pxToRem(13),
    marginLeft: theme.typography.pxToRem(20),
  },
  loginActions: {
    marginTop: theme.typography.pxToRem(50),
  },
  loginHeader: {
    marginBottom: theme.typography.pxToRem(20),
  },
  loginLogo: {
    marginBottom: theme.typography.pxToRem(30),
  },
});

const LoginForm = (props) =>  {
  const { classes } = props;

  return (
    <Fragment>
      <div className={classes.loginLogo}>
        <img src={educatorPassportLogo} />
      </div>
      <Typography className={classes.loginHeader}>
        Login
      </Typography>
      <FlashMessages flashMessages={props.flashMessages}/>
      <form action="/users/sign_in" method="post">
        <input type="hidden" name="authenticity_token" value={props.csrf} />
        <TextField
          variant="standard"
          autoFocus
          fullWidth
          id="email"
          label="Email"
          margin="normal"
          name="user[email]"
          type="email" />
        <TextField
          variant="standard"
          fullWidth
          id="password"
          label="Password"
          margin="normal"
          name="user[password]"
          type="password" />
        <Grid
          container
          alignItems="center"
          className={classes.loginActions}
        >
          <Button color="primary" type="submit" variant="contained">
            Login
          </Button>
          <a
            className={classes.backToLoginLink}
            data-turbolinks="false"
            href="/users/password/new"
          >
            Forgot password?
          </a>
        </Grid>
      </form>
    </Fragment>
  );
};

const StyledLoginForm = withStyles(LoginForm, styles, { withTheme: true });
const LoginApp = (props) => {
  return (<LoginLayout><StyledLoginForm {...props}/></LoginLayout>);
};

export default LoginApp;
