import React from 'react';
import { withStyles } from 'tss-react/mui';

import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import DepartmentEngagement from './DepartmentEngagement';
import Passports from './Passports';
import UserPoints from './user_points/UserPoints';
import PointsHistory from './PointsHistory';
import PointRequest from './PointRequest';
import Profiling from './Profiling';
import ProgramsEmployment from './ProgramsEmployment';
import ProgramsMonthly from './ProgramsMonthly';
import ProgramRegistration from './ProgramRegistration';
import NotificationReport from './notification-report/NotificationReport';
import StyledDivider from '../../../common/components/misc/StyledDivider';
import ApiService from '@common/services/ApiService';

const styles = (theme) => ({
  defaultTabText: {
    color: theme.typography.color.primary,
    opacity: 0.55
  },
  reportsHeadline: {
    marginTop: theme.typography.pxToRem(8),
    marginBottom: theme.typography.pxToRem(8)
  },
  selectedTabText: {
    opacity: 1
  },
  typographyTabText: {
    color: 'inherit',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500,
    textTransform: 'capitalize'
  }
});

class Reports extends React.Component {
  constructor(props) {
    super(props);

    this.reports = [
      ['passports', 'Passports'],
      ['user_points', 'User Points']
    ];

    if (props.user.is_institute_admin || props.user.is_super_admin) {
      this.reports.push(['profiling', 'Profiling']);
    }

    this.reports.push(['points_history', 'Points History']);
    this.reports.push(['point_request', 'Point Request']);
    this.reports.push(['program_registration', 'Program Registration']);

    if (props.user.is_institute_admin || props.user.is_super_admin) {
      this.reports = [
        ...this.reports,
        ['programs_monthly', 'Programs Monthly'],
        ['programs_employment', 'Programs (Employment)'],
        ['department_engagement', 'Department Engagement'],
        ['notification', 'Notification']
      ];
    }

    this.state = {
      tabValue: this.selectTab(props.match.params.report_name),
      tabName: props.match.params.report_name,
      passportPeriod: undefined,
      loading: true
    };

    this.getPassportPeriodDates();
  }

  getPassportPeriodDates = () => {
    const api = new ApiService();
    const { institute } = this.props;

    api.query(
      `/api/v1/institutes/${institute.id}/passport_period`
    ).then((passportPeriod) => {
      this.setState({ passportPeriod, loading: false });
    });
  };

  selectTab = (reportName) => {
    var index = this.reports.findIndex((report) => reportName === report[0]);
    if (index < 0 || index >= this.reports.length) {
      console.warn('Bad report selection: ' + reportName);
      index = 0;
    }
    return index;
  };

  handleTabChange = (event, tabValue) => {
    this.props.history.push('/reports/' + this.reports[tabValue][0]);
  };

  makeTab = (report) => {
    const { classes } = this.props;
    return (
      <Tab
        key={report[0]}
        classes={{
          root: classes.defaultTabText,
          selected: classes.selectedTabText
        }}
        label={
          <Typography className={classes.typographyTabText}>
            {report[1]}
          </Typography>
        }
      />
    );
  };

  render() {
    const { classes, institute } = this.props;
    const { tabValue, tabName, passportPeriod, loading } = this.state;

    const tabs = this.reports.map((report) => {
      return this.makeTab(report);
    });

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className={classes.reportsHeadline} variant="h5">
            Reports
          </Typography>
          <Tabs
            onChange={this.handleTabChange}
            textColor="secondary"
            indicatorColor="secondary"
            value={tabValue}
            variant='scrollable'
            scrollButtons="auto"
          >
            {tabs}
          </Tabs>
          <StyledDivider />
        </Grid>

        {loading ? (
          <span>Loading...</span>
        ) : (
          <Grid item xs={12}>
            {tabName === 'passports' && (
              <Passports history={this.props.history} institute={institute} />
            )}
            {tabName === 'profiling' && <Profiling passportPeriod={passportPeriod} />}
            {tabName === 'programs_monthly' && <ProgramsMonthly />}
            {tabName === 'programs_employment' && <ProgramsEmployment passportPeriod={passportPeriod} />}
            {tabName === 'department_engagement' && <DepartmentEngagement passportPeriod={passportPeriod} />}
            {tabName === 'points_history' && <PointsHistory passportPeriod={passportPeriod} institute={institute} />}
            {tabName === 'point_request' && (
              <PointRequest institute={this.props.institute} passportPeriod={passportPeriod} />
            )}
            {tabName === 'program_registration' && <ProgramRegistration passportPeriod={passportPeriod} institute={institute} />}
            {tabName === 'notification' && <NotificationReport passportPeriod={passportPeriod} />}
            {tabName === 'user_points' && (
              <UserPoints
                history={this.props.history}
                institute={this.props.institute}
                passportPeriod={passportPeriod}
              />
            )}
          </Grid>
        )}
      </Grid>
    );
  }
}

export default withStyles(Reports, styles, { withTheme: true });
