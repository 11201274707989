import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function AddUserIcon(props) {
  return (
    <SvgIcon {...props} style={{opacity: 0.6}}>
      <path d="M17.6 8.9C17.6 5.6 15 3 11.8 3 8.6 3 5.9 5.6 5.9 8.9 5.9 10.7 6.8 12.5 8.3 13.5 5.4 14.8 3.4 17.4 3 20.5L4.5 20.5C5 17.2 8.4 14.3 11.8 14.3 15.2 14.3 18.5 17.2 19 20.5L20.5 20.5C20.2 17.4 18.1 14.8 15.3 13.5 16.7 12.5 17.6 10.7 17.6 8.9ZM11.8 13.2C9.4 13.2 7.5 11.2 7.5 8.9 7.5 6.5 9.4 4.5 11.8 4.5 14.1 4.5 16.1 6.5 16.1 8.9 16.1 11.2 14.1 13.2 11.8 13.2Z"/>
    </SvgIcon>
  );
}
