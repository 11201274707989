import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function ImportPointsIcon(props) {
  return (
    <SvgIcon {...props} style={{opacity: 0.6}} viewBox="0 0 20 21">
      <g id="V1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="4.0.1_Add-User" transform="translate(-1374.000000, -791.000000)" strokeWidth="1.5" stroke="#646464">
              <g id="Group-10" transform="translate(1364.000000, 782.000000)">
                  <g id="Group-9" transform="translate(7.000000, 7.000000)">
                      <g id="Group-8" transform="translate(8.701844, 2.000000)">
                          <line x1="4.29815579" y1="0" x2="4.29815579" y2="12.7279221" id="Path-6"></line>
                          <polyline id="Path-7" points="8.59631157 9 4.29815579 13.2981558 9.09526335e-13 9"></polyline>
                      </g>
                  </g>
              </g>
          </g>
      </g>
    </SvgIcon>
  );
}
