import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import WaveTextArea from '../form/WaveTextArea';

const styles = theme => ({
  capabilityFields: {
    marginBottom: theme.typography.pxToRem(10),
  },
  formTextArea: {
    marginBottom: theme.typography.pxToRem(10)
  }
});

class CapabilityForm extends React.Component {

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Grid
          container
          className={classes.capabilityFields}
          justifyContent="space-between"
        >
          <Grid item xs={12} className={classes.formTextArea}>
            <WaveTextArea
              label="Capability Statement"
              placeholder="Enter capability statement here"
              maxLength={150}
              rows={4}
              {...this.props.makeFieldProps('statement')}
            />
          </Grid>
        </Grid>
      </Fragment>
    );
  }

}

export default withStyles(CapabilityForm, styles, { withTheme: true });
