import React from 'react';
import { withStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';
import { DefaultCheckbox } from '@common/components/default-checkbox';

const styles = theme => ({
  root: {
    marginBottom: 0,
    paddingLeft: theme.typography.pxToRem(20),
    paddingRight: theme.typography.pxToRem(20)
  }
});

class DomainsFilter extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes, filter } = this.props;

    return (
      <Paper className={classes.root}>
        <DefaultCheckbox
          name="hideDeactivated"
          label="Hide deactivated Domains & Capability Statements (Note: All deactivated Domains and Statements must remain at the bottom of each list.)"
          onChange={this.handleInputChange}
          checkboxValue={true}
          unCheckValue={false}
          value={filter.hideDeactivated}
        />
      </Paper>
    );
  }

  handleInputChange = event => {
    const { onChange } = this.props;
    onChange({ [event.target.name]: event.target.value });
  };
}

export default withStyles(DomainsFilter, styles, { withTheme: true });
