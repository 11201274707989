const SelectFieldOptions = {
  passportStatus: {
    'COMPLETE': {
      id: 'COMPLETE',
      label: 'Complete'
    },
    'IN_PROGRESS': {
      id: 'IN_PROGRESS',
      label: 'In Progress'
    },
    'INCOMPLETE': {
      id: 'INCOMPLETE',
      label: 'Incomplete'
    },
  },
  profileStatus: {
    'COMPLETE': {
      id: 'COMPLETE',
      label: 'Complete'
    },
    'EDUCATOR_COMPLETE': {
      id: 'EDUCATOR_COMPLETE',
      label: 'Educator Complete'
    },
    'INCOMPLETE': {
      id: 'INCOMPLETE',
      label: 'Incomplete'
    },
  },
  pointsAnniversary: {
    1: {
      id: 1,
      label: 'January',
    },
    2: {
      id: 2,
      label: 'February',
    },
    3: {
      id: 3,
      label: 'March',
    },
    4: {
      id: 4,
      label: 'April',
    },
    5: {
      id: 5,
      label: 'May',
    },
    6: {
      id: 6,
      label: 'June',
    },
    7: {
      id: 7,
      label: 'July',
    },
    8: {
      id: 8,
      label: 'August',
    },
    9: {
      id: 9,
      label: 'September',
    },
    10: {
      id: 10,
      label: 'October',
    },
    11: {
      id: 11,
      label: 'November',
    },
    12: {
      id: 12,
      label: 'December',
    }
  }
};

export default SelectFieldOptions;
