import React, { useState } from 'react';
import { withStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import ApiService from '@common/services/ApiService';
import AdminTable from '@common/components/table/AdminTable';
import UserPointsFilters from './UserPointsFilters';

const styles = (theme) => ({
  exportHeader: {
    padding: `${theme.typography.pxToRem(22)} ${theme.typography.pxToRem(
      25
    )} ${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(25)}`
  }
});

const UserPoints = ({ classes, history, institute, passportPeriod }) => {
  const fields = [
    'user_full_name',
    'division_name',
    'department_name',
    'employment_type_name',
    institute.custom_user_field_1_enabled ? 'custom_1' : null,
    institute.custom_user_field_2_enabled ? 'custom_2' : null,
    'professional_points',
    'vocational_points'
  ].filter(Boolean);

  const {
    requirement_1_name,
    requirement_1_enabled,
    requirement_2_name,
    requirement_2_enabled,
    custom_user_field_1,
    custom_user_field_2,
  } = institute;

  // conditionally display institute requirement fields if enabled
  if (requirement_1_name && requirement_1_enabled) fields.push('requirement_1');
  if (requirement_2_name && requirement_2_enabled) fields.push('requirement_2');

  const metadata = {
    user_full_name: {
      label: 'Educator Name',
      sortable: true,
      type: 'string'
    },
    division_name: {
      label: 'Division',
      sortable: true,
      type: 'string'
    },
    department_name: {
      label: 'Department',
      sortable: true,
      type: 'string'
    },
    employment_type_name: {
      label: 'Employment Type',
      sortable: true,
      type: 'string'
    },
    professional_points: {
      label: 'Professional Points',
      sortable: true,
      type: 'integer'
    },
    vocational_points: {
      label: 'Vocational Points',
      sortable: true,
      type: 'integer'
    },
    requirement_1: {
      label: requirement_1_name,
      sortable: true,
      type: 'string'
    },
    requirement_2: {
      label: requirement_2_name,
      sortable: true,
      type: 'string'
    },
    custom_1: {
      label: custom_user_field_1,
      sortable: true,
      type: 'string'
    },
    custom_2: {
      label: custom_user_field_2,
      sortable: true,
      type: 'string'
    }
    
  };

  const [filters, setFilters] = useState({ active_eq: '1' });
  const [order, setOrder] = useState('');
  const [orderBy, setOrderBy] = useState('');

  const api = new ApiService();

  const handleApplyFilters = (newFilters) => setFilters(newFilters);

  const handleApplySorting = (newOrder, newOrderBy) => {
    setOrder(newOrder);
    setOrderBy(newOrderBy);
  };

  const handleEducatorCellClicked = (row) =>
    history.push('/users/' + row.user_id);

  const handleExport = () => {
    var sortingParams = {
      direction: order,
      field: orderBy
    };
    var searchRequest = api.searchUrl(filters, null, null, sortingParams, null);

    var apiUrl = '';
    searchRequest !== ''
      ? (apiUrl = '/api/v1/reports/user_points.csv?')
      : (apiUrl = '/api/v1/reports/user_points.csv');
    var csvUrl = apiUrl + searchRequest;
    return csvUrl;
  };

  return (
    <Grid container className={classes.userPointsReport} spacing={2}>
      <Grid item xs={12}>
        <Paper>
          <UserPointsFilters
            handleApplyFilters={handleApplyFilters}
            passportPeriod={passportPeriod}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Grid
            container
            className={classes.exportHeader}
            justifyContent="space-between"
          >
            <Typography variant="h6">User Points</Typography>
            <a href={`${handleExport()}`} target="_blank">
              <Button color="secondary" size="small" variant="contained">
                <Typography variant="button">Export</Typography>
              </Button>
            </a>
          </Grid>
          <AdminTable
            showTablePagination
            fields={fields}
            filters={filters}
            handleApplySorting={handleApplySorting}
            metadata={metadata}
            onCellClicked={{
              user_full_name: handleEducatorCellClicked
            }}
            onCellEdit={{}}
            resource="reports/user_points"
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default withStyles(UserPoints, styles, { withTheme: true });
