const TooltipText = {
  primaryColour: "Avoid using very light colours as they are often displayed on top of white or grey",
  primaryLightColour: "This should be a lighter version of Primary Colour",
  secondaryColour: "Avoid using very light colours as they are often displayed on top of white or grey",
  facilitator: "Assign a Facilitator role to a user in the database before making your selection.",
  icalType: "Regular - will include all workshops in email attachment (ics file); Invite - will create an invite-style attachment per workshop, this may be required for some calendaring systems to recognise the attachment. (This setting applies to email attachment, not Add to Calendar link); None - turns off the calendar attachment file altogether (user can still use the Add to Calendar links in the body of the email.",
  addAllToCalendarEnabled: "Turns on the link in the program registration email to add all workshops in a program to the user’s calendar. This may not work as expected with all calendaring software so can be disabled.  When disabled, workshops can still be added one at a time.",
  summaryDescription: "Summary description is shown in the Points History report.",
}

export default TooltipText;
