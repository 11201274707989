import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Tooltip from '@mui/material/Tooltip';

class WaveTextField extends React.Component {

  renderFormHelpers(maxLength, value) {
    if (maxLength === 0 || maxLength === undefined || maxLength === null) {
      return (
        <FormHelperText>{this.props.helperText}</FormHelperText>
      )
    }
    else {
      return (
        <Grid container direction="row" justifyContent="space-between">
          <FormHelperText>{this.props.helperText}</FormHelperText>
          <FormHelperText error={false}>
            {`${value.length} / ${this.props.maxLength}`}
          </FormHelperText>
        </Grid>
      );
    }
  }

  render() {
    const { classes, error, fileUpload, label, maxLength, name, onChange, readOnly, tooltipProps, type, min, max } = this.props;
    const value = (this.props.value == null ? '' : this.props.value + '');

    return (
      <FormControl variant="standard" fullWidth error={error}>
        <InputLabel>
          {label}
          {tooltipProps &&
            <Tooltip
              classes={{
                popper: tooltipProps.tooltipPopper,
                tooltip: tooltipProps.tooltipContainer
              }}
              placement={tooltipProps.placement}
              title={tooltipProps.titleComponent}
            >
              <span> ⓘ</span>
            </Tooltip>
          }
        </InputLabel>
        <Input
          endAdornment={fileUpload}
          inputProps={{ maxLength: maxLength, min, max }}
          name={name}
          onChange={onChange}
          readOnly={readOnly}
          type={type || "text"}
          value={value}
        />
        {this.renderFormHelpers(maxLength, value)}
      </FormControl>
    );
  }

}

export default WaveTextField;
