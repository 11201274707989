import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';

import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import Autosuggest from 'react-autosuggest';
import ApiService from '../../../common/services/ApiService';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

function getSuggestionValue(suggestion) {
  return suggestion.full_name;
}

function renderInput(inputProps) {
  const { classes, ref, ...other } = inputProps;
  const tooltipProps = inputProps.tooltipprops;

  return (
    <TextField
      variant="standard"
      fullWidth
      className={inputProps.error ? '' : inputProps.errorstyles}
      error={inputProps.error}
      helperText={inputProps.errorhelpers}
      InputProps={{
        classes: {
          input: classes.input,
        },
        inputRef: ref,
        ...other,
      }}
      label={
        <Fragment>
          <span>{inputProps.label}</span>
          {tooltipProps &&
            <Tooltip
              disableFocusListener
              classes={{
                popper: tooltipProps.tooltipPopper,
                tooltip: tooltipProps.tooltipContainer
              }}
              placement={tooltipProps.placement}
              title={tooltipProps.titleComponent}
            >
              <span> ⓘ</span>
            </Tooltip>
          }
        </Fragment>
      } />
  );
}

function formatName(modalMode, user) {
  var string = '';

  if (modalMode) {

    if (user.family_name) {
      string += user.family_name.toUpperCase();
    }

    if (user.given_name) {
      if(string.length > 0) {
        string += ', ';
      }
      string += user.given_name;
    }

    if (user.unique_identifier) {
      string += ' – ' + user.unique_identifier;
    }

  } else { // inline form mode

    if (user.given_name) {
      string += user.given_name;
    }

    if (user.family_name) {
      if(string.length > 0) {
        string += ' ';
      }
      string += user.family_name;
    }

  }

  string = string.trim();

  if (string.length == 0) {
    string = user.full_name;
  }

  return string;
}

function renderSuggestionsContainer(options) {
  const { containerProps, children } = options;

  return (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  );
}

const styles = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  highlighted: {
    fontWeight: 300,
  },
  itemContainer: {
    height: 'auto',
  },
  notHighlighted: {
    fontWeight: 500,
  },
  suggestionsContainerOpen: {
    left: 0,
    marginTop: theme.spacing(1),
    position: 'absolute',
    right: 0,
    zIndex: 1301,
  },
  suggestion: {
    display: 'block',
    '&:before': {
      display: 'none'
    }
  },
  suggestionsList: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
  },
  wrapSuggestionText: {
    hyphens: 'auto',
    overflowWrap: 'break-word',
    whiteSpace: 'normal',
    wordWrap: 'break-word'
  }
});

class Autocomplete extends React.Component {

  api = new ApiService();
  lastSuggestions = [];
  state = {
    dirty: false,
    suggestions: [],
  };

  findSuggestion = (value) => {
    return this.lastSuggestions.find((suggestion) => {
      return suggestion.full_name === value;
    });
  };

  handleBlur = () => {
    if (this.state.dirty == false) {
      return;
    }
    var object = this.findSuggestion(this.props.value);
    if (object) {
      this.props.onChange(object.id, object.full_name);
    } else {
      this.props.onChange(null, '');
    }
    this.setState({ dirty: false });
  };

  handleChange = (event, x) => {
    this.setState({ dirty: true });
    this.props.onChange(null, x.newValue);
  };

  handleSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };



  handleSuggestionsFetchRequested = ({ value }) => {
    const { modalMode, userStatus = 'all' } = this.props;
    const roles = this.props.role ? (Array.isArray(this.props.role) ? this.props.role : [this.props.role]) : ['educator'];
    const roleFilter = 'q[' + roles.map(r => 'is_' + r).join('_or_') + '_true]=1';
    const sort = modalMode ? 'q[s]=family_name' : 'q[s]=given_name';
    const search = 'q[given_name_or_family_name_or_unique_identifier_cont]=' + encodeURIComponent(value);
    const userStatusFilter = userStatus === 'all' ? null : 'q[active_' + (userStatus === 'active') + ']=1';
    const params = [roleFilter, userStatusFilter, search, sort].filter(val => val).join('&');
    this.api.query('/api/v1/users/autocomplete?' + params)
      .then((json) => {
        this.lastSuggestions = json.data;
        this.setState({ suggestions: json.data });
      });
  };

  handleSuggestionSelected = (event, {suggestion}) => {
    this.props.onChange(suggestion.id, suggestion.full_name);
  };

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const { classes, modalMode } = this.props;

    const string = formatName(modalMode, suggestion);
    const matches = match(string, query);
    const parts = parse(string, matches);

    return (
      <MenuItem
        className={classes.itemContainer}
        component="div"
        selected={isHighlighted}
      >
        <div>
          {parts.map((part, index) => {
            return part.highlight ? (
              <span
                key={String(index)}
                className={`${classes.wrapSuggestionText} ${classes.higlighted}`}
              >
                {part.text}
              </span>
            ) : (
              <strong
                key={String(index)}
                className={`${classes.wrapSuggestionText} ${classes.notHighlighted}`}
              >
                {part.text}
              </strong>
            );
          })}
        </div>
      </MenuItem>
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Autosuggest
          getSuggestionValue={getSuggestionValue}
          inputProps={{
            classes,
            error: this.props.error,
            errorhelpers: this.props.helperText,
            errorstyles: this.props.errorSpacing,
            label: this.props.label,
            onBlur: this.handleBlur,
            onChange: this.handleChange,
            tooltipprops: this.props.tooltipProps,
            value: this.props.value,
          }}
          onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
          onSuggestionSelected={this.handleSuggestionSelected}
          renderInputComponent={renderInput}
          renderSuggestion={this.renderSuggestion}
          renderSuggestionsContainer={renderSuggestionsContainer}
          suggestions={this.state.suggestions}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
        />
      </Fragment>
    );
  }
}

Autocomplete.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(Autocomplete, styles);
