import React, { useCallback } from 'react';
import { withStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { green } from '@mui/material/colors';

const styles = (theme) => ({
  actionButtonsWrapper: {
    minWidth: '265px'
  },
  actionButtons: {
    marginLeft: theme.typography.pxToRem(18)
  },
  approveButton: {
    backgroundColor: green[600]
  },
  rejectButton: {
    backgroundColor: theme.palette.error.dark
  }
});

function ButtonActions({
  registration,
  toggleApproveModal,
  toggleRejectModal,
  classes
}) {
  const handleApproveModal = useCallback(() => {
    toggleApproveModal(registration);
  }, [registration, toggleApproveModal]);

  const handleRejectModal = useCallback(() => {
    toggleRejectModal(registration);
  }, [registration, toggleRejectModal]);

  return (
    <div className={classes.actionButtonsWrapper}>
      <Button
        onClick={handleApproveModal}
        className={`${classes.actionButtons} ${classes.approveButton}`}
        color="secondary"
        size="small"
        variant="contained"
      >
        <Typography variant="button">Approve</Typography>
      </Button>

      <Button
        onClick={handleRejectModal}
        className={`${classes.actionButtons} ${classes.rejectButton}`}
        color="secondary"
        size="small"
        variant="contained"
      >
        <Typography variant="button">Incomplete</Typography>
      </Button>
    </div>
  );
}

export default withStyles(ButtonActions, styles, { withTheme: true });
