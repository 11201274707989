import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import WaveTextField from '../form/WaveTextField';
import WaveTextArea from '../form/WaveTextArea';
import { DefaultCheckbox } from '@common/components/default-checkbox';
import InputAdornment from '@mui/material/InputAdornment';

const styles = theme => ({
  formFields: {
    paddingBottom: theme.typography.pxToRem(16),
  },
  bannerFields: {
    marginBottom: theme.typography.pxToRem(10)
  },
  tooltipContainer: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '2px',
    boxShadow: '0 2px 10px 0 #00000028',
    padding: theme.typography.pxToRem(20),
    opacity: 1
  },
  tooltipText: {
    color: '#0c0606de',
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.5
  },
  popper: {
    opacity: 1
  },
  hideInput: {
    display: 'none',
  },
  fileUpload: {
    marginBottom: theme.typography.pxToRem(20),
  },
});

class BannerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      desktopImageFilename: null,
      mobileImageFilename: null
    };
  }

  render() {
    const { classes } = this.props;
    const banner = this.props.entity;

    const desktopImage = this.props.makeFieldProps('desktop_image');
    const mobileImage = this.props.makeFieldProps('mobile_image');

    return (
      <Fragment>
        <Grid
          container
          className={classes.bannerFields}
        >
          <Grid item xs={12} className={classes.formFields}>
            <WaveTextField
              label="Title"
              maxLength={50}
              {...this.props.makeFieldProps('title')}
              helperText={this.props.errorHelpers('title') || 'You can use the placeholders [given_name] and [family_name]'}
            />
          </Grid>
          <Grid item xs={12} className={classes.formFields}>
            <WaveTextArea
                label="Content"
                placeholder="Enter content paragraph here. You can use the placeholders [given_name] and [family_name] to personalise the message."
                maxLength={100}
                rows={4}
                {...this.props.makeFieldProps('content')}
                />
          </Grid>
          <Grid item xs={12} className={classes.formFields}>
            <WaveTextField
              label="Button Label"
              maxLength={20}
              {...this.props.makeFieldProps('button_label')}
            />
          </Grid>
          <Grid item xs={12} className={classes.formFields}>
            <WaveTextField
                label="Button URL"
                placeholder="Enter button url here."
                maxLength={2000}
                {...this.props.makeFieldProps('button_url')}
                helperText="Ensure URL includes https://"
                />
          </Grid>
          <Grid item xs={12} className={classes.formFields}>
            <WaveTextField
              name={desktopImage.name}
              error={desktopImage.error}
              helperText={desktopImage.helperText || 'jpg max 2MB, preferred dimensions 1440x280 px'}
              fileUpload={
                <InputAdornment position="end">
                  <Button
                    className={classes.fileUpload}
                    color="secondary"
                    component="label"
                    size="small"
                    variant="contained"
                  >
                    <Typography variant="button">Browse</Typography>
                    <input
                      accept=".jpg"
                      className={classes.hideInput}
                      onChange={(event) => {
                        this.props.onFileChanged('desktop_image', event.target.files[0])
                        this.setState({ desktopImageFilename: event.target.files[0].name });
                        event.target.value = null;
                      }}
                      type="file"
                    />
                  </Button>
                </InputAdornment>
              }
              label="Desktop Image"
              value={this.state.desktopImageFilename || banner.desktop_image_filename}
            />
          </Grid>
          <Grid item xs={12} className={classes.formFields}>
            <WaveTextField
              name={mobileImage.name}
              error={mobileImage.error}
              helperText={mobileImage.helperText || 'jpg max 2MB, preferred dimensions 360x350 px'}
              fileUpload={
                <InputAdornment position="end">
                  <Button
                    className={classes.fileUpload}
                    color="secondary"
                    component="label"
                    size="small"
                    variant="contained"
                  >
                    <Typography variant="button">Browse</Typography>
                    <input
                      accept=".jpg"
                      className={classes.hideInput}
                      onChange={(event) => {
                        this.props.onFileChanged('mobile_image', event.target.files[0])
                        this.setState({ mobileImageFilename: event.target.files[0].name });
                        event.target.value = null;
                      }}
                      type="file"
                    />
                  </Button>
                </InputAdornment>
              }
              label="Mobile Image"
              value={this.state.mobileImageFilename || banner.mobile_image_filename}
            />
          </Grid>
          <Grid item xs={12} className={classes.formFields}>
            <DefaultCheckbox
              label="Active - Show on dashboard?"
              checkboxValue={true}
              unCheckValue={false}
              {...this.props.makeFieldProps('active')}
            />
          </Grid>
        </Grid>
      </Fragment>
    )
  }

}

export default withStyles(BannerForm, styles, { withTheme: true });