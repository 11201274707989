
export default class Utils {

  static isIE() {
    var ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
    var msie = ua.indexOf('MSIE '); // IE 10 or older
    var trident = ua.indexOf('Trident/'); //IE 11

    return (msie > 0 || trident > 0);
  }

  static ieDatePickerFix() {
    return Utils.isIE() ? {
      //background: 'yellow',
      '& input': {
        minWidth: 1
      },
      '& div div': {
        marginBottom: 10,
        marginTop: -10
      }
    }: {};
  }

}
