import React, { Fragment } from 'react';
import format from 'date-fns/format';
import { addYears, isFuture, isAfter } from 'date-fns';
import { withStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ApiService from '../../../common/services/ApiService';
import Autocomplete from '../../../common/components/autocomplete/Autocomplete';
import SelectFieldOptions from '../../../common/config/SelectFieldOptions';
import WaveSelectField from '../../../common/components/form/WaveSelectField';
import Utils from '../../../common/services/Utils';
import InfoIcon from '../../../common/icons/InfoIcon';

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. Unsupported arrow function syntax.
//Unexpected value type of CallExpression.
const styles = (theme) => ({
  adjustedSpacing: {
    padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(
      20
    )} 0 ${theme.typography.pxToRem(20)}`
  },
  adjustedSpacingLeft: {
    padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(
      20
    )} 0 ${theme.typography.pxToRem(25)}`
  },
  adjustedSpacingRight: {
    padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(
      25
    )} 0 ${theme.typography.pxToRem(20)}`
  },
  adjustedSpacingProfileStatus: {
    padding: `${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(
      20
    )} ${theme.typography.pxToRem(40)} ${theme.typography.pxToRem(25)}`
  },
  adjustedSpacingPassportStatus: {
    padding: `${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(
      20
    )} ${theme.typography.pxToRem(40)} ${theme.typography.pxToRem(20)}`
  },
  adjustedSpacingDate: {
    padding: `${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(
      20
    )} ${theme.typography.pxToRem(40)} ${theme.typography.pxToRem(20)}`
  },
  adjustedSpacingUserStatus: {
    padding: `${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(
      25
    )} ${theme.typography.pxToRem(40)} ${theme.typography.pxToRem(20)}`
  },
  clearFilters: {
    color: theme.typography.color.primary,
    '&:hover': {
      color: theme.typography.color.primary,
      cursor: 'pointer'
    },
    '&:visited': {
      color: theme.typography.color.primary
    },
    fontSize: theme.typography.pxToRem(13),
    marginRight: theme.typography.pxToRem(20),
    opacity: 0.7
  },
  filterHeader: {
    padding: `${theme.typography.pxToRem(19)} ${theme.typography.pxToRem(
      25
    )} 0 ${theme.typography.pxToRem(25)}`
  },
  ieFix: Utils.ieDatePickerFix(),
  tooltipContainer: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '2px',
    boxShadow: '0 2px 10px 0 #00000028',
    padding: theme.typography.pxToRem(20),
    opacity: 1
  },
  tooltipText: {
    color: '#0c0606de',
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.5
  },
  popper: {
    opacity: 1
  }
});

const userStatusOptions = {
  true: {
    id: 'active',
    label: 'Active'
  },
  false: {
    id: 'deactivated',
    label: 'Deactivated'
  }
};

class PassportFilters extends React.Component {
  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.state = {
      division_id: 'all',
      divisionOptions: [],
      department_id: 'all',
      departmentOptions: [],
      employment_type_id: 'all',
      employmentTypeOptions: [],
      errorMessage: null,
      passport_status: 'all',
      profile_status: 'all',
      supervisor_id: null,
      supervisorLabel: '',
      filtered_date: null,
      user_status: 'true',
      reportPeriodOptions: [],
      filters: {}
    };
  }

  componentDidMount() {
    this.getFiltersOptions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.division_id !== prevState.division_id) {
      this.setState({ department_id: 'all' });
    }
  }

  arrayToObject = (arr, keyField) =>
    Object.assign({}, ...arr.map((item) => ({ [item[keyField]]: item })));

  clearFilters = () => {
    this.setState({
      division_id: 'all',
      department_id: 'all',
      employment_type_id: 'all',
      errorMessage: null,
      passport_status: 'all',
      profile_status: 'all',
      supervisor_id: null,
      supervisorLabel: '',
      filtered_date: this.getCurrentPeriodOption(
        this.state.reportPeriodOptions
      ),
      user_status: 'true',
      filters: {}
    });

    this.props.handleApplyFilters(this.state.filters);
  };

  // Find the current available period, the correct period is the period which the nearest future end
  getCurrentPeriodOption = (periodOptions) =>
    Object.keys(periodOptions).reduce((currentPeriodOption, periodOption) => {
      if (!currentPeriodOption) {
        return periodOption;
      }

      const periodOptionEnd = addYears(new Date(periodOption), 1);

      return !isFuture(periodOptionEnd) ||
        isAfter(periodOptionEnd, new Date(currentPeriodOption))
        ? currentPeriodOption
        : periodOption;
    }, undefined);

  getDepartments = (departments, divisionId) => {
    var departmentsArray = [];
    Object.keys(departments).map((obj) =>
      departments[obj].division_id === divisionId
        ? departmentsArray.push(departments[obj])
        : ''
    );
    return departmentsArray;
  };

  getFiltersOptions = () => {
    const divisionOptionsPromise = this.api.query('/api/v1/divisions/options');
    const departmentOptionsPromise = this.api.query(
      '/api/v1/departments/options'
    );
    const employmentTypeOptionsPromise = this.api.query(
      '/api/v1/employment_types/options'
    );
    const reportPeriodOptionsPromise = this.api.query(
      '/api/v1/institutes/report_periods'
    );

    Promise.all([
      divisionOptionsPromise,
      departmentOptionsPromise,
      employmentTypeOptionsPromise,
      reportPeriodOptionsPromise
    ])
      .then(
        ([
          divisionOptions,
          departmentOptions,
          employmentTypeOptions,
          reportPeriodOptions
        ]) => {
          this.setState({
            divisionOptions: divisionOptions,
            departmentOptions: departmentOptions,
            employmentTypeOptions: employmentTypeOptions,
            reportPeriodOptions: reportPeriodOptions,
            filtered_date: this.getCurrentPeriodOption(reportPeriodOptions)
          });
          const { onOptionsFetched } = this.props;

          onOptionsFetched();
          this.onFilter();
        }
      )
      .catch(this.processError);
  };

  getTodaysDate() {
    return format(new Date(), 'yyyy-MM-dd');
  }

  handleAutocompleteChange = (id, value) => {
    this.setState({ supervisor_id: id, supervisorLabel: value });
  };

  handleDateChange = (name, date) => {
    const convertedDate =
      date == null ? null : format(new Date(date), 'yyyy-MM-dd');
    this.setState({ [name]: convertedDate });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({ [name]: value });
  };

  makeFieldProps = (fieldName, fieldValue) => {
    return {
      name: fieldName,
      onChange: this.handleInputChange,
      value: fieldValue
    };
  };

  onFilter = () => {
    var filters = this.state.filters;
    filters = {};

    if (this.state.division_id != null && this.state.division_id != 'all') {
      filters['division_id_eq'] = this.state.division_id;
    }
    if (this.state.department_id != null && this.state.department_id != 'all') {
      filters['department_id_eq'] = this.state.department_id;
    }
    if (
      this.state.employment_type_id != null &&
      this.state.employment_type_id != 'all'
    ) {
      filters['employment_type_id_eq'] = this.state.employment_type_id;
    }
    if (
      this.state.profile_status != null &&
      this.state.profile_status != 'all'
    ) {
      filters['profile_status_eq'] = this.state.profile_status;
    }
    if (
      this.state.passport_status != null &&
      this.state.passport_status != 'all'
    ) {
      filters['passport_status_eq'] = this.state.passport_status;
    }
    if (this.state.supervisor_id != null && this.state.supervisor_id != 'all') {
      filters['supervisor_id_eq'] = this.state.supervisor_id;
    }
    if (this.state.filtered_date != null) {
      filters['date_eq'] = this.state.filtered_date;
    }
    if (this.state.user_status != null) {
      filters['user_active_eq'] = this.state.user_status;
    }

    this.props.handleApplyFilters(filters);
  };

  // unexpected errors
  processError = (ex) => {
    console.error(ex);
    this.setState({ ...s, errorMessage: ex.message });
  };

  render() {
    const { classes } = this.props;
    var {
      division_id,
      divisionOptions,
      department_id,
      departmentOptions,
      employment_type_id,
      employmentTypeOptions,
      filtered_date,
      passport_status,
      profile_status,
      supervisorLabel,
      user_status,
      reportPeriodOptions
    } = this.state;
    var divisionIdInt = parseInt(division_id);
    var departmentsArray = this.getDepartments(
      departmentOptions,
      divisionIdInt
    );
    var filteredDepartments = this.arrayToObject(departmentsArray, 'id');

    return (
      <Fragment>
        <Grid
          container
          className={classes.filterHeader}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h6">Filter</Typography>
          <Grid item>
            <a
              className={classes.clearFilters}
              onClick={() => this.clearFilters()}
            >
              Clear
            </a>
            <Button
              color="secondary"
              onClick={() => this.onFilter()}
              size="small"
              variant="contained"
            >
              <Typography variant="button">Filter</Typography>
            </Button>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item className={classes.adjustedSpacingLeft} xs={3}>
            <WaveSelectField
              filterByAllItem
              filterByAllItemLabel="All"
              noValidation
              label="Division"
              menuItems={divisionOptions}
              {...this.makeFieldProps('division_id', division_id)}
            />
          </Grid>
          <Grid item className={classes.adjustedSpacing} xs={3}>
            <WaveSelectField
              filterByAllItem
              filterByAllItemLabel="All"
              noValidation
              label="Department"
              menuItems={
                divisionIdInt ? filteredDepartments : departmentOptions
              }
              {...this.makeFieldProps('department_id', department_id)}
            />
          </Grid>
          <Grid item className={classes.adjustedSpacing} xs={3}>
            <WaveSelectField
              filterByAllItem
              filterByAllItemLabel="All"
              noValidation
              label="Employment Type"
              menuItems={employmentTypeOptions}
              {...this.makeFieldProps('employment_type_id', employment_type_id)}
            />
          </Grid>
          <Grid item className={classes.adjustedSpacingRight} xs={3}>
            <Autocomplete
              label="Supervisor"
              name="supervisor_id"
              onChange={this.handleAutocompleteChange}
              role="supervisor"
              value={supervisorLabel || ''}
            />
          </Grid>

          <Grid item className={classes.adjustedSpacingProfileStatus} xs={3}>
            <WaveSelectField
              filterByAllItem
              filterByAllItemLabel="All"
              noValidation
              label="Profile Status"
              menuItems={SelectFieldOptions.profileStatus}
              {...this.makeFieldProps('profile_status', profile_status)}
            />
          </Grid>
          <Grid item className={classes.adjustedSpacingPassportStatus} xs={3}>
            <WaveSelectField
              filterByAllItem
              filterByAllItemLabel="All"
              noValidation
              label="Passport Status"
              menuItems={SelectFieldOptions.passportStatus}
              {...this.makeFieldProps('passport_status', passport_status)}
            />
          </Grid>
          <Grid item className={classes.adjustedSpacingDate} xs={3}>
            <WaveSelectField
              noValidation
              label="Period"
              menuItems={reportPeriodOptions}
              tooltipProps={{
                placement: 'top',
                titleComponent: (
                  <Typography className={classes.tooltipText}>
                    The time-frame (passport year) as defined by the institute.
                  </Typography>
                ),
                tooltipContainer: classes.tooltipContainer,
                tooltipPopper: classes.popper
              }}
              {...this.makeFieldProps('filtered_date', filtered_date)}
            />
          </Grid>
          <Grid item className={classes.adjustedSpacingUserStatus} xs={3}>
            <WaveSelectField
              filterByAllItem
              filterByAllItemLabel="All"
              noValidation
              label="User Status"
              menuItems={userStatusOptions}
              {...this.makeFieldProps('user_status', user_status)}
            />
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(PassportFilters, styles, { withTheme: true });
