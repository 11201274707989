import React, { Fragment } from 'react';
import Typography from '@mui/material/Typography';
import format from "date-fns/format";
import Chisholm from "../../../common/config/Chisholm";

class UserFormSummary extends React.Component {

  render() {
    const { classes, user } = this.props;
    var visibility = 'visible';
    user.is_educator ? visibility = 'visible' : visibility = 'hidden';

    return (
      <Fragment>
        <Typography variant="h5">
          {user.full_name || "New User"}
        </Typography>

        <div className={classes.userRoles}>
          <Typography className={classes.userRolesText} color="primary">
            {(user.employment_type && user.employment_type.name) || "Employment Type"}
          </Typography>
          <Typography
            className={classes.userRolesText}
            color="primary"
            style={{visibility: `${visibility}`}}
          >
            Supervisor - {(user.supervisor && user.supervisor.full_name) || ''}
          </Typography>
          { user.current_passport &&
            <Typography className={classes.userRolesText} color="primary">
              Passport Obtained - {format(new Date(user.current_passport.issued_at), Chisholm.standardDate)}
            </Typography>
          }
        </div>
      </Fragment>
    );
  }

}

export default UserFormSummary;
