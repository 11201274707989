import React from 'react';
import Checkbox from '@mui/material/Checkbox';

export const AttendanceCheckboxCell = ({ attribute, onCellEdit, row, disableOnCompleted, }) => {
  const value = row[attribute];
  const handleChange = event => {
    onCellEdit(event.target.checked);
  };

  return <Checkbox checked={value} color="primary" onChange={handleChange} disabled={row.completed && disableOnCompleted} />;
};
