import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';

import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';

import Autocomplete from '../../../common/components/autocomplete/Autocomplete';

const styles = theme => ({
  closeModal: {
    color: theme.typography.color.primary,
    cursor: 'pointer',
    opacity: 0.3,
  },
  profileOption: {
    fontFamily: 'IBM Plex Sans, medium',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500,
    opacity: 0.7,
  },
  selectProfiles: {
    padding: `0 ${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(25)}`,
    '&:hover' : {
      cursor: 'pointer',
    },
  },
  selectSupervisor: {
    padding: `${theme.typography.pxToRem(24)} ${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(25)}`,
    '&:hover' : {
      cursor: 'pointer',
    },
  },
  supervisorButton: {
    marginTop: theme.typography.pxToRem(15),
  },
  supervisorModal: {
    backgroundColor: theme.palette.background.paper,
    left: '50%',
    margin: '0',
    padding: `${theme.typography.pxToRem(24)} ${theme.typography.pxToRem(29)}`,
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
  },
  supervisorModalHeader: {
    marginBottom: theme.typography.pxToRem(22),
  },
  supervisorModalHelper: {
    color: '#1f1f1fde',
    fontSize: theme.typography.pxToRem(15),
    lineHeight: 1.6,
    marginBottom: theme.typography.pxToRem(30),
  },
});

class SupervisorFilter extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      autocompleteId: null,
      autocompleteValue: '',
      open: false,
    };
  }

  handleOpenSupervisorModal = () => {
    this.setState({ open: true });
  };

  handleCloseSupervisorModal = () => {
    this.setState({ open: false });
  };

  handleAutocompleteChange = (id, value) => {
    this.setState({ autocompleteId: id, autocompleteValue: value });
  };

  onProfileSelect = (event) => {
    const target = event.target;
    const value = target.value;

    this.props.handleProfileChange(value);
  };

  onSupervisorSelect = (id, name) => {
    this.props.handleSupervisorChange(id, name);

    this.setState({ autocompleteId: null, autocompleteValue: '' });
    this.handleCloseSupervisorModal();
  };

  render() {
    const { classes, currentProfile, currentSupervisor, user } = this.props;
    const { autocompleteId, autocompleteValue, open } = this.state;
    const canPickSupervisor = user.is_super_admin || user.is_institute_admin;

    return (
      <Fragment>
        <Grid
          container
          className={classes.selectSupervisor}
          justifyContent="space-between"
          onClick={canPickSupervisor ? this.handleOpenSupervisorModal : undefined}
        >
          <Typography variant="h6">
            {`${currentSupervisor}’s Educators`}
          </Typography>
          {canPickSupervisor && <ArrowDropDownIcon />}
        </Grid>
        <Grid container className={classes.selectProfiles}>
          <FormControl variant="standard" fullWidth>
            <Select
              variant="standard"
              disableUnderline
              onChange={this.onProfileSelect}
              value={currentProfile}>
              <MenuItem component='div' value="open">
                <Typography className={classes.profileOption}>
                  Open Profiles
                </Typography>
              </MenuItem>
              <MenuItem component='div' value="resolved">
                <Typography className={classes.profileOption}>
                  Resolved Profiles
                </Typography>
              </MenuItem>
              <MenuItem component='div' value="in_progress">
                <Typography className={classes.profileOption}>
                  Educator In Progress
                </Typography>
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Modal open={open} onClose={this.handleCloseSupervisorModal}>
          <div className={classes.supervisorModal}>
            <Grid
              container
              className={classes.supervisorModalHeader}
              justifyContent="space-between"
            >
              <Typography variant="h5">Select a Supervisor</Typography>
              <a onClick={this.handleCloseSupervisorModal}>
                <CloseIcon className={classes.closeModal}/>
              </a>
            </Grid>
            <Typography className={classes.supervisorModalHelper}>
              Choose a Supervisor to see their Educators
            </Typography>
            <Autocomplete
              modalMode
              label="Search by given name, family name or employee number"
              onChange={this.handleAutocompleteChange}
              role={'supervisor'}
              value={autocompleteValue}
            />
            <Button
              className={classes.supervisorButton}
              color="secondary"
              disabled={!autocompleteId}
              onClick={() => this.onSupervisorSelect(autocompleteId, autocompleteValue)}
              variant="contained"
            >
              <Typography variant="button">Select Supervisor</Typography>
            </Button>
          </div>
        </Modal>
      </Fragment>
    );
  }

}

export default withStyles(SupervisorFilter, styles, { withTheme: true });
