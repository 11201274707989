import React from 'react';
import { withStyles } from 'tss-react/mui';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import ApiService from '../../../common/services/ApiService';
import ProgramsMonthlyFilters from './ProgramsMonthlyFilters';
import ReportConfig from '../../../common/config/Reports';
import ReportTable from '../../../common/components/table/ReportTable';

const styles = theme => ({
  exportHeader: {
    padding: `${theme.typography.pxToRem(22)} ${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(25)}`,
  },
});

class ProgramsMonthly extends React.Component {

  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.state = {
      filters: { user_active_eq: "1" },
      loading: true,
      reportData: [],
    };
  }

  componentDidMount = () => {
    this.getAllData();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.filters !== this.state.filters) {
      this.getAllData();
    }
  }

  splitFilters = () => {
    var q_filters = {};
    var params = {};
    if (this.state.filters != null) {
      Object.keys(this.state.filters).forEach((key) => {
        if(key === "year" || key ===  "mode" || key === "view_by") {
          params[key] = this.state.filters[key]
        } else {
          q_filters[key] = this.state.filters[key]
        }
      });
    }
    return [q_filters, params];
  };

  getAllData = () => {
    var pagination = null;

    var [q_filters, params] = this.splitFilters();

    var options = {
      filters: q_filters,
      params,
      pagination,
      sorting: {
        'direction': 'asc',
        'field': '',
      },
    };

    this.api.search('', options, 'reports/program_monthly').then((data) => {
      this.setState({
        loading: false,
        reportData: data.data,
      });
    }).catch((ex) => {
      console.error(ex);
      this.setState({ loading: false });
    });
  };

  handleApplyFilters = (filters) => {
    this.setState({ filters: filters });
  };

  handleExport = () => {
    var sortingParams = { direction: 'asc', field: '' };
    var [filters, params] = this.splitFilters();

    var searchRequest = this.api.searchUrl(filters, params, null, sortingParams, null);

    var apiUrl = '';
    searchRequest !== '' ? apiUrl = '/api/v1/reports/program_monthly.csv?' : apiUrl = '/api/v1/reports/program_monthly.csv';
    var csvUrl = apiUrl + searchRequest;
    return csvUrl;
  };

  render() {
    const { classes } = this.props;
    const { reportData } = this.state;

    if (this.state.loading == true) {
      return (<Paper>Loading...</Paper>);
    }

    return (
      <Grid container className={classes.programsMonthlyReport} spacing={2}>
        <Grid item xs={12}>
          <Paper>
            <ProgramsMonthlyFilters handleApplyFilters={this.handleApplyFilters} />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Grid
              container
              className={classes.exportHeader}
              justifyContent="space-between"
            >
              <Typography variant="h6">Programs (Monthly)</Typography>
              <a href={`${this.handleExport()}`} target="_blank">
                <Button color="secondary" size="small" variant="contained">
                  <Typography variant="button">Export</Typography>
                </Button>
              </a>
            </Grid>
            <ReportTable
              reportData={reportData}
              tableConfig={ReportConfig.programsMonthly}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }

}

export default withStyles(ProgramsMonthly, styles, { withTheme: true });
