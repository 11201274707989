import { attendanceStyles } from './style';
import React from 'react';
import { DefaultModal } from '@common/components/default-modal';
import { DefaultButton } from '@common/components/default-button';
import { DefaultCheckbox } from '@common/components/default-checkbox';
import { withStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';

class AttendanceProgramCompleteModal extends React.Component {
  state = {
    notify_user: true
  };

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { notify_user } = this.state;

    onSubmit(notify_user);
  };

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({ [name]: value });
  };

  render() {
    const { onSubmit, classes, ...rest } = this.props;
    const { notify_user } = this.state;

    return (
      <DefaultModal
        {...rest}
        action={
          <DefaultButton
            containerClass={classes.markEducatorIncomplete}
            color="secondary"
            onClick={this.handleSubmit}
            size="small"
            variant="contained"
          >
            Add
          </DefaultButton>
        }
        title="Mark Educator as Complete"
      >
        <Typography className={classes.incompletEducatorModalHelper}>
          Add points to the Educators program history!
        </Typography>
        <Grid item xs={12}>
          <FormControl variant="standard">
            <FormGroup row>
              <DefaultCheckbox
                name="notify_user"
                label="Notify the user that points have been added to their points history"
                onChange={this.handleInputChange}
                checkboxValue={true}
                value={notify_user}
              />
            </FormGroup>
          </FormControl>
        </Grid>
      </DefaultModal>
    );
  }
}

const StyledAttendanceProgramCompleteModal = withStyles(AttendanceProgramCompleteModal, attendanceStyles, {
  withTheme: true
});

export {
  StyledAttendanceProgramCompleteModal as AttendanceProgramCompleteModal
};
