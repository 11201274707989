import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export const DefaultButton = ({
  children,
  containerClass,
  titleClass,
  onClick,
  ...rest
}) => (
  <Button className={containerClass} onClick={onClick} {...rest}>
    <Typography className={titleClass} variant="button">
      {children}
    </Typography>
  </Button>
);
