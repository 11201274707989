import { green, lightBlue } from '@mui/material/colors';
import Utils from '@common/services/Utils';

export const pointRequestStyles = (theme) => ({
  pointRequest: {
    padding: theme.typography.pxToRem(25)
  },
  actionButtons: {
    marginLeft: theme.typography.pxToRem(18)
  },
  editIconButton: {
    marginRight: theme.typography.pxToRem(10)
  },
  approveButton: {
    backgroundColor: green[600]
  },
  rejectButton: {
    backgroundColor: theme.palette.error.dark
  },
  convertButton: {
    backgroundColor: lightBlue[600]
  },
  span: {
    display: 'inline'
  },
  div: {
    display: 'block'
  }
});

export const modalStyles = (theme) => ({
  editButton: {
    marginTop: theme.typography.pxToRem(15)
  },
  pointsFieldSpacing: {
    paddingRight: theme.typography.pxToRem(27)
  },
  ieFix: Utils.ieDatePickerFix()
});
