import React, { useCallback, useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';
import ApiService from '@common/services/ApiService';
import { DefaultModal } from '@common/components/default-modal';
import { DefaultButton } from '@common/components/default-button';
import { DefaultCheckbox } from '@common/components/default-checkbox';
import WaveTextField from '@common/components/form/WaveTextField';
import WaveTextArea from '@common/components/form/WaveTextArea';
import { modalStyles } from './styles';

const ApproveModal = ({
  classes,
  isOpened,
  onClose,
  onRefreshRequest,
  pointRequest,
  user
}) => {
  const { points, learning_journey_equivalent, type } = pointRequest || {};
  const api = new ApiService();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);
  const initialData = {
    points,
    learning_journey_equivalent,
    notify_user: true
  };
  const [data, setData] = useState(initialData);

  useEffect(() => {
    const { points, learning_journey_equivalent } = pointRequest || {};
    setData((prevData) => ({
      ...prevData,
      points,
      learning_journey_equivalent
    }));
  }, [pointRequest, setData]);

  const errorStatus = useCallback(
    (field) => {
      return validationErrors && validationErrors.hasOwnProperty(field);
    },
    [validationErrors]
  );

  const errorHelpers = useCallback(
    (field) => {
      return errorStatus(field) ? validationErrors[field][0] : null;
    },
    [errorStatus, validationErrors]
  );

  const makeFieldProps = useCallback(
    (field, fieldValue, errorField) => {
      return {
        error: errorStatus(errorField || field),
        helperText: errorHelpers(errorField || field),
        name: field,
        value: fieldValue
      };
    },
    [errorStatus, errorHelpers]
  );

  const handleInputChange = useCallback(
    (event) => {
      const { value, name } = event.target;

      setData({ ...data, [name]: value });
    },
    [data, setData]
  );

  const setErrors = useCallback(
    (json) => {
      if (json.code == 1) {
        setErrorMessage(json.message);
      } else if (json.code == 2) {
        setValidationErrors(json.messages);
      }
    },
    [setErrorMessage, setValidationErrors]
  );

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);

    try {
      const url = `/api/v1/users/${user.id}/point_requests/${pointRequest.id}/approve`;
      const res = await api.put(url, data);

      if (res.status === 204) {
        handleClose();
        onRefreshRequest();
      } else if (res.status === 422 || res.status === 400) {
        setErrors(res.json);

        if (res.status === 422) {
          let errorMessage = res.json.message;
          if (errorMessage.includes('Vocational activity')) {
            errorMessage = errorMessage.replace('Vocational activity', '');
          }

          const message = `${errorMessage}. Please close this box and edit this record to add any missing information before further action can be taken.`;
          setErrorMessage(message);
        }
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [data, pointRequest]);

  const handleClose = useCallback(() => {
    setErrorMessage(null);
    setValidationErrors([]);
    setData(initialData);
    onClose();
  }, [setErrorMessage, setValidationErrors, onClose]);

  return (
    <DefaultModal
      isOpened={isOpened}
      isLoading={isLoading}
      onClose={handleClose}
      title="Approve Activity"
      action={
        <DefaultButton
          className={classes.editButton}
          color="secondary"
          onClick={handleSubmit}
          size="small"
          variant="contained"
        >
          Approve
        </DefaultButton>
      }
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography color="error">{errorMessage}</Typography>
        </Grid>
        <Grid item xs={12}>
          <WaveTextArea
            label="Approval Reason"
            placeholder="Enter approval reason here"
            maxLength={1000}
            rows={4}
            onChange={handleInputChange}
            {...makeFieldProps('approval_reason', data.approval_reason)}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>Add points to the Educators Points History</Typography>
        </Grid>
        <Grid item xs={12}>
          <WaveTextField
            label="Points"
            name="points"
            onChange={handleInputChange}
            value={data.points}
          />
        </Grid>
        {type === 'ReaPointRequest' && (
          <Grid item xs={12}>
            <DefaultCheckbox
              name="learning_journey_equivalent"
              label="Learning Journey Equivalent"
              checkboxValue={true}
              unCheckValue={false}
              onChange={handleInputChange}
              value={data.learning_journey_equivalent}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <DefaultCheckbox
            name="notify_user"
            label="Notify the Educator by email that the points have been added to their points history"
            onChange={handleInputChange}
            checkboxValue={true}
            unCheckValue={false}
            value={data.notify_user}
          />
        </Grid>
      </Grid>
    </DefaultModal>
  );
};

export default withStyles(ApproveModal, modalStyles, { withTheme: true });
