import React from 'react';
import { withStyles } from 'tss-react/mui';
import { defaultModalStyles } from './styles';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import clsx from 'clsx';

const DefaultModal = ({
  isOpened,
  onClose,
  classes,
  isLoading,
  action,
  children,
  title,
  wide,
}) => {
  const containerClass = wide ? clsx(classes.modalContentContainer, classes.wideModalContentContainer) : classes.modalContentContainer

  return (
    <Modal open={isOpened} onClose={onClose}>
      <div className={containerClass}>
        <Grid container className={classes.modalHeader} justifyContent="space-between">
          <Typography variant="h5">{title}</Typography>
          <a onClick={onClose}>
            <CloseIcon className={classes.modalClose} />
          </a>
        </Grid>
        {children}
        {isLoading ? <CircularProgress /> : action}
      </div>
    </Modal>
  );
};

const StyledDefaultModal = withStyles(DefaultModal, defaultModalStyles, { withTheme: true });

export { StyledDefaultModal as DefaultModal };
