import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function DownIcon(props) {
  return (
    <SvgIcon {...props} style={{opacity: 0.6}}>
      <path d="M12 18l-6-6 1.4-1.4 4.6 4.6 4.6-4.6L18 12z"/>
    </SvgIcon>
  );
}
