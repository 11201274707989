import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Grid';
import { DatePicker } from '@mui/x-date-pickers';
import EventIcon from '@mui/icons-material/Event';
import Chisholm from '../../../../common/config/Chisholm';
import { notificationReportFilterStyles } from './styles';
import { withStyles } from 'tss-react/mui';
import { WaveDatePicker } from '../../../../common/components/WaveDatePicker';

class NotificationReportFilter extends React.Component {
  state = {
    filters: {
      date_from_eq: this.props.passportPeriod.from,
      date_to_eq: this.props.passportPeriod.to
    }
  };

  handleClearFilters = () => {
    const { onFilterChange, passportPeriod } = this.props;

    this.setState(
      {
        filters: {
          date_from_eq: passportPeriod.from,
          date_to_eq: passportPeriod.to
        }
      },
      () => {
        onFilterChange(undefined);
      }
    );
  };

  handleFieldChange = name => value => {
    this.setState(({ filters }) => ({
      filters: {
        ...filters,
        [name]: value
      }
    }));
  };

  handleUpdateFilter = () => {
    const { onFilterChange } = this.props;
    const { filters } = this.state;

    onFilterChange(filters);
  };

  render() {
    const { date_from_eq, date_to_eq } = this.state.filters || {};
    const {
      filterContainer,
      filterHeaderContainer,
      clearButton,
      ieDatePickerFix
    } = this.props.classes;

    return (
      <Paper className={filterContainer}>
        <div className={filterHeaderContainer}>
          <Typography variant="h6">Filter</Typography>
          <div>
            <Button
              color="secondary"
              variant="text"
              size="small"
              className={clearButton}
              onClick={this.handleClearFilters}
            >
              Clear
            </Button>
            <Button
              color="secondary"
              onClick={this.handleUpdateFilter}
              size="small"
              variant="contained"
            >
              Filter
            </Button>
          </div>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <WaveDatePicker
              format={Chisholm.rangeDate}
              label="From"
              name="date_from_eq"
              onChange={this.handleFieldChange('date_from_eq')}
              value={date_from_eq}
              className={ieDatePickerFix}
            />
          </Grid>
          <Grid item xs={3}>
            <WaveDatePicker
              format={Chisholm.rangeDate}
              label="To"
              name="date_to_eq"
              onChange={this.handleFieldChange('date_to_eq')}
              value={date_to_eq}
              className={ieDatePickerFix}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

const StyledNotificationReportFilter = withStyles(NotificationReportFilter, notificationReportFilterStyles, {
  withTheme: true
});

export { StyledNotificationReportFilter as NotificationReportFilter };
