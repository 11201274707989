import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';

import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';

import ApiService from '../../../common/services/ApiService';

const styles = (theme) => ({
  capabilityStatement: {
    fontFamily: 'IBM Plex Sans, medium',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500,
    lineHeight: 1.13,
    marginBottom: theme.typography.pxToRem(6)
  },
  currentDomain: {
    fontFamily: 'IBM Plex Sans, semi-bold',
    fontSize: theme.typography.pxToRem(13),
    fontWeight: 600,
    lineHeight: 2.15,
    marginBottom: theme.typography.pxToRem(16),
    textTransform: 'uppercase'
  },
  radioField: {
    marginRight: theme.typography.pxToRem(27)
  },
  responses: {
    fontSize: theme.typography.pxToRem(13),
    opacity: 0.7
  },
  singleCapability: {
    marginBottom: theme.typography.pxToRem(23)
  }
});

class ResponsesForm extends React.Component {
  constructor(props) {
    super(props);
    this.api = new ApiService();
  }

  convertResponse = (response) => {
    if (response === null) {
      return 'No response given';
    }

    var label = this.props.responseLabels.find((label) => {
      return label.value === response;
    });

    if (label) {
      return label.text;
    } else {
      return response + '';
    }
  };

  getSupervisorResponse = (response) => {
    if (!response) {
      return null;
    }
    if (response.SupervisorResponse === null) {
      return null;
    } else {
      return response.SupervisorResponse.toString();
    }
  };

  handleResponse = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    const postUrl = '/api/v1/supervisor_responses';
    var responseData = {
      supervisor_response: {
        profile_id: this.props.profile.id,
        capability_id: parseInt(name),
        value: parseInt(value)
      }
    };

    this.api
      .post(postUrl, responseData)
      .then((res) => {
        if (res.status === 201) {
          this.props.updateProgressBar(
            res.json.meta.profile.supervisor_completed_percent
          );
          this.props.updateResponses(res.json.data, this.props.responsesList);
        } else {
          console.error('Unknown response', res);
        }
      })
      .catch(this.processError);
  };

  renderCapabilityQuestions = (domainData, responsesList, disableRadioBtn) => {
    const { classes, responseLabels, profile } = this.props;
    var domainCapabilities = domainData.capabilities;
    const completedWithoutSupervisor =
      profile.completed && !profile.supervisor_completed;

    return Object.keys(domainCapabilities).map((obj, index) => {
      var capability = domainCapabilities[index];
      var capabilityId = capability.id;
      var supervisorResponse =
        responsesList[capabilityId] === null
          ? null
          : this.getSupervisorResponse(responsesList[capabilityId]);

      const radios = responseLabels.map((responseLabel) => {
        return (
          <FormControlLabel
            key={responseLabel.value + ':' + responseLabel.text}
            classes={{ root: classes.radioField }}
            control={<Radio disabled={disableRadioBtn} />}
            label={responseLabel.text}
            value={responseLabel.value + ''}
          />
        );
      });

      return (
        <Grid container className={classes.singleCapability} key={capabilityId}>
          <Typography className={classes.capabilityStatement}>
            {`${index + 1}. ${capability.statement}`}
          </Typography>
          <Grid container direction="column">
            <Typography className={classes.responses}>
              Educator Response:{' '}
              {responsesList[capabilityId] === undefined
                ? 'No response given'
                : this.convertResponse(
                    responsesList[capabilityId].EducatorResponse
                  )}
            </Typography>
            <Typography className={classes.responses}>
              Business Ideal:{' '}
              {responsesList[capabilityId] === undefined
                ? 'No response given'
                : this.convertResponse(
                    responsesList[capabilityId].BusinessIdealResponse
                  )}
            </Typography>
          </Grid>
          {completedWithoutSupervisor ? null : (
            <FormControl variant="standard" fullWidth>
              <RadioGroup
                row
                name={`${capabilityId}`}
                onChange={this.handleResponse}
                value={supervisorResponse}
              >
                {radios}
              </RadioGroup>
            </FormControl>
          )}
        </Grid>
      );
    });
  };

  render() {
    const { classes, currentDomain, disableRadioBtn, responsesList } =
      this.props;

    return (
      <Fragment>
        <Typography className={classes.currentDomain}>
          {currentDomain.name}
        </Typography>
        {this.renderCapabilityQuestions(
          currentDomain,
          responsesList,
          disableRadioBtn
        )}
      </Fragment>
    );
  }
}

export default withStyles(ResponsesForm, styles, { withTheme: true });
