import Utils from '../../../../common/services/Utils';

export const notificationReportStyles = theme => ({
  reportHeader: {
    padding: '22px 25px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
});

export const notificationReportFilterStyles = theme => ({
  filterContainer: {
    padding: '22px 25px 40px 25px',
    marginBottom: 16
  },
  filterHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  clearButton: {
    marginRight: 16
  },
  ieDatePickerFix: Utils.ieDatePickerFix()
});
