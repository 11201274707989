import React from 'react';
import { withStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ReportConfig from '../../../common/config/Reports';

const styles = theme => ({
  lastRow: {
    borderBottom: 'none'
  },
  numericCell: {
    textAlign: 'center'
  },
  tableContainer: {
    overflowX: 'auto',
    paddingBottom: theme.typography.pxToRem(15)
  }
});

class ReportTable extends React.Component {
  render() {
    const { classes, tableConfig, reportData } = this.props;
    var headerRow = reportData[0] || {};
    var headerArr = [];
    Object.keys(headerRow).map(obj => {
      if (obj !== 'row_type') {
        headerArr.push(obj);
      }
    });

    const header = Object.keys(headerRow).map(obj => {
      if (obj !== 'row_type') {
        var colConfig = tableConfig[obj];
        var textStyles = colConfig && colConfig.textOptions;
        var cellOptions = colConfig && colConfig.cellOptions;
        return (
          <TableCell
            key={obj}
            padding={cellOptions && cellOptions.padding}
            style={textStyles}
          >
            {headerRow[obj]}
          </TableCell>
        );
      }
    });

    const body = reportData.map((row, index) => {
      if (row.row_type !== 'HEADER') {
        switch (row.row_type) {
          case 'SECTION':
            var rowStyles = Object.assign({}, ReportConfig.sectionRow);
            break;
          case 'TOTAL':
            var rowStyles = Object.assign({}, ReportConfig.totalRow);
            break;
          default:
            '';
        }

        return (
          <TableRow key={index} style={rowStyles}>
            {headerArr.map(columnName => {
              var colConfig = tableConfig[columnName];
              var textStyles = colConfig && colConfig.textOptions;
              var cellOptions = colConfig && colConfig.cellOptions;

              return (
                <TableCell
                  className={row.row_type === 'TOTAL' ? classes.lastRow : ''}
                  key={columnName}
                  padding={cellOptions && cellOptions.padding}
                  style={textStyles}
                >
                  {row[columnName]}
                </TableCell>
              );
            })}
          </TableRow>
        );
      }
    });

    return (
      <div className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>{header}</TableRow>
          </TableHead>
          <TableBody>{body}</TableBody>
        </Table>
      </div>
    );
  }
}

export default withStyles(ReportTable, styles, { withTheme: true });
