import React, { Fragment } from 'react';
import format from 'date-fns/format';
import { withStyles } from 'tss-react/mui';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import EventIcon from '@mui/icons-material/Event';
import { DatePicker } from '@mui/x-date-pickers';

import ApiService from '../../../common/services/ApiService';
import Chisholm from '../../../common/config/Chisholm';
import WaveSelectField from '../../../common/components/form/WaveSelectField';
import InfoIcon from '../../../common/icons/InfoIcon';

const styles = theme => ({
  adjustedSpacing: {
    padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(20)} 0 ${theme.typography.pxToRem(20)}`,
  },
  adjustedSpacingLeft: {
    padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(20)} 0 ${theme.typography.pxToRem(25)}`,
  },
  adjustedSpacingEngagement: {
    padding: `${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(40)} ${theme.typography.pxToRem(20)}`,
  },
  adjustedSpacingUserStatus: {
    padding: `${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(40)} ${theme.typography.pxToRem(20)}`,
  },
  adjustedSpacingViewBy: {
    padding: `${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(40)} ${theme.typography.pxToRem(25)}`,
  },
  clearFilters: {
    color: theme.typography.color.primary,
    '&:hover': {
      color: theme.typography.color.primary,
      cursor: 'pointer',
    },
    '&:visited': {
      color: theme.typography.color.primary,
    },
    fontSize: theme.typography.pxToRem(13),
    marginRight: theme.typography.pxToRem(20),
    opacity: 0.7,
  },
  filterHeader: {
    padding: `${theme.typography.pxToRem(19)} ${theme.typography.pxToRem(25)} 0 ${theme.typography.pxToRem(25)}`
  },
  tooltipContainer: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '2px',
    boxShadow: '0 2px 10px 0 #00000028',
    padding: theme.typography.pxToRem(20),
    opacity: 1
  },
  tooltipText: {
    color: '#0c0606de',
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.5
  },
  popper: {
    opacity: 1
  }
});

const registrationModeOptions = {
  'registrations': {
    id: 'registrations',
    label: 'Registrations',
    tooltip: 'Number of registrations made.'
  },
  'registrants': {
    id: 'registrants',
    label: 'Unique Registrants',
    tooltip: 'Distinct users that have registered.'
  },
  'completions': {
    id: 'completions',
    label: 'Unique Completions',
    tooltip: 'Distinct users that have completed a program. Completed is defined as registering and having the attendance for the program marked as complete.'
  }
};

const viewByOptions = {
  'code': {
    id: 'code',
    label: 'Reporting Code'
  },
  'name': {
    id: 'name',
    label: 'Program Name'
  },
  'program_type': {
    id: 'program_type',
    label: 'Program Type'
  },
  'domain_id': {
    id: 'domain_id',
    label: 'Domain'
  }
};

const userStatusOptions = {
  'true': {
    id: 'active',
    label: 'Active'
  },
  'false': {
    id: 'deactivated',
    label: 'Deactivated'
  }
};

class ProgramsMonthlyFilters extends React.Component {
  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.state = {
      division_id: 'all',
      divisionOptions: [],
      department_id: 'all',
      departmentOptions: [],
      errorMessage: null,
      filters: {},
      mode: 'registrations',
      view_by: 'name',
      year: this.getCurrentYear(),
      yearsRange: this.createYearRange(),
      groupCalculationTooltipTitle: 'Number of registrations made.',
      user_status: 'true',
    };
  }

  componentDidMount() {
    this.getFiltersOptions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.division_id !== prevState.division_id) {
      this.setState({ department_id: 'all' });
    };
  }

  arrayToObject = (arr, keyField) =>
    Object.assign({}, ...arr.map(item => ({[item[keyField]]: item}))
  );

  clearFilters = () => {
    this.setState({
      division_id: 'all',
      department_id: 'all',
      errorMessage: null,
      filters: {},
      mode: 'registrations',
      view_by: 'name',
      year: this.getCurrentYear(),
      groupCalculationTooltipTitle: 'Number of registrations made.',
      user_status: 'true',
    });

    this.props.handleApplyFilters(this.state.filters);
  };

  getDepartments = (departments, divisionId) => {
    var departmentsArray = [];
    Object.keys(departments).map(obj =>
      departments[obj].division_id === divisionId ? departmentsArray.push(departments[obj]) : ''
    );
    return departmentsArray;
  };

  getFiltersOptions = () => {
    const divisionOptionsPromise = this.api.query('/api/v1/divisions/options');
    const departmentOptionsPromise = this.api.query('/api/v1/departments/options');

    Promise.all([divisionOptionsPromise, departmentOptionsPromise]).then(([divisionOptions, departmentOptions]) => {
      this.setState({
        divisionOptions: divisionOptions,
        departmentOptions: departmentOptions,
      });
    }).catch(this.processError);
  };

  getCurrentYear() {
    return format(new Date().setFullYear(new Date().getFullYear()), 'yyyy');
  };

  createYearRange = () => {
    const numYears = 11;
    var yearsArr = [];
    var y = format(new Date().setFullYear(new Date().getFullYear() + 1), 'yyyy');
    for (var i = 0; i < numYears; i++) {
      yearsArr.push({id: y - i, label: y - i});
    }
    return this.arrayToObject(yearsArr, 'id');
  };

  handleDateChange = (name, date) => {
    const convertedDate = date == null ? null : format(new Date(date), 'yyyy-MM-dd');
    this.setState({ [name]: convertedDate });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if(name == "mode") {
      let selectedMode = Object.values(registrationModeOptions).find(element => {
        return element.id == value;
      });
      this.setState({
        groupCalculationTooltipTitle: selectedMode.tooltip
      });
    }

    this.setState({ [name]: value });
  };

  makeFieldProps = (fieldName, fieldValue) => {
    return {
      name: fieldName,
      onChange: this.handleInputChange,
      value: fieldValue,
    };
  };

  onFilter = () => {
    var filters = this.state.filters;
    filters = {};

    if (this.state.division_id != null && this.state.division_id != 'all') {
      filters['division_id_eq'] = this.state.division_id;
    }
    if (this.state.department_id != null && this.state.department_id != 'all') {
      filters['department_id_eq'] = this.state.department_id;
    }
    if (this.state.year != null) {
      filters['year'] = this.state.year;
    }
    if (this.state.mode != null) {
      filters['mode'] = this.state.mode;
    }
    if (this.state.view_by != null) {
      filters['view_by'] = this.state.view_by;
    }
    if (this.state.user_status != null) {
      filters['user_active_eq'] = this.state.user_status;
    }

    this.props.handleApplyFilters(filters);
  }

  // unexpected errors
  processError = (ex) => {
    console.error(ex);
    this.setState({ ...s, errorMessage: ex.message });
  };

  render() {
    const { classes } = this.props;
    var { division_id, divisionOptions, department_id, departmentOptions, from_date, mode, view_by, year, yearsRange, groupCalculationTooltipTitle, user_status } = this.state;
    var divisionIdInt = parseInt(division_id);
    var departmentsArray = this.getDepartments(departmentOptions, divisionIdInt);
    var filteredDepartments = this.arrayToObject(departmentsArray, 'id');

    return (
      <Fragment>
        <Grid
          container
          className={classes.filterHeader}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h6">Filter</Typography>
          <Grid item>
            <a
              className={classes.clearFilters}
              onClick={() => this.clearFilters()}
            >
              Clear
            </a>
            <Button
              color="secondary"
              onClick={() => this.onFilter()}
              size="small"
              variant="contained"
            >
              <Typography variant="button">Filter</Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item className={classes.adjustedSpacingLeft} xs={3}>
            <WaveSelectField
              filterByAllItem
              filterByAllItemLabel="All"
              noValidation
              label="Division"
              menuItems={divisionOptions}
              {...this.makeFieldProps('division_id', division_id)}
            />
          </Grid>
          <Grid item className={classes.adjustedSpacing} xs={3}>
            <WaveSelectField
              filterByAllItem
              filterByAllItemLabel="All"
              noValidation
              label="Department"
              menuItems={divisionIdInt ? filteredDepartments : departmentOptions}
              {...this.makeFieldProps('department_id', department_id)}
            />
          </Grid>
          <Grid item className={classes.adjustedSpacing} xs={3}>
            <WaveSelectField
              noValidation
              label="Period"
              menuItems={yearsRange}
              {...this.makeFieldProps('year', year)}
            />
          </Grid>
          <Grid item xs={3}></Grid>

          <Grid item className={classes.adjustedSpacingViewBy} xs={3}>
            <WaveSelectField
              noValidation
              label="Group By"
              menuItems={viewByOptions}
              {...this.makeFieldProps('view_by', view_by)}
            />
          </Grid>
          <Grid item className={classes.adjustedSpacingEngagement} xs={3}>
            <WaveSelectField
              noValidation
              label="Group Calculation"
              menuItems={registrationModeOptions}
              tooltipProps={{
                placement: "top",
                titleComponent: <Typography className={classes.tooltipText}>
                  {groupCalculationTooltipTitle}
                </Typography>,
                tooltipContainer: classes.tooltipContainer,
                tooltipPopper: classes.popper
              }}
              {...this.makeFieldProps('mode', mode)}
            />
          </Grid>
          <Grid item className={classes.adjustedSpacingUserStatus} xs={3}>
            <WaveSelectField
              filterByAllItem
              filterByAllItemLabel="All"
              noValidation
              label="User Status"
              menuItems={userStatusOptions}
              {...this.makeFieldProps('user_status', user_status)}
            />
          </Grid>

        </Grid>
      </Fragment>
    );
  }

}

export default withStyles(ProgramsMonthlyFilters, styles, { withTheme: true });
