import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';
import format from 'date-fns/format';

import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import Chisholm from '../../../common/config/Chisholm';
import StyledDivider from '../../../common/components/misc/StyledDivider';
import ValueDate from '../../../common/components/table/ValueDate';

const styles = (theme) => ({
  info: {
    color: theme.typography.color.primary,
    fontSize: theme.typography.pxToRem(13),
    fontFamily: 'IBM Plex Sans, light',
    fontWeight: 300
  },
  menuItem: {
    height: 'auto',
    padding: `${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(25)}`
  },
  profileName: {
    color: theme.typography.color.primary,
    fontSize: theme.typography.pxToRem(16)
  },
  status: {
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '2px',
    color: theme.palette.secondary.contrastText,
    fontSize: theme.typography.pxToRem(13),
    fontFamily: 'IBM Plex Sans, medium',
    fontWeight: 500,
    marginTop: theme.typography.pxToRem(10),
    padding: `${theme.typography.pxToRem(5)} ${theme.typography.pxToRem(8)}`,
    textTransform: 'uppercase',
    width: 'fit-content'
  }
});

class ProfileListItem extends React.Component {
  getDetails = (statusText, statusBackground, user) => {
    var details = {
      statusText: statusText,
      statusBackground: statusBackground,
      user: user
    };
    return details;
  };

  getCompletionStatus = (profile) => {
    var profileDetails,
      userCompleted = '';
    if (
      profile.supervisor_completed_percent > 0 &&
      profile.supervisor_completed === false
    ) {
      profileDetails = this.getDetails(
        'In Progress for Supervisor',
        '#4a90e2',
        'Educator'
      );
    } else if (profile.educator_completed === false) {
      profileDetails = this.getDetails(
        'In Progress with Educator',
        '#444444',
        'Educator'
      );
    } else if (profile.completed) {
      profileDetails = this.getDetails('', '', '');
    } else if (
      profile.supervisor_completed_percent === 0 &&
      profile.supervisor_completed === false
    ) {
      profileDetails = this.getDetails(
        'Waiting for Supervisor',
        '#f5a623',
        'Educator'
      );
    } else {
      profileDetails = this.getDetails('', '', '');
    }
    userCompleted =
      profileDetails.user === 'Educator'
        ? profile.educator_completed_at
        : profile.completed_at;

    return (
      <Fragment>
        {userCompleted && (
          <Typography className={this.props.classes.info}>
            {`${profileDetails.user} Completed:`}{' '}
            <ValueDate dateFormat="educatorProfile" value={userCompleted} />
          </Typography>
        )}
        {profileDetails.user === 'Educator' ? (
          <Typography
            className={this.props.classes.status}
            style={{ backgroundColor: `${profileDetails.statusBackground}` }}
          >
            {profileDetails.statusText}
          </Typography>
        ) : (
          ''
        )}
        <Typography className={this.props.classes.info}>
          {`${profile.educator_completed_count} responses`}
        </Typography>
      </Fragment>
    );
  };

  render() {
    const { classes, profileItem, selectedItem } = this.props;

    return (
      <Fragment>
        <MenuItem
          classes={{ root: classes.menuItem }}
          component="div"
          onClick={(event) =>
            this.props.handleProfileItemClick(event, profileItem)
          }
          selected={selectedItem === profileItem.id}
        >
          <Grid container direction="column">
            <Typography className={classes.profileName}>
              {profileItem.educator_full_name}
            </Typography>
            {this.getCompletionStatus(profileItem)}
          </Grid>
        </MenuItem>
        <StyledDivider />
      </Fragment>
    );
  }
}

export default withStyles(ProfileListItem, styles, { withTheme: true });
