import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import DomainsFilter from '../../../admin/components/institute/DomainsFilter';
import CapabilityPanel from '../../../admin/components/institute/CapabilityPanel';
import MetadataService from "../../../common/services/MetadataService";
import ApiService from "../../../common/services/ApiService";
import DomainForm from "../../../common/components/modal/DomainForm";
import AdminModal from '../../../common/components/modal/AdminModal';

const styles = theme => ({});

class Domains extends React.Component {

  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.meta = new MetadataService();
    this.state = {
      domains: [],
      domainIcon: null,
      domainSvgIcon: null,
      filter: {
        hideDeactivated: true
      }
    };
  }

  componentDidMount() {
    this.fetchDomains();
  }

  fetchDomains = () => {
    this.api.query('/api/v1/domains').then((response) => {
      this.setState({ domains: response.data });
    });
  };

  render() {
    const { id, mode, open, filter } = this.state;
    const fields = ['name', 'color', 'summary'];

    const capabilities = this.capabilityPanels();

    return (
      <Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DomainsFilter filter={filter} onChange={this.handleFilterChange} />
          </Grid>
          <Grid item xs={12}>
            {capabilities}
            <Button
              color="secondary"
              onClick={this.handleOpenAddModal}
              size="small"
              variant="contained"
            >
              <Typography variant="button">Create a Domain</Typography>
            </Button>
          </Grid>
        </Grid>
        <AdminModal
          component={DomainForm}
          fields={fields}
          id={id}
          mode={mode}
          onCloseModal={this.handleCloseModal}
          onForceRefresh={() => {}}
          open={open}
          resource="domains"
          title="Domain"
          onIconChange={this.handleIconChange}
          onSvgIconChange={this.handleSvgIconChange}
          callback={this.uploadIcons}
        />
      </Fragment>
    );
  }

  capabilityPanels = () => {
    const { domains, filter } = this.state;
    const domainsCount = domains.length;

    const filteredDomains = domains.filter(domain => filter.hideDeactivated ? domain.active : true );

    return filteredDomains.map((domain, index) => {
      return (
        <CapabilityPanel
          key={domain.id}
          domain={domain}
          domainsCount={domainsCount}
          index={index}
          onRefresh={this.fetchDomains}
          onMoveUp={this.moveUp}
          onMoveDown={this.moveDown}
          onEditDomain={this.handleOpenEditModal}
          filter={filter}
        />
      );
    });
  };

  handleFilterChange = (filter) => {
    this.setState({...this.state, filter});
  };

  moveUp = (domain) => {
    this.move(domain, true);
  };

  moveDown = (domain) => {
    this.move(domain, false);
  };

  move = (domain, directionUp) => {
    let domains = [...this.state.domains];
    const index = domains.findIndex(d => d.id === domain.id);
    const lower = (directionUp ? 1 : 0);
    const upper = (directionUp ? domains.length - 1 : domains.length - 2);
    if(index >= lower && index <= upper) {
      const otherIndex = index + (directionUp ? -1 : 1);
      const other = domains[otherIndex];
      domains[index] = other;
      domains[otherIndex] = domain;
      this.setState({domains: domains}, this.updateDisplayOrder);
    }
  };

  updateDisplayOrder = () => {
    var n = 1;
    const data = this.state.domains.map(domain => {
      return {id: domain.id, display_order: n++};
    });
    this.api.put('/api/v1/domains', { domains: data });
  };

  handleCloseModal = (save) => {
    if(save) {
      this.setState({ mode: null, open: false, id: null });
      this.fetchDomains();
    } else {
      this.setState({ mode: null, open: false, id: null });
    }
  };

  handleOpenAddModal = () => {
    this.setState({ mode: 'add', open: true, id: null });
  };

  handleOpenEditModal = (domain) => {
    this.setState({ mode: 'edit', open: true, id: domain.id });
  };

  handleIconChange = (icon) => {
    this.setState({ domainIcon: icon });
  };

  handleSvgIconChange = (svgIcon) => {
    this.setState({ domainSvgIcon: svgIcon });
  };

  uploadIcons = (domainId) => {
    Promise.all([this.uploadIcon(domainId), this.uploadSvgIcon(domainId)]).then(values => {
      console.log(values);
    });
  }

  uploadIcon = (domainId) => {
    if (this.state.domainIcon != null) {
      const url = '/api/v1/domains/' + domainId + '/icon';
      return this.api.upload(url, this.state.domainIcon, 'domain[icon]')
        .then((_response) => {
          this.setState({ mode: null, open: false, id: null });
          this.fetchDomains();
        })
        .catch((ex) => console.error(ex));
    }
    return null;
  };

  uploadSvgIcon = (domainId) => {
    if (this.state.domainSvgIcon != null) {
      const url = '/api/v1/domains/' + domainId + '/svg_icon';
      return this.api.upload(url, this.state.domainSvgIcon, 'domain[svg_icon]')
        .then((_response) => {
          this.setState({ mode: null, open: false, id: null });
          this.fetchDomains();
        })
        .catch((ex) => console.error(ex));
    }
    return null;
  };
}

export default withStyles(Domains, styles, { withTheme: true });
